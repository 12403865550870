import { Injectable } from "@angular/core";
import { Activity, ColumnSpecification, Form, Module, ModuleItem, Table, TableValueRow } from "../../model/experiment.interface";
import { ActivityInputType, AddChangeReasonCommand, ExperimentEventType, NodeType, SpecType, ValueState, ValueType } from "../../api/data-entry/models";
import { ActivityPrompt, FieldDefinitionResponse, FieldGroupResponse, FormItemType, PromptItem, PromptType } from "../../api/models";
import { last, startCase } from "lodash-es";
import { ChangeReasonConstants } from "../change-reason/change-reason-constants";
import { ExperimentService } from "./experiment.service";
import { NA } from "bpt-ui-library/shared";
import { DateAndInstantFormat, formatInstant, formatLocalDate } from "../../shared/date-time-helpers";
import { UnitLoaderService } from "../../services/unit-loader.service";
import { SpecificationService } from "../../shared/specification-input/specification.service";
import { ChangeReasonService } from "./change-reason.service";
import { AuditHistoryEventsService } from "../audit-history/audit-history-events.service";

@Injectable({
  providedIn: 'root'
})
export class ChangeReasonHelper {
  private changeReasonContextTitle = '';
  private get currentActivity() {
    return this.experimentService.currentActivity;
  }

  constructor(
    private readonly experimentService: ExperimentService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly specificationService: SpecificationService,
    private readonly auditHistoryEventsService: AuditHistoryEventsService
  ){}

  public setContextPath: { [key: string]: (changeDetails?: any) => string } = {
    [ExperimentEventType.FieldChanged]: this.setContextTitleForForm.bind(this),
    [ExperimentEventType.CellChanged]: this.setCellChangeContextPath.bind(this),
    [ExperimentEventType.RowRemoved]: this.setRowRemovedOrRestoredContextPath.bind(this),
    [ExperimentEventType.RowRestored]: this.setRowRemovedOrRestoredContextPath.bind(this),
    [ExperimentEventType.InstrumentEventNonRoutineIssueEncountered]: this.setContextTitleForNonRoutineIssue.bind(this),
    [ExperimentEventType.InstrumentEventReturnedToService]: this.setContextTitleForReturnedToService.bind(this),
    [ExperimentEventType.ActivityCrossReferenceChanged]: this.setCrossReferenceChangedContextPath.bind(this),
    [ExperimentEventType.ActivityCrossReferenceRemoved]: this.setCrossReferenceRemovedOrRestoredContextPath.bind(this),
    [ExperimentEventType.ActivityCrossReferenceRestored]: this.setCrossReferenceRemovedOrRestoredContextPath.bind(this),
    [ExperimentEventType.LabItemsCellChanged]: this.getContextTitleForLabItemsCellChange.bind(this),
    [ExperimentEventType.LabItemsConsumableRemoved]: this.getContextTitleForLabItemsRemoved.bind(this, ActivityInputType.Consumable),
    [ExperimentEventType.LabItemsConsumableRestored]: this.getContextTitleForLabItemsRestored.bind(this, ActivityInputType.Consumable),
    [ExperimentEventType.LabItemsMaterialRemoved]: this.getContextTitleForLabItemsRemoved.bind(this, ActivityInputType.Material),
    [ExperimentEventType.LabItemsMaterialRestored]: this.getContextTitleForLabItemsRestored.bind(this, ActivityInputType.Material),
    [ExperimentEventType.LabItemsInstrumentRemoved]: this.getContextTitleForLabItemsRemoved.bind(this, ActivityInputType.Instrument),
    [ExperimentEventType.LabItemsInstrumentRestored]: this.getContextTitleForLabItemsRestored.bind(this, ActivityInputType.Instrument),
    [ExperimentEventType.LabItemsInstrumentColumnRemoved]: this.getContextTitleForLabItemsRemoved.bind(this, ActivityInputType.InstrumentColumn),
    [ExperimentEventType.LabItemsInstrumentColumnRestored]: this.getContextTitleForLabItemsRestored.bind(this, ActivityInputType.InstrumentColumn),
    [ExperimentEventType.LabItemPreparationRemoved]: this.getContextTitleForLabItemsRemoved.bind(this, ActivityInputType.Preparation),
    [ExperimentEventType.LabItemPreparationRestored]: this.getContextTitleForLabItemsRestored.bind(this, ActivityInputType.Preparation),
    [ExperimentEventType.PromptSatisfied]: this.getContextTitleForPrompts.bind(this)
  }

  public setNewValue: { [key: string]: (changeDetails: any) => string } = {
    [ExperimentEventType.FieldChanged]: this.setNewValueForFormField.bind(this),
    [ExperimentEventType.CellChanged]: this.setNewValueForTableCell.bind(this),
    [ExperimentEventType.RowRemoved]: this.setEmptyValue.bind(this),
    [ExperimentEventType.RowRestored]: this.setEmptyValue.bind(this),
    [ExperimentEventType.InstrumentEventNonRoutineIssueEncountered]: this.setNewValueForNonRoutineIssue.bind(this),
    [ExperimentEventType.InstrumentEventReturnedToService]: this.setNewValueForReturnedToService.bind(this),
    [ExperimentEventType.ActivityCrossReferenceChanged]: this.setNewValueForCrossReferenceChanged.bind(this),
    [ExperimentEventType.ActivityCrossReferenceRemoved]: this.setEmptyValue.bind(this),
    [ExperimentEventType.ActivityCrossReferenceRestored]: this.setEmptyValue.bind(this),
    [ExperimentEventType.LabItemsCellChanged]: this.setNewValueForLabItemField.bind(this),
    [ExperimentEventType.LabItemsConsumableRemoved]: () => '',
    [ExperimentEventType.LabItemsConsumableRestored]: () => '',
    [ExperimentEventType.LabItemsMaterialRemoved]: () => '',
    [ExperimentEventType.LabItemsMaterialRestored]: () => '',
    [ExperimentEventType.LabItemsInstrumentRemoved]: () => '',
    [ExperimentEventType.LabItemsInstrumentRestored]: () => '',
    [ExperimentEventType.LabItemsInstrumentColumnRemoved]: () => '',
    [ExperimentEventType.LabItemsInstrumentColumnRestored]: () => '',
    [ExperimentEventType.LabItemPreparationRemoved]: () => '',
    [ExperimentEventType.LabItemPreparationRestored]: () => '',
    [ExperimentEventType.PromptSatisfied]: () => ''
  }

  public getNodeId(changeDetails: any, operationType: string) {
    switch(operationType) {
      case ExperimentEventType.CellChanged: {
        return changeDetails.tableIds[0];
      }
      case ExperimentEventType.RowRemoved:
      case ExperimentEventType.RowRestored: {
        return changeDetails.tableId;
      }
      case ExperimentEventType.FieldChanged: {
        return changeDetails.formId;
      }
      default: {
        return this.currentActivity?.activityId;
      }
    }
  }

  public setOldValue() {
    if (ChangeReasonService.oldValue.type === ValueType.Instant) {
      ChangeReasonService.oldValue = formatInstant(ChangeReasonService.oldValue.value, DateAndInstantFormat.dateTimeToSecond);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.Specification) {
      this.getSpecificationValue(ChangeReasonService.oldValue);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.LocalDate) {
      ChangeReasonService.oldValue = formatLocalDate(ChangeReasonService.oldValue.value);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.Number) {
      const unit = this.unitLoaderService.allUnits.find((unit) => unit.id === ChangeReasonService.oldValue.unit)?.abbreviation;
      const value = `${ChangeReasonService.oldValue.value} ${unit}`;
      ChangeReasonService.oldValue = value;
    }
    else if (
      ChangeReasonService.oldValue.type === ValueType.String ||
      ChangeReasonService.oldValue.type === ValueType.StringArray ||
      ChangeReasonService.oldValue.type === ValueType.Html
    ) {
      ChangeReasonService.oldValue = ChangeReasonService.oldValue.value;
    }
    else {
      ChangeReasonService.oldValue = ChangeReasonService.oldValue.toString();
    }
  }

  public addChangeReasonNodeToCurrentExperiment(addChangeReasonCommand: AddChangeReasonCommand) {
    const activityChangeReasonNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    if(!activityChangeReasonNode) {
      const changeReasonId = addChangeReasonCommand.changeReasonId;
      this.experimentService.currentExperiment?.activityChangeReasonNodes?.push({
        _ts: 0,
        activityId: addChangeReasonCommand.activityId,
        changeReasons: [{
          changeReasonCategory: addChangeReasonCommand.changeReasonCategory,
          changeReasonDetails: addChangeReasonCommand.changeReasonDetails,
          changeReasonId: changeReasonId,
          index: 0,
          nodeId: addChangeReasonCommand.nodeId
        }],
        experimentId: this.experimentService.currentExperiment.id,
        id: '',
        itemType: NodeType.ChangeReason,
        changeReasonAvailabilityMapping: {
          changeReasonId: {
            isMapped: true,
            contextPath: []
          }
        }
      },
      );
      return;
    }
    if (activityChangeReasonNode.changeReasonAvailabilityMapping[addChangeReasonCommand.changeReasonId])
      activityChangeReasonNode.changeReasonAvailabilityMapping[addChangeReasonCommand.changeReasonId].isMapped = true;
    activityChangeReasonNode.changeReasons?.push({
      changeReasonCategory: addChangeReasonCommand.changeReasonCategory,
      changeReasonDetails: addChangeReasonCommand.changeReasonDetails,
      changeReasonId: addChangeReasonCommand.changeReasonId,
      nodeId: addChangeReasonCommand.nodeId,
      index: addChangeReasonCommand.index
    });
    this.auditHistoryEventsService.updateAuditHistoryDataSource.next();
  }

  public hideChangeReasonSlider() {
    this.experimentService.changeReasonSliderDisplayDetails.next({
      isVisible: false,
      oldValue: '',
      newValue: '',
      contextTitle: '',
      showCloseIcon: false,
      nodeId: '',
      showChangedValues: true
    });
  }

  private getContextTitleForLabItemsCellChange(changeDetails: any) {
    const type = this.getLabItemType(changeDetails.itemType);
    return `${this.currentActivity?.itemTitle} > Lab Items > ${type} > ${changeDetails.propertyName ?? ''}`;
  }

  private getContextTitleForLabItemsRemoved(labItemType: string, changeDetails: any) {
    const type = this.getLabItemType(labItemType);
    return `${this.currentActivity?.itemTitle} > Lab Item Removed > ${type} > ${changeDetails.itemReference}`;
  }

  private getContextTitleForLabItemsRestored(labItemType: string, changeDetails: any) {
    const type = this.getLabItemType(labItemType);
    return `${this.currentActivity?.itemTitle} > Lab Item Restored > ${type} > ${changeDetails.itemReference}`;
  }

  private getContextTitleForPrompts(changeDetails: any) {
    const prompt = this.getPrompt(changeDetails);
    const promptTypeTitles: { [key: string]: string } = {
      materials: $localize`:@@LabItemsMaterialsTableTitle:Materials`,
      preparations: $localize`:@@preparationModuleHeader:Preparations`,
      columns: $localize`:@@LabItemsColumnTitle:Columns`,
      instruments: $localize`:@@LabItemsInstrumentsTableTitle:Instruments`,
      consumablesAndSupplies: $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`,
    }
    const promptTitle = promptTypeTitles[prompt.type ?? PromptType.Invalid];
    return `${this.currentActivity?.itemTitle} > Lab Items > ${promptTitle} > prompts | ${ExperimentEventType.PromptSatisfied}`;
  }

  private getLabItemType(labItemType: string) {
    if(labItemType === ActivityInputType.Consumable) return 'Consumable and Supplies';
    if(labItemType === ActivityInputType.InstrumentColumn) return 'Instrument Column';
    return startCase(labItemType);
  }

  private getPrompt(changeDetails: any): PromptItem {
    const activityPrompts = this.experimentService.currentExperiment?.activityPrompts?.filter((activity: ActivityPrompt) => activity.activityId === changeDetails.activityId)[0].prompts;
    return activityPrompts?.filter(prompt => prompt.promptId === changeDetails.promptId)[0] as PromptItem;
  }

  //this section deals with all methods involved in setting new value to display the slider
  private setNewValueForTableCell(changeDetails: any): string {
    if (changeDetails.columnValues[0].propertyValue.state === ValueState.Set) {
      return this.getNewValue(changeDetails.columnValues[0].propertyValue);
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.columnValues[0].propertyValue.state);
    }
  }

  private setNewValueForFormField(changeDetails: any): string {
    if (changeDetails.newValue.state === ValueState.Set) {
      return this.getNewValue(changeDetails.newValue);
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.newValue.state);
    }
  }

  private setNewValueForLabItemField(changeDetails: any): string {
    if (changeDetails.propertyValue.state === ValueState.Set) {
      return this.getNewValue(changeDetails.propertyValue);
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.propertyValue.state);
    }
  }

  private getNewValue(changeDetails: any): string {
    if (changeDetails.type === ValueType.Instant) {
      return formatInstant(changeDetails.value, DateAndInstantFormat.dateTimeToSecond);
    }
    else if (changeDetails.type === ValueType.Specification) {
      return this.getSpecificationValue(changeDetails);
    }
    else if (changeDetails.type === ValueType.LocalDate) {
      return formatLocalDate(changeDetails.value);
    }
    else if (changeDetails.type === ValueType.StringArray) {
      return changeDetails.value.toString();
    }
    else if (changeDetails.type === ValueType.Number) {
      const unit = this.unitLoaderService.allUnits.find((unit) => unit.id === changeDetails.unit)?.abbreviation;
      return `${changeDetails.value} ${unit}`;
    }
    else {
      return changeDetails.value;
    }
  }

  private getSpecificationValue(changeDetails: any): string {
    if (changeDetails.specType === SpecType.SingleValue) {
      return (this.specificationService.getSingleValueDisplayString(changeDetails));
    }
    else if (changeDetails.specType === SpecType.SingleValueRange) {
      return (this.specificationService.getSingleValueRangeDisplayString(changeDetails));
    }
    else if (changeDetails.specType === SpecType.TwoValueRange) {
      return (this.specificationService.getTwoValueRangeDisplayString(changeDetails));
    }
    else
      return changeDetails.value?.value ?? changeDetails.value;
  }

  private setNewValueForNonRoutineIssue(changeDetails: any): string {
    if(changeDetails.nonRoutineIssueEncountered.state === ValueState.Set) {
      return changeDetails.nonRoutineIssueEncountered.value;
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.nonRoutineIssueEncountered.state);
    }
  }

  private setNewValueForReturnedToService(changeDetails: any): string {
    if(changeDetails.returnedToService.state === ValueState.Set) {
      return changeDetails.returnedToService.value;
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.returnedToService.state);
    }
  }

  private setNewValueForCrossReferenceChanged(changeDetails: any): string {
    if(changeDetails.propertyValue.state === ValueState.Set) {
      return changeDetails.propertyValue.value;
    }
    else {
      return this.setValueForEmptyOrNA(changeDetails.propertyValue.state);
    }
  }

  private setEmptyValue(): string {
    return '';
  }

  private setValueForEmptyOrNA(state: ValueState) {
    return state === ValueState.NotApplicable ? NA : '';
  }


  //the below section contains all methods pertaining to setting of context path for the slider
  private setCellChangeContextPath(changeDetails: any): string {
    const modules = this.currentActivity?.dataModules;
    if(this.currentActivity?.activityReferences.documentsTable || this.currentActivity?.activityReferences.compendiaTable) {
      const isDocumentTable = this.currentActivity.activityReferences.documentsTable && this.currentActivity.activityReferences.documentReferencesTableId === changeDetails.tableIds[0];
      if(isDocumentTable) {
        this.setDocumentsTableCellChangePath(changeDetails, this.currentActivity);
      }
      const isCompendiaTable = this.currentActivity.activityReferences.compendiaTable && this.currentActivity.activityReferences.compendiaReferencesTableId === changeDetails.tableIds[0];
      if(isCompendiaTable) {
        this.setCompendiaTableCellChangePath(changeDetails, this.currentActivity);
      }
    }
    else {
      this.setContextTitleForTable(changeDetails, modules, this.currentActivity);
    }
    return this.changeReasonContextTitle;
  }

  private setRowRemovedOrRestoredContextPath(changeDetails: any): string {
    const modules = this.currentActivity?.dataModules;
    if(this.currentActivity?.activityReferences.documentsTable || this.currentActivity?.activityReferences.compendiaTable) {
      const isDocumentTable = this.currentActivity.activityReferences.documentsTable && this.currentActivity.activityReferences.documentReferencesTableId === changeDetails.tableId;
      if(isDocumentTable) {
        this.setDocumentsTableRowContextPath(changeDetails, this.currentActivity);
      }
      const isCompendiaTable = this.currentActivity.activityReferences.compendiaTable && this.currentActivity.activityReferences.compendiaReferencesTableId === changeDetails.tableId;
      if(isCompendiaTable) {
        this.setCompendiaTableRowContextPath(changeDetails, this.currentActivity);
      }
    }
    else {
      this.setContextTitleForTable(changeDetails, modules, this.currentActivity);
    }
    return this.changeReasonContextTitle;
  }

  private setDocumentsTableCellChangePath(changeDetails?: any, currentActivity?: Activity | undefined): string {
    if (changeDetails.columnValues.length > 1 || changeDetails.rowIds.length > 1) {
      return this.getDocumentsTableContextPath(currentActivity);
    }
    else {
      const column = this.getColumnDetails(currentActivity?.activityReferences.documentsTable as Table, changeDetails.columnValues[0].propertyName);
      const rowNumber = this.getRowNumber(currentActivity?.activityReferences.documentsTable as Table, changeDetails.rowIds[0]);
      return `${this.getDocumentsTableContextPath(currentActivity)} > Row Number ${rowNumber} > ${column?.label}`;
    }
  }

  private setDocumentsTableRowContextPath(changeDetails?: any, currentActivity?: Activity | undefined): string {
    const rowNumber = this.getRowNumber(currentActivity?.activityReferences.documentsTable as Table, changeDetails.rowIds[0]);
    return `${this.getDocumentsTableContextPath(currentActivity)} > Row Number ${rowNumber}`;
  }

  private getDocumentsTableContextPath(currentActivity: Activity | undefined): string {
    return `${currentActivity?.itemTitle} > ${ChangeReasonConstants.ReferencesHeader} > ${ChangeReasonConstants.DocumentsTableHeader}`;
  }

  private setCompendiaTableCellChangePath(changeDetails?: any, currentActivity?: Activity | undefined): string {
    if (changeDetails.columnValues.length > 1 || changeDetails.rowIds.length > 1) {
      return this.getCompendiaTableContextPath(currentActivity);
    }
    else {
      const column = this.getColumnDetails(currentActivity?.activityReferences.compendiaTable as Table, changeDetails.columnValues[0].propertyName);
      const rowNumber = this.getRowNumber(currentActivity?.activityReferences.compendiaTable as Table, changeDetails.rowIds[0]);
      return `${this.getCompendiaTableContextPath(currentActivity)} > Row Number ${rowNumber} > ${column?.label}`;
    }
  }

  private setCompendiaTableRowContextPath(changeDetails?: any, currentActivity?: Activity | undefined): string {
    const rowNumber = this.getRowNumber(currentActivity?.activityReferences.compendiaTable as Table, changeDetails.rowIds[0]);
    return `${this.getDocumentsTableContextPath(currentActivity)} > Row Number ${rowNumber}`;
  }

  private getCompendiaTableContextPath(currentActivity: Activity | undefined): string {
    return `${currentActivity?.itemTitle} > ${ChangeReasonConstants.ReferencesHeader} > ${ChangeReasonConstants.CompendiaTableHeader}`;
  }

  private setContextTitleForTable(changeDetails: any, modules: Module[] | undefined, currentActivity: Activity | undefined) {
    const tableId = changeDetails.tableId ?? changeDetails.tableIds[0];
    modules?.forEach((module: Module) => module.items.forEach((item: ModuleItem) => {
      if('tableId' in item && item.tableId === tableId) {
        if(!changeDetails.hasOwnProperty('columnValues')) {
          this.getContextTitleForRows(item, changeDetails, currentActivity, module);
        }
        else {
          this.getContextTitleForCellValueChange(item, changeDetails, currentActivity, module);
        }
      }
    }));
  }

  private getContextTitleForCellValueChange(item: Table, changeDetails: any, currentActivity: Activity | undefined, module: Module) {
    if (changeDetails.columnValues.length > 1 || changeDetails.rowIds.length > 1) {
      this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle}`;
    }
    else {
      const column = this.getColumnDetails(item, changeDetails.columnValues[0].propertyName);
      const rowNumber = this.getRowNumber(item, changeDetails.rowIds[0]);
      this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > Row Number ${rowNumber} > ${column?.label}`;
    }
  }

  private getContextTitleForRows(item: Table, changeDetails: any, currentActivity: Activity | undefined, module: Module) {
    const rowNumber = this.getRowNumber(item, changeDetails.rowId);
    this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > Row Number ${rowNumber}`;
  }

  private getRowNumber(table: Table, rowId: string) {
    const index = table.value.findIndex((row: TableValueRow) => row.id === rowId);
    return (index + 1).toString();
  }

  private getColumnDetails(table: Table, fieldName: string) {
    return table.columnDefinitions.find((colDef: ColumnSpecification) => colDef.field === fieldName);
  }

  private setContextTitleForForm(changeDetails: any): string {
    const modules = this.currentActivity?.dataModules;
    modules?.forEach((module: Module) => {
      module.items.forEach((item: ModuleItem) => {
        if('formId' in item && item.formId === changeDetails.formId) {
          const fieldName = this.getFieldName(item, last(changeDetails.path) ?? '');
          this.changeReasonContextTitle = `${this.currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > ${fieldName}`;
        }
      })
    });
    return this.changeReasonContextTitle;
  }

  private getFieldName(form: Form, fieldName: string): string {
    for(const formItem of form.fieldDefinitions) {
      if(formItem.itemType === FormItemType.Field) {
        if(formItem.field === fieldName) {
          return (formItem as FieldDefinitionResponse).label;
        }
      }
      else {
        return this.getFieldNameFromFieldGroup(formItem as FieldGroupResponse, fieldName);
      }
    }
    return '';
  }

  private getFieldNameFromFieldGroup(formItem: FieldGroupResponse, fieldName: string): string {
    for(const item of formItem.fieldDefinitions) {
      if(item.itemType === FormItemType.Field) {
        if(item.field === fieldName) {
          return (item as FieldDefinitionResponse).label;
        }
      }
      else {
        return this.getFieldNameFromFieldGroup(item as FieldGroupResponse, fieldName);
      }
    }
    return '';
  }

  private setContextTitleForNonRoutineIssue(): string {
    return `${this.currentActivity?.itemTitle} > ${ChangeReasonConstants.OutputsHeader} > ${ChangeReasonConstants.ImpactAssessmentHeader} > ${ChangeReasonConstants.NonRoutineIssueHeader}`;
  }

  private setContextTitleForReturnedToService(): string {
    return `${this.currentActivity?.itemTitle} > ${ChangeReasonConstants.OutputsHeader} > ${ChangeReasonConstants.ImpactAssessmentHeader} > ${ChangeReasonConstants.ReturnToServiceHeader}`;
  }

  private setCrossReferenceChangedContextPath(changeDetails: any): string {
    const rowNumber = this.currentActivity?.activityReferences.crossReferences.findIndex(cr => cr.id === changeDetails.crossReferenceId) ?? -1;
    return `${this.currentActivity?.itemTitle} > ${ChangeReasonConstants.ReferencesHeader} > ${ChangeReasonConstants.CrossReferencesHeader} > Row Number ${rowNumber + 1} > ${ChangeReasonConstants.PurposeColumnHeader}`;
  }

  private setCrossReferenceRemovedOrRestoredContextPath(changeDetails: any): string {
    const rowNumber = this.currentActivity?.activityReferences.crossReferences.findIndex(cr => cr.id === changeDetails.crossReferenceId) ?? -1;
    return `${this.currentActivity?.itemTitle} > ${ChangeReasonConstants.ReferencesHeader} > ${ChangeReasonConstants.CrossReferencesHeader} > Row Number ${rowNumber + 1}`;
  }
}