<div *ngIf="overlayVisibleBalance" class="overlay-backdrop"></div>
<div
  class="eln-field"
  [id]="id"
  [title]="title"
  #container
  [style.width]="autoResize ? '100%' : fieldDefinition.widthPct + '%'"
  (dblclick)="openBalanceModal($event)"
>
  <ng-container *ngIf="fieldDefinition">
    <bpt-progress-spinner
      *ngIf="isHistoryLoading"
      i18n-message="@@loadingHistory"
      message="History Loading..."
      [useOverlay]="true"
    ></bpt-progress-spinner>

    <bpt-rich-text-editor
      #bptTextblock
      class="eln-readonlyEditor"
      *ngIf="fieldType === 'textblock'"
      [ngModel]="label"
      [readOnly]="true"
      [disabled]="disabled"
      [expandByDefault]="false"
      (mousedown)="$event.stopPropagation()"
    ></bpt-rich-text-editor>

    <div class="specificationInputWrapper">
      <bpt-text-input
        #specInput
        *ngIf="fieldType === 'specification'"
        type="specification"
        [cancelValueOnEscape]=true
        [commitValueOnEnter]=true
        lastCommittedValue="{{primitiveValue}}"
        [ngModel]="primitiveValue"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="modelEdited($event)"
        [label]="label"
        inputId="eln-field-definition-spec"
        [contextMenu]="getContextMenu()"
        [placeholder]="labelText"
        [style.width.%]="fieldDefinition.widthPct"
        (textInputKeyDown)="handleKeyDown($event)"
        (textInputFocus)="onFocus($event)"
        (textInputBlur)="onBlur($event)"
        [autoResize]="autoResize ?? true"
        [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
        [suppressContextMenu]="false"
        [tabIndex]="
          fieldDefinition.fieldAttributes.tabOrder
            ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
            : fieldDefinition.fieldAttributes.tabOrder
        "
        [bottomRightFlagEnabled]="InternalFlagEnabled"
        [isBottomRightFlagHollow]="isBottomFlagHollow"
        [bottomRightFlagColor]="internalCommentsFlagColor"
        [bottomRightFlagHoverText]="InternalCommentsHoverText"
        (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
        [topRightFlagEnabled]="noteFlagEnabled"
        [topRightFlagColor]="clientFacingNoteFlagColor"
        [topRightFlagHoverText]="noteHoverText"
        (topRightFlagClick)="noteFlagClicked($event)"
        (dblclick)="toggleSpecificationSlider($event)"
        (mousedown)="$event.stopPropagation()"
        (copy)="copySpec($event)"
        (paste)="pasteSpec($event)"
        [formatNaToUpperCase]="true"
      ></bpt-text-input>
    </div>

    <bpt-text-input
      #bptTextbox
      *ngIf="fieldType === 'textbox'"
      type="text"
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      [ngModel]="primitiveValue"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="modelEdited($event)"
      [label]="label"
      [disabled]="disabled"
      [readOnly]="isFieldReadOnly && !disabled"
      inputId="eln-field-definition-text"
      [contextMenu]="getContextMenu()"
      [placeholder]="labelText"
      [style.width.%]="fieldDefinition.widthPct"
      (textInputKeyDown)="handleKeyDown($event)"
      (textInputFocus)="onFocus($event)"
      (textInputBlur)="onBlur($event)"
      [autoResize]="autoResize ?? true"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [suppressContextMenu]="isOnOutputsPage"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="noteFlagEnabled"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
    ></bpt-text-input>

    <!-- Not filtering to just blur because it doesn't really apply to user interactions with a checkbox -->
    <p-checkbox
      *ngIf="fieldType === 'checkbox'"
      [(ngModel)]="value"
      (ngModelChange)="modelEdited($event)"
      [binary]="true"
      [disabled]="disabled || isFieldReadOnly"
      [ngClass]="{ 'bpt-readonly': isFieldReadOnly && !disabled }"
      [label]="label"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
    ></p-checkbox>

    <bpt-dropdown
      #list
      *ngIf="fieldType === 'list'"
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      [ngModel]="primitiveValue"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="modelEdited($event)"
      [options]="listValues ?? []"
      [label]="label"
      [readOnly]="isFieldReadOnly && !disabled"
      [disabled]="disabled"
      inputId="eln-field-definition-list"
      [contextMenu]="getContextMenu()"
      [style.width.%]="fieldDefinition.widthPct"
      (dropdownFocus)="onFocus($event)"
      (dropdownBlur)="onBlur($event)"
      (dropdownShow)="handleEvent($event)"
      (dropdownHide)="handleHideEvent($event)"
      (keyUp)="handleKeyDown($event)"
      [showClear]="true"
      [multiSelect]="allowMultiSelect ?? false"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [suppressContextMenu]="false"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="note ? true : false"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
    ></bpt-dropdown>

    <bpt-dropdown
      #editableList
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      *ngIf="fieldType === 'editableList'"
      inputId="eln-field-definition-editableList"
      [ngModel]="primitiveValue"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="modelEdited($event)"
      [options]="listValues ?? []"
      [label]="label"
      labelField="label"
      valueField="value"
      [allowNA]="fieldDefinition.fieldAttributes.allowNA ?? false"
      [editable]="fieldDefinition.fieldAttributes.allowCustomOptionsForDropdown ?? false"
      [readOnly]="isFieldReadOnly && !disabled"
      [disabled]="disabled"
      [contextMenu]="getContextMenu()"
      [style.width.%]="fieldDefinition.widthPct"
      [showClear]="true"
      [showHeader]="true"
      [showToggleAll]="true"
      (dropdownFocus)="onFocus($event)"
      (dropdownBlur)="onBlur($event)"
      (dropdownShow)="handleEvent($event)"
      (dropdownHide)="handleHideEvent($event)"
      (keyUp)="handleKeyDown($event)"
      [multiSelect]="allowMultiSelect ?? false"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [suppressContextMenu]="false"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="note ? true : false"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
    ></bpt-dropdown>

    <!-- Regarding autoResize... -->
    <!-- when width type is fixed the user cannot resize the textarea -->
    <!-- the textarea is a special case. -->
    <bpt-text-input
      #bptInputArea
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      *ngIf="fieldType === 'textarea'"
      [multiLine]="true"
      [autoResize]="!(autoResize ?? false)"
      [ngModel]="primitiveValue"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="modelEdited($event)"
      [label]="label"
      inputId="eln-field-text-area"
      [readOnly]="isFieldReadOnly && !disabled"
      [disabled]="disabled"
      [contextMenu]="getContextMenu()"
      [minlength]="minCharLength ?? 0"
      [maxlength]="maxCharLength ?? defaultMaxLength"
      [style.width.%]="fieldDefinition.widthPct"
      [style.height.px]="fieldDefinition.heightPct"
      (textInputKeyDown)="handleKeyDown($event)"
      (textInputFocus)="onFocus($event)"
      (textInputBlur)="onBlur($event)"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [suppressContextMenu]="false"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="note ? true : false"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
      [formatNaToUpperCase]="true"
    ></bpt-text-input>

    <bpt-text-input
    #bptInputEntry
    *ngIf="fieldType === 'textentry'"
    [cancelValueOnEscape]=true
    [commitValueOnEnter]=true
    lastCommittedValue="{{primitiveValue}}"
    [multiLine]="false"
    [autoResize]="!(autoResize ?? false)"
    [ngModel]="primitiveValue"
    [ngModelOptions]="{ updateOn: 'blur' }"
    (ngModelChange)="modelEdited($event)"
    [label]="label"
    inputId="eln-field-text-entry"
    [readOnly]="isFieldReadOnly && !disabled"
    [disabled]="disabled"
    [contextMenu]="getContextMenu()"
    [minlength]="minCharLength ?? 0"
    [maxlength]="maxCharLength ?? defaultMaxLength"
    [style.width.%]="fieldDefinition.widthPct"
    [style.height.px]="fieldDefinition.heightPct"
    (textInputKeyDown)="handleKeyDown($event)"
    (textInputFocus)="onFocus($event)"
    (textInputBlur)="onBlur($event)"
    [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
    [suppressContextMenu]="false"
    [tabIndex]="
      fieldDefinition.fieldAttributes.tabOrder
        ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
        : fieldDefinition.fieldAttributes.tabOrder
    "
    [bottomRightFlagEnabled]="InternalFlagEnabled"
    [bottomRightFlagColor]="internalCommentsFlagColor"
    [bottomRightFlagHoverText]="InternalCommentsHoverText"
    (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
    [topRightFlagEnabled]="note ? true : false"
    [isBottomRightFlagHollow]="isBottomFlagHollow"
    [topRightFlagColor]="clientFacingNoteFlagColor"
    [topRightFlagHoverText]="noteHoverText"
    (topRightFlagClick)="noteFlagClicked($event)"
    (mousedown)="$event.stopPropagation()"
    [formatNaToUpperCase]="true"
      ></bpt-text-input>

    <bpt-datetime
      #dateTime
      *ngIf="fieldType === 'datepicker'"
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      [ngModel]="primitiveValue"
      [useJsJodaTypes]="true"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="modelEdited($event)"
      [hourFormat]="'12'"
      [label]="label"
      [readOnly]="isFieldReadOnly && !disabled"
      [disabled]="disabled"
      [contextMenu]="getContextMenu()"
      [maxDate]="maxDate ?? ''"
      [minDate]="minDate ?? ''"
      [includeTime]="allowTimeSelect ?? false"
      [showButtonBar]="fieldDefinition.fieldAttributes.showButtonBar"
      selectionMode="single"
      [style.width.%]="fieldDefinition.widthPct"
      [allowNA]="allowNA ?? true"
      (datetimeFocus)="onFocus($event)"
      (datetimeBlur)="onBlur($event)"
      (datetimeShow)="handleEvent($event)"
      (datetimeHide)="handleHideEvent($event)"
      (keyUp)="handleKeyDown($event)"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [suppressContextMenu]="false"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="note ? true : false"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
      [ianaTimeZone]="labSiteTimeZone"
    ></bpt-datetime>

    <bpt-quantity
      #quantityInput
      [cancelValueOnEscape]=true
      [commitValueOnEnter]=true
      lastCommittedValue="{{primitiveValue}}"
      *ngIf="fieldType === 'quantity' && allowedUnits"
      ngDefaultControl

      inputId="eln-field-input-quantity"
      [allowedUnits]="allowedUnits"
      [ngModel]="primitiveValue"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (quantityChange)="quantityEdited($event)"
      [disable]="disabled"
      [readOnly]="isFieldReadOnly && !disabled"
      [allowNA]="allowNA ?? true"
      [allowNegative]="allowNegative ?? true"
      [allowDecimal]="allowDecimal ?? true"
      [minValue]="minNumericValue"
      [maxValue]="maxNumericValue"
      [highlightAllOnFocus]="false"
      (quantityFocus)="onFocus($event)"
      (quantityBlur)="onBlur($event)"
      (quantityChange)="handleEvent($event)"
      (blur)="handleEvent($event)"
      [label]="label"
      [contextMenu]="getContextMenu()"
      [style.width.%]="fieldDefinition.widthPct"
      [suppressContextMenu]="false"
      [onlyAllowUnit]="onlyAllowUnits"
      [controlCustomDefinitionValidator]="controlCustomDefinitionValidator"
      [enableSignificantFigures]="enableSignificantFigures ?? true"
      [tabIndex]="
        fieldDefinition.fieldAttributes.tabOrder
          ? greatestTabOrder + fieldDefinition.fieldAttributes.tabOrder
          : fieldDefinition.fieldAttributes.tabOrder
      "
      [bottomRightFlagEnabled]="InternalFlagEnabled"
      [bottomRightFlagColor]="internalCommentsFlagColor"
      [bottomRightFlagHoverText]="InternalCommentsHoverText"
      (bottomRightFlagClick)="internalCommentsFlagClicked($event)"
      [topRightFlagEnabled]="note ? true : false"
      [isBottomRightFlagHollow]="isBottomFlagHollow"
      [topRightFlagColor]="clientFacingNoteFlagColor"
      [topRightFlagHoverText]="noteHoverText"
      (topRightFlagClick)="noteFlagClicked($event)"
      (mousedown)="$event.stopPropagation()"
    ></bpt-quantity>
  </ng-container>
</div>

<p-overlayPanel #overlayPanel [dismissable]="false">
  <app-instrument-balance-reading
    *ngIf="overlayVisibleBalance"
    (closeEvent)="closeOverlay()"
    (actualValueEvent)="onInstrumentReadingsReceived($event)"
    (unitEvent)="onUnitChange($event)"
  >
  </app-instrument-balance-reading>
</p-overlayPanel>
