import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { firstValueFrom, forkJoin, map, Observable, Subject } from 'rxjs';
import { DateAndInstantFormat, formatInstant, formatLocalDate } from '../../shared/date-time-helpers';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { UserService } from '../../api/services/user.service';
import { UserService as CurrentUserService } from 'services/user.service';
import { WorkflowEventNotification } from '../../api/data-entry/models/workflow-event-notification';
import {
  ActivityInputType,
  ActivityLabItemsNode,
  ActivityPrompt,
  ChangeReason,
  ClientFacingNote,
  ColumnType,
  Consumable,
  ExperimentPreparation,
  ExperimentResponse,
  ExperimentWorkflowState,
  FieldDefinitionResponse,
  FieldGroupResponse,
  Labsite,
  ModifiableDataValue,
  NotificationDetails,
  NotificationResult,
  NumberValue,
  PromptItem,
  TemplateType,
  Unit,
  User,
  ValueState,
} from '../../api/models';
import {
  ActivityInputCellChangedEventNotification,
  ActivityInputRowRefreshedEventNotification,
  ActivityInputRowRemovedEventNotification,
  ActivityInputRowRestoredEventNotification,
  ActivityPreparationRemovedNotification,
  ActivityPreparationRestoredNotification,
  AddRowEventNotification,
  AliquotAddedEventNotification,
  AliquotTest,
  AssignedAnalystsChangedEventNotification,
  AssignedReviewersChangedEventNotification,
  AssignedSupervisorsChangedEventNotification,
  AttachedFileEventNotification,
  AuthorizationDueDateChangedEventNotification,
  CellChangedEventNotification,
  ChromatographyDataImportedEventNotification,
  ChromatographyDataRefreshedEventNotification,
  ChromatographyDataRemovedEventNotification,
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteContextType,
  ClientFacingNoteCreatedEventNotification,
  CrossReferenceAddedEventNotification,
  CrossReferenceChangedEventNotification,
  CrossReferenceRemovedEventNotification,
  CrossReferenceRestoredEventNotification,
  CrossReferenceType,
  DeletedFilesEventNotification,
  ExperimentAuthorizedEventNotification,
  ExperimentCancelledEventNotification,
  ExperimentClientsChangedEventNotification,
  ExperimentCreatedEventNotification,
  ExperimentDataRecordNotification,
  ExperimentDataValue,
  ExperimentEventType,
  ExperimentNodeOrderChangedNotification,
  ExperimentNodeTitleChangedNotification,
  ExperimentPreparationStatusChangedNotification,
  ExperimentProjectsChangedEventNotification,
  ExperimentRecipeAppliedEventNotification,
  ExperimentSentForCorrectionEventNotification,
  ExperimentSentForReviewEventNotification,
  ExperimentStartedEventNotification,
  ExperimentTemplateAppliedEventNotification,
  FieldChangedEventNotification,
  InstrumentAddedEventNotification,
  InstrumentDateRemovedChangedEventNotification,
  InstrumentDescriptionChangedEventNotification,
  InstrumentEventExceptionReferenceChangedEventNotification,
  InstrumentEventJustificationChangedEventNotification,
  InstrumentReadingValue,
  InstrumentRemovedFromServiceChangedEventNotification,
  LabItemPreparationRemovedEventNotification,
  LabItemPreparationRestoredEventNotification,
  LabItemsCellChangedEventNotification,
  LabItemsConsumableAddedNotification,
  LabItemsConsumableRemovedEventNotification,
  LabItemsConsumableRestoredEventNotification,
  LabItemsInstrumentColumnRefreshedNotification,
  LabItemsInstrumentColumnRemovedEventNotification,
  LabItemsInstrumentColumnRestoredEventNotification,
  LabItemsInstrumentRefreshedNotification,
  LabItemsInstrumentRemovedEventNotification,
  LabItemsInstrumentRestoredEventNotification,
  LabItemsMaterialRefreshedNotification,
  LabItemsMaterialRemovedEventNotification,
  LabItemsMaterialRestoredEventNotification,
  LabItemsPreparationRefreshedNotification,
  MaintenanceEventSelectedEventNotification,
  MarkDataPackageGeneratedEventNotification,
  MaterialAddedEventNotification,
  MaterialAddedNotification,
  NodeType,
  NonRoutineIssueEncounteredDataChangeEventNotification,
  ObservationSpec,
  PreparationCellChangedNotification,
  PreparationDiscardedOrConsumedNotification,
  PreparationInternalInformationChangedNotification,
  PromptRemovedEventNotification,
  PromptRestoredEventNotification,
  PromptSatisfiedEventNotification,
  ReferenceTemplateAppliedEventNotification,
  RenumberRowsCommand,
  ReturnToServiceDataChangeEventNotification,
  RowRemovedEventNotification,
  RowRestoredEventNotification,
  RowsAddedForEachEventNotification,
  RowsAddedForEachTableDetails,
  RowsRenumberedEventNotification,
  RowsRenumberedResponse,
  SampleTestAddedEventNotification,
  ScheduledReviewStartDateChangedEventNotification,
  ScheduledStartDateChangedEventNotification,
  SingleValueSpec,
  SpecComplianceAssessorType,
  SpecificationValue,
  SpecType,
  StatementAppliedEventNotification,
  StatementContentDetails,
  StatementContextType,
  StringTypeDictionaryValue,
  StringValue,
  StudyActivitySelectedEventNotification,
  SubBusinessUnitsChangedEventNotification,
  TableCell,
  TableRow,
  TagsChangedEventNotification,
  TitleChangedEventNotification,
  TwoValueRangeSpec,
  ValueType,
} from '../../api/data-entry/models';
import { AuditHistory } from './audit-history.interface';
import { ExperimentService } from './experiment.service';
import { LabsiteService } from '../../api/services';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import { ELNAppConstants } from '../../shared/eln-app-constants';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { clone, difference, first, last, reverse, camelCase } from 'lodash-es';
import { UnitLoaderService } from 'services/unit-loader.service';
import { DataValue, DataValueService } from './data-value.service';
import { Activity, Experiment, Form, Module, ModuleItem, Table, TableValueRow } from '../../model/experiment.interface';
import { ConditionType, DataType, SearchCriteria, StringMatchType } from '../../api/search/models';
import { BookshelfService } from '../../api/search/services';
import { environment } from '../../../environments/environment';
import { LabItemsConsumablesTableOptions } from '../labItems/consumables/lab-items-consumable-table-options';
import { CrossReferencesColumns } from '../references/cross-references/cross-references.component';
import { LabItemsMaterialTableOptions } from '../labItems/materials/lab-items-material/lab-items-material-table-options';
import { LabItemsInstrumentTableOptions } from '../labItems/instruments/lab-items-instrument/lab-items-instrument-table-options';
import { ClientFacingNoteModel } from '../comments/client-facing-note/client-facing-note.model';
import { SpecificationService } from '../../shared/specification-input/specification.service';
import { LabItemsColumnTableOptions } from '../labItems/columns/lab-items-column/lab-items-column-table-options';
import { OutputEmpowerService } from './output-empower.service';
import { SampleTableGridOptions } from '../inputs/sample-table/sample-table-grid-options';
import { ExperimentPreparationsCreatedNotification } from '../model/preparations/experiment-preparation-created-notification';
import { ExperimentNotificationOnlyResponse } from '../model/experiment-notification-only-response.model';
import { Message, MessageService } from 'primeng/api';
import { Instant } from '@js-joda/core';
import { ExperimentRecordTypesHelper } from './experiment-data-record-types-helper';
import { BlowerState } from '../model/instrument-connection/blower-state';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { PhMeterMode } from '../model/instrument-connection/ph-meter-modes';
import { PreparationConstants } from '../../preparation/preparation-constants';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import { LabItemPreparationAddedEventNotification } from '../../api/data-entry/models/lab-item-preparation-added-event-notification';
import { ReferencesService as ActivityReferencesService } from '../references/references.service';
import { PreparationTableOptions } from '../../preparation/preparation-table-options';
import { objectCache } from '../../shared/rx-js-helpers';
import { ExperimentRecordType } from '../../api/audit/models/experiment-record-type';
import { StudyActivityTableGridOptions } from '../inputs/study-activity-table/study-activity-table-grid-options';
import { PromptType } from '../../api/cookbook/models';
import { ChangeReasonContext } from '../../model/change-reason-context';
import { TableDataService } from '../data/table/table-data.service';
import { DataRecordUtilityService } from './data-record-utility.service';
import { RecipeDataRecordService } from './reicpe-data-record.service';
import { TableService } from '../../api/data-entry/services';
import { ChangeReasonConstants } from '../change-reason/change-reason-constants';

const caseNotHandledEvenThoughPlanned = 'LOGIC ERROR: case not handled even though planned.';
const rowNumberText = $localize`:@@RowNumber:Row Number`;
const activityInputType = 'activityInputType';

/** TODO this will be removed once the server side event is exposed. */
export type SetVariableEventNotification = ExperimentDataRecordNotification & {
  nodeId: string;
  value: ModifiableDataValue;
  name: string;
};

interface CellUpdateHistory {
  [cellKey: string]: string[];
}

/**
 * Union type for readability
 */
type AssignmentEventNotifications =
  ExperimentCreatedEventNotification |
  SubBusinessUnitsChangedEventNotification | AssignedSupervisorsChangedEventNotification |
  AssignedAnalystsChangedEventNotification | AssignedReviewersChangedEventNotification;

/**
 * Defines a type for the subtype of ExperimentDataRecordNotification matching a given value for the nested discriminant eventContext.eventType
 * Ref: https://github.com/microsoft/TypeScript/issues/18758#issuecomment-1172487806
 *      https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 */
type GetTypeForEventType<
  ExperimentEventType,
  TEvent = ExperimentDataRecordNotification
> = TEvent extends { eventContext: { eventType: ExperimentEventType } } ? TEvent : never;

/**
 * Type predicate that narrows ExperimentDataRecordNotification to the subtype with the given value for the nested discriminant eventContext.eventType
 * @example
 * ```
 * if (isEventOfType(event, ExperimentEventType.FieldChanged)) {
 *   functionThatRequiresAParameterOfType_FieldChangedEventNotification(event);
 * }
 * ```
 */
export const isEventOfType = <
  ExperimentEventType extends ExperimentDataRecordNotification['eventContext']['eventType']
>(
  event: ExperimentDataRecordNotification,
  eventType: ExperimentEventType
): event is GetTypeForEventType<ExperimentEventType> => event.eventContext.eventType === eventType;

/**
 * Provides two functions regard DataRecordEventNotification objects
 *   * Routing for collaborative editing watching
 *   * Projecting events into the History presentation
 */
@Injectable({
  providedIn: 'root'
})
export class DataRecordService {
  private allModuleItemsInExperiment: Array<ModuleItem> = [];
  private allModulesInExperiment: Array<Module> = [];

  get experiment(): Experiment {
    /*
      Tests break this rule so skipping until they can be changed.
      if (!this.experimentService.currentExperiment) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperiment as Experiment;
  }

  /**
   * @deprecated ExperimentResponse is not the current state of the Experiment. Experiment is.
   * We can sometimes get away with this. But don't count always reloading the experiment to get this refreshed.
   */
  get experimentResponse(): ExperimentResponse | undefined {
    /*
      Tests break this rule so skipping until they can be changed.
      if (!this.experimentService.currentExperimentResponse) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperimentResponse;
  }

  usersList!: User[];
  labsites!: Array<Labsite>;
  /** Dictionary of experimentNumber keyed by experimentId. This data is safe to cache forever. */
  experimentNumberCache: { [key: string]: string } = {};
  /** Dictionary of activityNumber keyed by activityId. This data is safe to cache forever. */
  activityNumberCache: { [key: string]: string | undefined } = {};
  cellUpdateHistory: CellUpdateHistory = {};

  get experimentNumber(): string {
    return this.experiment.experimentNumber;
  }

  private readonly activityInputsTitle = $localize`:@@activityInputPageTitle:Inputs`;
  private readonly activityOutputsTitle = $localize`:@@outputs:Outputs`;
  private readonly cover = $localize`:@@Cover:Cover`;
  private readonly crossReferencesTitle = $localize`:@@crossReferences:Cross References`;
  private readonly instrumentPageTitle = $localize`:@@Instrument:Instrument`;
  private readonly labItemsConsumableTitle = $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`;
  private readonly labItemsInstrumentColumnTitle = $localize`:@@LabItemsColumnsTableTitle:Columns`;
  private readonly labItemsInstrumentTitle = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
  private readonly labItemsMaterialTitle = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
  private readonly labItemsTitle = $localize`:@@activityLabItemsPageTitle:Lab Items`;
  private readonly promptsTitle = $localize`:@@prompts:prompts`;
  private readonly materialAliquotsTitle = $localize`:@@StudyActivities:Study Activities`;
  private readonly referencesTitle = $localize`:@@references:References`;
  private readonly sampleAliquotsTitle = $localize`:@@SampleTableTitle:Samples & Aliquots`;
  private readonly expectedToFindTableMessage = 'LOGIC ERROR: Expected to find table with ID: ';
  private readonly labItemsPreparationTitle = $localize`:@@preparations:Preparations`;
  private readonly experimentPreparationTitle = $localize`:@@preparations:Preparations`;

  private readonly preparationColumns: Record<string, string> = {
    Name: $localize`:@@name:Name`,
    FormulaComponents: $localize`:@@formulationOrComponents:Formulation/Components`,
    ExpirationValue: $localize`:@@expiration:Expiration`,
    StorageCondition: $localize`:@@storageCondition:Storage Condition`,
    Concentration: $localize`:@@concentration:Concentration`,
    Description: $localize`:@@containerDescription:Container Description`,
  };

  public readonly activityInputCellChangedDataRecordReceiver = new Subject<ActivityInputCellChangedEventNotification>();
  public readonly activityInputRowRefreshedDataRecordReceiver = new Subject<ActivityInputRowRefreshedEventNotification>();
  public readonly activityInputRowRemovedDataRecordReceiver = new Subject<ActivityInputRowRemovedEventNotification>();
  public readonly activityInputRowRestoredDataRecordReceiver = new Subject<ActivityInputRowRestoredEventNotification>();
  public readonly activityReferenceTemplateAppliedEventNotificationReceiver = new Subject<ReferenceTemplateAppliedEventNotification>();
  public readonly addRowsDataRecordReceiver = new Subject<AddRowEventNotification>();
  public readonly rowsAddedForEachDataRecordReceiver = new Subject<RowsAddedForEachEventNotification>();
  public readonly attachedFileEventNotification = new Subject<AttachedFileEventNotification>();
  public readonly cellChangedDataRecordReceiver = new Subject<CellChangedEventNotification>();
  public readonly chromatographyDataImportedEventNotificationReceiver = new Subject<ChromatographyDataImportedEventNotification>();
  public readonly chromatographyDataRefreshedEventNotificationReceiver = new Subject<ChromatographyDataRefreshedEventNotification>();
  public readonly chromatographyDataRemovedEventNotificationReceiver = new Subject<ChromatographyDataRemovedEventNotification>();
  public readonly crossReferenceAddedEventReceiver = new Subject<CrossReferenceAddedEventNotification>();
  public readonly crossReferenceChangedEventReceiver = new Subject<CrossReferenceChangedEventNotification>();
  public readonly crossReferenceRemovedEventReceiver = new Subject<CrossReferenceRemovedEventNotification>();
  public readonly crossReferenceRestoredEventReceiver = new Subject<CrossReferenceRestoredEventNotification>();
  public readonly experimentNodeTitleChangedNotificationReceiver = new Subject<ExperimentNodeTitleChangedNotification>();
  public readonly experimentPreparationStatusChangedNotificationReceiver = new Subject<ExperimentPreparationStatusChangedNotification>();
  public readonly experimentWorkFlowDataRecordReceiver = new Subject<WorkflowEventNotification>();
  public readonly fieldChangedDataRecordReceiver = new Subject<FieldChangedEventNotification>();
  public readonly instrumentDateRemovedChangedDataRecordReceiver = new Subject<InstrumentDateRemovedChangedEventNotification>();
  public readonly instrumentDescriptionChangedDataRecordReceiver = new Subject<InstrumentDescriptionChangedEventNotification>();
  public readonly instrumentRemovedFromServiceChangedDataRecordReceiver = new Subject<InstrumentRemovedFromServiceChangedEventNotification>();
  public readonly labItemsCellChangedEventNotificationReceiver = new Subject<LabItemsCellChangedEventNotification>();
  public readonly labItemsConsumableAddedEventNotificationReceiver = new Subject<LabItemsConsumableAddedNotification>();
  public readonly labItemsConsumableRemovedEventNotificationReceiver = new Subject<LabItemsConsumableRemovedEventNotification>();
  public readonly labItemsConsumableRestoredEventNotificationReceiver = new Subject<LabItemsConsumableRestoredEventNotification>();
  public readonly labItemsInstrumentRemovedEventNotificationReceiver = new Subject<LabItemsInstrumentRemovedEventNotification>();
  public readonly labItemsInstrumentRestoredEventNotificationReceiver = new Subject<LabItemsInstrumentRestoredEventNotification>();
  public readonly labItemsMaterialRemovedEventNotificationReceiver = new Subject<LabItemsMaterialRemovedEventNotification>()
  public readonly labItemsMaterialRestoredEventNotificationReceiver = new Subject<LabItemsMaterialRestoredEventNotification>();
  public readonly labItemsRefreshedColumnNotification = new Subject<LabItemsInstrumentColumnRefreshedNotification>();
  public readonly labItemsRefreshedInstrumentNotification = new Subject<LabItemsInstrumentRefreshedNotification>();
  public readonly labItemsRefreshedMaterialNotification = new Subject<LabItemsMaterialRefreshedNotification>();
  public readonly labItemsRemovedColumnNotification = new Subject<LabItemsInstrumentColumnRemovedEventNotification>();
  public readonly labItemsRestoredColumnNotification = new Subject<LabItemsInstrumentColumnRestoredEventNotification>();
  public readonly maintenanceEventSelectedDataRecordReceiver = new Subject<MaintenanceEventSelectedEventNotification>();
  public readonly nonRoutineIssueEncounteredDataChangeEventNotificationReceiver = new Subject<NonRoutineIssueEncounteredDataChangeEventNotification>();
  public readonly InstrumentEventExceptionReferenceChangedEventNotificationReceiver = new Subject<InstrumentEventExceptionReferenceChangedEventNotification>();
  public readonly InstrumentEventJustificationChangedEventNotificationReceiver = new Subject<InstrumentEventJustificationChangedEventNotification>();
  public readonly returnedToServiceDataChangeEventNotificationReceiver = new Subject<ReturnToServiceDataChangeEventNotification>();
  public readonly rowRemovedDataRecordReceiver = new Subject<RowRemovedEventNotification>();
  public readonly rowRestoredDataRecordReceiver = new Subject<RowRestoredEventNotification>();
  public readonly rowsRenumberedDataRecordReceiver = new Subject<RowsRenumberedEventNotification>();
  public readonly sampleTestAddedDataRecordReceiver = new Subject<SampleTestAddedEventNotification>();
  public readonly setVariableDataRecordReceiver = new Subject<SetVariableEventNotification>();
  public readonly studyActivitySelectedDataRecordReceiver = new Subject<StudyActivitySelectedEventNotification>();
  public readonly labItemPreparationRefreshedNotification = new Subject<LabItemsPreparationRefreshedNotification>();
  public readonly labItemsPreparationRemovedEventNotificationReceiver = new Subject<LabItemPreparationRemovedEventNotification>();
  public readonly labItemsPreparationRestoredEventNotificationReceiver = new Subject<LabItemPreparationRestoredEventNotification>();
  public readonly labItemsPromptSatisfiedEventNotificationReceiver = new Subject<PromptSatisfiedEventNotification>();
  public readonly labItemsPromptRemovedEventNotificationReceiver = new Subject<PromptRemovedEventNotification>();
  public readonly labItemsPromptRestoredEventNotificationReceiver = new Subject<PromptRestoredEventNotification>();
  /**
   * Subject for subscribing to @see ClientFacingNoteChangedEventNotification
   * Note: There will be multiple subscribers. @see CommentsComponent will be first and observer order is guaranteed for a Subject.
   */
  public readonly clientFacingNoteChangedDataRecordReceiver = new Subject<ClientFacingNoteChangedEventNotification>();
  /**
   * Subject for subscribing to @see ClientFacingNoteCreatedEventNotification
   * Note: There will be multiple subscribers. @see CommentsComponent will be first and observer order is guaranteed for a Subject.
   */
  public readonly clientFacingNoteCreatedDataRecordReceiver = new Subject<ClientFacingNoteCreatedEventNotification>();
  public readonly experimentNodeOrderChangedNotificationReceiver = new Subject<ExperimentNodeOrderChangedNotification>();

  public static readonly labelsByItemType: {
    [itemType: string]: { heading: string };
  } = {
      material: {
        heading: $localize`:@@Material:Material`
      },
      instrumentDetails: {
        heading: $localize`:@@Instrument:Instrument`
      }
    };

  public static readonly Title = $localize`:@@title:Title`;

  private readonly nodeTitleChangeAuditHistory: {
    [nodeType: string]: {
      historyComposer: (notification: ExperimentNodeTitleChangedNotification) => AuditHistory;
    };
  } = {
      activity: {
        historyComposer: this.getActivityTitleChangedAuditContext.bind(this)
      },
      module: {
        historyComposer: this.getModuleTitleChangedAuditContext.bind(this)
      },
      table: {
        historyComposer: this.getTableTitleChangedAuditContext.bind(this)
      },
      form: {
        historyComposer: this.getFormTitleChangedAuditContext.bind(this)
      }
    };

  experimentWorkFlowPreviousStatus?: WorkflowEventNotification;

  public readonly experimentNotificationOnlyReceiver: {
    [key: string]: Subject<ExperimentNotificationOnlyResponse>;
  } = {
      chromatographyDataImported: new Subject<ExperimentNotificationOnlyResponse>(),
      chromatographyDataRefreshed: new Subject<ExperimentNotificationOnlyResponse>()
    };

  public static readonly MessageTypeMapForNotification: { [key: string]: string } = {
    information: 'info',
    warning: 'warn',
    error: 'error',
    validation: 'warn',
    1: 'info',
    2: 'warn',
    3: 'error',
    4: 'warn'
  };

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly dataValueService: DataValueService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    private readonly labsiteService: LabsiteService,
    private readonly messageService: MessageService,
    private readonly searchService: BookshelfService,
    private readonly specificationService: SpecificationService,
    private readonly tableService: TableService,
    private readonly titleCasePipe: TitleCasePipe,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly userService: UserService,
    public readonly projectLogLoaderService: ProjectLogLoaderService,
    private readonly activityReferencesService: ActivityReferencesService,
    private readonly dataRecordUtilityService: DataRecordUtilityService,
    private readonly recipeDataRecordService: RecipeDataRecordService
  ) {
    searchService.rootUrl = environment.searchServiceUrl;
    this.experimentNotificationService.dataRecordReceiver.subscribe((data) =>
      this.applyDataRecord(data)
    );
    this.experimentNotificationService.experimentNotificationOnlyReceiver.subscribe({
      next: this.notifyExperimentNotificationOnly.bind(this)
    });
  }

  /**
   * Updates experiment (and percent completion etc) synchronously! THEN broadcasts that the event occurred.
   *
   * Note: for some events these two steps are not done correctly. Update should be done in service, not UI components (which might not exist)
   * */
  private applyDataRecord(dataRecords: ExperimentDataRecordNotification | ExperimentDataRecordNotification[]) {
    this.experimentService.amICollaborator().subscribe({
      next: amICollaborator => {
        if (amICollaborator) this.experimentService._isCurrentUserCollaboratorSubject$.next(amICollaborator);
      }
    });
    if (!Array.isArray(dataRecords)) dataRecords = [dataRecords];
    dataRecords.forEach(data => {
      switch (data.eventContext.eventType) {
        case ExperimentEventType.RowRemoved:
        case ExperimentEventType.RowRestored:
        case ExperimentEventType.RowsRenumbered:
        case ExperimentEventType.CellChanged:
        case ExperimentEventType.RowsAdded:
        case ExperimentEventType.RowsAddedForEach:
          this.applyDataRecordForTable(data);
          break;
        case ExperimentEventType.FieldChanged: {
          const fieldChangeDr = data as FieldChangedEventNotification;
          this.fieldChangedDataRecordReceiver.next(fieldChangeDr);
          this.experimentService.applyFieldChange(
            fieldChangeDr.formId,
            fieldChangeDr.path,
            fieldChangeDr.newValue
          );
          break;
        }
        case ExperimentEventType.ClientFacingNoteCreated:
          this.clientFacingNoteCreatedDataRecordReceiver.next(data as ClientFacingNoteCreatedEventNotification);
          break;
        case ExperimentEventType.ClientFacingNoteChanged:
          this.clientFacingNoteChangedDataRecordReceiver.next(data as ClientFacingNoteChangedEventNotification);
          break;
        case ExperimentEventType.ExperimentStarted:
        case ExperimentEventType.ExperimentCancelled:
        case ExperimentEventType.ExperimentRestored:
        case ExperimentEventType.ExperimentSentForReview:
        case ExperimentEventType.ExperimentSentForCorrection:
        case ExperimentEventType.ExperimentAuthorized:
          this.experimentWorkFlowDataRecordReceiver.next(data as WorkflowEventNotification);
          break;
        case ExperimentEventType.ExperimentTemplateApplied:
          this.notifyTemplateAppliedMessage(data as ExperimentTemplateAppliedEventNotification);
          break;
        case ExperimentEventType.SampleTestChanged:
          this.sampleTestAddedDataRecordReceiver.next(data as SampleTestAddedEventNotification);
          break;
        case ExperimentEventType.ActivityInputCellChanged:
        case ExperimentEventType.ActivityInputRowRestored:
        case ExperimentEventType.ActivityInputRowRemoved:
        case ExperimentEventType.ActivityInputRowRefreshed:
          this.applyDataRecordForActivityInputs(data);
          break;
        case ExperimentEventType.ActivityCrossReferenceAdded:
        case ExperimentEventType.ActivityCrossReferenceChanged:
        case ExperimentEventType.ActivityCrossReferenceRemoved:
        case ExperimentEventType.ActivityCrossReferenceRestored:
        case ExperimentEventType.ActivityReferenceTemplateApplied:
          this.applyDataRecordForActivityReferences(data);
          break;
        case ExperimentEventType.VariableCreated:
          this.setVariableDataRecordReceiver.next(data as SetVariableEventNotification);
          break;
        case ExperimentEventType.StudyActivitySelected:
          this.studyActivitySelectedDataRecordReceiver.next(data as StudyActivitySelectedEventNotification);
          break;
        case ExperimentEventType.MaintenanceEventSelected:
          this.maintenanceEventSelectedDataRecordReceiver.next(data as MaintenanceEventSelectedEventNotification);
          break;
        case ExperimentEventType.InstrumentDescriptionChanged:
          this.instrumentDescriptionChangedDataRecordReceiver.next(data as InstrumentDescriptionChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentDateRemovedChanged:
          this.instrumentDateRemovedChangedDataRecordReceiver.next(data as InstrumentDateRemovedChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentRemovedFromServiceChanged:
          this.instrumentRemovedFromServiceChangedDataRecordReceiver.next(data as InstrumentRemovedFromServiceChangedEventNotification);
          break;
        case ExperimentEventType.LabItemsMaterialRemoved:
        case ExperimentEventType.LabItemsMaterialRefreshed:
        case ExperimentEventType.LabItemsInstrumentRefreshed:
        case ExperimentEventType.LabItemsInstrumentColumnRefreshed:
        case ExperimentEventType.LabItemsInstrumentColumnRemoved:
        case ExperimentEventType.LabItemsInstrumentColumnRestored:
        case ExperimentEventType.LabItemsMaterialRestored:
        case ExperimentEventType.LabItemsCellChanged:
        case ExperimentEventType.LabItemsInstrumentRemoved:
        case ExperimentEventType.LabItemsInstrumentRestored:
        case ExperimentEventType.LabItemsConsumableAdded:
        case ExperimentEventType.LabItemsConsumableRemoved:
        case ExperimentEventType.LabItemsConsumableRestored:
        case ExperimentEventType.LabItemPreparationRefreshed:
        case ExperimentEventType.LabItemPreparationRemoved:
        case ExperimentEventType.LabItemPreparationRestored:
          this.applyDataRecordForLabItems(data);
          break;
        case ExperimentEventType.ExperimentNodeTitleChanged:
          this.experimentNodeTitleChangedNotificationReceiver.next(data as ExperimentNodeTitleChangedNotification);
          break;
        case ExperimentEventType.ExperimentNodeOrderChanged:
          this.experimentNodeOrderChangedNotificationReceiver.next(data as ExperimentNodeOrderChangedNotification);
          break;
        case ExperimentEventType.ChromatographyDataImported:
          this.chromatographyDataImportedEventNotificationReceiver.next(data as ChromatographyDataImportedEventNotification);
          break;
        case ExperimentEventType.ChromatographyDataRefreshed:
          this.chromatographyDataRefreshedEventNotificationReceiver.next(data as ChromatographyDataRefreshedEventNotification);
          break;
        case ExperimentEventType.ChromatographyDataRemoved:
          this.chromatographyDataRemovedEventNotificationReceiver.next(data as ChromatographyDataRemovedEventNotification);
          break;
        case ExperimentEventType.InstrumentEventNonRoutineIssueEncountered:
          this.nonRoutineIssueEncounteredDataChangeEventNotificationReceiver.next(data as NonRoutineIssueEncounteredDataChangeEventNotification);
          break;
        case ExperimentEventType.InstrumentEventExceptionReferenceChanged:
          this.InstrumentEventExceptionReferenceChangedEventNotificationReceiver.next(data as InstrumentEventExceptionReferenceChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentEventJustificationChanged:
          this.InstrumentEventJustificationChangedEventNotificationReceiver.next(data as InstrumentEventJustificationChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentEventReturnedToService:
          this.returnedToServiceDataChangeEventNotificationReceiver.next(data as ReturnToServiceDataChangeEventNotification);
          break;
        case ExperimentEventType.ActivityFilesAdded:
          this.attachedFileEventNotification.next(data as AttachedFileEventNotification);
          break;
        case ExperimentEventType.ExperimentPreparationStatusChanged:
          this.experimentPreparationStatusChangedNotificationReceiver.next(
            data as ExperimentPreparationStatusChangedNotification
          );
          break;
        case ExperimentEventType.PromptRemoved:
          this.labItemsPromptRemovedEventNotificationReceiver.next(data as PromptRemovedEventNotification);
          break;
        case ExperimentEventType.PromptRestored:
          this.labItemsPromptRestoredEventNotificationReceiver.next(data as PromptRestoredEventNotification);
          break;
        case ExperimentEventType.PromptSatisfied:
          this.labItemsPromptSatisfiedEventNotificationReceiver.next(data as PromptSatisfiedEventNotification);
          break;
        default:
          console.warn(data.eventContext.eventType + ' event is not handled by applyDataRecord');
      }
      this.experimentService.lastProcessedDataRecordTimeStamp = data.eventContext.eventTime;
    });
  }

  private applyDataRecordForTable(data: ExperimentDataRecordNotification) {
    switch (data.eventContext.eventType) {
      case ExperimentEventType.RowRemoved: {
        const rowRemoved = data as RowRemovedEventNotification;
        this.experimentService.applyRowRemovedDataRecord(rowRemoved);
        this.rowRemovedDataRecordReceiver.next(rowRemoved);
        break;
      }
      case ExperimentEventType.RowRestored: {
        const rowRestored = data as RowRestoredEventNotification;
        this.experimentService.applyRowRestoredDataRecord(rowRestored);
        this.rowRestoredDataRecordReceiver.next(rowRestored);
        break;
      }
      case ExperimentEventType.RowsRenumbered: {
        const rowsRenumbered = data as RowsRenumberedEventNotification;
        this.experimentService.applyRowsRenumberedDataRecord(rowsRenumbered);
        this.rowsRenumberedDataRecordReceiver.next(rowsRenumbered);
        break;
      }
      case ExperimentEventType.CellChanged: {
        const cellChangedDr = data as CellChangedEventNotification;
        this.experimentService.applyCellChange(
          cellChangedDr.tableIds,
          cellChangedDr.rowIds,
          cellChangedDr.columnValues
        );
        this.cellChangedDataRecordReceiver.next(cellChangedDr);
        break;
      }
      case ExperimentEventType.RowsAdded: {
        const tableAddRowDr = data as AddRowEventNotification;
        this.experimentService.applyAddRow(tableAddRowDr.tableId, tableAddRowDr.rows ?? []);
        this.addRowsDataRecordReceiver.next(tableAddRowDr);
        break;
      }
      case ExperimentEventType.RowsAddedForEach: {
        const dataRecord = data as RowsAddedForEachEventNotification;
        this.applyRowsAddedForEachDataRecord(dataRecord);
        this.rowsAddedForEachDataRecordReceiver.next(dataRecord);
        break;
      }
    }
  }

  private applyDataRecordForActivityInputs(data: ExperimentDataRecordNotification) {
    switch (data.eventContext.eventType) {
      case ExperimentEventType.ActivityInputCellChanged:
        this.activityInputCellChangedDataRecordReceiver.next(data as ActivityInputCellChangedEventNotification);
        break;
      case ExperimentEventType.ActivityInputRowRestored:
        this.activityInputRowRestoredDataRecordReceiver.next(data as ActivityInputRowRestoredEventNotification);
        break;
      case ExperimentEventType.ActivityInputRowRemoved:
        this.activityInputRowRemovedDataRecordReceiver.next(data as ActivityInputRowRemovedEventNotification);
        break;
      case ExperimentEventType.ActivityInputRowRefreshed:
        this.activityInputRowRefreshedDataRecordReceiver.next(data as ActivityInputRowRefreshedEventNotification);
        break;
    }
  }

  private applyDataRecordForActivityReferences(data: ExperimentDataRecordNotification) {
    switch (data.eventContext.eventType) {
      case ExperimentEventType.ActivityCrossReferenceAdded:
        this.activityReferencesService.applyActivityCrossReferenceAdded(data as CrossReferenceAddedEventNotification);
        this.crossReferenceAddedEventReceiver.next(data as CrossReferenceAddedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceChanged:
        this.activityReferencesService.applyActivityCrossReferenceChanged(data as CrossReferenceChangedEventNotification);
        this.crossReferenceChangedEventReceiver.next(data as CrossReferenceChangedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceRemoved:
        this.activityReferencesService.applyActivityCrossReferenceRemoved(data as CrossReferenceRemovedEventNotification);
        this.crossReferenceRemovedEventReceiver.next(data as CrossReferenceRemovedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceRestored:
        this.activityReferencesService.applyActivityCrossReferenceRestored(data as CrossReferenceRestoredEventNotification);
        this.crossReferenceRestoredEventReceiver.next(data as CrossReferenceRestoredEventNotification);
        break;
      case ExperimentEventType.ActivityReferenceTemplateApplied:
        this.activityReferenceTemplateAppliedEventNotificationReceiver.next(data as ReferenceTemplateAppliedEventNotification);
        break;
    }
  }

  private applyDataRecordForLabItems(data: ExperimentDataRecordNotification) {
    switch (data.eventContext.eventType) {
      case ExperimentEventType.LabItemsMaterialRemoved:
        this.labItemsMaterialRemovedEventNotificationReceiver.next(data as LabItemsMaterialRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsMaterialRefreshed:
        this.labItemsRefreshedMaterialNotification.next(data as LabItemsMaterialRefreshedNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRefreshed:
        this.labItemsRefreshedInstrumentNotification.next(data as LabItemsInstrumentRefreshedNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRefreshed:
        this.labItemsRefreshedColumnNotification.next(data as LabItemsInstrumentColumnRefreshedNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRemoved:
        this.labItemsRemovedColumnNotification.next(data as LabItemsInstrumentColumnRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRestored:
        this.labItemsRestoredColumnNotification.next(data as LabItemsInstrumentColumnRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsMaterialRestored:
        this.labItemsMaterialRestoredEventNotificationReceiver.next(data as LabItemsMaterialRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsCellChanged:
        this.labItemsCellChangedEventNotificationReceiver.next(data as LabItemsCellChangedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRemoved:
        this.labItemsInstrumentRemovedEventNotificationReceiver.next(data as LabItemsInstrumentRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRestored:
        this.labItemsInstrumentRestoredEventNotificationReceiver.next(data as LabItemsInstrumentRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableAdded:
        this.labItemsConsumableAddedEventNotificationReceiver.next(data as LabItemsConsumableAddedNotification);
        break;
      case ExperimentEventType.LabItemsConsumableRemoved:
        this.labItemsConsumableRemovedEventNotificationReceiver.next(data as LabItemsConsumableRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableRestored:
        this.labItemsConsumableRestoredEventNotificationReceiver.next(data as LabItemsConsumableRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRefreshed:
        this.labItemPreparationRefreshedNotification.next(data as LabItemsPreparationRefreshedNotification);
        break;
      case ExperimentEventType.LabItemPreparationRemoved:
        this.labItemsPreparationRemovedEventNotificationReceiver.next(data as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRestored:
        this.labItemsPreparationRestoredEventNotificationReceiver.next(data as LabItemPreparationRestoredEventNotification);
        break;
    }
  }

  private notifyTemplateAppliedMessage(event: ExperimentTemplateAppliedEventNotification): void {
    this.experimentTemplateEventService.notifyAppliedTemplateEvent(event);
  }

  private notifyExperimentNotificationOnly(notification: ExperimentNotificationOnlyResponse): void {
    if (this.experimentNotificationOnlyReceiver[notification.operationType]) {
      this.experimentNotificationOnlyReceiver[notification.operationType].next(notification);
    } else {
      this.displayUnHandledErrorNotification(
        notification.notifications,
        notification.operationType
      );
    }
  }

  /** Flattens current view of this Experiment and caches it for each access when determining the context for history entries */
  private extractNodesFromCurrentExperiment(): void {
    if (!this.experiment) {
      return;
    }
    this.allModuleItemsInExperiment = [];
    this.allModulesInExperiment = [];
    this.dataRecordUtilityService.allModulesInExperiment = [];
    this.experiment.activities.forEach(activity => {
      this.extractModuleNodeFromCurrentExperiment(activity);
    })
  }

  private extractModuleNodeFromCurrentExperiment(activity: Activity): void {
    if (!activity.dataModules) {
      return;
    }
    activity.dataModules.forEach(module => {
      this.extractTableAndFormFromCurrentExperiment(module);
      this.allModulesInExperiment.push(module);
    });
    this.dataRecordUtilityService.allModulesInExperiment = this.allModulesInExperiment;
  }

  private extractTableAndFormFromCurrentExperiment(module: Module): void {
    if (!module.items) {
      return;
    }
    module.items.forEach(moduleItem => {
      this.allModuleItemsInExperiment.push(moduleItem);
    })
  }

  /**
   * Creates 0 or more history entries for each ExperimentDataRecordNotification.
   *
   * See AuditHistory for meaning of *context and Description.
   *
   * Also, see createHistoryEntries.
   */
  public async createHistoryAsync(records: ExperimentDataRecordNotification[], nodeType?: NodeType, nodeId?: string | undefined): Promise<AuditHistory[]> {
    this.experimentWorkFlowPreviousStatus = undefined;
    this.extractNodesFromCurrentExperiment();
    if (typeof this.usersList === 'undefined' || typeof this.labsites === 'undefined') {
      await forkJoin({
        r1: this.userService.usersActiveUsersPost$Json({
          body: ['ELN Analyst', 'ELN Reviewer', 'ELN Supervisor', 'ELN Viewer']
        }),
        r2: this.labsiteService.labsitesSubBusinessUnitsGet$Json({
          labsiteCodes: [this.currentUserService.currentUser.labSiteCode as string].join(',')
        })
      })
        .toPromise()
        .then((result: any) => {
          this.usersList = JSON.parse(JSON.stringify(result.r1));
          this.dataRecordUtilityService.usersList = this.usersList;
          this.labsites = result.r2.labsites;
        })
        .catch((e) => {
          console.error(e);
        });
    }
    records = this.splitLabItemsCompositeDataRecords(records);
    const crossReferenceLinks = records
      .filter((r): r is CrossReferenceAddedEventNotification => r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceAdded);
    const experimentIds = crossReferenceLinks
      .filter((r) => r.type === CrossReferenceType.Experiment)
      .map((r) => r.linkId);
    const activityIds = crossReferenceLinks
      .filter((r) => r.type === CrossReferenceType.Activity)
      .map((r) => r.linkId);
    if (experimentIds.length) {
      (await this.lookupExperimentNumbers(experimentIds).toPromise())?.forEach(
        ({ experimentId, experimentNumber }) => this.experimentNumberCache[experimentId] = experimentNumber
      );
    }
    if (activityIds.length) {
      (await this.lookupActivityNumbers(activityIds).toPromise())?.forEach(
        ({ activityId, activityNumber }) => this.activityNumberCache[activityId] = activityNumber
      );
    }
    const dataSource: AuditHistory[] = [];
    this.projectRecordsToHistory(records, dataSource, nodeType, nodeId);
    dataSource.forEach(audit => audit.Time = formatInstant(audit.Time, DateAndInstantFormat.dateTimeToSecond));
    return Promise.resolve(dataSource);
  }

  lookupExperimentNumbers(experimentIds: string[]): Observable<{ experimentId: string, experimentNumber: string }[]> {
    const labSiteCode = this.experiment.organization.labSiteCode;
    const search: SearchCriteria = {
      bypassSecurity: false,
      filterConditions: [{
        conditionType: ConditionType.And,
        filters: [
          { columnName: 'experimentId', matchType: StringMatchType.In, values: experimentIds, isSecurityFlag: false, dataType: DataType.String },
          { columnName: 'labsiteCode', matchType: StringMatchType.Word, text: labSiteCode, isSecurityFlag: true, dataType: DataType.String },
        ],
      }],
      pagination: { pageNumber: 1, pageSize: 5000 },
      sort: []
    };
    return this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: search })
      .pipe(map((result) => result.records
        .filter((e) => experimentIds.includes(e.experimentId))
        .map((e) => ({ experimentId: e.experimentId, experimentNumber: e.experimentNumber }))
      ));
  }

  lookupActivityNumbers(activityIds: string[]): Observable<{ activityId: string, activityNumber: string | undefined }[]> {
    const labSiteCode = this.experiment.organization.labSiteCode;
    const search: SearchCriteria = {
      bypassSecurity: false,
      filterConditions: [{
        conditionType: ConditionType.And,
        filters: [
          { columnName: 'activityDetails.activityId', matchType: StringMatchType.In, values: activityIds, isSecurityFlag: false, dataType: DataType.String },
          { columnName: 'labsiteCode', matchType: StringMatchType.Word, text: labSiteCode, isSecurityFlag: true, dataType: DataType.String },
        ],
      }],
      pagination: { pageNumber: 1, pageSize: 5000 },
      sort: []
    };
    return this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: search })
      .pipe(map((result) => result.records
        .flatMap((e) => e.activityDetails)
        .filter((a) => activityIds.includes(a.activityId))
        .map((a) => ({ activityId: a.activityId, activityNumber: a.activityNumber }))
      ));
  }

  private splitLabItemsCompositeDataRecords(records: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    records = this.splitLabItemsMaterialRefreshedCompositeDataRecords(records);
    return records;
  }

  private splitLabItemsMaterialRefreshedCompositeDataRecords(records: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    const compositeRecords = records
      .filter((record): record is LabItemsMaterialRefreshedNotification => record.eventContext.eventType === ExperimentEventType.LabItemsMaterialRefreshed);
    records = records.filter(record => record.eventContext.eventType !== ExperimentEventType.LabItemsMaterialRefreshed);
    compositeRecords.forEach(compositeRecord => {
      const dataValues = (compositeRecord).refreshedDataValues;
      Object.keys(dataValues).forEach((fieldName) => {
        const recordTemplate = clone(compositeRecord);
        recordTemplate.refreshedDataValues = {};
        recordTemplate.refreshedDataValues[fieldName] = dataValues[fieldName];
        records.push(recordTemplate);
      });
    });
    return records;
  }

  private getLabItemsMaterialRefreshedRecord(notification: ExperimentDataRecordNotification): AuditHistory {
    const record = notification as LabItemsMaterialRefreshedNotification;
    const changedField = Object.keys(record.refreshedDataValues)[0];
    const path = `${this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle)} > ${record.itemReference} > ${changedField}`;
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsMaterialRefreshed}`,
      `${(record.refreshedDataValues[changedField] as StringValue | NumberValue).value}`,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getLabItemsInstrumentRefreshedRecord(notification: ExperimentDataRecordNotification): AuditHistory {
    const record = notification as LabItemsInstrumentRefreshedNotification;
    const changedField = Object.keys(record.refreshedDataValues)[0];
    const path = `${this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle)} > ${record.itemReference} > ${changedField}`;
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsInstrumentRefreshed}`,
      `${(record.refreshedDataValues[changedField] as StringValue | NumberValue).value}`,
      $localize`:@@Instrument:Instrument`
    );
  }

  /**
   * Gets the history-style formatted context and initial title of activity
   * @param nodeId Experiment's nodeId of the activity
   * @param title Title of the activity
   * @returns Context and the Title of the activity
   */
  private getActivityContextWithInitialTitle(nodeId: string, title: string): { fullPath: string, title: string } {
    const noTitle = $localize`:@@noTitle:No Title`;
    const currentActivity = this.experiment.activities.find(a => a.activityId === nodeId);
    return { fullPath: `${currentActivity?.itemTitle ?? noTitle}`, title: `${title ?? noTitle}` };
  }

  private getActivityInputContextByType(type: ActivityInputType, contextTitle: string): string {
    const pageTitle = this.getActivityInputTypeTitleByType(type);
    let activityId = this.experimentService.currentContextMenuActivityId;
    if (activityId === '' && this.experimentService.currentActivity?.activityId) {
      activityId = this.experimentService.currentActivity?.activityId;
    }
    return `${this.dataRecordUtilityService.getActivityContext(activityId).fullPath} > ${contextTitle} > ${pageTitle}`;
  }

  private getActivityInputTypeTitleByType(type: ActivityInputType): string {
    let pageTitle = '';
    switch (type) {
      case ActivityInputType.Material:
        pageTitle = this.labItemsMaterialTitle;
        break;
      case ActivityInputType.InstrumentDetails:
        pageTitle = this.labItemsInstrumentTitle;
        break;
      case ActivityInputType.Consumable:
        pageTitle = this.labItemsConsumableTitle;
        break;
      case ActivityInputType.InstrumentColumn:
        pageTitle = this.labItemsInstrumentColumnTitle;
        break;
      case ActivityInputType.Preparation:
        pageTitle = this.labItemsPreparationTitle;
        break;
    }
    return pageTitle;
  }

  private getLabItemClientFacingNoteContext(note: ClientFacingNote): string {
    const labItemContext = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(labItemContext.labItemType as ActivityInputType);
    let fieldName;
    if (note.path[3] === ActivityInputType.Material) {
      fieldName = LabItemsMaterialTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    } else if (note.path[3] === ActivityInputType.InstrumentDetails) {
      fieldName = LabItemsInstrumentTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    } else if (note.path[3] === ActivityInputType.InstrumentColumn) {
      fieldName = LabItemsColumnTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    } else if (note.path[3] === ActivityInputType.Consumable) {
      fieldName = LabItemsConsumablesTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    }
    return `${this.dataRecordUtilityService.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${this.labItemsTitle
      } > ${labItemsContextTitle} > ${labItemContext.rowId} > ${fieldName}`;
  }

  private getActivityInputClientFacingNoteContext(note: ClientFacingNote): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const fieldName = SampleTableGridOptions.prepareColumns().find(c => c.field === context.columnField);
    return `${this.dataRecordUtilityService.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${this.activityInputsTitle} > ${context.rowId} > ${fieldName?.label}`;
  }

  private getExperimentPreparationClientFacingNoteContext(note: ClientFacingNote): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.dataRecordUtilityService.getActivityContext(activityId).fullPath : '';
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';
    const rowId = context.rowId ? this.getExperimentPreparationNumber(note.nodeId, context.rowId) : '';
    return `${fullPath} > ${this.experimentPreparationTitle} > ${rowId} > ${fieldName}`;
  }

  private getLabItemPreparationClientFacingNoteContext(note: ClientFacingNote): string {
    const labItemContext = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.dataRecordUtilityService.getActivityContext(activityId).fullPath : '';
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(ActivityInputType.Preparation);
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';
    const rowId = labItemContext.rowId ? this.getPreparationNumber(note.nodeId, labItemContext.rowId) : '';
    return `${fullPath} > ${this.labItemsTitle} > ${labItemsContextTitle} > ${rowId} > ${fieldName}`;
  }

  private getExperimentPreparationNumber(activityId: string, preparationId: string): string {
    const preparations = this.experimentService.currentExperiment?.activities?.find(
      (activity: Activity) => activity.activityId === activityId)?.preparations;
    if (preparations) {
      return preparations.find((preparation: ExperimentPreparation) => preparation.preparationId === preparationId)?.preparationNumber ?? '';
    }
    return '';
  }

  private getActivityLabItemNode(activityId: string): ActivityLabItemsNode | undefined {
    return this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityId
    );
  }

  private getPreparationNumber(activityId: string, preparationId: string): string {
    const preparations = this.getActivityLabItemNode(activityId)?.preparations;
    if (preparations) {
      return preparations.find((preparation: ExperimentPreparation) => preparation.preparationId === preparationId)?.preparationNumber ?? '';
    }
    return '';
  }

  isFormHistoryRecord(rec: ExperimentDataRecordNotification): rec is HistoryRecord {
    if (!rec) return false;
    return 'newValue' in rec
  }

  isTableHistoryRecord(rec: ExperimentDataRecordNotification): rec is HistoryRecord {
    if (!rec) return false;
    return 'columnValues' in rec
  }

  /**
   * Project events into a history entry array
   * @param records events to project into history entries
   * @param history array of history entries to collect into
   * @param nodeType
   */
  private projectRecordsToHistory(records: ExperimentDataRecordNotification[], history: AuditHistory[], nodeType?: NodeType, nodeId?: string | undefined) {
    records.forEach((record) => {
      let newValue: any; // this is for replaying history for a multiselect, where generating the delta is required for the Description.
      if (this.isTableHistoryRecord(record)) {
        newValue = record?.columnValues[0].propertyValue.value;
      } else if (this.isFormHistoryRecord(record)) {
        newValue = record?.newValue.value;
      }

      const isMultiselect = Array.isArray(newValue);
      const entries = this.createHistoryEntries(record, records, nodeType, nodeId);
      entries.forEach((entry: AuditHistory) => {
        const previousEntriesForSameContext = history.filter((c) => c.ActualContext === entry.ActualContext).length;
        entry.RecordVersion = previousEntriesForSameContext + 1;

        if (isMultiselect) this.processMultiselectHistory(records[records.indexOf(record) - 1], newValue, entry);

        history?.push(entry);
      });
    });
    this.experimentService.inputImpactAssessmentAuditHistoryFlag = false;
    this.experimentService.outputImpactAssessmentAuditHistoryFlag = false;
  }

  public processMultiselectHistory(previousVersion: ExperimentDataRecordNotification, newValue: any, history: AuditHistory) {
    let oldValue: string[] = [];

    if (previousVersion) {
      if (this.isFormHistoryRecord(previousVersion)) {
        oldValue = previousVersion.newValue.value;
      } else {
        oldValue = this.isTableHistoryRecord(previousVersion) ? previousVersion.columnValues[0].propertyValue.value : [];
      }
    }

    if (Array.isArray(oldValue)) {
      const selected = difference(newValue, oldValue);
      const unselected = difference(oldValue, newValue);
      history.Description = this.getMultiselectDescription(history.Description, selected, unselected);
    }
  }

  /**
   * TODO: This method needs revisit as it currently needs to check all the event types for every record refer PBI 3084763
   *
   * Creates 0 or more history entries for an ExperimentDataRecordNotification. They may be multiple entires from the same event. And, there may or many not be an entry of the
   * event itself, either due to it being overly technical or due to other entries being more significant. Examples: RowAdded, RowsAddedForEach
   *
   * @param record event to create history entries for
   * @param records: events that might be needed to replay history up to a point in time, such as the time of the event
   * @param nodeType: scope of the history (but does not go as low as table row, table cell, form item…)
   */
  public createHistoryEntries(
    record: ExperimentDataRecordNotification,
    records: ExperimentDataRecordNotification[],
    nodeType?: NodeType,
    nodeId?: string | undefined
  ): AuditHistory[] {
    const noContext = $localize`:@@NoContext:No Context`;
    let history: AuditHistory[];
    switch (record.eventContext.eventType) { //NOSONAR: a switch statement with a large number of cases is appropriate for this "router" logic
      case ExperimentEventType.AssignedAnalystsChanged:
      case ExperimentEventType.AssignedReviewersChanged:
      case ExperimentEventType.AssignedSupervisorsChanged:
      case ExperimentEventType.AuthorizationDueDateChanged:
      case ExperimentEventType.ExperimentCreated:
      case ExperimentEventType.ExperimentTemplateApplied:
      case ExperimentEventType.ExperimentRecipeApplied:
      case ExperimentEventType.ScheduledReviewStartDateChanged:
      case ExperimentEventType.ScheduledStartDateChanged:
      case ExperimentEventType.SubBusinessUnitsChanged:
      case ExperimentEventType.TagsChanged:
      case ExperimentEventType.ClientsChanged:
      case ExperimentEventType.ProjectsChanged:
      case ExperimentEventType.TitleChanged:
        history = this.getExperimentRecords(record, records, nodeType, nodeId);
        break;
      case ExperimentEventType.ExperimentAuthorized:
      case ExperimentEventType.ExperimentCancelled:
      case ExperimentEventType.ExperimentRestored:
      case ExperimentEventType.ExperimentSentForCorrection:
      case ExperimentEventType.ExperimentSentForReview:
      case ExperimentEventType.ExperimentStarted:
        history = this.getExperimentWorkflowStateRecords(record as WorkflowEventNotification);
        break;
      case ExperimentEventType.CellChanged:
      case ExperimentEventType.RowRemoved:
      case ExperimentEventType.RowRestored:
      case ExperimentEventType.RowsAdded:
      case ExperimentEventType.RowsRenumbered:
        history = this.getTableRecords(record, records);
        break;
      case ExperimentEventType.FieldChanged:
        history = this.getFormRecordContext(record, records);
        break;
      case ExperimentEventType.ClientFacingNoteChanged:
        history = [this.getClientFacingNoteChangedRecord(record)];
        break;
      case ExperimentEventType.ClientFacingNoteCreated:
        history = [this.getClientFacingNoteCreatedRecord(record)];
        break;
      case ExperimentEventType.StatementApplied:
        history = this.getStatementAppliedRecords(record);
        break;
      case ExperimentEventType.ActivityCrossReferenceAdded:
      case ExperimentEventType.ActivityCrossReferenceRemoved:
      case ExperimentEventType.ActivityCrossReferenceRestored:
      case ExperimentEventType.ActivityInputCellChanged:
      case ExperimentEventType.ActivityInputRowRefreshed:
      case ExperimentEventType.ActivityInputRowRemoved:
      case ExperimentEventType.ActivityInputRowRestored:
      case ExperimentEventType.AliquotAdded:
      case ExperimentEventType.InstrumentAdded:
      case ExperimentEventType.InstrumentColumnAdded:
      case ExperimentEventType.InstrumentDateRemovedChanged:
      case ExperimentEventType.InstrumentDescriptionChanged:
      case ExperimentEventType.InstrumentRemovedFromServiceChanged:
      case ExperimentEventType.LabItemsCellChanged:
      case ExperimentEventType.LabItemsConsumableAdded:
      case ExperimentEventType.LabItemsConsumableRemoved:
      case ExperimentEventType.LabItemsConsumableRestored:
      case ExperimentEventType.LabItemsInstrumentAdded:
      case ExperimentEventType.LabItemsInstrumentRemoved:
      case ExperimentEventType.LabItemsInstrumentRestored:
      case ExperimentEventType.LabItemsMaterialAdded:
      case ExperimentEventType.LabItemsMaterialRemoved:
      case ExperimentEventType.LabItemsMaterialRestored:
      case ExperimentEventType.MaintenanceEventSelected:
      case ExperimentEventType.MaterialAdded:
      case ExperimentEventType.SampleTestChanged:
      case ExperimentEventType.StudyActivitySelected:
        history = this.getActivityRecords(record, records);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRemoved:
        history = this.getLabItemsInstrumentColumnRemovedRecord(record as LabItemsInstrumentColumnRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRestored:
        history = this.getLabItemsInstrumentColumnRestoredRecord(record as LabItemsInstrumentColumnRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRefreshed:
        history = [this.getLabItemsInstrumentColumnRefreshedRecord(record as LabItemsInstrumentColumnRefreshedNotification)];
        break;
      case ExperimentEventType.LabItemsMaterialRefreshed:
        history = [this.getLabItemsMaterialRefreshedRecord(record)];
        break;
      case ExperimentEventType.LabItemsInstrumentRefreshed:
        history = [this.getLabItemsInstrumentRefreshedRecord(record)];
        break;
      case ExperimentEventType.ExperimentNodeTitleChanged:
        history = [this.getExperimentNodeTitleChangedRecord(record as ExperimentNodeTitleChangedNotification)];
        break;
      case ExperimentEventType.ActivityCrossReferenceChanged:
        history = this.getActivityCrossReferenceChangedRecord(record as CrossReferenceChangedEventNotification, records);
        break;
      case ExperimentEventType.ChromatographyDataImported:
        history = [this.getChromatographyDataImportedRecord(record as ChromatographyDataImportedEventNotification)];
        break;
      case ExperimentEventType.ChromatographyDataRemoved:
        history = [this.getChromatographyDataRemovedRecord(record as ChromatographyDataRemovedEventNotification)];
        break;
      case ExperimentEventType.ChromatographyDataRefreshed:
        history = [...this.getChromatographyDataRefreshedRecord(record as ChromatographyDataRefreshedEventNotification)];
        break;
      case ExperimentEventType.InstrumentEventNonRoutineIssueEncountered:
        history = this.getInstrumentEventNonRoutineIssueEncounteredRecord(record as NonRoutineIssueEncounteredDataChangeEventNotification, records);
        break;
      case ExperimentEventType.InstrumentEventExceptionReferenceChanged:
        history = [this.getInstrumentEventExceptionReferenceChangedEventRecordContext(record as InstrumentEventExceptionReferenceChangedEventNotification)];
        break;
      case ExperimentEventType.InstrumentEventJustificationChanged:
        history = [this.getInstrumentEventJustificationChangedEventRecordContext(record as InstrumentEventJustificationChangedEventNotification)];
        break;
      case ExperimentEventType.InstrumentEventReturnedToService:
        history = this.getInstrumentEventReturnedToServiceRecord(record as ReturnToServiceDataChangeEventNotification, records);
        break;
      case ExperimentEventType.ExperimentPreparationCreated:
        history = this.getPreparationEventRecord(record as ExperimentPreparationsCreatedNotification);
        break;
      case ExperimentEventType.ExperimentPreparationRestored:
        history = [this.getPreparationRestoredEventRecord(record as ActivityPreparationRestoredNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationRemoved:
        history = [this.getPreparationRemovedEventRecord(record as ActivityPreparationRestoredNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationDiscardedOrConsumed:
        history = [this.getPreparationDiscardOrConsumedEventRecord(record as PreparationDiscardedOrConsumedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationInternalInformationChanged:
        history = [this.getPreparationInternalInformationChangedEventRecord(record as PreparationInternalInformationChangedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationCellChanged:
        history = [this.getPreparationCellChangedEventRecord(record as PreparationCellChangedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationStatusChanged:
        history = [this.getPreparationStatusChangedEventRecord(record as ExperimentPreparationStatusChangedNotification)];
        break;
      case ExperimentEventType.ActivityReferenceTemplateApplied:
        history = [this.getActivityReferenceTemplateAppliedEventRecord(record as ReferenceTemplateAppliedEventNotification)];
        break;
      case ExperimentEventType.ActivityFilesAdded:
        history = [this.getActivityFilesAddedEventRecord(record as AttachedFileEventNotification)];
        break;
      case ExperimentEventType.ActivityFilesDeleted:
        history = [this.getActivityFilesDeletedEventRecord(record as DeletedFilesEventNotification)];
        break;
      case ExperimentEventType.LabItemsPreparationAdded:
        history = [this.getLabItemsPreparationAddedRecord(record as LabItemPreparationAddedEventNotification)];
        break;
      case ExperimentEventType.LabItemPreparationRemoved:
        history = this.getLabItemsPreparationRemovedRecord(record as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRestored:
        history = this.getLabItemsPreparationRestoredRecord(record as LabItemPreparationRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRefreshed:
        history = [this.getLabItemsPreparationRefreshedRecord(record as LabItemsPreparationRefreshedNotification)];
        break;
      case ExperimentEventType.DataPackageGenerated:
        history = this.getDataPackageGeneratedRecord(record as MarkDataPackageGeneratedEventNotification);
        break;
      case ExperimentEventType.PromptSatisfied:
        history = this.getPromptSatisfactionRecord(record as PromptSatisfiedEventNotification);
        break;
      case ExperimentEventType.ChangeReasonAdded:
        history = [];
        break;
      case ExperimentEventType.RowsAddedForEach:
        history = this.getRowsAddedForEachRecords(record as RowsAddedForEachEventNotification, nodeType, nodeId);
        break;
      default:
        history = [{
          Source: record,
          Time: record.eventContext.eventTime,
          Context: noContext,
          RecordType: record.eventContext.eventType,
          ContextType: noContext,
          Name: noContext,
          Description: noContext,
          RecordVersion: 1,
          ActualContext: noContext
        }];
        break;
    }
    return history;
  }

  /**
   * Creates a broad set of history entries from a repeat rows for each activity input event by synthesizing AddRowEventNotifications.
   * There is no entry for the event itself, only for the rows and cells affected.
   */
  private getRowsAddedForEachRecords(event: RowsAddedForEachEventNotification, nodeType?: NodeType, nodeId?: string | undefined): AuditHistory[] {
    // a RowsAddedForEachEventNotification can pertain to many cells in many rows in many tables in one activity
    let filterForScope: (table: RowsAddedForEachTableDetails) => boolean | undefined;
    switch (nodeType) {
      case NodeType.Experiment:
        filterForScope = () => true;
        break;
      case NodeType.Activity:
        filterForScope = () => !nodeId || event.activityId === nodeId;
        break;
      case NodeType.Module:
        filterForScope = (table) => !nodeId ||
          this.experimentService.currentExperiment?.activities.find(a => a.nodeId === event.activityId)
            ?.dataModules.find(m => m.nodeId === nodeId)?.items.some(mi => mi.nodeId === table.tableId);
        break;
      case NodeType.Table:
        filterForScope = (table) => !nodeId || table.tableId === nodeId;
        break;
      default:
        filterForScope = () => false;
        break;
    }
    return event.tables
      .filter(filterForScope)
      .flatMap(t => this.getRowsAddedAndContainedCellValuesRecords({
        ...event,
        experimentId: event.eventContext.experimentId,
        tableId: t.tableId,
        rows: t.rows.map(r => r.data),
      }));
  }

  private getAppliedStatementDescription(statementInputString: string | undefined, time: string): string {
    if (statementInputString) {
      const parsedStatement = JSON.parse(statementInputString);
      const formattedTime = formatInstant(time, DateAndInstantFormat.dateTimeToSecond);
      return `${parsedStatement.statementValue} statement applied by ${parsedStatement.puid} ${parsedStatement.userName} on ${formattedTime}`
    }
    return '';
  }

  private getStatementRecordType() {
    const eSign = $localize`:@@eSignature:E-Signature`;
    const statement = $localize`:@@recordType-statement:Statement`;
    return `${eSign},${statement}`;
  }

  private getStatementsContextType(record: StatementAppliedEventNotification) {
    return record.contextType === StatementContextType.ClientFacingNote ?
      $localize`:@@ClientFacingNote:Client Facing Note`
      : $localize`:@@experiment:Experiment`;
  }

  private getAppliedStatementContext(statementAppliedEventNotification: StatementAppliedEventNotification,
    currentStatementRecord: StatementContentDetails
  ): string {

    switch (statementAppliedEventNotification.contextType) {
      case StatementContextType.TableCell:
        return `${this.getTableCellContext(statementAppliedEventNotification.nodeId, currentStatementRecord.path[0], currentStatementRecord.path[1])}`;
      case StatementContextType.ClientFacingNote:
        return this.getCFNStatementsHistoryContext(statementAppliedEventNotification.cfnNumber);

      case StatementContextType.Invalid:
      default:
        // this can't happen unless new work is started but not finished.
        throw new Error(
          'Logic Error: Unimplemented StatementContextType' + statementAppliedEventNotification.contextType
        );
    }
  }

  private getCFNStatementsHistoryContext(cfnNumber?: number): string {
    const outputFormFieldIdentifiers = ['Non-Routine Issue Encountered', 'Exception Reference', 'Justification', 'Returned to Service'];

    const parentCfn = this.experiment?.clientFacingNotes?.find(n => n.number === cfnNumber);

    switch (parentCfn?.contextType) {
      case ClientFacingNoteContextType.FormField:
        return outputFormFieldIdentifiers.includes(parentCfn.path[0]) ?
          `${this.getStatementFieldContextForImpactAssessmentForm(parentCfn)}` :
          `${this.getFormContext(parentCfn.nodeId)} > ${this.getFieldLabelPathFromField(
            parentCfn.nodeId,
            parentCfn.path[0]
          ).join(' > ')}`;
      case ClientFacingNoteContextType.TableCell:
        return `${this.getTableCellContext(parentCfn.nodeId, parentCfn.path[0], parentCfn.path[1])}`;
      case ClientFacingNoteContextType.CrossReference:
        return `${this.getCrossReferenceCellContext(parentCfn.nodeId, parentCfn.path[0], parentCfn.path[1])}`;
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.LabItems:
        return `${this.getStatementLabItemClientFacingNoteContext(parentCfn, parentCfn.contextType)}`;
      case ClientFacingNoteContextType.LabItemsPreparation:
        return `${this.getStatementLabItemPreparationClientFacingNoteContext(parentCfn, parentCfn.contextType)}`;
      case ClientFacingNoteContextType.ActivityInput:
        return `${this.getStatementActivityInputClientFacingNoteContext(parentCfn, parentCfn.contextType)}`;
      case ClientFacingNoteContextType.Preparations:
        return `${this.getStatementExperimentPreparationClientFacingNoteContext(parentCfn, parentCfn.contextType)}`;
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Table:
      default:
        // this can't happen unless new work is started but not finished.
        throw new Error('Logic Error: Unimplemented ClientFacingNoteContextType' + parentCfn?.contextType);
    }
  }

  private getStatementExperimentPreparationClientFacingNoteContext(note: ClientFacingNoteModel, clientFacingNoteContextType: ClientFacingNoteContextType): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      clientFacingNoteContextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.dataRecordUtilityService.getActivityContext(activityId).fullPath : '';
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';
    const rowId = context.rowId ? this.getExperimentPreparationNumber(note.nodeId, context.rowId) : '';
    return `${fullPath} > ${this.experimentPreparationTitle} > ${rowId} > ${fieldName}`;
  }

  private getStatementActivityInputClientFacingNoteContext(note: ClientFacingNoteModel, clientFacingNoteContextType: ClientFacingNoteContextType): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      clientFacingNoteContextType,
      note.path
    );
    const columns = [...StudyActivityTableGridOptions.prepareColumns(), ...SampleTableGridOptions.prepareColumns()];
    const fieldName = columns.find(c => c.field === context.columnField);
    return `${this.dataRecordUtilityService.getActivityContext(this.experimentService.currentContextMenuActivityId).fullPath} > ${this.activityInputsTitle} > ${context.rowId} > ${fieldName?.label}`;
  }

  public getContextByTypeAndPath(nodeId: string, contextType: ClientFacingNoteContextType, path: string[]) {
    switch (contextType) {
      case ClientFacingNoteContextType.TableCell:
        return { tableId: nodeId, rowId: path[0], columnField: path[1] };
      case ClientFacingNoteContextType.LabItems:
        return { labItemId: path[2], rowId: path[0], columnField: path[1], labItemType: path[3] };
      case ClientFacingNoteContextType.Preparations:
        return { nodeId: path[2], preparationsTableId: path[2].concat('-Preparations'), rowId: path[0], columnField: path[1] };
      case ClientFacingNoteContextType.LabItemsPreparation:
        return {
          nodeId: path[2],
          preparationsTableId: path[2].concat('-labItemsPreparations'),
          rowId: path[0],
          columnField: path[1],
          labItemsPreparationIdentifier: 'labItemsPreparation'
        };
      case ClientFacingNoteContextType.FormField:
        return { formId: nodeId, fieldIdentifier: path[0] };
      case ClientFacingNoteContextType.CrossReference:
        return { activityId: nodeId, rowId: path[0], columnField: path[1] };
      case ClientFacingNoteContextType.ActivityInput:
        return { activityInputId: nodeId, rowId: path[0], columnField: path[1], activityId: path[2], tableTitle: path[3], label: path[4] };
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default: // default to table cell. from here on out, this should never get hit unless we're accessing a note older than 10/25/2022.
        return { tableId: nodeId, rowId: path[0], columnField: path[1] };
    }
  }

  private getStatementLabItemPreparationClientFacingNoteContext(note: ClientFacingNoteModel, clientFacingNoteContextType: ClientFacingNoteContextType): string {
    const labItemContext = this.getContextByTypeAndPath(
      note.nodeId,
      clientFacingNoteContextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.dataRecordUtilityService.getActivityContext(activityId).fullPath : '';
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(ActivityInputType.Preparation);
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';
    const rowId = labItemContext.rowId ? this.getPreparationNumber(note.nodeId, labItemContext.rowId) : '';
    return `${fullPath} > ${this.labItemsTitle} > ${labItemsContextTitle} > ${rowId} > ${fieldName}`;
  }

  private getStatementLabItemClientFacingNoteContext(note: ClientFacingNoteModel, clientFacingNoteContextType: ClientFacingNoteContextType): string {
    const labItemContext = this.getContextByTypeAndPath(
      note.nodeId,
      clientFacingNoteContextType,
      note.path
    );
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(labItemContext.labItemType as ActivityInputType);
    let fieldName;
    switch (note.path[3]) {
      case ActivityInputType.Material:
        fieldName = LabItemsMaterialTableOptions.ColumnDefinition[note.path[1]]?.displayName;
        break;
      case ActivityInputType.InstrumentDetails:
        fieldName = LabItemsInstrumentTableOptions.ColumnDefinition[note.path[1]]?.displayName;
        break;
      case ActivityInputType.InstrumentColumn:
        fieldName = LabItemsColumnTableOptions.ColumnDefinition[note.path[1]]?.displayName;
        break;
      case ActivityInputType.Consumable:
        fieldName = LabItemsConsumablesTableOptions.ColumnDefinition[note.path[1]]?.displayName;
        break;
    }
    return `${this.dataRecordUtilityService.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${this.labItemsTitle
      } > ${labItemsContextTitle} > ${labItemContext.rowId} > ${fieldName}`;
  }

  private getStatementFieldContextForImpactAssessmentForm(node: ClientFacingNoteModel): string {
    const experimentName = this.experiment.experimentNumber;
    const activityName = this.experiment?.activities.find(activity => activity.activityId === node.nodeId)?.itemTitle;
    return `${experimentName} > ${activityName} > Outputs > Instrument Event Impact Assessment > Impact Assessment > ${node.path[0]}`;
  }

  private getStatementAppliedRecords(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as StatementAppliedEventNotification;
    const recordType = this.getStatementRecordType();
    const contextType = this.getStatementsContextType(record);

    const auditHistory: AuditHistory[] = [];
    record.contentDetails.forEach(c => {
      auditHistory.push(this.dataRecordUtilityService.getHistory(
        experimentRecord,
        this.getAppliedStatementContext(record, c),
        recordType,
        contextType,
        'C',
        this.getAppliedStatementDescription(c.content, experimentRecord.eventContext.eventTime),
        this.experiment.experimentNumber
      ))
    });
    return auditHistory;
  }

  getActivityReferenceTemplateAppliedEventRecord(event: ReferenceTemplateAppliedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const refAdded = $localize`:@@referenceAdded:Reference table added`;
    const recordType = $localize`:@@referenceTemplateApplied:Reference, New`;
    const type = this.titleCasePipe.transform(event.type); // Capitalize first letter
    const context = `${this.experiment.title}/${activityName}/${type}`;
    return this.dataRecordUtilityService.getHistory(event, context, recordType, type, recordType, `${refAdded}: ${activityName}/${type}`);
  }

  getExperimentWorkflowStateRecords(experimentRecord: WorkflowEventNotification): AuditHistory[] {
    const historyList: AuditHistory[] = [];
    experimentRecord.eSignatureContext = {
      signed: true
    };
    let eSignedRecord: AuditHistory | undefined;
    switch (experimentRecord.eventContext.eventType) {
      case ExperimentEventType.ExperimentRestored:
        eSignedRecord = this.getESignedRecordOfExperimentRestoredToSetupTransition(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSetupReviewRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentStarted:
        eSignedRecord = this.getESignedRecordOfExperimentStarted(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentStartedRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentSentForReview:
        eSignedRecord = this.getESignedRecordOfExperimentInReview(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSentForReviewRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentSentForCorrection:
        eSignedRecord = this.getESignedRecordOfExperimentInCorrection(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSentForCorrectionRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentAuthorized:
        eSignedRecord = this.getESignedRecordOfExperimentAuthorized(experimentRecord);
        historyList.push(this.getExperimentAuthorizedRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentCancelled:
        eSignedRecord = this.getESignedRecordOfExperimentCancelled(experimentRecord);
        historyList.push(this.getExperimentCancelledRecord(experimentRecord));
        break;
    }
    if (eSignedRecord) {
      historyList.push(eSignedRecord);
    }
    this.experimentWorkFlowPreviousStatus = experimentRecord;
    return historyList;
  }

  /**
   * Gets the history-style formatted context of a table
   */
  private getTableContext(tableId: string): string {

    const tableTitle = this.getTableTitleForExperiment(tableId);

    const parentModule = this.experiment.activities.flatMap(a => a.dataModules)
      .find(m => m.items.filter((mi): mi is Table => mi.itemType === NodeType.Table).find((t: Table) => t.tableId === tableId));

    // activity reference tables are not associated with a module, therefore we need to inspect the activity's references.
    const parentActivity = this.experiment.activities.find(a => {
      const module = a.dataModules.find(m => m.moduleId === parentModule?.moduleId);
      return !!module || a.activityReferences.compendiaReferencesTableId === tableId || a.activityReferences.documentReferencesTableId === tableId
    });

    let parentPath = '';
    if (parentModule) {
      // traditional table nested within a module
      parentPath = `${parentActivity?.itemTitle} > ${parentModule.moduleLabel}`;
    } else if (parentActivity) {
      // specialized table node not associated with a module, such as document/compendia
      const label = $localize`:@@references:References`;
      parentPath = `${parentActivity.itemTitle} > ${label}`;
    }
    return `${parentPath} > ${tableTitle}`;
  }

  public getTableTitleForExperiment(tableId: string): string {
    return this.experimentService.getTable(tableId)?.itemTitle ?? $localize`:@@NoTitle:No Title`
  }

  public getFormTitle(formId: string): string {
    const form = this.allModuleItemsInExperiment.find((f) => f.itemType === NodeType.Form && (f as Form).formId === formId);
    return form?.itemTitle ?? $localize`:@@noTitle:No Title`
  }

  private getActivityInputContext(type: ActivityInputType): string {
    let pageTitle = '';
    let activityId = this.experimentService.currentContextMenuActivityId;
    if (activityId === '' && this.experimentService.currentActivity?.activityId) {
      activityId = this.experimentService.currentActivity?.activityId;
    }
    switch (type) {
      case ActivityInputType.Aliquot:
        pageTitle = this.sampleAliquotsTitle;
        break;
      case ActivityInputType.Material:
        pageTitle = this.materialAliquotsTitle;
        break;
      case ActivityInputType.Instrument:
        pageTitle = this.instrumentPageTitle;
        break;
    }
    return `${this.dataRecordUtilityService.getActivityContext(activityId).fullPath} > ${this.activityInputsTitle} > ${pageTitle}`;
  }

  /**
   * Gets the history-style formatted context of a form
   */
  private getFormContext(formId: string): string {
    const formName =
      this.experimentResponse?.forms.find(f => f.formId === formId)?.itemTitle === undefined
        ? $localize`:@@noTitle:No Title`
        : this.experimentResponse?.forms.find(f => f.formId === formId)?.itemTitle;
    const moduleId = this.experimentResponse?.modules.find((m) =>
      m.childOrder.find((c) => c === formId)
    )?.moduleId;
    const moduleDetails = [
      this.experimentResponse?.modules.find(m => m.childOrder.find(c => c === formId))?.moduleLabel,
      moduleId
    ];
    const activityName = this.experimentResponse?.activities.find(a =>
      a.childOrder.find((c) => c === moduleDetails[1])
    )?.itemTitle;
    const module = `${activityName} > ${moduleDetails[0]}`;
    return `${module} > ${formName}`;
  }

  /**
   * Gets the history-style formatted context and initial title of form/table
   * @param templateId templateId of the form/table
   * @param nodeId Experiment's nodeId of the form/table
   * @param title (optional) Title of the form/table
   * @returns Context and Title of the form/table
   */
  private getFormOrTableContextWithInitialTitle(templateId: string, nodeId: string, title = ''): {
    fullPath: string,
    title: string
  } {
    //If the event does not store the form/table title, the title is taken from the corresponding node of the applied template
    const formOrTableTitle = title || objectCache[templateId]?.itemTitle; // Do not replace || with ??
    const currentFormOrTableTitle = this.allModuleItemsInExperiment.find(a => a.nodeId === nodeId)?.itemTitle;
    const module = this.allModulesInExperiment.find(m => m.items.find(i => i.nodeId === nodeId));
    const moduleTitle = module?.moduleLabel as string;
    const activityTitle = this.experiment.activities.find(a => a.dataModules.some(m => m.nodeId === module?.nodeId))?.itemTitle ?? $localize`:@@noTitle:No Title`;
    return { fullPath: `${activityTitle} > ${moduleTitle} > ${currentFormOrTableTitle}`, title: `${formOrTableTitle}` };
  }

  /**
   * Searches a tree of fields for a leaf field, accumulating the labels on the path
   * @param fieldDefinitions array of field or field-groups to iterate over and search into
   * @param field is the leaf to find
   * @returns label path of field-groups down to the field
   */
  getFieldLabelPathFromField(formId: string, field: string): string[] {
    const form = this.experimentService.getForm(formId);
    if (!form) throw new Error('Logic Error: Form not found in experiment ' + formId); // this can't happen!

    const labels: string[] = [];
    this.getFieldLabelPathFromFieldImpl(form.fieldDefinitions, field, labels);
    return labels;
  }

  /**
   * Implementation for the recursive part of getFieldLabelPathFromField
   * @param fieldDefinitions array of field or field-groups to iterate over and search into
   * @param field is the leaf to find
   * @param labels is the array modified to hold the candidate result
   * @returns true if leave found
   */
  private getFieldLabelPathFromFieldImpl(
    fieldDefinitions: (FieldGroupResponse | FieldDefinitionResponse)[],
    field: string,
    labels: string[]
  ): boolean {
    for (const definition of fieldDefinitions) {
      if ('fieldDefinitions' in definition) {
        labels.push(definition.itemTitle); // itemTitle is an old synonym for label.
        if (definition.field === field) return true;
        const found = this.getFieldLabelPathFromFieldImpl(
          definition.fieldDefinitions,
          field,
          labels
        );
        if (found) return true;
        labels.pop();
      } else if (definition.field === field) {
        labels.push(definition.label);
        return true;
      }
    }
    return false;
  }
  /**
   * gets context of experiment cover records
   * @param experimentRecord
   * @returns
   */
  private getExperimentRecords(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[],
    nodeType? : NodeType, nodeId?: string | undefined): AuditHistory[] {
    let history: AuditHistory[];
    switch (experimentRecord.eventContext.eventType) {
      case ExperimentEventType.AssignedAnalystsChanged:
        history = [this.getAssignedAnalystsRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.AssignedReviewersChanged:
        history = [this.getAssignedReviewersRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.AssignedSupervisorsChanged:
        history = [this.getAssignedSupervisorsRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.ClientsChanged:
        history = [this.getClientChangedRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.ProjectsChanged:
        history = [this.getProjectsChangedRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.AuthorizationDueDateChanged:
        history = [this.getAuthorizedDueDateRecord(experimentRecord)];
        break;
      case ExperimentEventType.ExperimentCreated:
        history = this.getExperimentCreatedRecord(experimentRecord);
        break;
      case ExperimentEventType.ScheduledReviewStartDateChanged:
        history = [this.getScheduledReviewStartDateRecord(experimentRecord)];
        break;
      case ExperimentEventType.ScheduledStartDateChanged:
        history = [this.getScheduledStartDateRecord(experimentRecord)];
        break;
      case ExperimentEventType.SubBusinessUnitsChanged:
        history = [this.getSubBusinessUnitsRecord(experimentRecord, records)];
        break;
      case ExperimentEventType.TagsChanged:
        history = [this.getTagsChangedRecord(experimentRecord)];
        break;
      case ExperimentEventType.TitleChanged:
        history = [this.getTitleChangedRecord(experimentRecord)];
        break;
      case ExperimentEventType.ExperimentTemplateApplied:
        history = this.getTemplateAppliedRecord(experimentRecord, records, nodeType);
        break;
      case ExperimentEventType.ExperimentRecipeApplied:
        history = this.recipeDataRecordService.getRecipeAppliedRecord(experimentRecord, nodeType, nodeId);
        break;
      default:
        history = [];
        console.error(caseNotHandledEvenThoughPlanned);
        break;
    }
    return history;
  }

  private getClientFacingNoteChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ClientFacingNoteChangedEventNotification;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.getClientFacingNoteHistoryContext(record.number),
      $localize`:@@ClientFacingNoteChanged:Client Facing Note Changed`,
      $localize`:@@ClientFacingNote:Client Facing Note`,
      'C',
      record.content.value?.toString() ?? ''
    );
  }

  private getClientFacingNoteCreatedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ClientFacingNoteCreatedEventNotification;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.getClientFacingNoteHistoryContext(record.number),
      $localize`:@@ClientFacingNoteCreated:Client Facing Note Created`,
      $localize`:@@ClientFacingNote:Client Facing Note`,
      'C',
      record.content.value?.toString() ?? ''
    );
  }

  private getClientFacingNoteHistoryContext(number: number): string {
    const outputFormFieldIdentifiers = ['Non-Routine Issue Encountered', 'Exception Reference', 'Justification', 'Returned to Service'];
    if (!this.experimentResponse) throw new Error('Logic Error: Experiment not found for client-facing note ' + number); // this can't happen!

    const note = this.experimentResponse.clientFacingNotes.find(n => n.number === number);
    if (!note) throw new Error('Logic Error: Note not found in experiment for client-facing note ' + number); // this can't happen!

    switch (note.contextType) {
      case ClientFacingNoteContextType.FormField:
        return outputFormFieldIdentifiers.includes(note.path[0]) ?
          `${this.getFieldContextForImpactAssessmentForm(note)}` :
          `${this.getFormContext(note.nodeId)} > ${this.getFieldLabelPathFromField(
            note.nodeId,
            note.path[0]
          ).join(' > ')}`;
      case ClientFacingNoteContextType.TableCell:
        return `${this.getTableCellContext(note.nodeId, note.path[0], note.path[1])}`;
      case ClientFacingNoteContextType.CrossReference:
        return `${this.getCrossReferenceCellContext(note.nodeId, note.path[0], note.path[1])}`;

      // Some of these will be implemented in Product Backlog Item 3146899: Client-facing Notes: Use for other contexts
      // https://alm1.eurofins.local/tfs/BPTCollection/Eurofins%20ELN/_workitems/edit/3146899
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.LabItems:
        return `${this.getLabItemClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.LabItemsPreparation:
        return `${this.getLabItemPreparationClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.ActivityInput:
        return `${this.getActivityInputClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.Preparations:
        return `${this.getExperimentPreparationClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Table:
      default:
        // this can't happen unless new work is started but not finished.
        throw new Error(
          'Logic Error: Unimplemented ClientFacingNoteContextType' + note.contextType
        );
    }
  }

  private getFieldContextForImpactAssessmentForm(note: ClientFacingNote): string {
    const experimentName = this.experiment.experimentNumber;
    const activityName = this.experiment?.activities.find(activity => activity.activityId === note.nodeId)?.itemTitle;
    return `${experimentName} > ${activityName ?? 'No Title'} > Outputs > Instrument Event Impact Assessment > Impact Assessment > ${note.path[0]}`;
  }

  private getExperimentAuthorizedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentAuthorizedEventNotification;
    const authorized = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = authorized + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentSentForCorrectionRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    const sentForCorrection = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = sentForCorrection + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentCancelledRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentCancelledEventNotification;
    const sentForCancellation = $localize`:@@TransitionedTo:Transitioned to `;
    const description = sentForCancellation + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentSentForReviewRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForReviewEventNotification;
    const sentForReview = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = sentForReview + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getTemplateAppliedRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[], nodeType?: NodeType) {
    const record = experimentRecord as ExperimentTemplateAppliedEventNotification;
    const defaultRecordType = ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.New];
    const recordType = ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, defaultRecordType);
    const templateAppliedAuditHistory: AuditHistory[] = this.getAuditHistoryForTemplateAppliedRecord(record, recordType, nodeType);
    if (records.some(r => r.eventContext.eventType === ExperimentEventType.ExperimentCreated)) {
      const templateTitle =
        record.activityReferenceNumber !== NA
          ? `${record.templateTitle} ${record.activityReferenceNumber}`
          : record.templateTitle;
      const expTemplateApplied = $localize`:@@ExperimentTemplateApplied:Experiment Template Applied`;
      const appliedDescription = $localize`:@@TemplatedAddedToExperiment:Template added to an experiment: ` + templateTitle;
      templateAppliedAuditHistory.push(this.dataRecordUtilityService.getHistory(
        experimentRecord,
        `${this.cover} > ${expTemplateApplied}`,
        expTemplateApplied,
        this.cover,
        `${this.cover} > ${expTemplateApplied}`,
        appliedDescription
      ));
    }
    return templateAppliedAuditHistory;
  }

  private getTitleChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as TitleChangedEventNotification;
    const titleChanged = $localize`:@@title:Title`;
    const description = $localize`:@@titleChangedColon:Title Changed: ` + record.title;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${titleChanged}`,
      $localize`:@@titleChanged:Title Changed`,
      this.cover,
      `${this.cover} > ${titleChanged}`,
      description
    );
  }

  private getTagsChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as TagsChangedEventNotification;
    let description = '';
    if (record.addedTags?.length > 0) {
      description =
        $localize`:@@TagsAdded:Added Tags: ` + record.addedTags?.join(`,${ELNAppConstants.WhiteSpace}`);
    }
    if (record.removedTags?.length > 0) {
      description =
        description.length > 0
          ? description.concat(
            ELNAppConstants.WhiteSpace,
            $localize`:@@TagsRemoved:Removed Tags: ` + record.removedTags?.join(`,${ELNAppConstants.WhiteSpace}`)
          )
          : description.concat(
            $localize`:@@TagsRemoved:Removed Tags: ` + record.removedTags?.join(`,${ELNAppConstants.WhiteSpace}`)
          );
    }
    const tagsChanged = $localize`:@@tags:Tags`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${tagsChanged}`,
      $localize`:@@TagsChanged:Tags Changed`,
      this.cover,
      `${this.cover} > ${tagsChanged}`,
      description
    );
  }

  private getScheduledStartDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ScheduledStartDateChangedEventNotification;
    const scheduledStartDate =
      record.scheduledStartDate === null || record.scheduledStartDate === undefined
        ? ''
        : formatLocalDate(record.scheduledStartDate);
    const description = $localize`:@@ScheduledStartDateChanged:Scheduled Start Date Changed`;
    const scheduledStart = $localize`:@@ScheduledStartDate:Scheduled Start Date`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${scheduledStart}`,
      $localize`:@@ScheduledStartDateChanged:Scheduled Start Date Changed`,
      this.cover,
      `${this.cover} > ${scheduledStart}`,
      description.concat(': ', scheduledStartDate)
    );
  }

  private getScheduledReviewStartDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ScheduledReviewStartDateChangedEventNotification;
    const scheduledReviewDate =
      record.scheduledReviewStartDate === null || record.scheduledReviewStartDate === undefined
        ? ''
        : formatLocalDate(record.scheduledReviewStartDate);
    const description = $localize`:@@ScheduledReviewStartDateChanged:Scheduled Review Start Date Changed`;
    const scheduledReview = $localize`:@@ScheduledReviewStartDate:Scheduled Review Start Date`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${scheduledReview}`,
      $localize`:@@ScheduledReviewStartDateChanged:Scheduled Review Start Date Changed`,
      this.cover,
      `${this.cover} > ${scheduledReview}`,
      description.concat(': ', scheduledReviewDate)
    );
  }

  private getExperimentStartedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentStartedEventNotification;
    const experimentStarted = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = experimentStarted + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentCreatedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const expCreated = $localize`:@@experimentCreated:Experiment Created`;
    const experimentCreatedAudit = [];
    const record = experimentRecord as ExperimentCreatedEventNotification;
    const defaultRecordType = ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.New];
    const recordType = ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, defaultRecordType);
    const title = $localize`:@@title:Title`;
    const experimentTitle = record.title;
    experimentCreatedAudit.push(this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${title}`,
      recordType,
      this.cover,
      $localize`:@@experimentCreated:Experiment Created`,
      experimentTitle
    ));
    experimentCreatedAudit.push(this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${expCreated}`,
      $localize`:@@experimentCreated:Experiment Created`,
      this.cover,
      $localize`:@@experimentCreated:Experiment Created`,
      expCreated
    ));
    return experimentCreatedAudit;
  }

  private getAuthorizedDueDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as AuthorizationDueDateChangedEventNotification;
    const authorizedDueDate =
      record.authorizationDueDate === null || record.authorizationDueDate === undefined
        ? ''
        : formatLocalDate(record.authorizationDueDate);
    const description = $localize`:@@AssignedDueDateChanged:Assigned Due Date Changed`;
    const assignedDueDate = $localize`:@@AssignedDueDate:Assigned Due Date`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedDueDate}`,
      $localize`:@@assignedDueDateChanged:Assigned Due Date Changed`,
      this.cover,
      `${this.cover} > ${assignedDueDate}`,
      description.concat(': ', authorizedDueDate)
    );
  }

  private getSubBusinessUnitsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as SubBusinessUnitsChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(
      this.getSubBusinessUnits(currentValue).join(', '),
      this.getSubBusinessUnits(record.addedSubBusinessUnits),
      this.getSubBusinessUnits(record.removedSubBusinessUnits)
    );

    const subBusinessUnitsChanged = $localize`:@@SubBusinessUnits:Sub Business Units`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${subBusinessUnitsChanged}`,
      $localize`:@@SubBusinessUnitsChanged:Sub Business Units Changed`,
      this.cover,
      `${this.cover} > ${subBusinessUnitsChanged}`,
      description
    );
  }

  /** Travel back in time and replay history to that point to see what the current value was at any given point in time */
  static replayHistory(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): any[] {
    const eventType = experimentRecord.eventContext.eventType;
    const time = Instant.parse(experimentRecord.eventContext.eventTime);

    const recordsOfType = records.filter((r): r is AssignmentEventNotifications => ([eventType, ExperimentEventType.ExperimentCreated].includes(r.eventContext.eventType)));
    const historyRecords = recordsOfType
      .filter(r => r.eventContext.experimentId === experimentRecord.eventContext.experimentId)
      .map(h => ({ ...h, eventTime: Instant.parse(h.eventContext.eventTime) }))
      .sort((a, b) => a.eventTime.compareTo(b.eventTime));

    const createdEventAdded = (createdEvent: ExperimentCreatedEventNotification) => {
      switch (eventType) {
        case ExperimentEventType.AssignedAnalystsChanged: return createdEvent.assignedAnalysts;
        case ExperimentEventType.AssignedSupervisorsChanged: return createdEvent.assignedSupervisors;
        case ExperimentEventType.AssignedReviewersChanged: return createdEvent.assignedReviewers;
        case ExperimentEventType.SubBusinessUnitsChanged: return createdEvent.subBusinessUnits;
        case ExperimentEventType.ClientsChanged: return createdEvent.clients ? createdEvent.clients : [];
        case ExperimentEventType.ProjectsChanged: return createdEvent.projects ? createdEvent.projects : [];
        default: return [];
      };
    };

    let currentValue: any[] = [];
    const timeTravel = historyRecords.filter(r => r.eventTime.compareTo(time) < 1);
    timeTravel.forEach(record => {
      let added: any[] = [];
      let removed: any[] = [];
      switch (record.eventContext.eventType) {
        case ExperimentEventType.ExperimentCreated:
          added = createdEventAdded(record as ExperimentCreatedEventNotification);
          break;
        case ExperimentEventType.AssignedSupervisorsChanged: {
          const assignedSupervisorsChangedEventNotification = record as AssignedSupervisorsChangedEventNotification;
          added = assignedSupervisorsChangedEventNotification.addedSupervisors;
          removed = assignedSupervisorsChangedEventNotification.removedSupervisors;
          break;
        }
        case ExperimentEventType.AssignedAnalystsChanged: {
          const assignedAnalystsChangedEventNotification = record as AssignedAnalystsChangedEventNotification;
          added = assignedAnalystsChangedEventNotification.addedAnalysts;
          removed = assignedAnalystsChangedEventNotification.removedAnalysts;
          break;
        }
        case ExperimentEventType.AssignedReviewersChanged: {
          const assignedReviewersChangedEventNotification = record as AssignedReviewersChangedEventNotification;
          added = assignedReviewersChangedEventNotification.addedReviewers;
          removed = assignedReviewersChangedEventNotification.removedReviewers;
          break;
        }
        case ExperimentEventType.SubBusinessUnitsChanged: {
          const subBusinessUnitsChangedEventNotification = record as SubBusinessUnitsChangedEventNotification;
          added = subBusinessUnitsChangedEventNotification.addedSubBusinessUnits;
          removed = subBusinessUnitsChangedEventNotification.removedSubBusinessUnits;
          break;
        }
        case ExperimentEventType.ClientsChanged: {
          const experimentClientsChangedEventNotification = record as Partial<ExperimentClientsChangedEventNotification>;
          if (experimentClientsChangedEventNotification.addedClients && experimentClientsChangedEventNotification.removedClients) {
            added = experimentClientsChangedEventNotification.addedClients;
            removed = experimentClientsChangedEventNotification.removedClients;
          }
          break;
        }
        case ExperimentEventType.ProjectsChanged: {
          const experimentProjectsChangedEventNotification = record as Partial<ExperimentProjectsChangedEventNotification>;
          if (experimentProjectsChangedEventNotification.addedProjects && experimentProjectsChangedEventNotification.removedProjects) {
            added = experimentProjectsChangedEventNotification.addedProjects;
            removed = experimentProjectsChangedEventNotification.removedProjects;
          }
          break;
        }
        default:
          throw new Error('Cannot replay history on unsupported type: ' + experimentRecord.eventContext.eventType);
      }
      currentValue.push(...difference(added, currentValue)); // duplicate data records is known issue, so need to de-dupe them
      currentValue = difference(currentValue, removed);
    });

    return currentValue;
  }

  private getAssignedSupervisorsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as AssignedSupervisorsChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedSupervisors), this.getUsers(record.removedSupervisors));

    const assignedSupervisors = $localize`:@@AssignedSupervisors:Assigned Supervisors`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedSupervisors}`,
      $localize`:@@AssignedSupervisorsChanged:Assigned Supervisors Changed`,
      this.cover,
      `${this.cover} > ${assignedSupervisors}`,
      description
    );
  }

  private getAssignedReviewersRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as AssignedReviewersChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedReviewers), this.getUsers(record.removedReviewers));

    const assignedReviewers = $localize`:@@AssignedReviewers:Assigned Reviewers`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedReviewers}`,
      $localize`:@@AssignedReviewersChanged:Assigned Reviewers Changed`,
      this.cover,
      `${this.cover} > ${assignedReviewers}`,
      description
    );
  }

  private getAssignedAnalystsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const record = experimentRecord as AssignedAnalystsChangedEventNotification;
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedAnalysts), this.getUsers(record.removedAnalysts));

    const assignedAnalyst = $localize`:@@assignedAnalystsNoParens:Assigned Analysts`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedAnalyst}`,
      $localize`:@@AssignedAnalystsChanged:Assigned Analysts Changed`,
      this.cover,
      `${this.cover} > ${assignedAnalyst}`,
      description
    );
  }

  private getClientChangedRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const record = experimentRecord as ExperimentClientsChangedEventNotification;
    const description = this.getMultiselectDescription(this.getClients(currentValue).join(', '), this.getClients(record.addedClients), this.getClients(record.removedClients));
    const client = $localize`:@@client:Client(s)`;

    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${client}`,
      $localize`:@@ClientsChanged:Client(s) Changed`,
      this.cover,
      `${this.cover} > ${client}`,
      description
    );
  }

  private getProjectsChangedRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const record = experimentRecord as ExperimentProjectsChangedEventNotification;
    const description = this.getMultiselectDescription(this.getProjects(currentValue).join(', '), this.getProjects(record.addedProjects), this.getProjects(record.removedProjects));
    const project = $localize`:@@project:Project(s)`;

    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      `${this.cover} > ${project}`,
      $localize`:@@ProjectsChanged:Project(s) Changed`,
      this.cover,
      `${this.cover} > ${project}`,
      description
    )
  }

  /**
   * @param currentValue String representing full value to display to user. Usually, this is a ', ' (comma-space) separated string.
   * i.e. 'Some value, Some other value'
   */
  private getMultiselectDescription(currentValue: string, selected: string[], unselected: string[]) {
    const selectedText = $localize`:@@selected:Selected`;
    const unselectedText = $localize`:@@unselected:Unselected`;
    const currentValueText = $localize`:@@currentValue:Current Value`;
    let description = `${currentValueText}: ${currentValue}\n`;

    selected.forEach((v: string) => {
      description += `${selectedText} ${v}\n`;
    });

    unselected.forEach((v: string) => {
      description += `${unselectedText} ${v}\n`;
    });

    return description.trim();
  }

  private getUsers(addedAnalysts: string[]) {
    return this.usersList.filter((u) => addedAnalysts.includes(u.puid)).map((m) => m.fullName);
  }

  private getClients(record: string[]) {
    return this.projectLogLoaderService.getAllClients().filter(c => record.includes(c.value)).map(m => m.label);
  }

  private getProjects(projectIds: string[]) {
    return this.projectLogLoaderService.getProjects(projectIds).map(projectIds => projectIds.label);
  }

  private getSubBusinessUnits(record: string[]) {
    return first(this.labsites)
      ?.subBusinessUnits?.filter((item: { code: string }) => record.includes(item.code))
      .map((m: { displayLabel: any }) => m.displayLabel) as string[];
  }

  private getExperimentState(state: ExperimentWorkflowState) {
    let currentState = '';
    switch (state) {
      case ExperimentWorkflowState.Setup:
        currentState = $localize`:@@setupState:Setup`;
        break;
      case ExperimentWorkflowState.InProgress:
        currentState = $localize`:@@inProgressState:In Progress`;
        break;
      case ExperimentWorkflowState.InReview:
        currentState = $localize`:@@inReviewState:In Review`;
        break;
      case ExperimentWorkflowState.InCorrection:
        currentState = $localize`:@@inCorrectionState:In Correction`;
        break;
      case ExperimentWorkflowState.Authorized:
        currentState = $localize`:@@authorizedState:Authorized`;
        break;
      case ExperimentWorkflowState.Cancelled:
        currentState = $localize`:@@cancelledState:Cancelled`;
        break;
    }
    return currentState;
  }

  private getTableRecords(tableRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    switch (tableRecord.eventContext.eventType) {
      case ExperimentEventType.CellChanged:
        const allCellChangedRecords: CellChangedEventNotification[]
          = records.filter((r): r is CellChangedEventNotification => r.eventContext.eventType === ExperimentEventType.CellChanged);
        return this.getCellChangedRecord(tableRecord as CellChangedEventNotification, allCellChangedRecords);
      case ExperimentEventType.RowsAdded:
        return this.getRowsAddedAndContainedCellValuesRecords(tableRecord as AddRowEventNotification);
      case ExperimentEventType.RowRemoved:
        return [this.getRowRemovedRecord(tableRecord)];
      case ExperimentEventType.RowRestored:
        return [this.getRowRestoredRecord(tableRecord)];
      case ExperimentEventType.RowsRenumbered:
        return [this.getRowsRenumberedRecord(tableRecord)];
      default:
        return [];
    }
  }

  private getChromatographyDataImportedRecord(event: ChromatographyDataImportedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${event.resultSetId}`;
    const imported = $localize`:@@imported:Imported`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No Title'} > ${this.activityOutputsTitle} > ${resultSetName}`,
      RecordType: $localize`:@@new:New`,
      ContextType: $localize`:@@Table:Table`,
      Name: resultSetName,
      Description: `${imported}: ${resultSetName}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.chromatographyDataId + event.eventContext.eventType
    }
  }

  private getChromatographyDataRemovedRecord(event: ChromatographyDataRemovedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const resultSetId =
      this.experiment.activityOutputChromatographyResultSetsSummary?.find(resultSet => resultSet.chromatographyDataId === event.chromatographyDataId)?.resultSetId
      ?? OutputEmpowerService.removedChromatographyData.get(event.chromatographyDataId);
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${resultSetId}`;

    const removed = $localize`:@@removed:Removed`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${resultSetName}`,
      RecordType: $localize`:@@removed:Removed`,
      ContextType: $localize`:@@Table:Table`,
      Name: resultSetName,
      Description: `${removed}: ${resultSetName}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.chromatographyDataId + event.eventContext.eventType
    }
  }

  private getChromatographyDataRefreshedRecord(event: ChromatographyDataRefreshedEventNotification): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const resultSetId = this.experiment.activityOutputChromatographyData?.find(resultSet => resultSet.chromatographyDataId === event.chromatographyDataId)?.resultSetId;
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${resultSetId}`;
    const refreshHistory: AuditHistory[] = []
    event.resultsAdded?.forEach((idOfResultSet) => {
      refreshHistory.push({
        Source: event,
        Time: event.eventContext.eventTime,
        Context: `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${resultSetName}`,
        RecordType: $localize`:@@new:New`,
        ContextType: $localize`:@@Table:Table`,
        Name: $localize`:@@empowerTableTitle:Empower Results - ` + resultSetId,
        Description: $localize`:@@EmpowerResultIdAdded:Empower Result ${idOfResultSet} Added`,
        RecordVersion: 1,
        PerformedBy: this.usersList?.find(
          (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
        )?.fullName,
        ActualContext: event.chromatographyDataId + event.eventContext.eventType
      })
    })
    event.resultsRemoved?.forEach((result) => {
      refreshHistory.push({
        Source: event,
        Time: event.eventContext.eventTime,
        Context: `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${resultSetName}`,
        RecordType: $localize`:@@rowRemoved:Row Removed`,
        ContextType: $localize`:@@Table:Table`,
        Name: $localize`:@@empowerTableTitle:Empower Results - ` + resultSetId,
        Description: $localize`:@@EmpowerResultIdRemoved:Empower Result ${result} Removed`,
        RecordVersion: 1,
        PerformedBy: this.usersList?.find(
          (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
        )?.fullName,
        ActualContext: event.chromatographyDataId + event.eventContext.eventType
      })
    })
    return refreshHistory
  }

  private getInstrumentEventNonRoutineIssueEncounteredRecord(event: NonRoutineIssueEncounteredDataChangeEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessmentLabel = $localize`:@@impactAssessmentLabel:Impact Assessment`;
    const ImpactAssessmentRecords = records.filter((r): r is NonRoutineIssueEncounteredDataChangeEventNotification => r.eventContext.eventType === ExperimentEventType.InstrumentEventNonRoutineIssueEncountered);
    const allRecords = ImpactAssessmentRecords.filter((record: NonRoutineIssueEncounteredDataChangeEventNotification) => record.activityId === event.activityId);
    const recordType = $localize`:@@nonRoutineIssueEncounteredChanged:Non-Routine Issue Encountered Changed`;
    const context = `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${impactAssessmentLabel}`;
    const description = event.nonRoutineIssueEncountered?.value ?? '';

    const auditHistory: AuditHistory[] = [];
    this.addFirstChangeRecordForNonRoutineIssue(
      event,
      recordType,
      description,
      context,
      auditHistory
    );
    this.addChangeReasonRecordForNonRoutineIssue(
      event,
      allRecords,
      recordType,
      description,
      context,
      auditHistory
    );
    return auditHistory;
  }

  private addFirstChangeRecordForNonRoutineIssue(
    actualRecord: NonRoutineIssueEncounteredDataChangeEventNotification,
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ) {
    const isFirstTimeChange = !actualRecord.eventContext.additionalContext || !actualRecord.eventContext.additionalContext['ChangeReason'];
    if (!isFirstTimeChange) return;

    const changeReasonContext: ChangeReasonContext = {
      changeReasonDetails: NA,
      canUpdate: false,
      nodeId: ''
    };

    const history = this.dataRecordUtilityService.getHistory(
      actualRecord,
      context,
      recordType,
      context,
      context.concat(` | ${ExperimentEventType.InstrumentEventNonRoutineIssueEncountered}`),
      description,
      $localize`:@@impactAssessmentLabel:Impact Assessment`,
      changeReasonContext
    );
    if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
  }

  private addChangeReasonRecordForNonRoutineIssue(
    actualRecord: NonRoutineIssueEncounteredDataChangeEventNotification,
    records: NonRoutineIssueEncounteredDataChangeEventNotification[],
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ){
    const additionalContext = actualRecord.eventContext.additionalContext;
    if(!additionalContext || !additionalContext['ChangeReason']) return;
    const eventChangeReasonId = additionalContext['ChangeReason'];
    const changeReasons = this.getChangeReasons(eventChangeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!eventChangeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: eventChangeReasonId,
        nodeId: actualRecord.activityId.concat(ChangeReasonConstants.crossReferenceNodeHeader)
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        actualRecord,
        context,
        recordType,
        context,
        context.concat(` | ${ExperimentEventType.FieldChanged}`),
        description,
        $localize`:@@impactAssessmentLabel:Impact Assessment`,
        this.getChangeReasonContextForNonRoutineIssue(actualRecord.eventContext.eventTime, records, changeReasons[i], canUpdate)
      );
      if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
    }
  }

  private getChangeReasonContextForNonRoutineIssue(
    eventTime: string,
    records: NonRoutineIssueEncounteredDataChangeEventNotification[],
    changeReason: ChangeReason,
    canUpdate: boolean
  ): (ChangeReasonContext | undefined){
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getNonRoutineIssueChangedOldValue(eventTime, records),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getNonRoutineIssueChangedOldValue(eventTime: string, records: NonRoutineIssueEncounteredDataChangeEventNotification[]) {
    const oldRecordIndex = (records.findIndex(r => r.eventContext.eventTime === eventTime)) - 1;
    const oldValueRecord = records[oldRecordIndex];
    if (!oldValueRecord?.nonRoutineIssueEncountered) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.nonRoutineIssueEncountered as DataValue);
  }

  private getInstrumentEventJustificationChangedEventRecordContext(event: InstrumentEventJustificationChangedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessmentLabel = $localize`:@@impactAssessmentLabel:Impact Assessment`;

    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${impactAssessmentLabel}`,
      RecordType: $localize`:@@justificationChanged:Justification Changed`,
      ContextType: $localize`:@@Form:Form`,
      Name: impactAssessmentLabel,
      Description: event.justification?.value ?? '',
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.instrumentId + event.eventContext.eventType
    };
  }

  private getInstrumentEventExceptionReferenceChangedEventRecordContext(event: InstrumentEventExceptionReferenceChangedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessmentLabel = $localize`:@@impactAssessmentLabel:Impact Assessment`;

    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${impactAssessmentLabel}`,
      RecordType: $localize`:@@exceptionReferenceChanged:Exception Reference Changed`,
      ContextType: $localize`:@@Form:Form`,
      Name: impactAssessmentLabel,
      Description: event.exceptionReference?.value ?? '',
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.instrumentId + event.eventContext.eventType
    };
  };

  private getInstrumentEventReturnedToServiceRecord(event: ReturnToServiceDataChangeEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessmentLabel = $localize`:@@impactAssessmentLabel:Impact Assessment`;
    const ImpactAssessmentRecords = records.filter((r): r is ReturnToServiceDataChangeEventNotification => r.eventContext.eventType === ExperimentEventType.InstrumentEventReturnedToService);
    const allRecords = ImpactAssessmentRecords.filter((record: ReturnToServiceDataChangeEventNotification) => record.activityId === event.activityId);
    const recordType = $localize`:@@returnedToServiceChanged:Returned To Service Changed`;
    const context = `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${impactAssessmentLabel}`;
    const description = event.returnedToService?.value ?? '';
    const auditHistory: AuditHistory[] = [];

    this.addFirstChangeRecordForReturnedToService(
      event,
      recordType,
      description,
      context,
      auditHistory
    );
    this.addChangeReasonHistoryForReturnToService(
      event,
      allRecords,
      recordType,
      description,
      context,
      auditHistory
    );
    return auditHistory;
  }

  private addFirstChangeRecordForReturnedToService(
    actualRecord: ReturnToServiceDataChangeEventNotification,
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ){
    const isFirstTimeChange = !actualRecord.eventContext.additionalContext || !actualRecord.eventContext.additionalContext['ChangeReason'];
    if (!isFirstTimeChange) return;

    const changeReasonContext: ChangeReasonContext = {
      changeReasonDetails: NA,
      canUpdate: false,
      nodeId: ''
    };

    const history = this.dataRecordUtilityService.getHistory(
      actualRecord,
      context,
      recordType,
      context,
      context.concat(` | ${ExperimentEventType.InstrumentEventReturnedToService}`),
      description,
      context,
      changeReasonContext
    );
    if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
  }

  private addChangeReasonHistoryForReturnToService(
    actualRecord: ReturnToServiceDataChangeEventNotification,
    records: ReturnToServiceDataChangeEventNotification[],
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ){
    const additionalContext = actualRecord.eventContext.additionalContext;
    if(!additionalContext || !additionalContext['ChangeReason']) return;
    const eventChangeReasonId = additionalContext['ChangeReason'];
    const changeReasons = this.getChangeReasons(eventChangeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!eventChangeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: eventChangeReasonId,
        nodeId: actualRecord.activityId.concat(ChangeReasonConstants.crossReferenceNodeHeader)
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        actualRecord,
        context,
        recordType,
        context,
        context.concat(` | ${ExperimentEventType.FieldChanged}`),
        description,
        $localize`:@@impactAssessmentLabel:Impact Assessment`,
        this.getChangeReasonContextForReturnToService(actualRecord.eventContext.eventTime, records, changeReasons[i], canUpdate)
      );
      if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
    }
  }

  private getChangeReasonContextForReturnToService(
    eventTime: string,
    records: ReturnToServiceDataChangeEventNotification[],
    changeReason: ChangeReason,
    canUpdate: boolean
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getReturnToServiceChangedOldValue(eventTime, records),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getReturnToServiceChangedOldValue(eventTime: string, records: ReturnToServiceDataChangeEventNotification[]){
    const oldRecordIndex = (records.findIndex(r => r.eventContext.eventTime === eventTime)) - 1;
    const oldValueRecord = records[oldRecordIndex];
    if (!oldValueRecord?.returnedToService) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.returnedToService as DataValue);
  }

  private getRowsAddedAndContainedCellValuesRecords(rowsAdded: AddRowEventNotification): AuditHistory[] {
    const table = this.experimentService.getTable(rowsAdded.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + rowsAdded.tableId);

    let rowNumber: string | undefined;
    const startIndex = (table.value.find((v: any) => v.id === (first(rowsAdded.rows) as TableRow).id)?.rowIndex?.value as NumberValue | undefined)?.value;
    if (!startIndex || rowsAdded.rows.length < 2) {
      rowNumber = startIndex;
    } else {
      // Note: this is sketchy. Row indices are obtained one at a time so there is no guarantee that they would be consecutive for
      // any one of several add-row commands that might be racing and leap-frogging each other to get row indices.
      const lastRowAddedIndex: number = (+startIndex as unknown as number) + rowsAdded.rows.length - 1;
      rowNumber = `${startIndex} to ${lastRowAddedIndex}`;
    }

    const auditRecords: AuditHistory[] = [];
    const recordTypes = ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(rowsAdded, $localize`:@@New:New`);

    auditRecords.push(this.dataRecordUtilityService.getHistory(
      rowsAdded,
      `${this.getTableContext(rowsAdded.tableId)} > ` + $localize`:@@RowsAdded:Rows Added`,
      recordTypes,
      $localize`:@@Table:Table`,
      `${this.getTableContext(rowsAdded.tableId)} > ${(first(rowsAdded.rows) as TableRow).id}`,
      $localize`:@@RowIndexAdded:Row Index: ${rowNumber as string} Added`,
      `${this.getTableTitleForExperiment(rowsAdded.tableId)}`
    ));

    rowsAdded.rows.forEach(row => {
      const rowData = row.data;
      rowData.forEach(cellData => {
        if (cellData.propertyValue.state === ValueState.Empty) return;

        if (cellData.propertyName === 'rowIndex') {
          rowNumber = (cellData.propertyValue as StringValue).value;
          return;
        }

        const columnLabel = table.columnDefinitions?.find((f) => f.field === cellData.propertyName)?.label;
        const description =  this.checkStateAndGetValue(cellData.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace);
        const cellEntry = this.dataRecordUtilityService.getHistory(
          rowsAdded,
          `${this.getTableContext(rowsAdded.tableId)} > ${rowNumberText} ${rowNumber} > ${columnLabel}`,
          recordTypes,
          $localize`:@@Table:Table`,
          `${this.getTableContext(rowsAdded.tableId)} > ${rowNumberText} ${rowNumber} > ${columnLabel} | ${ExperimentEventType.CellChanged}`,
          description,
          `${this.getTableTitleForExperiment(rowsAdded.tableId)}`
        );
        // History is presented in reverse chronological order as well as reverse logical order: row added is conceptually before cell value setting
        auditRecords.unshift(cellEntry);
      });
    });

    return auditRecords;
  }

  private getRowRemovedRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowRemovedEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    const rowNumber = (table.value.find(
      (v: any) => v.id === actualRecordTbl.rowId
    )?.rowIndex.value as NumberValue).value;

    return this.dataRecordUtilityService.getHistory(
      tableRecord,
      `${this.getTableContext(actualRecordTbl.tableId)} > ` + $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > ${rowNumber}`,
      $localize`:@@RowIndexRemoved:Row Index: ${rowNumber} Removed`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`,
    );
  }

  private getRowRestoredRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowRestoredEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    const rowNumber = (table.value.find(
      (v: any) => v.id === actualRecordTbl.rowId
    )?.rowIndex.value as NumberValue).value;

    return this.dataRecordUtilityService.getHistory(
      tableRecord,
      `${this.getTableContext(actualRecordTbl.tableId)} > ` + $localize`:@@rowRestored:Row Restored`,
      $localize`:@@rowRestored:Row Restored`,
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > ${rowNumber}`,
      $localize`:@@RowIndexRestored:Row Index: ${rowNumber} Restored`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  // private getChangeReasonContextForRowRemovedOrRestored(
  //   eventTime
  // ){}

  private getRowsRenumberedRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowsRenumberedEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    return this.dataRecordUtilityService.getHistory(
      tableRecord,
      this.getTableContext(actualRecordTbl.tableId),
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(tableRecord), // BTW nothing like New or Change apply here
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > StepsReordered`,
      $localize`:@@stepsReordered:Steps Reordered`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  private getCellChangedRecord(record: CellChangedEventNotification, records: CellChangedEventNotification[]) {
    const historyStack: AuditHistory[] = [];
    record.tableIds.forEach(tableId => {
      const table = this.experimentService.getTable(tableId);
      if (!table) throw new Error(this.expectedToFindTableMessage + tableId);

      record.columnValues.forEach((colVal) => {
        const columnName = table?.columnDefinitions?.find((f) => f.field === colVal.propertyName)?.label;
        const rowNumbers: { rowNumber: string, rowId: string }[] = [];
        record.rowIds.forEach((rowId: string) => {
          const rowNumber = (table.value.find((row: any) => row.id === rowId)?.rowIndex?.value as NumberValue).value as string;
          rowNumbers.push({ rowNumber, rowId });
        });

        let description = this.checkStateAndGetValue(colVal.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace);
        let recordType = '';
        const instrumentReading = (colVal.propertyValue as NumberValue).instrumentReading;
        const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === (colVal.propertyValue as NumberValue).unit)?.abbreviation;

        rowNumbers.forEach(r => {
          const cellKey = `${tableId}-${columnName}-${r.rowId}`;
          const timestamp = record.eventContext.eventTime;
          if (instrumentReading && 'equipmentId' in instrumentReading) {
            recordType = this.getRecordType(cellKey, timestamp);
            description = this.getInstrumentDescription(instrumentReading, unit);
            this.addToCellUpdateHistory(cellKey, timestamp);
          } else if (instrumentReading && (instrumentReading as InstrumentReadingValue).instrumentType === InstrumentType.phMeter) {
            description = this.getPhMeterInstrumentDescription(instrumentReading, timestamp);
            recordType = this.getRecordType(cellKey, timestamp);
            this.addToCellUpdateHistory(cellKey, timestamp);
          } else {
            recordType = ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotificationByItsKey(record, `${r.rowId}:${colVal.propertyName}`, $localize`:@@Change:Change`);
          }
          this.AddNewChangeAuditHistory(record, recordType, description, r, historyStack, columnName);
          this.AddChangeReasonsAuditHistory(records, record, recordType, description, r, historyStack, columnName, colVal.propertyName);
        });
      });
    });
    return historyStack;
  }

  private AddNewChangeAuditHistory(
    actualRecordTbl: CellChangedEventNotification,
    recordType: string,
    description: string,
    row: { rowNumber: string, rowId: string },
    historyStack: AuditHistory[],
    columnName?: string
  ) {
    const isFirstTimeChange = !actualRecordTbl.eventContext.additionalContext || !actualRecordTbl.eventContext.additionalContext['ChangeReason'];
    if (!isFirstTimeChange) return;

    const changeReasonContext: ChangeReasonContext = {
      changeReasonDetails: NA,
      canUpdate: false,
      nodeId: ''
    };
    const history = this.dataRecordUtilityService.getHistory(
      actualRecordTbl,
      `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${rowNumberText} ${row.rowNumber} > ${columnName}`,
      recordType,
      $localize`:@@Table:Table`,
      `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${rowNumberText} ${row.rowNumber} > ${columnName} | ${ExperimentEventType.CellChanged}`,
      description,
      `${this.getTableTitleForExperiment(first(actualRecordTbl.tableIds) as string)}`,
      changeReasonContext
    );
    if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
  }

  private AddChangeReasonsAuditHistory(
    records: CellChangedEventNotification[],
    actualRecordTbl: CellChangedEventNotification,
    recordType: string,
    description: string,
    row: { rowNumber: string, rowId: string },
    historyStack: AuditHistory[],
    columnName?: string,
    columnField?: string
  ) {
    const additionalContext = actualRecordTbl.eventContext.additionalContext;
    if (!additionalContext || !additionalContext['ChangeReason']) return;
    const eventChangeReasonId = additionalContext['ChangeReason'];
    const changeReasons = this.getChangeReasons(eventChangeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!eventChangeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: eventChangeReasonId,
        nodeId: first(actualRecordTbl.tableIds) as string
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        actualRecordTbl,
        `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${rowNumberText} ${row.rowNumber} > ${columnName}`,
        recordType,
        $localize`:@@Table:Table`,
        `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${rowNumberText} ${row.rowNumber} > ${columnName} | ${ExperimentEventType.CellChanged}`,
        description,
        `${this.getTableTitleForExperiment(first(actualRecordTbl.tableIds) as string)}`,
        this.getChangeReasonContext(actualRecordTbl.eventContext.eventTime, row.rowId, records, changeReasons[i], canUpdate, columnField)
      );
      if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
    }
  }

  private getChangeReasons(changeReasonId: string): ChangeReason[] {
    const activityChangeReasonNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    return activityChangeReasonNode?.changeReasons.filter(c => c.changeReasonId === changeReasonId) ?? [];
  }

  private getCellChangeOldValue(eventTIme: string, rowId: string, records: CellChangedEventNotification[], columnName?: string) {
    const allRecords = records.filter(record => record.rowIds.includes(rowId) && record.columnValues[0].propertyName.toLowerCase() === columnName?.toLowerCase());
    const oldRecordIndex = (allRecords.findIndex(r => r.eventContext.eventTime === eventTIme)) - 1;
    const oldValueRecord = allRecords[oldRecordIndex];
    if (!oldValueRecord?.columnValues[0].propertyValue) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.columnValues[0].propertyValue as DataValue);
  }

  private getChangeReasonContext(eventTIme: string,
    rowId: string,
    records: CellChangedEventNotification[],
    changeReason: ChangeReason,
    canUpdate = true,
    columnName?: string
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getCellChangeOldValue(eventTIme, rowId, records, columnName),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getPhMeterInstrumentDescription(instrumentReading: InstrumentReadingValue, timeStamp: string): string {
    const readingMetaData = instrumentReading.instrumentMetaData;
    const timeStampData = formatInstant(timeStamp, DateAndInstantFormat.dateTimeToSecond);
    const unitToDisplay = readingMetaData?.phMeterMode === PhMeterMode.mV ? PhMeterMode.mV : ''
    return $localize`:@@instrumentDetailsForPhMeter:    ${readingMetaData?.actual} ${unitToDisplay}
    Instrument Name: ${instrumentReading.instrumentName},
    Manufacturer: ${instrumentReading.manufacturer},
    Instrument ID: ${instrumentReading.instrumentName},
    Model: ${instrumentReading.modelNumber},
    Mode: ${readingMetaData?.phMeterMode},
    Serial Number: ${instrumentReading.serialNumber},
    Temperature, Push: ${readingMetaData?.temperatureValue} ${readingMetaData?.temperatureUnit} @ ${timeStampData},
    mV, Push: ${readingMetaData?.mvValue} ${readingMetaData?.mvUnit} @ ${timeStampData},
    pH, Push: ${readingMetaData?.phValue} ${readingMetaData?.phUnit} @ ${timeStampData}`;
  }

  private getInstrumentDescription(instrumentReading: InstrumentReadingValue, unitId?: string): string {
    const blowerState = $localize`:@@blowerStateValue:(${instrumentReading.instrumentMetaData?.BlowerState})`;
    const toleranceBlowerDetails = $localize`:@@toleranceBlowerDetails:${instrumentReading.instrumentMetaData?.BlowerState === BlowerState.No_Blower ? '' : blowerState}`;
    const toleranceSpecValueDetails = $localize`:@@toleranceSpecValueDetails:"${instrumentReading.instrumentMetaData?.ToleranceSpec}"`;
    const toleranceSpecValue = $localize`:@@toleranceSpecValue:${instrumentReading.instrumentMetaData?.ToleranceSpec === NA ? NA : toleranceSpecValueDetails}`;
    const toleranceSpecDetails = $localize`:@@toleranceSpecDetails:,\n      Tolerance Spec ${toleranceBlowerDetails} : ${toleranceSpecValue}`
    const commonInstrumentDetails =
      $localize`:@@commonInstrumentDetails:Instrument Name: ${instrumentReading.instrumentName},
        Instrument ID: ${instrumentReading.equipmentId},
        Instrument Type: ${instrumentReading.instrumentType},
        Manufacturer: ${instrumentReading.manufacturer},
        Model Number: ${instrumentReading.modelNumber},
        Serial Number: ${instrumentReading.serialNumber},
        Reading Method: ${instrumentReading.instrumentMetaData?.ReadingMethod}${toleranceSpecDetails}
        `;

    const panSample = instrumentReading.instrumentMetaData?.panSample;
    const panSampleReadMethod = instrumentReading.instrumentMetaData?.panSampleReadMethod;
    const panResidualReadMethod = instrumentReading.instrumentMetaData?.panResidualReadMethod;
    const actual = instrumentReading.instrumentMetaData?.actual;
    const actualReadTime = instrumentReading.instrumentMetaData?.actualReadTime;
    const methodsMap = {
      ResidualDifference: $localize`:@@residualDifferenceHistory:
      ${actual} ${unitId},
      Pan + Sample : ${panSample} ${unitId} ${panSampleReadMethod} ${instrumentReading.instrumentMetaData?.panSampleReadTime},
      Pan + Residual : ${instrumentReading.instrumentMetaData?.panResidual} ${unitId} ${panResidualReadMethod} ${instrumentReading.instrumentMetaData?.panResidualReadTime},
      Actual : ${actual} ${unitId} computed ${actualReadTime},
      ${commonInstrumentDetails}
      `,
      Direct: $localize`:@@directHistory:
      ${actual} ${unitId},
      Tare : ${instrumentReading.instrumentMetaData?.tare} ${unitId} ${instrumentReading.instrumentMetaData?.tareReadMethod} ${instrumentReading.instrumentMetaData?.tareReadTime},
      Actual : ${actual} ${unitId} ${instrumentReading.instrumentMetaData?.actualReadMethod} ${actualReadTime},
      ${commonInstrumentDetails}
      `,
      'Difference': $localize`:@@differenceHistory:
      ${actual} ${unitId},
      Pan : ${instrumentReading.instrumentMetaData?.pan} ${unitId} ${instrumentReading.instrumentMetaData?.panReadMethod} ${instrumentReading.instrumentMetaData?.panReadTime},
      Pan + Sample : ${panSample} ${unitId} ${panSampleReadMethod} ${instrumentReading.instrumentMetaData?.panSampleReadTime},
      Actual : ${actual} ${unitId} computed ${actualReadTime},
      ${commonInstrumentDetails}
      `
    };

    return methodsMap[instrumentReading.instrumentMetaData?.ReadingMethod as keyof typeof methodsMap] || '';
  }

  private getRecordType(cellKey: string, currentTimestamp: string): string {
    if (!this.cellUpdateHistory[cellKey]) {
      this.cellUpdateHistory[cellKey] = [currentTimestamp];
      return $localize`:@@ReadingNew:Reading, New`;
    }

    if (this.cellUpdateHistory[cellKey][0] === currentTimestamp) {
      return $localize`:@@ReadingNew:Reading, New`;
    } else {
      return $localize`:@@ReadingChange:Reading, Change`;
    }
  }

  private addToCellUpdateHistory(cellKey: string, timestamp: string): void {
    if (!this.cellUpdateHistory[cellKey]) {
      this.cellUpdateHistory[cellKey] = [];
    }
    this.cellUpdateHistory[cellKey].push(timestamp);
  }

  private getTableCellContext(tableId: string, rowId: string, columnField: string): string {
    if (!this.experimentResponse) throw new Error('Logic Error: Experiment not found for table ' + tableId); // this can't happen!

    const table = this.experimentService.getTable(tableId);
    if (!table) throw new Error('Logic Error: table not found in experiment ' + tableId); // this can't happen!
    if (!table.columnDefinitions) throw new Error('Logic Error: column definitions not found for table ' + tableId); // this can't happen!

    const row = table.value.find((r) => r.id === rowId);
    if (!row) throw new Error('Logic Error: row not found in table ' + rowId); // this can't happen!

    const rowIndex = (row.rowIndex.value as NumberValue).value;
    const column = table.columnDefinitions.find((c) => c.field === columnField);
    if (!column) throw new Error('Logic Error: column not found in table ' + columnField); // this can't happen!

    const columnLabel = column.label;

    return `${this.getTableContext(tableId)} > ${rowNumberText} ${rowIndex} > ${columnLabel}`;
  }

  getActivityCrossReferenceChangedRecord(event: CrossReferenceChangedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    const context = this.getCrossReferenceCellContext(event.activityId, event.crossReferenceId, event.property);

    const allCrossReferenceRecords = records.filter((r): r is CrossReferenceChangedEventNotification => r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceChanged);
    const allRecords = allCrossReferenceRecords.filter((record: CrossReferenceChangedEventNotification) => record.activityId === event.activityId);
    // The right values are sent via the needed parameters. Others might be garbage due to a lack of understanding.
    const experimentRecord = event;
    const recordType = $localize`:@@Change:Change`;
    const description = event.propertyValue.value ?? '';
    const name = context;
    const auditHistory:AuditHistory[] = [];
    this.addFirstChangeRecordForCrossReference(
      experimentRecord,
      recordType,
      description,
      context,
      auditHistory
    );
    this.addChangeReasonAuditHistoryForCrossReference(
      allRecords,
      experimentRecord,
      recordType,
      description,
      context,
      auditHistory
    );
    return auditHistory;
  }

  private getCrossReferenceCellContext(activityId: string, rowId: string, columnField: string): string {
    const crossReference = this.experiment.activities
      .find((a) => a.activityId === activityId)?.activityReferences.crossReferences
      .find((r) => r.id === rowId);
    if (!crossReference) throw new Error('Logic Error: row not found in table ' + rowId); // this can't happen!

    const rowIndex = (crossReference.rowIndex.value as StringValue).value;
    const column = CrossReferencesColumns.find((c) => c.field === columnField);
    if (!column) throw new Error('Logic Error: column not found in table ' + columnField); // this can't happen!

    const crossReferencesText = $localize`:@@CrossReferences:Cross References`;
    const columnLabel = column.label;

    return `${this.dataRecordUtilityService.getActivityContext(activityId).fullPath} > ${this.referencesTitle} > ${crossReferencesText} > ${rowNumberText} ${rowIndex} > ${columnLabel}`;
  }

  private addFirstChangeRecordForCrossReference(
    actualRecord: CrossReferenceChangedEventNotification,
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ) {
    const isFirstTimeChange = !actualRecord.eventContext.additionalContext || !actualRecord.eventContext.additionalContext['ChangeReason'];
    if (!isFirstTimeChange) return;

    const changeReasonContext: ChangeReasonContext = {
      changeReasonDetails: NA,
      canUpdate: false,
      nodeId: ''
    };

    const history = this.dataRecordUtilityService.getHistory(
      actualRecord,
      context,
      recordType,
      context,
      context.concat(` | ${ExperimentEventType.ActivityCrossReferenceChanged}`),
      description,
      context,
      changeReasonContext
    );
    if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
  }

  addChangeReasonAuditHistoryForCrossReference(
    records: CrossReferenceChangedEventNotification[],
    actualRecord: CrossReferenceChangedEventNotification,
    recordType: string,
    description: string,
    context: string,
    auditHistory: AuditHistory[]
  ){
    const additionalContext = actualRecord.eventContext.additionalContext;
    if(!additionalContext || !additionalContext['ChangeReason']) return;
    const eventChangeReasonId = additionalContext['ChangeReason'];
    const changeReasons = this.getChangeReasons(eventChangeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!eventChangeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: eventChangeReasonId,
        nodeId: actualRecord.activityId.concat(ChangeReasonConstants.crossReferenceNodeHeader)
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        actualRecord,
        context,
        recordType,
        context,
        context.concat(` | ${ExperimentEventType.FieldChanged}`),
        description,
        context,
        this.getChangeReasonContextForCrossReference(actualRecord.eventContext.eventTime, records, changeReasons[i], canUpdate)
      );
      if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
    }
  }

  private getChangeReasonContextForCrossReference(
    eventTime: string,
    records: CrossReferenceChangedEventNotification[],
    changeReason: ChangeReason,
    canUpdate: boolean
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getCrossReferenceChangedOldValue(eventTime, records),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getCrossReferenceChangedOldValue(eventTime: string, records: CrossReferenceChangedEventNotification[]) {
    const oldRecordIndex = (records.findIndex(r => r.eventContext.eventTime === eventTime)) - 1;
    const oldValueRecord = records[oldRecordIndex];
    if (!oldValueRecord?.propertyValue) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.propertyValue as DataValue);
  }

  /** IMPORTANT if modifying this logic, it must also be modified in Domain.ExperimentData.ModifiableDataValue within core services */
  public static getModifiableDataValue(
    newValue: ExperimentDataValue,
    oldValue: ModifiableDataValue | undefined
  ): ModifiableDataValue {
    const wasEmpty = !oldValue || oldValue?.value?.state === ValueState.Empty;
    return {
      isModified: oldValue?.isModified || !wasEmpty, // DO NOT change to ??
      value: newValue
    };
  }

  private getActivityRecords(
    activityInputRecord: ExperimentDataRecordNotification,
    records: ExperimentDataRecordNotification[]
  ): AuditHistory[] {
    let history: AuditHistory[];

    switch (activityInputRecord.eventContext.eventType) { //NOSONAR: a switch statement with a large number of cases is appropriate for this "router" logic
      case ExperimentEventType.AliquotAdded: {
        const actualInputEvent = activityInputRecord as AliquotAddedEventNotification;
        history = [this.getActivityInputAddedRecord(actualInputEvent)];
        break;
      }
      case ExperimentEventType.ActivityInputRowRestored: {
        const actualInputRowRestoredEvent = activityInputRecord as ActivityInputRowRestoredEventNotification;
        history = [this.getActivityInputRowRestoredRecord(actualInputRowRestoredEvent)];
        break;
      }
      case ExperimentEventType.ActivityInputRowRemoved: {
        const actualInputRowRemovedEvent = activityInputRecord as ActivityInputRowRemovedEventNotification;
        if(actualInputRowRemovedEvent[activityInputType]===ActivityInputType.Instrument)
          history = this.fetchHistoryBasedOnNodeTypeForInstruments(actualInputRowRemovedEvent);
        else
          history = this.fetchHistoryBasedOnNodeTypeForActivityInputs(actualInputRowRemovedEvent);
        break;
      }
      case ExperimentEventType.ActivityInputRowRefreshed: {
        const actualInputRowRefreshedEvent = activityInputRecord as ActivityInputRowRefreshedEventNotification;
        if (actualInputRowRefreshedEvent.aliquotsDetails.find(ad => ad.modifiedFields.length > 0) ||
          actualInputRowRefreshedEvent.materialsDetails.find(md => md.modifiedFields.length > 0)) {
          history = [this.getActivityInputRowRefreshedRecord(actualInputRowRefreshedEvent)];
        } else {
          history = [];
          console.error(caseNotHandledEvenThoughPlanned);
        }
        break;
      }
      case ExperimentEventType.MaterialAdded:
        history = [this.getMaterialAddedRecord(activityInputRecord as MaterialAddedEventNotification)];
        break;
      case ExperimentEventType.LabItemsMaterialAdded:
        history = [this.getLabItemsMaterialAddedRecord(activityInputRecord as MaterialAddedNotification)];
        break;
      case ExperimentEventType.SampleTestChanged:
        history = [this.getSampleTestAddedRecord(activityInputRecord as SampleTestAddedEventNotification, records)];
        break;
      case ExperimentEventType.StudyActivitySelected:
        history = [this.getStudyActivitySelectedRecord(activityInputRecord as StudyActivitySelectedEventNotification, records)];
        break;
      case ExperimentEventType.ActivityInputCellChanged:
        history = [this.getActivityInputCellChangedRecord(activityInputRecord as ActivityInputCellChangedEventNotification)];
        break;
      case ExperimentEventType.LabItemsCellChanged:
        history = this.getLabItemsCellChangedRecord(activityInputRecord as LabItemsCellChangedEventNotification, records);
        break;
      case ExperimentEventType.InstrumentAdded:
        history = this.fetchHistoryBasedOnNodeType(activityInputRecord);
        break;
      case ExperimentEventType.InstrumentColumnAdded:
        history = [this.getInstrumentColumnAddedRecord(activityInputRecord)];
        break;
      case ExperimentEventType.MaintenanceEventSelected:
        history = [this.getMaintenanceEventSelectedRecord(activityInputRecord as MaintenanceEventSelectedEventNotification)];
        break;
      case ExperimentEventType.InstrumentDateRemovedChanged:
        history = [this.getInstrumentDateRemovedChangedRecord(activityInputRecord as InstrumentDateRemovedChangedEventNotification)];
        break;
      case ExperimentEventType.InstrumentDescriptionChanged:
        history = [this.getInstrumentDescriptionChangedRecord(activityInputRecord as InstrumentDescriptionChangedEventNotification)];
        break;
      case ExperimentEventType.InstrumentRemovedFromServiceChanged:
        history = [this.getInstrumentRemovedFromServiceChangedRecord(activityInputRecord as InstrumentRemovedFromServiceChangedEventNotification)];
        break;
      case ExperimentEventType.LabItemsMaterialRemoved: {
        const labItemsMaterialRemovedEvent = activityInputRecord as LabItemsMaterialRemovedEventNotification;
        if (labItemsMaterialRemovedEvent.studyLinkIdentified) {
          history = this.getLabItemsMaterialRemovedOnRefreshRecord(labItemsMaterialRemovedEvent);
        } else {
          history = this.getLabItemsMaterialRemovedRecord(labItemsMaterialRemovedEvent);
        }
        break;
      }
      case ExperimentEventType.LabItemsMaterialRestored:
        history = this.getLabItemsMaterialRestoredRecord(activityInputRecord as LabItemsMaterialRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRemoved:
        history = this.getLabItemsInstrumentRemovedRecord(activityInputRecord as LabItemsInstrumentRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRestored:
        history = this.getLabItemsInstrumentRestoredRecord(activityInputRecord as LabItemsInstrumentRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentAdded:
        history = [this.getLabItemsInstrumentAddedRecord(activityInputRecord as InstrumentAddedEventNotification)];
        break;
      case ExperimentEventType.LabItemsConsumableAdded:
        history = [this.getLabItemsConsumableAddedRecord(activityInputRecord as LabItemsConsumableAddedNotification)];
        break;
      case ExperimentEventType.ActivityCrossReferenceAdded:
        history = this.getActivityCrossReferenceAddedRecord(activityInputRecord as CrossReferenceAddedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceRemoved:
        history = [this.getActivityCrossReferenceRemovedRecord(activityInputRecord as CrossReferenceRemovedEventNotification)];
        break;
      case ExperimentEventType.ActivityCrossReferenceRestored:
        history = [this.getActivityCrossReferenceRestoredRecord(activityInputRecord as CrossReferenceRestoredEventNotification)];
        break;
      case ExperimentEventType.LabItemsConsumableRemoved:
        history = this.getLabItemsConsumableRemovedRecord(activityInputRecord as LabItemsConsumableRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableRestored:
        history = this.getLabItemsConsumableRestoredRecord(activityInputRecord as LabItemsConsumableRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsPreparationAdded:
        history = [this.getLabItemsPreparationAddedRecord(activityInputRecord as LabItemPreparationAddedEventNotification)];
        break;
      case ExperimentEventType.LabItemPreparationRemoved:
        history = this.getLabItemsPreparationRemovedRecord(activityInputRecord as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRestored:
        history = this.getLabItemsPreparationRestoredRecord(activityInputRecord as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRefreshed:
        history = [this.getLabItemsPreparationRefreshedRecord(activityInputRecord as LabItemsPreparationRefreshedNotification)];
        break;
      default:
        history = [];
        console.error(caseNotHandledEvenThoughPlanned)
        break;
    }
    return history;
  }

  private fetchHistoryBasedOnNodeType(activityInputRecord: ExperimentDataRecordNotification) {
    if (this.experimentService.outputImpactAssessmentAuditHistoryFlag)
      return [this.getImpactAssessmentRecords(activityInputRecord as InstrumentAddedEventNotification)];
    else if (this.experimentService.inputImpactAssessmentAuditHistoryFlag)
      return [this.getInstrumentAddedRecord(activityInputRecord)];
    else
      return [this.getImpactAssessmentRecords(activityInputRecord as InstrumentAddedEventNotification), this.getInstrumentAddedRecord(activityInputRecord)];
  }

  private fetchHistoryBasedOnNodeTypeForActivityInputs(actualInputRowRemovedEvent: ActivityInputRowRemovedEventNotification) {
    if (this.experimentService.outputImpactAssessmentAuditHistoryFlag)
      return [];
    else
      return [this.getActivityInputRowRemovedRecord(actualInputRowRemovedEvent)];
  }

  private fetchHistoryBasedOnNodeTypeForInstruments(actualInputRowRemovedEvent: ActivityInputRowRemovedEventNotification) : AuditHistory[] {
    if (this.experimentService.outputImpactAssessmentAuditHistoryFlag) {
      return [this.getImpactAssessmentRecords(actualInputRowRemovedEvent)];
    }
    else if (this.experimentService.inputImpactAssessmentAuditHistoryFlag) {
      return [this.getActivityInputRowRemovedRecord(actualInputRowRemovedEvent)];
    }
    else {
      return [this.getImpactAssessmentRecords(actualInputRowRemovedEvent), this.getActivityInputRowRemovedRecord(actualInputRowRemovedEvent)];
    }
  }

  private getActivityInputRowRestoredRecord(
    notification: ActivityInputRowRestoredEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@rowRestored:Row Restored`,
      $localize`:@@Table:Table`,
      '',
      `${this.getActivityInputName(notification.activityInputType)}: ${notification.activityInputReference} ` + $localize`:@@restoredState:Restored`
    );
  }

  private getActivityInputRowRemovedRecord(
    notification: ActivityInputRowRemovedEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@Table:Table`,
      '',
      `${this.getActivityInputName(notification.activityInputType)}: ${notification.activityInputReference} ` + $localize`:@@removed:Removed`
    );
  }

  private getLabItemsMaterialRemovedRecord(notification: LabItemsMaterialRemovedEventNotification): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsMaterialRemoved:Lab Item Removed`;
    const context = `${path} | ${ExperimentEventType.LabItemsMaterialRemoved}`;
    const name = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsMaterialRemovedOnRefreshRecord(notification: LabItemsMaterialRemovedEventNotification): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsMaterialRefreshedAndRemoved:Lab Item Refreshed & Removed`;
    const context = `${path} | ${ExperimentEventType.LabItemsMaterialRefreshed}`;
    const name = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
    const description = `${notification.itemReference} removed on refresh due to latest study activities associated to the material`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name, description);
  }

  private getLabItemsMaterialRestoredRecord(
    notification: LabItemsMaterialRestoredEventNotification
  ): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsMaterialRestored:Lab Item Restored`;
    const context = `${path} | ${ExperimentEventType.LabItemsMaterialRestored}`;
    const name = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getActivityInputRowRefreshedRecord(
    notification: ActivityInputRowRefreshedEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    const messageData = this.getActivityInputRefreshMessageData(notification);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@AliquotRefreshed:Rows Refreshed`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.ActivityInputRowRefreshed}`,
      `Aliquot(s): ${messageData} Refreshed`
    );
  }

  private getLabItemsInstrumentRemovedRecord(
    notification: LabItemsInstrumentRemovedEventNotification
  ): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsInstrumentRemoved:Lab Item Removed`;
    const context = `${path} | ${ExperimentEventType.LabItemsInstrumentRemoved}`;
    const name = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsInstrumentRestoredRecord(
    notification: LabItemsInstrumentRestoredEventNotification
  ): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsInstrumentRemoved:Lab Item Restored`;
    const context = `${path} | ${ExperimentEventType.LabItemsInstrumentRestored}`;
    const name = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsInstrumentAddedRecord(notification: InstrumentAddedEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsInstrumentAdded}`,
      `${notification.activityInputReference}`,
      $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    );
  }

  private getLabItemsInstrumentColumnRemovedRecord(notification: LabItemsInstrumentColumnRemovedEventNotification): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsInstrumentColumnRemoved:Lab Item Removed`;
    const context = `${path} | ${ExperimentEventType.LabItemsInstrumentColumnRemoved}`;
    const name = $localize`:@@LabItemsColumnsTableTitle:Columns`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsInstrumentColumnRestoredRecord(notification: LabItemsInstrumentColumnRestoredEventNotification): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsInstrumentColumnRestored:Lab Item Restored`;
    const context = `${path} | ${ExperimentEventType.LabItemsInstrumentColumnRestored}`;
    const name = $localize`:@@LabItemsColumnsTableTitle:Columns`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsInstrumentColumnRefreshedRecord(notification: LabItemsInstrumentColumnRefreshedNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsInstrumentColumnRefreshed}`,
      `${notification.itemReference}`,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }

  private getLabItemsConsumableAddedRecord(notification: LabItemsConsumableAddedNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    const rowIndexField = notification.tableData.find(row => !!row.rowIndex);
    const rowIndex = (rowIndexField?.rowIndex.value as NumberValue).value;

    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@LabItemsConsumableAdded:Lab Item Consumable Added`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsConsumableAdded}`,
      $localize`:@@RowIndexAdded:Row Index: ${rowIndex} Added`,
      $localize`:@@ConsumablesTag:Consumables`
    );
  }

  private getActivityCrossReferenceAddedRecord(notification: CrossReferenceAddedEventNotification): AuditHistory[] {
    if (![CrossReferenceType.Experiment, CrossReferenceType.Activity].includes(notification.type)) throw Error('LOGIC ERROR: CrossReferenceType not implemented');

    const history: AuditHistory[] = [];
    const numberCache = notification.type === CrossReferenceType.Experiment ? this.experimentNumberCache : this.activityNumberCache;
    const referenceNumber = numberCache[notification.linkId];
    const context = `${this.dataRecordUtilityService.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
    const recordType = [$localize`:@@Reference:Reference`, $localize`:@@New:New`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3257070
    const description = [$localize`:@@AddedCrossReferenceTo:Added Cross Reference to`, `${referenceNumber}`].join(' ');
    const contextExp = NA;
    const actualContext = `${notification.activityId} | references | cross-references`;
    history.push(this.dataRecordUtilityService.getHistory(notification, context, recordType, contextExp, actualContext, description));

    const columnName = 'Purpose'; // This is the only column that could be filled in.
    const purpose = notification.purpose?.value;
    const purposeState = notification.purpose?.state ?? ValueState.Empty;
    if (purposeState !== ValueState.Empty && purpose) {
      const fullContextExp = `${context} > ${rowNumberText} ${notification.rowIndex?.value} > ${columnName}`;
      const purposeHistory = this.dataRecordUtilityService.getHistory(notification, fullContextExp, recordType, contextExp, actualContext, purpose);
      /**
       * Used unshift here to show first cross reference added entry then
       * followed by initial value for Purpose entry in audit history.
       */
      history.unshift(purposeHistory);
    }
    return history;
  }

  private getActivityCrossReferenceRemovedRecord(notification: CrossReferenceRemovedEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find(a => a.activityId === notification.activityId);
    const ref = activity?.activityReferences.crossReferences.find(r => r.id === notification.crossReferenceId);
    if (!ref) throw new Error('LOGIC ERROR: Expected to find cross reference');
    if (![CrossReferenceType.Experiment, CrossReferenceType.Activity].includes(ref.type)) throw Error('LOGIC ERROR: CrossReferenceType not implemented');

    const numberCache = ref.type === CrossReferenceType.Experiment ? this.experimentNumberCache : this.activityNumberCache;
    const referenceNumber = numberCache[ref.linkId];
    const context = `${this.dataRecordUtilityService.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
    const recordType = [$localize`:@@Reference:Reference`, $localize`:@@removed:Removed`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3257070
    const description = $localize`:@@removedCrossReferenceDescription:Removed Cross Reference to ${referenceNumber}`;
    const contextExp = NA;
    const actualContext = `${notification.activityId} | references | cross-references`;
    return this.dataRecordUtilityService.getHistory(notification, context, recordType, contextExp, actualContext, description);
  }

  private getActivityCrossReferenceRestoredRecord(notification: CrossReferenceRestoredEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find(a => a.activityId === notification.activityId);
    const ref = activity?.activityReferences.crossReferences.find(r => r.id === notification.crossReferenceId);
    if (!ref) throw new Error('LOGIC ERROR: Expected to find cross reference');
    if (![CrossReferenceType.Experiment, CrossReferenceType.Activity].includes(ref.type)) throw Error('LOGIC ERROR: CrossReferenceType not implemented');

    const numberCache = ref.type === CrossReferenceType.Experiment ? this.experimentNumberCache : this.activityNumberCache;
    const referenceNumber = numberCache[ref.linkId];
    const context = `${this.dataRecordUtilityService.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
    const recordType = [$localize`:@@Reference:Reference`, $localize`:@@restoredState:Restored`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3257070
    const description = $localize`:@@restoredCrossReferenceDescription:Restored Cross Reference to ${referenceNumber}`;
    const contextExp = NA;
    const actualContext = `${notification.activityId} | references | cross-references`;
    return this.dataRecordUtilityService.getHistory(notification, context, recordType, contextExp, actualContext, description);
  }

  private getActivityInputRefreshMessageData(notification: ActivityInputRowRefreshedEventNotification): string {
    if (notification.activityInputType === ActivityInputType.Aliquot) return notification.aliquotsDetails.map(ad => ad.activityInputReference).join(',');
    return notification.materialsDetails.map(md => md.activityInputReference).join(',');
  }

  private getActivityInputAddedRecord(notification: AliquotAddedEventNotification): AuditHistory {
    const description = this.getDescriptionForActivityInputs(notification);
    const path = this.getActivityInputContext(ActivityInputType.Aliquot);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputAdded:Input added`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getDescriptionForActivityInputs(notification: AliquotAddedEventNotification): string {
    if (notification.eventContext.eventType === ExperimentEventType.AliquotAdded) {
      return `${notification.activityInputReference} ` + $localize`:@@added:Added`;
    }

    return '';
  }

  private getSampleTestAddedRecord(notification: SampleTestAddedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory {
    const description = this.getDescriptionForSampleTestSelection(notification, records);
    const path = this.getActivityInputContext(ActivityInputType.Aliquot);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@SampleTestChanged:Sample Test Changed`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getDescriptionForStudyActivitySelection(notification: StudyActivitySelectedEventNotification, records: ExperimentDataRecordNotification[]): string {
    const studyActivitySelectedRecords = records.filter(r => r.eventContext.eventType === notification.eventContext.eventType) as StudyActivitySelectedEventNotification[];
    const historyRecords = studyActivitySelectedRecords.filter(r =>
      r.eventContext.experimentId === notification.eventContext.experimentId
      && r.activityId === notification.activityId
      && r.activityInputReference === notification.activityInputReference
    ).sort((a, b) => a.eventContext.eventTime.localeCompare(b.eventContext.eventTime));
    const previousVersion = historyRecords[historyRecords.indexOf(notification) - 1];
    const newValue = notification.studyActivities.map(a => a.code);

    let oldValue: string[] = [];

    if (previousVersion) {
      oldValue = previousVersion.studyActivities.map(a => a.code);
    }

    const selected = difference(newValue, oldValue);
    const unselected = difference(oldValue, newValue);
    return this.getMultiselectDescription(newValue.join(', '), selected, unselected);
  }

  private getStudyActivitySelectedRecord(notification: StudyActivitySelectedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory {
    const description = this.getDescriptionForStudyActivitySelection(notification, records);
    const path = this.getActivityInputContext(ActivityInputType.Material);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@StudyActivitySelected:Study Activity Selected`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getActivityInputCellChangedRecord(notification: ActivityInputCellChangedEventNotification): AuditHistory {
    const path = this.getActivityInputCellChangedContext(notification);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputCellChanged:Activity Input Cell Changed`,
      $localize`:@@Table:Table`,
      path,
      this.checkStateAndGetValue(notification.propertyValue).concat(ELNAppConstants.WhiteSpace)
    );
  }

  private getLabItemsCellChangedRecord(notification: LabItemsCellChangedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    const allCellChangedRecords = records.filter((r): r is LabItemsCellChangedEventNotification => r.eventContext.eventType === ExperimentEventType.LabItemsCellChanged);
    const historyStack: AuditHistory[] = []
    const path = this.getLabItemsCellChangedContext(notification);
    if (!notification.changeReasonId)
      return [this.dataRecordUtilityService.getHistory(
        notification,
        path,
        $localize`:@@LabItemsCellChanged:Lab Item Cell Changed`,
        $localize`:@@TableCell:Table Cell`,
        `${path} | ${ExperimentEventType.LabItemsCellChanged}`,
        this.checkStateAndGetValue(notification.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace),
        this.getPropertyName(notification)
      )]
    const changeReasons = this.getChangeReasons(notification.changeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!notification.changeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: notification.changeReasonId,
        nodeId: notification.activityId
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        notification,
        path,
        $localize`:@@LabItemsCellChanged:Lab Item Cell Changed`,
        $localize`:@@TableCell:Table Cell`,
        `${path} | ${ExperimentEventType.LabItemsCellChanged}`,
        this.checkStateAndGetValue(notification.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace),
        this.getPropertyName(notification),
        this.getChangeReasonContextForLabItems(notification.eventContext.eventTime, notification.itemReference , allCellChangedRecords, changeReasons[i], canUpdate, notification.propertyName)
      );
      if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
    }
    return historyStack;
  }

  private getChangeReasonContextForRemoveAndRestoreLabItems(
    changeReason: ChangeReason,
    canUpdate = true,
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: undefined,
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getChangeReasonContextForLabItems(eventTIme: string,
    rowId: string,
    records: LabItemsCellChangedEventNotification[],
    changeReason: ChangeReason,
    canUpdate = true,
    columnName?: string
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getCellChangeOldValueForLabItems(eventTIme, rowId, records, columnName),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getCellChangeOldValueForLabItems(eventTIme: string, rowId: string, records: LabItemsCellChangedEventNotification[], columnName?: string) {
    const allRecords = records.filter(record => record.itemReference === rowId && record.propertyName.toLowerCase() === columnName?.toLowerCase());
    const oldRecordIndex = (allRecords.findIndex(r => r.eventContext.eventTime === eventTIme)) - 1;
    const oldValueRecord = allRecords[oldRecordIndex];
    if (!oldValueRecord?.propertyValue) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.propertyValue as DataValue);
  }

  private getPropertyName(notification: LabItemsCellChangedEventNotification): string {
    switch (notification.itemType) {
      case ActivityInputType.Consumable: {
        const columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(false);
        const column = columnDefinitions.find((c) => c.field === notification.propertyName);
        return column?.label ?? '';
      }
      case ActivityInputType.Material: {
        const instrumentMaterialColumnDefinitions = LabItemsMaterialTableOptions.GetColumnDefinitions(undefined, () => undefined);
        const instrumentMaterialColumn = instrumentMaterialColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentMaterialColumn?.label ?? '';
      }
      case ActivityInputType.InstrumentColumn: {
        const instrumentColumnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions();
        const instrumentColumn = instrumentColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentColumn?.label ?? '';
      }
      case ActivityInputType.Instrument:
      case ActivityInputType.InstrumentDetails: {
        const instrumentTableDefinitions = LabItemsInstrumentTableOptions.GetColumnDefinitions();
        const instrumentTableColumn = instrumentTableDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentTableColumn?.label ?? '';
      }
      default:
        return notification.propertyName;
    }
  }

  private getInstrumentAddedRecord(notification: any): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@InstrumentAdded:Instrument Added`,
      $localize`:@@Form:Form`,
      '',
      notification.activityInputReference
    );
  }

  private getImpactAssessmentRecords(notification: ActivityInputRowRemovedEventNotification | InstrumentAddedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === notification.activityId)?.itemTitle;
    const impactAssessmentLabel = $localize`:@@impactAssessmentLabel:Impact Assessment`;
    const path = `${activityName ?? 'No title'} > ${this.activityOutputsTitle} > ${impactAssessmentLabel}`;
    if(notification.eventContext.eventType === ExperimentEventType.InstrumentAdded)
      {
      return this.dataRecordUtilityService.getHistory(
        notification,
        path,
        $localize`:@@added:Added`,
        $localize`:@@Form:Form`,
        '',
        $localize`:@@ImpactAssessmentAdded:Impact Assessment: ${notification.activityInputReference} Added`,
        $localize`:@@ImpactAssessment:Impact Assessment`
      );
    }
    else
    {
      return this.dataRecordUtilityService.getHistory(
        notification,
        path,
        $localize`:@@removed:Removed`,
        $localize`:@@Form:Form`,
        '',
        $localize`:@@ImpactAssessmentRemoved:Impact Assessment: ${notification.activityInputReference} Removed`,
        $localize`:@@ImpactAssessment:Impact Assessment`
      );
    }
  }

  private getInstrumentColumnAddedRecord(notification: any): AuditHistory {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.InstrumentColumnAdded}`,
      notification.activityInputReference,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }

  private getInstrumentRemovedFromServiceChangedRecord(notification: InstrumentRemovedFromServiceChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@InstrumentRemoveFromServiceChanged:Instrument Remove From Service Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.removedFromService?.value ?? ''
    );
  }

  private getInstrumentDescriptionChangedRecord(notification: InstrumentDescriptionChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@InstrumentDescriptionChanged:Instrument Description Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.description?.value ?? ''
    );
  }

  private getInstrumentDateRemovedChangedRecord(notification: InstrumentDateRemovedChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@InstrumentDateRemovedChanged:Instrument Date Removed Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.dateRemoved?.value ?? ''
    );
  }

  private getMaintenanceEventSelectedRecord(notification: MaintenanceEventSelectedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@MaintenanceEventSelected:Maintenance Event Selected`,
      $localize`:@@Form:Form`,
      '',
      notification?.nameDescription?.value ?? ''
    );
  }

  private getDescriptionForSampleTestSelection(notification: SampleTestAddedEventNotification, records: ExperimentDataRecordNotification[]): string {
    const sampleTestChangedRecords = records.filter(r => r.eventContext.eventType === notification.eventContext.eventType) as SampleTestAddedEventNotification[];
    const historyRecords = sampleTestChangedRecords.filter(r =>
      r.eventContext.experimentId === notification.eventContext.experimentId
      && r.activityId === notification.activityId
      && r.activityInputReference === notification.activityInputReference
    ).sort((a, b) => a.eventContext.eventTime.localeCompare(b.eventContext.eventTime));
    const previousVersion = historyRecords[historyRecords.indexOf(notification) - 1];
    const newValue = notification.aliquotTests;

    let oldValue: AliquotTest[] = [];

    if (previousVersion) oldValue = previousVersion.aliquotTests;

    const selected = newValue.filter((v: AliquotTest) => !oldValue.map(o => o.testId).includes(v.testId));
    const unselected = oldValue.filter((v: AliquotTest) => !newValue.map(n => n.testId).includes(v.testId));
    return this.getMultiselectDescription(newValue.map(t => t.testReportableName).join(', '), selected.map(t => t.testReportableName), unselected.map(t => t.testReportableName));
  }

  public formatTestProperties(property: string) {
    return property === NA ? NA : `"${property}"`;
  }

  private getMaterialAddedRecord(notification: MaterialAddedEventNotification): AuditHistory {
    const description = this.getDescriptionForMaterialAliquot(notification);
    const path = this.getActivityInputContext(ActivityInputType.Material);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputAdded:Input added`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getLabItemsMaterialAddedRecord(notification: MaterialAddedNotification): AuditHistory {
    const description = this.getDescriptionForLabItemsMaterial(notification);
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsMaterialAdded}`,
      description,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getDescriptionForMaterialAliquot(notification: MaterialAddedEventNotification): string {
    if (notification.eventContext.eventType === ExperimentEventType.MaterialAdded) {
      return `${notification.activityInputReference} ` + $localize`:@@added:Added`;
    }

    return '';
  }

  private getDescriptionForLabItemsMaterial(notification: MaterialAddedNotification): string {
    if (notification.eventContext.eventType === ExperimentEventType.LabItemsMaterialAdded) {
      return notification.activityInputReference;
    }

    return '';
  }

  private getFormRecordContext(formRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    let history: AuditHistory[] = [];
    /**
     * ! It will be replaced with Switch statements if the cases are greater than three
     */
    if (formRecord.eventContext.eventType === ExperimentEventType.FieldChanged) {
      const actualRecordForm = formRecord as FieldChangedEventNotification;
      const allFieldChangedRecords: FieldChangedEventNotification[]
        = records.filter((r): r is FieldChangedEventNotification => r.eventContext.eventType === ExperimentEventType.FieldChanged);
      history = this.getFieldChangedRecord(actualRecordForm, allFieldChangedRecords);
    } else {
      history = [];
      console.error(caseNotHandledEvenThoughPlanned);
    }
    return history;
  }

  private getLocalizedRecordTypeForField(notification: FieldChangedEventNotification): string {
    var recordTypes = ExperimentRecordTypesHelper.getDefaultRecordTypesFromNotification(notification);
    if (recordTypes.includes(ExperimentRecordType.Recipe)) {
      return ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(notification);
    } else {
      return $localize`:@@FieldChanged:Field Changed`;
    }
  }

  private getFieldChangedRecord(actualRecordForm: FieldChangedEventNotification, allRecords: FieldChangedEventNotification[]): AuditHistory[] {
    allRecords = allRecords.filter(record => record.formId === actualRecordForm.formId && last(record.path) === last(actualRecordForm.path));
    let description = this.checkStateAndGetValue(actualRecordForm.newValue as DataValue);
    const instrumentReading = (actualRecordForm.newValue as NumberValue).instrumentReading;
    let recordType = '';
    if (instrumentReading) {
      const fieldKey = `${actualRecordForm.formId}-${actualRecordForm.path[actualRecordForm.path.length - 1]}`;
      const timestamp = actualRecordForm.eventContext.eventTime
      const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === (actualRecordForm.newValue as NumberValue).unit)?.abbreviation;
      description = this.getInstrumentDescription(instrumentReading, unit);
      recordType = this.getRecordType(fieldKey, timestamp);
      this.addToCellUpdateHistory(fieldKey, timestamp);
    }
    const auditHistory: AuditHistory[] = [];
    this.addFirstChangeRecordForForm(
      actualRecordForm,
      instrumentReading ? recordType : this.getLocalizedRecordTypeForField(actualRecordForm),
      description,
      auditHistory
    );
    this.addChangeReasonAuditHistoryForForm(
      allRecords,
      actualRecordForm,
      instrumentReading ? recordType : $localize`:@@FieldChanged:Field Changed`,
      description,
      auditHistory
    );
    return auditHistory;
  }

  private addFirstChangeRecordForForm(
    actualRecord: FieldChangedEventNotification,
    recordType: string,
    description: string,
    auditHistory: AuditHistory[],
  ) {
    const isFirstTimeChange = !actualRecord.eventContext.additionalContext || !actualRecord.eventContext.additionalContext['ChangeReason'];
    if (!isFirstTimeChange) return;

    const path = this.getFieldLabelPathFromField(
      actualRecord.formId,
      last(actualRecord.path) as string
    ).join(' > ');
    const changeReasonContext: ChangeReasonContext = {
      changeReasonDetails: NA,
      canUpdate: false,
      nodeId: ''
    };
    const history = this.dataRecordUtilityService.getHistory(
      actualRecord,
      `${this.getFormContext(actualRecord.formId)} > ${path}`,
      recordType,
      $localize`:@@Form:Form`,
      `${this.getFormContext(actualRecord.formId)} > ${actualRecord.formId} > | ${ExperimentEventType.FieldChanged}`,
      description,
      `${this.getFormContext(actualRecord.formId)}`,
      changeReasonContext
    );
    if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
  }

  private addChangeReasonAuditHistoryForForm(
    records: FieldChangedEventNotification[],
    actualRecord: FieldChangedEventNotification,
    recordType: string,
    description: string,
    auditHistory: AuditHistory[],
  ) {
    const additionalContext = actualRecord.eventContext.additionalContext;
    if (!additionalContext || !additionalContext['ChangeReason']) return;
    const eventChangeReasonId = additionalContext['ChangeReason'];
    const changeReasons = this.getChangeReasons(eventChangeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!eventChangeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: eventChangeReasonId,
        nodeId: actualRecord.formId
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    const path = this.getFieldLabelPathFromField(
      actualRecord.formId,
      last(actualRecord.path) as string
    ).join(' > ');
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        actualRecord,
        `${this.getFormContext(actualRecord.formId)} > ${path}`,
        recordType,
        $localize`:@@Form:Form`,
        `${this.getFormContext(actualRecord.formId)} > ${actualRecord.formId} > | ${ExperimentEventType.FieldChanged}`,
        description,
        `${this.getFormContext(actualRecord.formId)}`,
        this.getChangeReasonContextForForm(actualRecord.eventContext.eventTime, records, changeReasons[i], canUpdate)
      );
      if (!auditHistory.find(h => JSON.stringify(h) === JSON.stringify(history))) auditHistory.push(history);
    }
  }

  private getChangeReasonContextForForm(
    eventTIme: string,
    records: FieldChangedEventNotification[],
    changeReason: ChangeReason,
    canUpdate = true
  ): (ChangeReasonContext | undefined) {
    const changeReasonContext: ChangeReasonContext = {
      oldValue: this.getFieldChangedOldValue(eventTIme, records),
      changeReasonId: changeReason?.changeReasonId,
      changeReasonDetails: changeReason?.index ? `${changeReason?.index}. ${changeReason?.changeReasonDetails}` : '',
      index: changeReason?.index,
      canUpdate: canUpdate,
      nodeId: changeReason?.nodeId
    }
    return changeReasonContext;
  }

  private getFieldChangedOldValue(eventTime: string, records: FieldChangedEventNotification[]) {
    const oldRecordIndex = (records.findIndex(r => r.eventContext.eventTime === eventTime)) - 1;
    const oldValueRecord = records[oldRecordIndex];
    if (!oldValueRecord?.newValue) return undefined;
    return this.checkStateAndGetValue(oldValueRecord.newValue as DataValue);
  }



  private checkStateAndGetValue(propertyValue: DataValue) {
    switch (propertyValue.state) {
      case ValueState.Empty:
        return propertyValue.type === ValueType.Number ? this.getValueType(propertyValue) : '';
      case ValueState.NotApplicable:
        return NA;
      case ValueState.Set:
        return this.getValueType(propertyValue);
      case ValueState.Invalid:
        return $localize`:@@InvalidValueError:Internal Error. Please contact support.`;
      default:
        return '';
    }
  }

  private getValueType(propertyValue: DataValue): string {
    switch (propertyValue.type) {
      case ValueType.Instant:
        return formatInstant(propertyValue.value as string, DateAndInstantFormat.dateTimeToSecond);
      case ValueType.LocalDate:
        return formatLocalDate(propertyValue.value as string);
      case ValueType.Number:
        const number = propertyValue as NumberValue;
        const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === number.unit);
        const quantity = new Quantity(number.state, number.value, unit, number.sigFigs, number.exact);
        return quantity.toString();
      case ValueType.Boolean:
      case ValueType.String:
      case ValueType.Html:
        return propertyValue.value as string;
        break;
      case ValueType.StringArray:
        return (propertyValue.value as string[]).join(`,${ELNAppConstants.WhiteSpace}`);
      case ValueType.Specification:
        return this.getSpecificationContext(propertyValue);
      case ValueType.StringDictionary:
        return this.dataValueService.joinValues(propertyValue as StringTypeDictionaryValue);
      case ValueType.Invalid:
        return $localize`:@@InvalidValueError:Internal Error. Please contact support.`;
      default:
        return '';
    }
  }

  getActivityInputName(activityInputType: ActivityInputType) {
    switch (activityInputType) {
      case ActivityInputType.Aliquot:
        return $localize`:@@SampleTableTitle:Samples & Aliquots`;
      case ActivityInputType.Material:
        return $localize`:@@StudyActivities:Study Activities`;
      case ActivityInputType.Instrument:
        return $localize`:@@instrumentEventPageHeader:Instrument Event`;
    }
    return '';
  }

  getLabItemsName(itemType: ActivityInputType) {
    switch (itemType) {
      case ActivityInputType.Aliquot:
        return $localize`:@@SampleTableTitle:Samples & Aliquots`;
      case ActivityInputType.Material:
        return $localize`:@@StudyActivities:Study Activities`;
    }
    return '';
  }

  private getActivityInputCellChangedContext(notification: ActivityInputCellChangedEventNotification): string {
    const activityId = this.experimentService.currentActivityId;

    const activityName = this.experiment.activities.find(
      (a) => a.activityId === activityId
    )?.itemTitle;
    const context = `${activityName ?? 'No title'} > ${this.activityInputsTitle} > ${this.getActivityInputName(notification.activityInputType)}`;

    return context.concat(` > ${notification.activityInputReference} > ${notification.propertyName}`)
  }

  private getLabItemsCellChangedContext(notification: LabItemsCellChangedEventNotification): string {
    let context = this.getActivityInputContextByType(notification.itemType, this.labItemsTitle);
    if (notification.itemReference) {
      context = context.concat(` > ${this.getItemReference(notification)} > ${this.getPropertyName(notification)}`);
    } else {
      context = context.concat(` > ${this.getItemReference(notification)}`);
    }

    return context;
  }

  private getItemReference(notification: LabItemsCellChangedEventNotification) {
    if (notification.itemType === ActivityInputType.Consumable) {
      const datasource = (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
      )?.consumables as Array<Consumable>) || [];
      const consumable = datasource.find(k => k.itemReference === notification.itemReference);
      const fields = consumable?.tableData.filter((r: any) => !!r.rowIndex);
      if (fields) {
        const rowIndexField = fields[0];
        const rowIndex = (rowIndexField?.rowIndex.value as NumberValue).value;
        return 'Row Number ' + rowIndex;
      }
      return '';
    }
    return notification.itemReference;
  }

  private getExperimentNodeTitleChangedRecord(
    notification: ExperimentNodeTitleChangedNotification
  ): AuditHistory {
    return this.nodeTitleChangeAuditHistory[notification.nodeType].historyComposer(notification);
  }

  private getTableTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = `${this.getTableContext(notification.nodeId)} > ${DataRecordService.Title}`;
    return this.dataRecordUtilityService.getHistory(
      notification,
      `${auditDisplayContext}`,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(notification, $localize`:@@Change:Change`),
      $localize`:@@Table:Table`,
      `${auditDisplayContext}`,
      notification.title,
      `${this.getTableTitleForExperiment(notification.nodeId)}`
    );
  }

  private getFormTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = `${this.getFormContext(notification.nodeId)} > ${DataRecordService.Title}`;
    return this.dataRecordUtilityService.getHistory(
      notification,
      `${auditDisplayContext}`,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(notification, $localize`:@@Change:Change`),
      $localize`:@@Form:Form`,
      `${auditDisplayContext}`,
      notification.title,
      `${this.getFormTitle(notification.nodeId)}`
    );
  }

  private getModuleTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = this.getModuleContext(notification.nodeId);
    return this.dataRecordUtilityService.getHistory(
      notification,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      $localize`:@@Change:Change`,
      $localize`:@@Module:Module`,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      notification.title,
      `${auditDisplayContext.title}`
    );
  }

  private getActivityTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = this.dataRecordUtilityService.getActivityContext(notification.nodeId);
    return this.dataRecordUtilityService.getHistory(
      notification,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      $localize`:@@Change:Change`,
      $localize`:@@Activity:Activity`,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      notification.title,
      `${auditDisplayContext.title}`
    );
  }

  private getLabItemsConsumableRemovedRecord(notification: LabItemsConsumableRemovedEventNotification): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsConsumableRestored:Consumable Removed`;
    const context = $localize`:@@RowIndexRestored:Row Index: ${(notification as any).rowIndex} Removed`;
    const name = $localize`:@@ConsumablesTag:Consumables`
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsConsumableRestoredRecord(
    notification: LabItemsConsumableRestoredEventNotification
  ): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsConsumableRestored:Consumable Restored`;
    const context = $localize`:@@RowIndexRestored:Row Index: ${(notification as any).rowIndex} Restored`;
    const name = $localize`:@@ConsumablesTag:Consumables`
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsPreparationAddedRecord(
    notification: LabItemPreparationAddedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemsPreparationAdded}`,
      `${notification.preparationNumber}`,
      $localize`:@@preparations:Preparations`
    );
  }

  private getLabItemRemovedOrRestoredRecord(notification: any,
    path: string,
    recordType: string,
    context: string,
    name: string,
    description?: string
    ): AuditHistory[] {
    const historyStack: AuditHistory[] = []
    if (!notification.changeReasonId) {
      return [this.dataRecordUtilityService.getHistory(
        notification,
        path,
        recordType,
        $localize`:@@Table:Table`,
        context,
        description ?? `${notification.itemReference}`,
        name
      )];
    }
    const changeReasons = this.getChangeReasons(notification.changeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!notification.changeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: notification.changeReasonId,
        nodeId: notification.activityId
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        notification,
        path,
        recordType,
        $localize`:@@Table:Table`,
        context,
        `${notification.itemReference}`,
        name,
        this.getChangeReasonContextForRemoveAndRestoreLabItems(changeReasons[i], canUpdate)
      );
      if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
    }
    return historyStack;
  }

  private getLabItemsPreparationRemovedRecord(
    notification: LabItemPreparationRemovedEventNotification
  ): AuditHistory[]  {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsRemoved:Lab Item Removed`;
    const context = `${path} | ${ExperimentEventType.LabItemPreparationRemoved}`;
    const name = $localize`:@@preparations:Preparations`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsPreparationRestoredRecord(
    notification: LabItemPreparationRestoredEventNotification
  ): AuditHistory[] {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    const recordType = $localize`:@@LabItemsRestored:Lab Item Restored`;
    const context = `${path} | ${ExperimentEventType.LabItemPreparationRestored}`;
    const name = $localize`:@@preparations:Preparations`;
    return this.getLabItemRemovedOrRestoredRecord(notification, path, recordType, context, name);
  }

  private getLabItemsPreparationRefreshedRecord(
    notification: LabItemsPreparationRefreshedNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.dataRecordUtilityService.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      `${path} | ${ExperimentEventType.LabItemPreparationRefreshed}`,
      this.getLabItemPreparationDescription(notification.refreshedDataValues),
      $localize`:@@preparations:Preparations`
    );
  }

  private getDataPackageGeneratedRecord(
    notification: MarkDataPackageGeneratedEventNotification
  ) {
    const auditHistories: AuditHistory[] = [];
    const dataPackageGeneratedEventLabel = $localize`:@@DataPackageCreatedEvent:Data Package Created Event`;
    const dataPackageLabel = $localize`:@@DataPackage:Data Package`;
    this.experimentService.loadAuditHistoryForActivityIds
      .filter(activityId => notification.generatedDataPackageDetails
        .some(activityFileDto => activityFileDto.activityId === activityId))
      .forEach((activityId) => {
        const activityName = this.experimentService.currentExperiment?.activities.find(activity => activity.activityId === activityId)?.itemTitle ?? activityId;
        const activityReference = this.experimentService.currentExperiment?.activities
          .find(activity => activity.activityId === activityId)?.activityReferenceNumber ?? activityName;
        const fileForActivity = notification.generatedDataPackageDetails.find(activityFileDetails => activityFileDetails.activityId === activityId)?.uploadedFileIds;
        fileForActivity?.forEach(s => {
          const file = this.experiment.activityFiles.find(activityFileNode => activityFileNode.activityId === activityId)?.files.find(fileDetails => fileDetails.fileId === s)
          auditHistories.push(this.dataRecordUtilityService.getHistory(
            notification,
            $localize`:@@DataPackageFileAuditContext:${activityName} > ${file?.title}`,
            dataPackageLabel,
            dataPackageLabel,
            '',
            $localize`:@@DataPackageFileAuditDescription:Initiated upload and linking of ${activityReference}, ${file?.title}`,
            dataPackageLabel
          ))
        })
        auditHistories.push(this.dataRecordUtilityService.getHistory(
          notification,
          activityName,
          dataPackageGeneratedEventLabel,
          dataPackageLabel,
          '',
          $localize`:@@DataPackageAuditDescription:Initiated generation of authorized activity data package for ${activityReference}`,
          dataPackageLabel
        ))
      });
    return auditHistories;
  }

  private getConsumableRowNumber(notification: PromptSatisfiedEventNotification) {
    const datasource = (this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    )?.consumables) || [];
    const rowIndex = datasource.findIndex(k => k.itemReference === notification.labItemId) + 1;
    return 'Row Number ' + rowIndex;
  }

  private getPromptSatisfactionRecord(record: PromptSatisfiedEventNotification): AuditHistory[] {
    const historyStack: AuditHistory[] = []
    const promptTypeTitles: { [key: string]: string } = {
      materials: $localize`:@@LabItemsMaterialsTableTitle:Materials`,
      preparations: $localize`:@@preparationModuleHeader:Preparations`,
      columns: $localize`:@@LabItemsColumnTitle:Columns`,
      instruments: $localize`:@@LabItemsInstrumentsTableTitle:Instruments`,
      consumablesAndSupplies: $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`,
    }
    const auditDisplayContext = this.dataRecordUtilityService.getActivityContext(record.activityId);
    const prompt = this.getPrompt(record);
    const itemReference = prompt.type === PromptType.ConsumablesAndSupplies ? this.getConsumableRowNumber(record) : record.labItemId;
    const promptTitle = promptTypeTitles[prompt.type ?? PromptType.Invalid];

    if (!record.changeReasonId) {
      return [this.dataRecordUtilityService.getHistory(
        record,
        `${auditDisplayContext.fullPath} > ${this.labItemsTitle} > ${promptTitle} > ${itemReference} > ${this.promptsTitle}`,
        $localize`:@@LabItemsCellChanged:Lab Item Cell Changed`,
        $localize`:@@TableCell:Table Cell`,
        `${auditDisplayContext.fullPath} > Lab Items > ${promptTitle} > ${itemReference} > prompts | ${ExperimentEventType.PromptSatisfied}`,
        record.isSatisfied ? prompt.name ?? '' : '',
        $localize`:@@prompt:Prompt`
      )];
    }
    const changeReasons = this.getChangeReasons(record.changeReasonId);
    const changeReasonNotGivenForValidChange = (changeReasons.length === 0 && !!record.changeReasonId);
    if (changeReasonNotGivenForValidChange) {
      const blankChangeReason: Partial<ChangeReason> = {
        changeReasonDetails: '',
        changeReasonId: record.changeReasonId,
        nodeId: record.activityId
      }
      changeReasons.push(blankChangeReason as ChangeReason);
    }
    for (let i = changeReasons.length - 1; i >= 0; i--) {
      const canUpdate = (i === changeReasons.length - 1);
      const history = this.dataRecordUtilityService.getHistory(
        record,
        `${auditDisplayContext.fullPath} > ${this.labItemsTitle} > ${promptTitle} > ${itemReference} > ${this.promptsTitle}`,
        $localize`:@@LabItemsCellChanged:Lab Item Cell Changed`,
        $localize`:@@TableCell:Table Cell`,
        `${auditDisplayContext.fullPath} > Lab Items > ${promptTitle} > ${itemReference} > prompts | ${ExperimentEventType.PromptSatisfied}`,
        record.isSatisfied ? prompt.name ?? '' : '',
        $localize`:@@prompt:Prompt`,
        this.getChangeReasonContextForRemoveAndRestoreLabItems(changeReasons[i], canUpdate)
      );
      if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
    }
    return historyStack;
  }

  private getPrompt(record: PromptSatisfiedEventNotification): PromptItem {
    const activityPrompts = this.experiment.activityPrompts?.filter((activity: ActivityPrompt) => activity.activityId === record.activityId)[0].prompts;
    return activityPrompts?.filter(prompt => prompt.promptId === record.promptId)[0] as PromptItem;
  }

  private isExperimentDataValue(fieldValue: ModifiableDataValue) {
    return !!fieldValue?.value?.state;
  }

  private getClient(clientId: string) {
    return this.projectLogLoaderService.getClient(clientId)?.label ?? '';
  }

  private getProject(projectId: string) {
    return this.projectLogLoaderService.getProject(projectId)?.label ?? '';
  }

  private getFieldName(fieldName: string): string {
    return fieldName.split('.').length > 1 ? fieldName.split('.')[1] : fieldName;
  }

  private getLabItemPreparationDescription(refreshedData: { [key: string]: any }) {
    let refreshedValues = '';
    Object.keys(refreshedData).forEach((key, index) => {
      const fieldName = this.getFieldName(key);
      const fieldValue = refreshedData[key];
      let refreshedValue = '';
      if (this.isExperimentDataValue(fieldValue)) {
        refreshedValue = this.checkStateAndGetValue(fieldValue?.value as ExperimentDataValue);
      } else {
        refreshedValue = this.getRefreshedValue(fieldName, fieldValue);
      }
      if (fieldName === PreparationConstants.expirationDateValueKey) {
        refreshedValue = refreshedValue === NA ? PreparationConstants.suitableForUseText : refreshedValue;
      }
      refreshedValues += PreparationTableOptions.getDisplayValue(camelCase(fieldName)).concat(': ', refreshedValue ?? '');
      if (index < Object.keys(refreshedData).length - 1) {
        refreshedValues += ', ';
      }
    });
    return refreshedValues;
  }

  private getRefreshedValue(fieldName: string, fieldValue: any): string {
    let refreshedValue = '';
    if (fieldName === PreparationConstants.clientKey) {
      refreshedValue = this.getClient(fieldValue);
    } else if (fieldName === PreparationConstants.projectKey) {
      refreshedValue = this.getProject(fieldValue);
    } else if (fieldName === PreparationConstants.discardedOrConsumedOnKey) {
      refreshedValue = fieldValue ? formatInstant(fieldValue, DateAndInstantFormat.date) : '';
    } else if (fieldName === PreparationConstants.stabilityKey || fieldName === PreparationConstants.originalQuantityKey) {
      const numberValue: NumberValue = {
        type: fieldValue?.Type, state: fieldValue?.State,
        value: fieldValue?.Value, unit: fieldValue?.Unit, exact: fieldValue?.Exact
      };
      refreshedValue = this.getValueType(numberValue);
    } else {
      refreshedValue = fieldValue ? fieldValue.toString() : '';
    }
    return refreshedValue;
  }

  /**
   * Gets the history-style formatted context of a module
   */
  private getModuleContext(nodeId: string): {
    fullPath: string,
    title: string
  } {
    const moduleTitle =
      this.allModulesInExperiment.find((f) => f.moduleId === nodeId)?.moduleLabel ??
      $localize`:@@NoTitle:No Title`;
    const activityName = this.experiment?.activities.find((a) =>
      a.dataModules.some((c) => c.moduleId === nodeId)
    )?.itemTitle;
    return { fullPath: `${activityName} > ${moduleTitle}`, title: moduleTitle };
  }

  /**
   * Gets the history-style formatted context and initial title of module
   * @param templateId templateId of the module
   * @param nodeId Experiment's nodeId of the module
   * @param title (optional) Title of the module
   * @returns Context and the Title of the module
   */
  private getModuleContextWithInitialTitle(templateId: string, nodeId: string, title = ''): { fullPath: string, title: string } {
    //If the event does not store the module title, the title is taken from the corresponding node of the applied template
    const moduleTitle = title || objectCache[templateId]?.moduleLabel; // Do not replace || with ??
    const currentModuleTitle =
      this.allModulesInExperiment.find(f => f.moduleId === nodeId)?.moduleLabel ??
      $localize`:@@NoTitle:No Title`;
    const activityTitle = this.experiment?.activities.find(a =>
      a.dataModules.some(m => m.moduleId === nodeId)
    )?.itemTitle;
    return { fullPath: `${activityTitle} > ${currentModuleTitle}`, title: moduleTitle };
  }

  /**
   * Gets the history-style formatted context of a specification
   */
  private getSpecificationContext(specification: SpecificationValue): string {
    if (!specification) return '';
    let ctx = this.specificationService.getDisplayString(specification);

    if (specification.state !== ValueState.Set) return ctx;

    const getComplianceContext = (spec: Partial<ObservationSpec> | Partial<SingleValueSpec> | Partial<TwoValueRangeSpec>): string => {
      if (spec.complianceAssessorType === SpecComplianceAssessorType.Invalid
        || spec.complianceAssessorType === SpecComplianceAssessorType.None) {
        return '';
      }
      let compliance = $localize`:@@complianceNeeded:Compliance needed`;
      switch (spec.complianceAssessorType) {
        case SpecComplianceAssessorType.ExactMatch: {
          const exactMatch = $localize`:@@exactMatch:Exact match`
          compliance = `${compliance}: ${exactMatch}`;
          break;
        }
        case SpecComplianceAssessorType.Round: {
          const round = $localize`:@@round:Round`;
          compliance = `${compliance}: ${round}`;
          break;
        }
        case SpecComplianceAssessorType.Truncate: {
          const truncate = $localize`:@@truncate:Truncate`;
          compliance = `${compliance}: ${truncate}`;
          break;
        }
        case SpecComplianceAssessorType.InexactMatch:
          break; // no change to the output needed, but we cannot hit the default.
        default:
          throw new Error('Specification compliance assessor is invalid');
      }
      return `(${compliance})`;
    }
    if (specification.specType === SpecType.Observation
      || specification.specType === SpecType.SingleValue
      || specification.specType === SpecType.TwoValueRange) {
      const complianceContext = getComplianceContext(specification);
      if (complianceContext !== '') {
        ctx = `${ctx} ${complianceContext}`;
      }
    }
    return ctx;
  }

  private getESignedRecordOfExperimentRestoredToSetupTransition(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus?: WorkflowEventNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentStartedEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus ||
      experimentWorkFlowPreviousStatus.state !== ExperimentWorkflowState.Cancelled
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForSetup:ESigned For Setup`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@cancelledToSetupESignatureDescription:Cancelled Experiment transitioned to Setup by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentStarted(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus?: WorkflowEventNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentStartedEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus ||
      experimentWorkFlowPreviousStatus.state !== ExperimentWorkflowState.Cancelled
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForStarted:ESigned For Started`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description
      = $localize`:@@cancelledToInProgressESignatureDescription:Cancelled Experiment transitioned to In progress by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentInReview(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus?: WorkflowEventNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForInReview:ESigned For InReview`;
    const description = this.getAuditDescriptionOfInReviewFor(
      experimentRecord,
      experimentWorkFlowPreviousStatus
    );
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentAuthorized(experimentRecord: ExperimentDataRecordNotification): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) return undefined;

    const eSignedTo = $localize`:@@eSignedForAuthorization:ESigned For Authorization`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@authorizedESignatureDescription:Reviewed and electronically authorized for specified Samples, where applicable by ${eSignedDetails.signedBy
      } on ${eSignedDetails.signedOn}`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentCancelled(experimentRecord: ExperimentDataRecordNotification): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) return undefined;

    const eSignedTo = $localize`:@@eSignedForCancelled:ESigned For Cancelled`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@cancelledESignatureDescription:Experiment cancelled by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentInCorrection(experimentRecord: ExperimentDataRecordNotification, experimentWorkFlowPreviousStatus?: WorkflowEventNotification)
    : AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForCorrection:ESigned For Correction`;
    const description = this.getAuditDescriptionOfInCorrectionFor(
      experimentRecord,
      experimentWorkFlowPreviousStatus
    );
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getAuditDescriptionOfInReviewFor(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus: WorkflowEventNotification
  ) {
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    if (experimentWorkFlowPreviousStatus.state === ExperimentWorkflowState.InProgress) {
      return $localize`:@@inProgressToInReviewESignatureDescription:Experiment complete and ready for review by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else {
      return $localize`:@@inCorrectionToInReviewESignatureDescription:Experiment corrected and send for review by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    }
  }

  private getAuditDescriptionOfInCorrectionFor(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus: WorkflowEventNotification
  ) {
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    if (experimentWorkFlowPreviousStatus.state === ExperimentWorkflowState.InReview) {
      return $localize`:@@inReviewToInCorrectionESignatureDescription:Experiment reviewed and send for correction by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else if (experimentWorkFlowPreviousStatus.state === ExperimentWorkflowState.Authorized) {
      return $localize`:@@authorizedToInCorrectionESignatureDescription:Authorized Experiment send for correction by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else {
      return $localize`:@@cancelledToInCorrectioneSignatureAuditDescriptionForExperimentStarted:Cancelled Experiment sent for correction by ${eSignedDetails.signedBy
        } on ${eSignedDetails.signedOn}`;
    }
  }

  private getExperimentSetupReviewRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForReviewEventNotification;
    const setup = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = setup + this.getExperimentState(record.state);
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getSignedDetails(experimentRecord: WorkflowEventNotification): {
    signedBy: string;
    signedOn: string;
  } {
    const signedBy =
      this.usersList?.find(
        u => u.puid.toLowerCase() === experimentRecord.eventContext.puid.toLowerCase()
      )?.fullName ?? '';
    const signedOn = formatInstant(
      experimentRecord.eventContext.eventTime,
      DateAndInstantFormat.dateTimeToSecond
    );
    return { signedBy, signedOn };
  }

  private getPreparationEventRecord(event: ExperimentPreparationsCreatedNotification): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const preparationCreated: AuditHistory[] = [];
    const addedFieldValues = this.getAllTableFieldValues(event.createdPreparations);
    reverse(event.createdPreparations).forEach(prep => {
      for (const [key, value] of addedFieldValues) {
        preparationCreated.push(
          {
            Source: event,
            Time: event.eventContext.eventTime,
            Context: `${activityName} > ${preparations} > ${preparations} > ${prep.preparationNumber} > ${key}`,
            RecordType: ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(event, $localize`:@@new:New`),
            ContextType: $localize`:@@preparationNew:Preparation, New`,
            Name: preparations,
            Description: `${value}`,
            RecordVersion: 1,
            PerformedBy: this.usersList?.find(
              u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
            )?.fullName,
            ActualContext: event.activityId + event.eventContext.eventType
          }
        )
      }
      preparationCreated.push(
        {
          Source: event,
          Time: event.eventContext.eventTime,
          Context: `${activityName} > ${preparations} > ${preparations} > ${prep.preparationNumber}`,
          RecordType: $localize`:@@preparationCreated:Preparation Created`,
          ContextType: $localize`:@@preparationNew:Preparation, New`,
          Name: preparations,
          Description: `${prep.preparationNumber}`,
          RecordVersion: 1,
          PerformedBy: this.usersList?.find(
            u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
          )?.fullName,
          ActualContext: event.activityId + event.eventContext.eventType
        }
      )
    });
    return preparationCreated;
  }

  private getPreparationRestoredEventRecord(event: ActivityPreparationRestoredNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: $localize`:@@preparationRestoredType:Preparation Restored`,
      ContextType: $localize`:@@preparationRestored:Preparation, Restored`,
      Name: preparations,
      Description: $localize`:@@auditPreparationRestored:${prepNumber} Preparation restored`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    }
  }

  private getPreparationRemovedEventRecord(event: ActivityPreparationRemovedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: $localize`:@@preparationRemoved:Preparation Removed`,
      ContextType: $localize`:@@preparationCommaRemoved:Preparation, Removed`,
      Name: preparations,
      Description: $localize`:@@auditPreparationRemoved:${prepNumber} Preparation removed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    }
  }

  private getPreparationDiscardOrConsumedEventRecord(event: PreparationDiscardedOrConsumedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: event.discardedOrConsumed ? $localize`:@@preparationDiscardType:Preparation Discarded or Consumed` :
        $localize`:@@preparationNotDiscardedorConsumedType:Preparation Not Discarded or Consumed`,
      ContextType: $localize`:@@preparationDiscardedOrConsumedContext:Preparation, Discarded or consumed`,
      Name: preparations,
      Description: event.discardedOrConsumed ? $localize`:@@auditPreparationDiscarded:${prepNumber} discarded or consumed` :
        $localize`:@@auditPreparationConsumed:${prepNumber} not discarded or consumed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    }
  }

  private getActivityFilesAddedEventRecord(event: AttachedFileEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const outputs = $localize`:@@outputs:Outputs`;
    const attachedFiles = $localize`:@@attachedFiles:Attached Files`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No title'} > ${outputs} > ${attachedFiles}`,
      RecordType: $localize`:@@recordType-attachedFile:Attached File`,
      ContextType: $localize`:@@recordType-attachedFile:Attached File`,
      Name: $localize`:@@attachedFiles:Attached Files`,
      Description: $localize`:@@attachedFileRecordDescription:Attached [${event.title}]`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    }
  }

  private getActivityFilesDeletedEventRecord(event: DeletedFilesEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const outputs = $localize`:@@outputs:Outputs`;
    const attachedFiles = $localize`:@@attachedFiles:Attached Files`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName ?? 'No title'} > ${outputs} > ${attachedFiles}`,
      RecordType: $localize`:@@recordType-attachedFile:Attached File`,
      ContextType: $localize`:@@recordType-attachedFile:Attached File`,
      Name: $localize`:@@attachedFiles:Attached Files`,
      Description: $localize`:@@deletedFilesRecordDescription:Removed [${event.fileName}]`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    };
  }

  private getPreparationCellChangedEventRecord(event: PreparationCellChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const changedPreparation = this.experiment.activities
      .find(activity => activity.activityId === event.activityId)?.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber;
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${changedPreparation} > ${this.getPreparationColumnForHistory(event.changedField)}`,
      RecordType: $localize`:@@preparationCellChanged:Preparation Cell Changed`,
      ContextType: $localize`:@@preparationCellChange:Preparation, Cell change`,
      Name: preparations,
      Description: this.getDescriptionForPreparationHistory(event),
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    };
  }

  private getPreparationInternalInformationChangedEventRecord(event: PreparationInternalInformationChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const changedPreparation = this.experiment.activities
      .find(activity => activity.activityId === event.activityId)?.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber;
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${changedPreparation}`,
      RecordType: $localize`:@@preparationInternalInfoChange:Preparation Internal Information Changed`,
      ContextType: $localize`:@@preparationChange:Preparation, Internal information change`,
      Name: preparations,
      Description: $localize`:@@auditPreparationInternalInfoChanged:${changedPreparation} internal information changed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    };
  }

  private getPreparationStatusChangedEventRecord(event: ExperimentPreparationStatusChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const status = $localize`:@@status:Status`;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    return {
      Source: event,
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber} > ${status}`,
      RecordType: $localize`:@@preparationStatusChange:Preparation Status Changed`,
      ContextType: $localize`:@@preparationStatusChangeContext:Preparation, Status change`,
      Name: preparations,
      Description: `${event.status.charAt(0).toUpperCase()}${event.status.slice(1)}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType
    };
  }

  public displayUnHandledErrorNotification(
    notification: NotificationResult,
    operationType: string
  ): void {
    if (notification.notifications.length === 0) return;

    const message = this.localizeNotificationMessage(notification.notifications[0]);
    this.displayNotification(message, operationType, DataRecordService.MessageTypeMapForNotification[notification.notifications[0].notificationType]);
  }

  private displayNotification(detail: string, operationType: string, severity: string): void {
    const errorMessage: Message = {
      key: 'notification',
      severity,
      summary: `${operationType}`,
      detail,
      sticky: false
    };
    this.messageService.add(errorMessage);
  }

  private localizeNotificationMessage(notification: NotificationDetails): string {
    const localize = this.getLocalize();
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = localize({
      '0': `:@@${notification.translationKey}:${notification.translationKey}`,
      raw: [':']
    } as any);
    if (translatedMessage !== notification.translationKey) {
      return translatedMessage;
    }
    return notification.message;
  }

  getLocalize() {
    return $localize;
  }

  getColumnType(type: ValueType): ColumnType {
    switch (type) {
      case ValueType.Number:
        return ColumnType.Quantity;
      case ValueType.String:
        return ColumnType.String;
      case ValueType.LocalDate:
      case ValueType.Instant:
        return ColumnType.Date;
      default: throw new Error('Unexpected value type was passed');
    }
  }

  getDescriptionForPreparationHistory(event: PreparationCellChangedNotification): string {
    if (event.changedField === 'ExpirationValue' && event.changedValue.state === ValueState.NotApplicable) {
      return $localize`:@@whileSuitable:While suitable for use`
    }
    if (event.changedValue.type !== ValueType.StringDictionary) {
      const columnType = this.getColumnType(event.changedValue.type)
      const primitiveValue = this.dataValueService.getPrimitiveValue(columnType, { isModified: false, value: event.changedValue });

      return (columnType === ColumnType.Date) ? this.formatDate(event.changedValue.type, primitiveValue) : primitiveValue;
    } else {
      return this.dataValueService.joinValues(event.changedValue as StringTypeDictionaryValue); // using type assertion since we are already checking the type above
    }
  }

  private formatDate(type: ValueType, value: any): string {
    return (type === ValueType.Instant) ? formatInstant(value, DateAndInstantFormat.dateTimeToMinute) : formatLocalDate(value);
  }

  getPreparationColumnForHistory(changedField: string): string {
    return this.preparationColumns[changedField];
  }

  getAllTableFieldValues(createdPreparations: ExperimentPreparation[]): Map<string, string> {
    const addedFields = new Map<string, string>();
    createdPreparations.forEach(prep => {
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.PreparationName),
        this.getDescriptionForPreparationHistoryForFieldValue(prep.name)
      );
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.formulaComponents),
        this.getDescriptionForPreparationHistoryForFieldValue(prep.summary.formulaComponents)
      );
      addedFields.set(
        PreparationConstants.status,
        this.titleCasePipe.transform(prep.status)
      );
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.expirationValue),
        prep.expirationValue?.expirationDateValue?.value.state === ValueState.NotApplicable
          ? $localize`:@@whileSuitable:While suitable for use`
          : this.getDescriptionForPreparationHistoryForFieldValue(prep.expirationValue?.expirationDateValue)
      );
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.storageCondition),
        this.getDescriptionForPreparationHistoryForFieldValue(prep.summary.storageCondition)
      );
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.concentration),
        this.getDescriptionForPreparationHistoryForFieldValue(prep.summary.concentration)
      );
      addedFields.set(
        this.getPreparationColumnForHistory(PreparationConstants.description),
        this.dataValueService.joinValues(prep.description.value as StringTypeDictionaryValue)
      );
    });
    return addedFields;
  }

  getDescriptionForPreparationHistoryForFieldValue(fieldValue: ModifiableDataValue | undefined): string {
    if (fieldValue === undefined) return NA;

    const columnType = this.getColumnType(fieldValue.value.type);
    const primitiveValue = this.dataValueService.getPrimitiveValue(columnType, { isModified: false, value: fieldValue.value });
    return (columnType === ColumnType.Date) ? this.formatDate(fieldValue.value.type, primitiveValue) : primitiveValue;
  }

  getAuditHistoryForTemplateAppliedRecord(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    nodeType?: NodeType
  ): AuditHistory[] {
    const templateAppliedAuditHistory: AuditHistory[] = [];
    const template = objectCache[record.templateReferenceId];
    let moduleOrFormOrTableItem: any;
    let isFiltered = false;
    let templateType = template.templateType ?? TemplateType.Invalid;
    if (record.addedInstanceIds[template.nodeId] === undefined) {
      isFiltered = true;
      const nodeIds = Object.keys(record.addedInstanceIds);
      if (nodeIds.length > 1) {
        moduleOrFormOrTableItem = template.dataModules.find((r: any) => nodeIds.includes(r.nodeId));
        templateType = (moduleOrFormOrTableItem !== undefined)
          ? TemplateType.Module
          : TemplateType.Invalid;
      } else {
        const nodeId = record.addedInstanceIds[nodeIds[0]];
        moduleOrFormOrTableItem = this.allModuleItemsInExperiment.find(item => item.nodeId === nodeId);
        templateType = moduleOrFormOrTableItem.itemType;
      }
    }
    this.populateAuditHistoryForNonSyntheticNodes(record, recordType, template, templateType, moduleOrFormOrTableItem, templateAppliedAuditHistory, isFiltered);
    this.populateAuditHistoryForSyntheticNodes(record, nodeType, recordType, templateAppliedAuditHistory);
    return templateAppliedAuditHistory;
  }

  private populateAuditHistoryForSyntheticNodes(
    record: ExperimentTemplateAppliedEventNotification,
    nodeType: NodeType | undefined,
    recordType: string,
    templateAppliedAuditHistory: AuditHistory[]
  ) {
    if (nodeType === undefined) return;
    if (record.moduleReference?.isSynthetic) {
      if (nodeType === NodeType.Module || nodeType === NodeType.Activity) {
        this.populateAuditHistoryForSyntheticModule(
          record,
          recordType,
          templateAppliedAuditHistory
        );
      }
    }
    if (record.activityReference?.isSynthetic) {
      if (nodeType === NodeType.Activity) {
        this.populateAuditHistoryForSyntheticActivity(
          record,
          recordType,
          templateAppliedAuditHistory
        );
      }
    }
  }

  /**
   * Retrieves the title of the existing activity when table/form is loaded to the existing activity as new module
   * @param moduleId moduleId of the existing activity
   * @returns current title of the activity
   */
  getActivityTitleFromTableOrFormTemplate(moduleId: string): string {
    return this.experiment.activities.find((a) =>
      a.dataModules.find((c) => c.moduleId === moduleId)
    )?.itemTitle ?? ''
  }
  /**
   * Retrieves the title of the synthetic activity formed when a module template is loaded as new activity
   * @param {string} templateId
   * @returns current title of the activity
   */
  getSyntheticActivityTitleFromModuleTemplate(templateId: string): string {
    return this.experiment.activities.find((a) =>
      a.dataModules.find((c) => c.sourceTemplateId === templateId)
    )?.itemTitle ?? '';
  }

  populateAuditHistoryForSyntheticModule(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    templateHistory: AuditHistory[],
  ) {
    const moduleId = record.moduleReference?.templateReferenceId ?? '';
    const currentActivityTitle = this.getActivityTitleFromTableOrFormTemplate(moduleId);
    const currentModuleLabel = this.allModulesInExperiment.find((f) => f.moduleId === moduleId)?.moduleLabel
      ?? $localize`:@@noTitle:No Title`;
    const moduleTitle = record.moduleReference?.templateTitle ?? '';
    const title = $localize`:@@title:Title`;

    templateHistory.push(this.dataRecordUtilityService.getHistory(
      record,
      currentActivityTitle + ` > ${currentModuleLabel} > ${title}`,
      recordType,
      $localize`:@@Module:Module`,
      currentActivityTitle + ` > ${currentModuleLabel} > ${title}`,
      moduleTitle,
      moduleTitle
    ));
  }

  populateAuditHistoryForSyntheticActivity(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    templateHistory: AuditHistory[],
  ) {
    const template = objectCache[record.templateReferenceId];
    const isModule = template.templateType === TemplateType.Module;
    const templateId = record.templateReferenceId;
    const activityId = record.activityReference?.templateReferenceId ?? '';
    const currentActivityTitle = isModule
      ? this.getSyntheticActivityTitleFromModuleTemplate(templateId)
      : this.experiment.activities.find(a => a.activityId === activityId)?.itemTitle || '';
    const initialActivityTitle = record.activityReference?.templateTitle ?? '';
    const title = $localize`:@@title:Title`;

    templateHistory.push(this.dataRecordUtilityService.getHistory(
      record,
      currentActivityTitle + ` > ${title}`,
      recordType,
      $localize`:@@Activity:Activity`,
      currentActivityTitle + ` > ${title}`,
      initialActivityTitle,
      initialActivityTitle
    ));
  }

  populateAuditHistoryForNonSyntheticNodes(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    template: any,
    templateType: TemplateType,
    moduleOrFormOrTableItem: any,
    templateHistory: AuditHistory[],
    isFiltered: boolean
  ) {
    switch (templateType) {
      case TemplateType.Activity: {
        this.populateAuditHistoryForActivity(record, recordType, template, templateHistory);
        break;
      }
      case TemplateType.Module: {
        this.populateAuditHistoryForModule(record, recordType, template, moduleOrFormOrTableItem, templateHistory, isFiltered);
        break;
      }
      default: {
        this.populateAuditHistoryForFormOrTable(record, recordType, moduleOrFormOrTableItem ?? template, templateHistory, isFiltered);
        break;
      }
    }
  }

  populateAuditHistoryForActivity(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    template: any,
    templateHistory: AuditHistory[]
  ) {
    const title = $localize`:@@title:Title`;
    // Order of pushing audit history: Form/Table -> Module -> Activity
    template.dataModules.forEach((dataModule: any) => {
      dataModule.items.forEach((item: any) => {
        this.populateAuditHistoryForFormOrTable(record, recordType, item, templateHistory);
      });
      const moduleNodeId = record.addedInstanceIds[dataModule.nodeId];
      const moduleContext = this.getModuleContextWithInitialTitle(dataModule.templateId, moduleNodeId);
      templateHistory.push(this.dataRecordUtilityService.getHistory(
        record,
        `${moduleContext.fullPath} > ${title}`,
        recordType,
        $localize`:@@Module:Module`,
        `${moduleContext.fullPath} > ${title}`,
        moduleContext.title,
        moduleContext.title
      ));
    });
    const activityNodeId = record.addedInstanceIds[template.nodeId];
    const activityContext = this.getActivityContextWithInitialTitle(activityNodeId, record.templateTitle);
    templateHistory.push(this.dataRecordUtilityService.getHistory(
      record,
      `${activityContext.fullPath} > ${title}`,
      recordType,
      $localize`:@@Activity:Activity`,
      `${activityContext.fullPath} > ${title}`,
      activityContext.title,
      activityContext.title
    ));
  }

  populateAuditHistoryForModule(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    template: any,
    module: any,
    templateHistory: AuditHistory[],
    isFiltered = true
  ) {
    const title = $localize`:@@title:Title`;
    // Order of pushing audit history: Form/Table -> Module
    for (const item of (module === undefined ? template.items : module.items)) {
      this.populateAuditHistoryForFormOrTable(record, recordType, item, templateHistory);
    }
    const currentModule = module ?? template;
    const moduleNodeId = record.addedInstanceIds[currentModule.nodeId];
    const moduleTitle = isFiltered ? '' : record.templateTitle;
    const moduleContext = this.getModuleContextWithInitialTitle(currentModule.templateId, moduleNodeId, moduleTitle);
    templateHistory.push(this.dataRecordUtilityService.getHistory(
      record,
      `${moduleContext.fullPath} > ${title}`,
      recordType,
      $localize`:@@Module:Module`,
      `${moduleContext.fullPath} > ${title}`,
      moduleContext.title,
      moduleContext.title
    ));
  }

  populateAuditHistoryForFormOrTable(
    record: ExperimentTemplateAppliedEventNotification,
    recordType: string,
    item: any,
    templateHistory: AuditHistory[],
    isFiltered = true
  ) {
    const title = $localize`:@@title:Title`;
    const formOrTableNodeId = record.addedInstanceIds[item.nodeId] ?? item.nodeId;
    const formOrTableTitle = isFiltered ? '' : record.templateTitle;
    switch (item.itemType) {
      case TemplateType.Form: {
        const formContext = this.getFormOrTableContextWithInitialTitle(item.templateId, formOrTableNodeId, formOrTableTitle);
        templateHistory.push(this.dataRecordUtilityService.getHistory(
          record,
          `${formContext.fullPath} > ${title}`,
          recordType,
          $localize`:@@Form:Form`,
          `${formContext.fullPath} > ${title}`,
          formContext.title,
          formContext.title
        ));
        break;
      }
      case TemplateType.Table: {
        const tableContext = this.getFormOrTableContextWithInitialTitle(item.templateId, formOrTableNodeId, formOrTableTitle);
        templateHistory.push(this.dataRecordUtilityService.getHistory(
          record,
          `${tableContext.fullPath} > ${title}`,
          recordType,
          $localize`:@@Table:Table`,
          `${tableContext.fullPath} > ${title}`,
          tableContext.title,
          tableContext.title
        ));
        break;
      }
    }
  }

  applyRowsAddedForEachDataRecord(data: RowsAddedForEachEventNotification) {
    const activity = this.experiment?.activities?.find(a => a.activityId === data.activityId);
    if (!activity)
      throw new Error('LOGIC ERROR: Received a RowsAddedForEachEventNotification for an activity not in this experiment. Something is very wrong.');

    data.tables.forEach(t => {
      const table = activity.dataModules.flatMap(m => m.items).find(i => i.nodeId === t.tableId);
      if (!table) throw new Error('LOGIC ERROR: Received a RowsAddedForEachEventNotification for a table not in this activity. Something is very wrong.');

      for (const row of t.rows) {
        if (table.value.some((r: TableValueRow) => r.id === row.rowId)) continue;

        const insertAfterRow = table.value.find((r: TableValueRow) => r.id === row.insertAfterRowId);
        const newRow: TableValueRow = {
          id: row.rowId, ...row.data.data.reduce((obj: any, item: TableCell) => {
            obj[item.propertyName] = { value: item.propertyValue, isModified: false };
            return obj;
          }, {})
        };
        if (insertAfterRow) {
          const index = table.value.indexOf(insertAfterRow);
          table.value.splice(index + 1, 0, newRow);
        } else {
          table.value.push(newRow);
        }
      }

      const placeHolderRows = table.value.filter((r: TableValueRow) => TableDataService.rowIsPlaceholder(r));
      placeHolderRows.forEach((r: TableValueRow) => {
        r[TableDataService.isConsumedField] = { isModified: false, value: { type: ValueType.String, state: ValueState.Set, value: 'true' } };
      });
    });
  }

  applyStepRenumberPostRowsAddedForEach(activityId: string, tableIds: string[]) {
    const activity = this.experiment.activities.find(a => a.activityId === activityId);
    if (!activity) throw new Error('LOGIC ERROR: Received a RowsAddedForEachEventNotification for an activity not in this experiment. Something is very wrong.');

    for (const t of tableIds) {
      const table = activity.dataModules.flatMap(m => m.items).find(i => i.nodeId === t) as Table;
      if (!table) throw new Error('LOGIC ERROR: Received a RowsAddedForEachEventNotification for a table not in this activity. Something is very wrong.');

      const stepCol = table.columnDefinitions.find(c => c.columnType === ColumnType.StepNumber); // By policy, there can only be one
      if (!stepCol) continue;

      this.renumberSteps(activityId, t, stepCol.field);
    }
  }

  renumberSteps(activityId: string, tableId: string, stepNumberField: string): Observable<RowsRenumberedForEachResponseAggregation> {
    const renumberCommand: RenumberRowsCommand = {
      activityId,
      experimentId: this.experiment.id,
      stepNumberField: stepNumberField,
      tableId: tableId
    };

    const renumberObservable = this.tableService.tableEventsRenumberRowsPost$Json({ body: renumberCommand })
      .pipe(map(result => ({
        result,
        stepColField: stepNumberField
      })));
    renumberObservable.subscribe({
      next: rowsRenumberedResponse => {
        const evt: RowsRenumberedEventNotification = {
          ...rowsRenumberedResponse.result,
          stepNumbers: rowsRenumberedResponse.result.newStepNumbers,
          stepNumberField: rowsRenumberedResponse.stepColField,
          eventContext: {
            eventType: ExperimentEventType.RowsRenumbered,
            experimentId: rowsRenumberedResponse.result.experimentId,
            eventTime: '', // Value not important for catching up experiment object
            puid: '' // Value not important for catching up experiment object
          },
          recordTypes: {} // Value not important for catching up experiment object
        };
        this.experimentNotificationService.dataRecordReceiver.next(evt);
      }
    });
    return renumberObservable;
  }

  async applyStepRenumberPreTransitionToInReview(): Promise<RowsRenumberedForEachResponseAggregation[]> {
    const allRequests: Observable<RowsRenumberedForEachResponseAggregation>[] = [];
    this.experiment.activities.forEach(activity => {
      const tables = activity.dataModules.flatMap(m => m.items).filter(i => i.itemType === NodeType.Table) as Table[];
      const filteredTables = tables.filter(t => t.columnDefinitions.some(cd => cd.columnType === ColumnType.StepNumber)
        && t.value.some(v => TableDataService.rowIsPlaceholder(v) && !TableDataService.rowIsRemovedOrConsumed(v)));
      filteredTables.forEach(table => {
        const colDef = table.columnDefinitions.find(cd => cd.columnType === ColumnType.StepNumber);
        if (!colDef) throw new Error('Expected to find Step Number column');
        return allRequests.push(this.renumberSteps(activity.activityId, table.tableId, colDef.field));
      });
    });

    const requestObs = forkJoin(allRequests);

    return firstValueFrom(requestObs, { defaultValue: [] });
  }
}

type HistoryRecord = ExperimentDataRecordNotification & {
  newValue: {
    value: any
  },
  columnValues: {
    propertyValue: {
      value: any
    }
  }[]
};

export type RowsRenumberedForEachResponseAggregation = {
  result: RowsRenumberedResponse,
  stepColField: string
};
