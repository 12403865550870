import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ChangeReasonService } from "../../experiment/services/change-reason.service";
import { camelCase } from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class ChangeReasonsInterceptor implements HttpInterceptor {
  constructor (
    private readonly changeReasonService: ChangeReasonService
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST') {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if(event.body.hasOwnProperty('changeReasonId')) {
              const operationType = this.getOperationType(event.body);
              ChangeReasonService.changeReasonId = event.body.changeReasonId;
              this.changeReasonService.setOldValue();
              this.changeReasonService.setContextTitle(req.body, operationType);
              this.changeReasonService.setNewValue(req.body, operationType);
              this.changeReasonService.triggerSliderVisibilityFromInterceptor(true);
            }
          }
          return event;
        })
      );
    }
    return next.handle(req);
  }

  private getOperationType(body: any): string {
    return body.hasOwnProperty('eventContext') ?
      body.eventContext.operationType ? camelCase(body.eventContext.operationType) : camelCase(body.eventContext.eventType) :
      camelCase(body.operationType);
  }
}