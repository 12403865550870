import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, NgZone } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { Activity, Experiment, Form, ReferenceGridType, SpecificationValue, Table } from 'model/experiment.interface';
import { Message, ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Observable, Subject, Subscription, throwError, map, forkJoin, of } from 'rxjs';
import { take, finalize, first, filter } from 'rxjs/operators';
import { ActivityInputHelper } from './inputs/shared/activity-input-helper';
import { ClientStateService } from 'services/client-state.service';
import {
  NodeType,
  TemplateType,
  ExperimentWorkflowState,
  ExperimentRaisedFlag,
  ExperimentFlagType,
  ActivityInputType,
  ActivityLabItemsNode,
  FormWithFieldDefinitionsResponse,
  SubBusinessUnit,
  ClientFacingNoteContextType,
  Aliquot
} from '../../app/api/models';
import {
  ApplyReferenceTemplateCommand,
  ClientFacingNoteCreatedEventNotification,
  ExperimentCancelledResponse,
  ExperimentEventType,
  ExperimentRestoredResponse,
  ExperimentStartedResponse,
  ReferenceTemplateAppliedResponse,
  ReferenceTemplateType,
  SetVariableCommand
} from '../../app/api/data-entry/models';
import { BaseComponent } from '../base/base.component';
import { ExperimentService, SpecificationEditorContext } from './services/experiment.service';
import { TemplateLoaderResult } from '../template-loader/models/template-loader-result';
import { SelectedTemplate } from '../template-loader/models/selected-template';
import { TemplateLoaderType } from '../template-loader/models/template-loader-type';
import { ExperimentTemplateEventService } from '../template-loader/experiment-template-load/services/experiment-template-event.service';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ClientValidationDetails } from 'model/client-validation-details';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { ExperimentEventsService } from '../api/data-entry/services/experiment-events.service';
import { DataRecordService, SetVariableEventNotification } from './services/data-record.service';
import { WorkflowEventNotification } from '../api/data-entry/models/workflow-event-notification';
import { ExperimentSentForReviewResponse } from '../api/data-entry/models/experiment-sent-for-review-response';
import { ExperimentAuthorizedResponse } from '../api/data-entry/models/experiment-authorized-response';
import { ExperimentSentForCorrectionResponse } from '../api/data-entry/models/experiment-sent-for-correction-response';
import { elnEncodeSpecialChars } from '../shared/url-path-serializer';
import { AccessibilityTypes, ClientState } from '../app.states';
import { ToolbarIcon } from 'bpt-ui-library/bpt-toolbar/model/toolbar-icons.interface';
import { MainMenuItem } from 'bpt-ui-library/bpt-layout/model/main-menu-item.interface';
import { AuditHistoryService } from './audit-history/audit-history.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentRecordType } from '../api/audit/models';
import { ShowNotesOrCommentsEventData } from './comments/client-facing-note/client-facing-note-event.model';
import { ElnProgressSpinnerService } from '../eln-progress-spinner-module/eln-progress-spinner.service';
import { NodeCompletionStatus } from './model/node-completion-status.interface';
import { ExperimentWarningService } from './services/experiment-warning.service';
import { RuleActionHandlerHostService } from './../rule-engine/handler/rule-action-handler-host.service';
import { ExperimentOptionsHelper } from '../shared/experiment-options-helper';
import { RuleActionNotificationService } from './../rule-engine/action-notification/rule-action-notification.service';
import { ActivityInputInstrumentEvent } from './model/activity-input-instrument-event';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { objectCache, UnsubscribeAll } from '../shared/rx-js-helpers';
import { Title } from '@angular/platform-browser';
import { LabItemsService } from './labItems/lab-items.service';
import { CommentContextType } from '../api/internal-comment/models';
import { CommentDetails } from './comments/comment.model';
import { CommentService } from './comments/comment.service';
import { FeatureService } from 'services/feature.service';
import { ELNFeatureFlags } from '../shared/eln-feature-flags';
import { DataPackageService } from './data-packages/data-package.service';
import { LabItemsFeatureManager } from './labItems/shared/lab-items-feature-manager';
import { ActivityReferenceEventsService } from '../api/data-entry/services';
import { ExperimentNodeRetitleService } from './services/experiment-node-re-title.service';
import { TemplateLoaderService } from '../recipe-template-loader/services/template-loader.service';
import { ExperimentUserPreferenceService } from './services/experiment-user-preference.service';
import { ActivityReferencesPseudoModuleId, ActivityReferencesPseudoModuleTitle } from './references/references.component';
import { OutputEmpowerService } from './services/output-empower.service';
import { ExperimentNodeReOrderService } from './services/experiment-node-re-order.service';
import { AuthResult, AuthStatusType } from '../shared/authentication-maintainance/model/auth.model';
import { AuthenticationHelperService } from '../shared/authentication-maintainance/services/authentication-helper.service';
import { ConfigurationService } from '../services/configuration.service';
import { ExperimentPreparationService } from './preparations/experiment-preparations/services/experiment-preparation.service';
import { BptReoderedEvent } from '../layout/main-menu/main-menu.component';
import { CompletionTrackingService } from '../services/completion-tracking.services';
import { SearchControl } from 'bpt-ui-library/bpt-search';
import { LoaderSearchCriteria } from '../recipe-template-loader/experiment-template-load/models/finalised-search-criteria';
import { SelectedTemplateCommand } from '../recipe-template-loader/models/template-loader-information';
import { TemplateLocationOptionsConstants } from '../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { TemplateLoaderFilterChangedEvent } from '../recipe-template-loader/models/template-loader-filter-changed-event.interface';
import { ExperimentTemplateApplyService } from '../template-loader/experiment-template-load/services/experiment-template-apply.service';
import { TemplateInsertLocationOptions } from '../recipe-template-loader/experiment-template-load/models/recipe-template-insert-location-options';
import { InstrumentConnectionHelper } from './instrument-connection/shared/instrument-connection-helper';
import { InstrumentType } from './instrument-connection/shared/instrument-type';
import { ActivityInputItemState } from './barcode-scanner/activity-input-item-state';
import { PhMeterService } from './services/ph-meter.service';
import { InstrumentNotificationService } from './instrument-connection/shared/instrument-notification-service';
import { ClientFacingNoteModel } from './comments/client-facing-note/client-facing-note.model';
import { ConditionType, DataType, SearchCriteria, SortDirection, StringMatchType } from '../api/search/models';
import { ProjectLogLoaderService } from '../services/project-log-loader.service';
import { replace } from 'lodash-es';
import { NA } from 'bpt-ui-library/shared';
import { ActivityPromptItem } from './model/activity-prompt-item';

/**
 * Root of the tree of components for an Experiment. Loads the one experiment asynchronously, succeeding or failing.
 * Never destroyed while page is open. Can be depended on to be present for the entire life of viewing the one experiment.
 */
@Component({
  selector: 'app-experiment',
  templateUrl: './experiment.component.html',
  styleUrls: ['./experiment.component.scss']
})
export class ExperimentComponent extends BaseComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  private readonly reOrderSubscription: Subscription[] = [];
  private readonly expWorkflowSubscription: Subscription[] = [];
  public experimentNumber?: string;
  public experiment?: Experiment;
  showConnectionIcon = true;
  auditLoading = false;
  loadingAppliedTemplate = false;
  isInvalid = false;
  errorMessages: Message[] = [];
  successMessages: Message[] = [];
  menuItems: MainMenuItem[] = [];
  user!: User;
  internalCommentData: CommentDetails = {} as CommentDetails;
  validation!: ClientValidationDetails;
  experimentWorkFlowEventSubscription?: Subscription;
  experimentCancelOptionSubscription?: Subscription;
  beginEditSpecification?: Subscription;
  specificationEditorContext?: SpecificationEditorContext;
  specificationEditorContextId = (_index: number, item: SpecificationEditorContext | undefined) =>
    item?.id ?? '00000000-0000-0000-0000-000000000000'; // for trackBy, default cannot be undefined; so return a constant to mean no context
  canStartExperimentFlag = true;
  canPendingCancelFlag?: ExperimentRaisedFlag;
  canCancelExperiment = false;
  canRestoreExperiment = false;
  canAddTemplate = true;
  canSendExperimentForReviewFromInProgressFlag = false;
  canSendExperimentForReviewFromCorrectionFlag = false;
  canSendExperimentForCorrectionFromAuthorizedStateFlag = false;
  canSendExperimentForCorrectionFromReviewFlag = false;
  canSendExperimentForAuthorizationFlag = false;
  isOutputsEnabled = false;
  sliderVisible = false;
  showTemplateLoader = false;
  disableAddTemplateButton = true;
  disableAddInstrumentEvent = false;
  disableAddConsumable = true;
  disableAddReferences = true;
  hideAddConsumableButton = false;
  hideAddInstrumentEvent = true;
  experimentHeader = '';
  experimentSubHeader: string | undefined = '';
  isBarcodeOpenedManually = true;
  templateLocationLookup: Array<{ id: string; name: string; type: NodeType }> = [];
  selectedTemplateName: string | undefined = undefined;
  selectedTemplate: SelectedTemplate | undefined = undefined;
  public insertLocationOptions: TemplateInsertLocationOptions[] = [];
  @Output() showComments: EventEmitter<ShowNotesOrCommentsEventData> =
    new EventEmitter<ShowNotesOrCommentsEventData>();
  templateLoaderType: TemplateLoaderType = TemplateLoaderType.TemplateToExperiment;
  inactiveTimeOut = 0;
  stopTimer = false;
  userInactiveTimeOut = 7200000;
  confirmationTimeOut = 60000;
  workflowStateTransitionOptions = [];
  showReconnectDialog = false;
  isReload = false;
  showConnecting = false;
  showBarcodeScanner = false;
  showBarcodeSlider = false;
  showCrossReferenceSlider = false;
  toolbarIcons: ToolbarIcon[] = [];
  dynamicDialogRef!: DynamicDialogRef;
  showAddTemplateButton = true;
  loadingMessage = $localize`:@@loadingExperiment:Loading Experiment...`;
  previewLoadingMessage = $localize`:@@loadingPreviewMessage:Loading Preview...`;
  workflowOptions: MenuItem[] = [];
  currentWorkflowState = '';
  currentWorkflowIcon = '';
  currentContextActivityId?: string;
  canRaiseExperimentFlags!: boolean;
  searchControls: Array<SearchControl> = [];
  experimentFlags: Array<ExperimentRaisedFlag> = [];
  activityInputSubscription?: Subscription;
  activityInputSelectedSubscription?: Subscription;
  BarcodeScannerOpenModeOptions: typeof ActivityInputType = ActivityInputType;
  scannerOpenMode?: ActivityInputType;
  instrumentType?: InstrumentType;
  currentClientState = ClientState.EXPERIMENT_COVER;
  addConsumable = new Subject<void>();
  historyIcon = 'fas fa-history';
  activityMenuStyleClass = 'eln-activity-menu-item';
  activityIconClass = 'pi pi-comments';
  consumableId = 'b3dee1b2-7ece-4262-8b02-b74e9f665188';
  toolTip = $localize`:@@TemplateNameSearchPlaceHolder:Search by Template/Recipe Name or Number`;
  titleOfConsumableAndSupplyTable = $localize`:@@LabItemsConsumablesTableTitle:Consumables & Supplies`;
  newActivity = TemplateLocationOptionsConstants.newActivity;
  existingModule = TemplateLocationOptionsConstants.existingModule;
  existingActivityAsModule = TemplateLocationOptionsConstants.existingActivityAsModule
  loaderSearchCriteria: LoaderSearchCriteria = {};
  isCollaborator = false;
  insertOptions: Array<{ label: string, value: string }> = [];
  isReviewer = this.userService.hasReviewerRights(this.userService.currentUser.puid);
  enableWorkflowAction = !this.readOnly;
  isMandatoryExperimentFieldsProvided = true;
  readonly recipe = 'Recipe';
  acceptCallback = () => { };

  private _loading = false;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value;
  }

  get routeIsReferencesComponent(): boolean {
    return this.router.url.endsWith('/References');
  }

  get currentActivity(): Activity | undefined {
    return this.experimentService.currentActivity;
  }

  get activityHasCrossReferences(): boolean {
    return (this.currentActivity?.activityReferences.crossReferences.length ?? 0) > 0;
  }

  get activityHasDocumentReferences(): boolean {
    return !!this.currentActivity?.activityReferences.documentReferencesTableId;
  }

  get activityHasCompendiumReferences(): boolean {
    return !!this.currentActivity?.activityReferences.compendiaReferencesTableId;
  }
  get clientOptions() {
    return this.projectLogLoaderService.getAllClients();
  }
  readonly includeOnlyInputsEventForAuditHistory: ExperimentEventType[] = [
    ExperimentEventType.ActivityInputCellChanged,
    ExperimentEventType.AliquotAdded,
    ExperimentEventType.ActivityInputAdded,
    ExperimentEventType.ActivityInputRowRefreshed,
    ExperimentEventType.ActivityInputRowRemoved,
    ExperimentEventType.ActivityInputRowRestored,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.MaterialAdded,

    /**
     * Instrument Related Events
     */
    ExperimentEventType.InstrumentAdded,
    ExperimentEventType.InstrumentRemovedFromServiceChanged,
    ExperimentEventType.InstrumentDescriptionChanged,
    ExperimentEventType.InstrumentEventReturnedToService,
    ExperimentEventType.InstrumentDateRemovedChanged,

    /**
     * Selection Events
     */
    ExperimentEventType.StudyActivitySelected,
    ExperimentEventType.MaintenanceEventSelected,
    ExperimentEventType.SampleTestChanged,

    /**
   * Statements & Witnessing
   */
    ExperimentEventType.StatementApplied
  ]

  readonly includeOnlyLabItemsEventForAuditHistory: ExperimentEventType[] = [

    ExperimentEventType.LabItemsCellChanged,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.ExperimentRecipeApplied,
    /**
     * Material Related Events
     */
    ExperimentEventType.LabItemsMaterialAdded,
    ExperimentEventType.LabItemsMaterialRemoved,
    ExperimentEventType.LabItemsMaterialRestored,
    ExperimentEventType.LabItemsMaterialRefreshed,

    /**
     * Instrument Related Events
     */
    ExperimentEventType.LabItemsInstrumentAdded,
    ExperimentEventType.LabItemsInstrumentRemoved,
    ExperimentEventType.LabItemsInstrumentRestored,
    ExperimentEventType.LabItemsInstrumentRefreshed,

    /**
     * Instrument Column Related Events
     */
    ExperimentEventType.InstrumentColumnAdded,
    ExperimentEventType.LabItemsInstrumentColumnRefreshed,
    ExperimentEventType.LabItemsInstrumentColumnRemoved,
    ExperimentEventType.LabItemsInstrumentColumnRestored,

    /**
     * Consumable Related Events
     */
    ExperimentEventType.LabItemsConsumableAdded,
    ExperimentEventType.LabItemsConsumableRemoved,
    ExperimentEventType.LabItemsConsumableRestored,

    /*
     * LabItem Preparation Related Events
     */
    ExperimentEventType.LabItemsPreparationAdded,
    ExperimentEventType.LabItemPreparationRefreshed,
    ExperimentEventType.LabItemPreparationRemoved,
    ExperimentEventType.LabItemPreparationRestored,
    ExperimentEventType.StatementApplied,

    /*
    * Prompts related events
    */
    ExperimentEventType.PromptSatisfied
  ]

  readonly preparationEventsForAuditHistory: ExperimentEventType[] = [
    /**
     * Events related to Preparations only
     */
    ExperimentEventType.ExperimentPreparationCreated,
    ExperimentEventType.ExperimentPreparationCellChanged,
    ExperimentEventType.ExperimentPreparationDiscardedOrConsumed,
    ExperimentEventType.ExperimentPreparationInternalInformationChanged,
    ExperimentEventType.ExperimentPreparationRemoved,
    ExperimentEventType.ExperimentPreparationRestored,
    ExperimentEventType.ExperimentPreparationStatusChanged,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.StatementApplied
  ]
  private readonly WorkflowStatusNames = {
    setup: $localize`:@@setupState:Setup`,
    inProgress: $localize`:@@inProgressState:In Progress`,
    inReview: $localize`:@@inReviewState:In Review`,
    inCorrection: $localize`:@@inCorrectionState:In Correction`,
    cancelled: $localize`:@@cancelledState:Cancelled`,
    authorized: $localize`:@@authorizedState:Authorized`,
    restored: $localize`:@@restoredState:Restored`
  };

  private readonly WorkflowStatusIcons = {
    setup: 'pi pi-cog',
    inProgress: 'icon-review icon-s',
    inReview: 'pi pi-eye',
    inCorrection: 'icon-correction icon-s',
    cancelled: 'pi pi-file-excel',
    authorized: 'icon-eln1 icon-s',
    restored: 'pi pi-replay'
  };
  //this will be changed to be dynamic search while implementing 3266720
  private readonly recipeCriteriaSearch: SearchCriteria = {
    bypassSecurity: false,
    filterConditions: [
      {
        conditionType: ConditionType.And,
        filters: [
          {
            columnName: 'available',
            matchType: StringMatchType.Word,
            text: 'true',
            isSecurityFlag: true,
            dataType: DataType.String
          },
          {
            columnName: 'state',
            matchType: StringMatchType.Word,
            text: 'Published',
            isSecurityFlag: true,
            dataType: DataType.String
          }
        ]
      },
      {
        conditionType: ConditionType.Or,
        filters: [
          {
            columnName: 'labsiteCode',
            matchType: StringMatchType.Word,
            text: this.userService.currentUser.labSiteCode,
            isSecurityFlag: true,
            dataType: DataType.String
          },
          {
            columnName: 'consumingLabsites',
            matchType: StringMatchType.Word,
            text: this.userService.currentUser.labSiteCode,
            isSecurityFlag: true,
            dataType: DataType.String
          }
        ]
      }
    ],
    pagination: { pageNumber: 1, pageSize: 10000 },
    sort: [
      {
        columnType: DataType.Date,
        columnName: 'createdOn',
        order: 1,
        sortDirection: SortDirection.Descending
      }
    ]
  };
  handleSubscriptions() {
    this.expWorkflowSubscription.push(this.experimentService.instrumentEventRemoved.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this._labItemServiceHost.MaterialAdded.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this._labItemServiceHost.MaterialRemoved.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this.activityInputHelper.aliquotRestored.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this.activityInputHelper.materialAliquotRestored.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this.activityInputHelper.aliquotRemoved.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
    this.expWorkflowSubscription.push(this.experimentService.chromatographyResultSetRemoved.subscribe(() => {
        this.setCurrentWorkflowTransitionAction();
    }));
}
  experimentOptions = ExperimentOptionsHelper.getOptionsFromRoute();
  subBusinessUnits: SubBusinessUnit[] = [];
  constructor(
    clientStateService: ClientStateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    public readonly experimentNotificationService: ExperimentNotificationService,
    public readonly experimentEventService: ExperimentEventsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly _ruleActionHandlerHostService: RuleActionHandlerHostService,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly browserTitle: Title,
    private readonly _labItemServiceHost: LabItemsService,
    public readonly activityInputHelper: ActivityInputHelper,
    private readonly commentService: CommentService,
    private readonly featureService: FeatureService,
    private readonly ngZone: NgZone,
    private readonly dataPackageService: DataPackageService,
    private readonly activityReferenceEventsService: ActivityReferenceEventsService,
    private readonly experimentNodeReTitleService: ExperimentNodeRetitleService,
    private readonly templateLoaderService: TemplateLoaderService,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly outputEmpowerService: OutputEmpowerService,
    private readonly experimentUserPreferenceService: ExperimentUserPreferenceService,
    public readonly experimentNodeReOrderService: ExperimentNodeReOrderService,
    private readonly authenticationHelperService: AuthenticationHelperService,
    private readonly experimentPreparationService: ExperimentPreparationService,
    private readonly completionTrackingService: CompletionTrackingService,
    public readonly templateApplyService: ExperimentTemplateApplyService,
    public readonly pHMeterService: PhMeterService,
    public readonly instrumentNotificationService: InstrumentNotificationService,
    private readonly projectLogLoaderService: ProjectLogLoaderService
  ) {
    super(clientStateService, route);
    this.user = { ...this.userService.currentUser };
    this.createTemplateOptionsQuery();
    this.subscriptions.splice(0, 0,
      this.dataRecordService.fieldChangedDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionStatusForOtherForm(data)
      ),
      this.dataRecordService.cellChangedDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionTrackingForOtherTable(data)
      ),
      this.dataRecordService.addRowsDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionTrackingForOtherTable(data)
      ),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver
        .subscribe((data: WorkflowEventNotification) => this.applyExperimentWorkflowDataRecord(data)),
      this.experimentService.nodeCompletionStatus
        .subscribe((data: NodeCompletionStatus) => this.updateCompletionStatus(data)),
      this.ruleActionNotificationService.SetVariableActionResponse
        .subscribe((data: SetVariableCommand) => this.experimentService.setVariableUsingCommand(data)),
      this.dataRecordService.setVariableDataRecordReceiver
        .subscribe((data: SetVariableEventNotification) => this.experimentService.setVariableUsingCommand(data)),
      this.experimentService.activityCompletionStatus
        .subscribe((activity: Activity) => this.updateMenuItems(activity)),
      this.experimentService.experimentFlags
        .subscribe((flags: Array<ExperimentRaisedFlag>) => {
          this.experimentFlags = flags;
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
        }),

      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((_route: { url: string }) => {
          if (_route.url.toLowerCase().includes('/labitems')) {
            if (this.doesConsumableExistsForLabItem()) this.hideAddConsumableButton = true;
            else this.hideAddConsumableButton = false;
          } else this.hideAddConsumableButton = true;
        }),
      this.experimentService.reviewerIsNowCollaborator
        .subscribe(() => {
          this.evaluateCurrentWorkflowTransitionActions()
        }),
      this.experimentService._isCurrentUserCollaboratorSubject$.subscribe((isUserBecameCollaborator: boolean) => {
        this.isCollaborator = isUserBecameCollaborator;
        this.setCurrentWorkflowTransitionAction(isUserBecameCollaborator);
      })
    );
    this.experimentService.instrumentEventRemoved.subscribe(() => this.setStateOfAddInstrumentButton(this.experiment?.workflowState as any));
    experimentNodeReTitleService.ActivityTitleChanged.subscribe({
      next: () => {
        this.loadMenu();
        this.populateExperimentHeaders();
      }
    });

    this.instrumentConnectionHelper.phMeterConnectionSuccess.subscribe(() => {
      this.isBarcodeOpenedManually = false;
      if (pHMeterService.retainConnection()) {
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@pHMeterConnectionRestored:Connection Restored : Connected to pH Meter`,
          detail: pHMeterService.formattedIdentification('\n'),
          sticky: false
        });
      } else {
        this.openBarcodeSlider(ActivityInputType.InstrumentDetails, InstrumentType.phMeter);
      }
    });

    this.barcodeScannerHelper.barcodeScanFailed.subscribe(() => {
      if (this.instrumentType === InstrumentType.phMeter) {
        this.closeBarcodeSlider(false);
        this.pHMeterService.disconnect();
      }
    });

    this.experimentService.barcodeScanned.subscribe(({ scanStatus, scannerMode, instrumentType }) => {
      this.processPhMeterScan(scanStatus, scannerMode, instrumentType);
    });

    this.experimentTemplateEventService.TemplateAddedByOtherUser.subscribe((data) => {
      this.reloadExpt(data.templateTitle);
      if ((data.activityReference?.templateReferenceId ===
        this.experiment?.reservedInstances[0]?.instanceId ||
        Object.values(data.addedInstanceIds).some(
          (s) => s === this.experiment?.reservedInstances[0]?.instanceId)) &&
        this.experimentService.currentExperiment?.activityInputs &&
        this.experimentService.currentExperiment?.activityInputs.length > 0
      ) {
        this.messageService.add({
          key: 'notification',
          severity: 'warn',
          summary: '',
          detail: $localize`:@@experimentInputOutputMovedToActivity:Input/Output items moved to the activity`,
          sticky: false
        });
      }
    });

    this.barcodeScannerHelper.barcodeScanSuccess.subscribe((successScan) => {
      if (successScan && this.instrumentType === InstrumentType.phMeter) {
        this.processPhMeterScan(ActivityInputItemState.Success, ActivityInputType.InstrumentDetails, this.instrumentType);
      } else if (!successScan && this.instrumentType === InstrumentType.phMeter) {
        this.processPhMeterScan(ActivityInputItemState.Warning, ActivityInputType.InstrumentDetails, this.instrumentType);
      }
      this.barcodeScannerHelper.refreshInputs.next(true);
    });

    this.barcodeScannerHelper.activityScannedNotification.subscribe(() => {
      this.setCurrentWorkflowTransitionAction();
    });

    this.experimentService.activityFileUploaded.subscribe(() => {
      this.setCurrentWorkflowTransitionAction();
    });

    this.reOrderSubscription.push(experimentNodeReOrderService.nodeOrderChanged.subscribe({
      next: (data) => {
        if (data.nodeType === NodeType.Activity) {
          let activityIndex = -1;
          const activity = this.experiment?.activities.find(
            (act, index) => {
              activityIndex = index;
              return act.activityId === data.nodeId;
            }
          );
          this.experiment?.activities.splice(activityIndex, 1);
          this.experiment?.activities.splice(data.position, 0, activity as Activity);
          this.loadMenu();
        }
      }
    }));

    this.experimentService.experimentWorkFlowState.subscribe({
      next: (_state) => {
        this.loadMenu();
      }
    })
    this.templateLoaderService.fetchFavoriteTemplates();
  }

  @HostListener('ShowSlider', ['$event'])
  onShowSliderEventCaptured(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showComments.emit(event.detail);
  }

  ngOnInit(): void {
    this.experimentPreparationService.subscribePreparationFieldsFilled();
    this.setLoadingMessage();
    this.loading = true;
    this.handleSubscriptions();
    this.watchTemplateAppliedNotification();
    this.watchRecipeAppliedNotification();
    this.templateLoaderService.fetchFavoriteTemplates();
    this.validation = new ClientValidationDetails();
    this.user = { ...this.userService.currentUser };
    this.initialize();
    this.experiment = this.experimentService.currentExperiment; // Note: this is suspicious; normal case is that this component is initialized before we have an experiment.
    this.subscriptions.splice(0, 0,
      this.route.paramMap.subscribe((params) => {
        this.experimentNumber = params.get('experimentNumber') ?? undefined;
        if (!this.experimentNumber) return;
        this.route.children[0].data.subscribe((params) => {
          this.currentClientState = params.clientState;
        });
        this.loadExperiment(this.experimentNumber);
      }),
      this.experimentNotificationService.connectionStartedAlert?.subscribe((_success) => {
        this.stopTimer = false;
        this.showReconnectDialog = false;
        this.showConnecting = false;
        this.startTimer();
      }),
      this.experimentNotificationService.reconnectedAlert?.subscribe((_success) => {
        this.stopTimer = false;
        this.showReconnectDialog = false;
        this.showConnecting = false;
        this.startTimer();
      }),
      this.experimentService.getSubBusinessUnits().subscribe(value => {
        this.subBusinessUnits = value.labsites[0].subBusinessUnits;
      }),
      this.experimentNotificationService.connectionLostAlert?.subscribe((_success) => {
        if (!this.isReload) {
          this.stopTimer = true;
          this.showReconnectDialog = true;
        }
      }),
      this.router.events
        .pipe(filter((event: any) => event instanceof ActivationStart))
        .subscribe((data) => {
          this.currentClientState = data.snapshot.data.clientState;
          this.setupBarcodeScanner();
          this.browserTitle.setTitle(this.experimentNumber as string);
        }),
      this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe((data: ActivityInputInstrumentEvent) => {
        if (this.userService.hasOnlyReviewerRights() ||
          !!data && data.activityInputType === ActivityInputType.Instrument && data.instrumentNumber && data.instrumentNumber.length > 0) {
          this.setStateOfAddInstrumentButton(this.experiment?.workflowState as ExperimentWorkflowState);
        } else {
          this.disableAddInstrumentEvent = false;
        }
      }),
      this.barcodeScannerHelper.activityInputSelected.subscribe((isActivityInputSelected: boolean) => {
        if (isActivityInputSelected) {
          this.hideAddInstrumentEvent = false;
          this.setStateOfAddInstrumentButton(this.experiment?.workflowState as ExperimentWorkflowState);
        } else {
          this.hideAddInstrumentEvent = true;
        }
      }),
      this.experimentService.beginEditSpecification
        .subscribe({
          next: (context) => {
            this.specificationEditorContext = context;
          }
        }),
      this.experimentService.labItemsConsumableAdded.subscribe(() => {
        this.hideAddConsumableButton = true;
      }),
      this.experimentService.activitySelectionChanged.subscribe((activityId: string | undefined) => {
        this.populateExperimentHeaders(activityId);
      })
    );
    this.browserTitle.setTitle(this.experimentNumber as string);
    this.watchForMandatoryExperimentFields();
  }

  private populateExperimentHeaders(activityId?: string | undefined) {
    if (activityId === undefined) {
      this.experimentHeader = this.experimentNumber ?? '';
      this.experimentSubHeader = this.experimentService.currentExperiment?.title ?? '';
      return;
    }
    if (this.experimentService.HasActivities() && this.experimentService.currentActivity) {
      const currentActivity = this.experiment?.activities.find(a => a.activityId === this.experimentService.currentActivityId);
      if (currentActivity?.activityReferenceNumber === NA) {
        this.experimentHeader = currentActivity?.itemTitle ?? this.experimentHeader;
        this.experimentSubHeader = undefined;
      } else {
        this.experimentHeader = currentActivity?.activityReferenceNumber ?? this.experimentHeader;
        this.experimentSubHeader = currentActivity?.itemTitle ?? this.experimentSubHeader;
      }
    } else {
      this.experimentHeader = this.experimentNumber ?? '';
      this.experimentSubHeader = this.experimentService.currentExperiment?.title ?? '';
    }
  }

  watchForMandatoryExperimentFields() {
    this.watchForTitleEmpty();
    this.watchForTitleChanged();
    this.watchForTitleNotChanged();
    this.watchForSubBusinessUnitNotSelected();
    this.watchForSubBusinessUnitSelected();
    this.watchForSubBusinessUnitNotChanged();
  }

  get isExperimentAuthorizedOrCancelled() {
    return ExperimentService.isExperimentAuthorizedOrCancelled(this.experiment?.workflowState as ExperimentWorkflowState);
  }

  private watchForTitleEmpty(): void {
    this.experimentService.isExperimentTitleEmpty.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = false;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  processPhMeterScan(scanStatus: ActivityInputItemState, scannerMode: ActivityInputType, instrumentType: InstrumentType) {
    if (scannerMode === ActivityInputType.InstrumentDetails && instrumentType === InstrumentType.phMeter) {
      this.closeBarcodeSlider(false);
      this.messageService.add({
        key: 'notification',
        severity: scanStatus === ActivityInputItemState.Success ? 'success' : 'error',
        summary: '',
        detail: scanStatus === ActivityInputItemState.Success ? $localize`:@@pHMeterInstrumentAddedToTheLabItems:Instrument added to the Lab Item` :
          $localize`:@@pHMeterInstrumentfailedToAddToTheLabItems:Invalid instrument ID`,
        sticky: false
      });
      this.isBarcodeOpenedManually = true;

      if (scanStatus === ActivityInputItemState.Warning) {
        if (this.instrumentConnectionHelper.phName) {
          this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper.phName, InstrumentType.phMeter);
        }
        this.pHMeterService.disconnect();
      } else {
        this.pHMeterService.phMeterConnected();
      }
    }
  }

  private watchForTitleChanged(): void {
    this.experimentService.isExperimentTitleChanged.subscribe({
      next: (response) => {
        if (response && this.experiment) {
          this.experiment.title = response;
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForTitleNotChanged(): void {
    this.experimentService.isExperimentTitleNotChanged.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitNotSelected(): void {
    this.experimentService.isSubBusinessUnitNotSelected.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = false;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitNotChanged(): void {
    this.experimentService.isSubBusinessUnitNotChanged.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitSelected(): void {
    this.experimentService.isSubBusinessUnitSelected.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private setEnableWorkflowAction(): void {
    this.enableWorkflowAction = !this.readOnly && this.isMandatoryExperimentFieldsProvided;
  }

  setLoadingMessage() {
    this.loadingMessage = ExperimentOptionsHelper.getOptionsFromRoute().previewMode
      ? this.previewLoadingMessage
      : this.loadingMessage;
  }

  updateCompletionStatus(nodeStatus: NodeCompletionStatus) {
    if (!this.experiment) return;

    const existing = this.experiment.experimentCompletionStatus.find(c => c.id === nodeStatus.id);
    if (!existing) {
      this.experiment.experimentCompletionStatus.push(nodeStatus);
    } else {
      existing.isComplete = nodeStatus.isComplete;
    }
  }

  /** Check if all complete, including preparations and show a toast if not */
  checkExperimentCompletion(): boolean {
    if (!this.experiment) return false; // punt

    let isComplete = true; // hypothesis
    let areInputsComplete = true;
    let isPreparationsComplete = this.experimentPreparationService.allPreparationsFieldsFilled; // check for current activity completion
    if (isPreparationsComplete) {
      isPreparationsComplete = this.experimentService.checkPreparationsAreComplete();
      if (!isPreparationsComplete) {
        this.displayFieldsIncompleteMessage();
        isComplete = isPreparationsComplete;
        return isComplete;
      }
    }
    if (this.experiment?.activityInputs) {
      areInputsComplete = this.experimentService.checkInputsCompletion(this.experiment.activityInputs);
      if (!areInputsComplete) {
        this.displayFieldsIncompleteMessage();
        isComplete = areInputsComplete;
        return isComplete;
      }
    }
    for (const nodeStatus of this.experiment.experimentCompletionStatus) {
      if (nodeStatus.isComplete === false) {
        this.displayFieldsIncompleteMessage();
        isComplete = false;
        break;
      }
    }
    return isComplete;
  }

  displayFieldsIncompleteMessage() {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      id: 'allFieldsNotCompleteMsg',
      summary: $localize`:@@notAllFieldsCompleteSummary:Not all fields are complete`,
      detail: $localize`:@@allFieldsNotComplete:All fields must be complete before transitioning the experiment.`,
      sticky: false
    });
  }

  applyExperimentWorkflowDataRecord(data: WorkflowEventNotification) {
    const receivedWorkflowState = data.state;
    this.setStateOfAddReferenceButtons(receivedWorkflowState);
    this.setStateOfAddTemplateButton(receivedWorkflowState);
    this.setStateOfAddInstrumentButton(receivedWorkflowState);
    this.setStateOfAddConsumableButton(receivedWorkflowState);
    this.setUpExperimentFlags();
    switch (receivedWorkflowState) {
      case ExperimentWorkflowState.InReview:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inReview}"`,
          $localize`:@@successSentForReviewMessageDetails:The experiment was sent for review successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inReview}'.`
        );
        break;
      case ExperimentWorkflowState.InCorrection:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inCorrection}"`,
          $localize`:@@successSentForCorrectionMessageDetails:The experiment was sent for correction successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inCorrection}'.`
        );
        break;
      case ExperimentWorkflowState.Cancelled:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.cancelled}"`,
          $localize`:@@successCancelledMessageDetails:The experiment was cancelled successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.cancelled}'.`
        );
        break;
      case ExperimentWorkflowState.Authorized:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.authorized}"`,
          $localize`:@@successAuthorizedMessageDetails:The experiment was authorized successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.authorized}'.`
        );
        break;
      case ExperimentWorkflowState.Setup:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.restored}"`,
          $localize`:@@successRestoredMessageDetails:The experiment was restored successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.setup}'.`
        );
        break;
      default:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inProgress}"`,
          $localize`:@@successStartMessageDetails:The experiment was started successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inProgress}'.`
        );
    }

    if (this.experiment) {
      this.experiment.workflowState = receivedWorkflowState;
      this.evaluateCurrentWorkflowTransitionActions();
      this.setupBarcodeScanner();
      this.loadMenu();
    }
  }
  windowVar = window as any;
  startExperiment = () => {
    if (this.experiment) {
      const startObs = this.experimentEventService.experimentEventsExperimentIdStartPost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        startObs,
        (experimentStartResponse: ExperimentStartedResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentStartResponse.experimentStartedEventNotification.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inProgress}"`,
            $localize`:@@experimentStartedMessageDetails:The experiment has been started successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InProgress);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  cancelExperiment = () => {
    if (this.experiment) {
      const cancelObs = this.experimentEventService.experimentEventsExperimentIdCancelPost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        cancelObs,
        (experimentCancelledResponse: ExperimentCancelledResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentCancelledResponse?.experimentCancelledEventNotification?.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.cancelled}"`,
            $localize`:@@experimentCancelledMessageDetails:The experiment has been cancelled successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.Cancelled);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  restoreExperiment = () => {
    if (this.experiment) {
      const cancelObs = this.experimentEventService.experimentEventsExperimentIdRestorePost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        cancelObs,
        (experimentRestoredResponse: ExperimentRestoredResponse, experiment: Experiment) => {
          if (experimentRestoredResponse.hasOwnProperty('experimentRestoredEventNotification')) {
            experiment.workflowState =
              experimentRestoredResponse?.experimentRestoredEventNotification?.state;
          }
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.restored}"`,
            $localize`:@@experimentRestoredMessageDetails:The experiment has been restored successfully`
          );
          this.experimentService.experimentWorkFlowState.next(experiment.workflowState);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  sendExperimentForReview = () => {
    const applyTransition = () => {
      if (this.experiment) {
        const sendForReviewObs =
          this.experimentEventService.experimentEventsExperimentIdSendForReviewPost$Json({
            experimentId: this.experiment.id
          });
        this.processResponse(
          sendForReviewObs,
          (experimentStartResponse: ExperimentSentForReviewResponse, experiment: Experiment) => {
            experiment.workflowState =
              experimentStartResponse.experimentSentForReviewEventNotification.state;
            this.createSuccessNotificationMessage(
              $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inReview}"`,
              $localize`:@@experimentSentForReviewMessageDetails:The experiment has been sent for review successfully`
            );
            this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InReview);
            this.setStateOfAddReferenceButtons(experiment?.workflowState);
            this.setStateOfAddTemplateButton(experiment?.workflowState);
            this.setStateOfAddInstrumentButton(experiment?.workflowState);
            this.setStateOfAddConsumableButton(experiment?.workflowState);
            this.setUpExperimentFlags();
            this.evaluateCurrentWorkflowTransitionActions();
            this.setupBarcodeScanner();
          }
        );
      }
    };
    const doTxn = applyTransition.bind(this);
    if (this.experiment) {
      this.dataRecordService.applyStepRenumberPreTransitionToInReview()
        .then((_) => {
          doTxn();
        });
    }
  };

  sendExperimentForCorrection = () => {
    if (this.experiment) {
      const sendForCorrectionObs =
        this.experimentEventService.experimentEventsExperimentIdSendForCorrectionPost$Json({
          experimentId: this.experiment.id
        });
      this.processResponse(
        sendForCorrectionObs,
        (experimentStartResponse: ExperimentSentForCorrectionResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentStartResponse.experimentSentForCorrectionEventNotification.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inCorrection}"`,
            $localize`:@@experimentSentForCorrectionMessageDetails:The experiment has been sent for correction successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InCorrection);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
          this.experimentNotificationService.updatehWarningsByDataRecordNotification(experimentStartResponse.experimentSentForCorrectionEventNotification);
        }
      );
    }
  };

  authorizeExperiment = () => {
    if (!this.checkExperimentCompletion()) {
      return;
    } else {
      if (this.showNotificationWhenPendingPreparationsAreFound()) return;
      this.experimentService.isExperimentCrossReferencesAuthorized().subscribe({
        next: (isExperimentCrossReferencesAuthorized) => {
          if (isExperimentCrossReferencesAuthorized) {
            this.processExperimentAuthorization();
          } else {
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              id: 'allCrossReferencesNotAuthorizedMsg',
              summary: $localize`:@@notAllCrossReferencesAuthorizedSummary:Not all cross references are authorized`,
              detail: $localize`:@@allCrossReferencesNotAuthorized:All cross references must be authorized before transitioning the experiment.`,
              sticky: false
            });
          }
        }
      });

    }
  };

  public processExperimentAuthorization() {
    if (!this.experiment) return;
    const authorizeObs =
      this.experimentEventService.experimentEventsExperimentIdAuthorizePost$Json({
        experimentId: this.experiment.id
      });
    this.processResponse(
      authorizeObs,
      (experimentStartResponse: ExperimentAuthorizedResponse, experiment: Experiment) => {
        experiment.workflowState =
          experimentStartResponse.experimentAuthorizedEventNotification.state;
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.authorized}"`,
          $localize`:@@experimentAuthorizedMessageDetails:The experiment has been authorized successfully`
        );
        this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.Authorized);
        this.setStateOfAddReferenceButtons(experiment?.workflowState);
        this.setStateOfAddTemplateButton(experiment?.workflowState);
        this.setStateOfAddInstrumentButton(experiment?.workflowState);
        this.setStateOfAddConsumableButton(experiment?.workflowState);
        this.setUpExperimentFlags();
        this.setCurrentWorkflowTransitionAction();
        this.setupBarcodeScanner();
      }
    );
  }

  private showNotificationWhenPendingPreparationsAreFound() {
    const hasPendingPreparations = this.experimentService.checkAnyExperimentPreparationsInPending();
    if (hasPendingPreparations) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'somePreparationsPendingMsg',
        summary: $localize`:@@somePreparationsPendingSummary:Cannot be Authorized`,
        detail: $localize`:@@somePreparationsPending:Some Preparations are Pending`,
        sticky: false
      });
      return hasPendingPreparations;
    }
    const hasPendingLabItemsPreparations = this.experimentService.checkAnyLabItemsPreparationsInPending();
    if (hasPendingLabItemsPreparations) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'someLabItemsPreparationsPendingMsg',
        summary: $localize`:@@somePreparationsPendingSummary:Cannot be Authorized`,
        detail: $localize`:@@SomeLabItemsPreparationsPending:Some Preparations lab items are not reviewed`,
        sticky: false
      });
      return hasPendingLabItemsPreparations;
    }
    return false;
  }
  private readonly confirmStartExperiment = () => {
    const confirmationMessage = $localize`:@@experimentStartConfirmation:Please confirm you wish to start the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () => this.startExperiment());
  };

  private readonly confirmCancelExperiment = () => {
    const confirmationMessage = $localize`:@@experimentCancelConfirmation:Please confirm you wish to CANCEL the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.cancelExperiment.bind(this))
    );
  };
  private readonly confirmRestoreExperiment = () => {
    const confirmationMessage = $localize`:@@experimentRestoreConfirmation:Please confirm you wish to Restore the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.restoreExperiment.bind(this))
    );
  };

  private readonly confirmSendForReview = () => {
    if (this.checkExperimentEmpty()) return;
    // Consider an extra call here before asking the use to confirm something that won't be allowed (or refactor), such as: if (!this.checkExperimentCompletion()) return;
    const confirmationMessage = $localize`:@@experimentSendForReviewConfirmation:Please confirm you wish to transition the experiment to "In Review".`;
    this.acceptCallback = () => {
      if (!this.checkExperimentCompletion()) {
        return;
      }
      this.reauthBeforeExperimentTransition(this.sendExperimentForReview.bind(this))
    }
    this.confirmWorkflowStateTransition(confirmationMessage, this.acceptCallback);
  };

  private readonly confirmSendForCorrection = () => {
    const confirmationMessage = $localize`:@@experimentSendForCorrectionConfirmation:Please confirm you wish to send the experiment for correction.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.sendExperimentForCorrection.bind(this))
    );
  };

  private readonly confirmAuthorization = () => {
    /**
     * Before moving experiment to authorized state if any preparations are pending then should not call fams authentication
     */
    if (this.showNotificationWhenPendingPreparationsAreFound()) {
      return
    }
    const confirmationMessage = $localize`:@@experimentAuthorizeConfirmation:Please confirm you wish to authorize the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () => {
      this.reauthBeforeExperimentTransition(this.authorizeExperiment.bind(this));
    }
    );
  };

  checkExperimentEmpty(): boolean {
    return this.experimentService.currentExperiment !== undefined
          && this.experimentService.currentExperiment.activities.length === 0
          && this.experimentService.currentExperiment.activityInputs?.length === 0
          && this.experimentService.currentExperiment.activityFiles.length === 0
          || (this.checkActivityInputsRemoved() && this.checkChromatographyResultSetEmpty())
  }

  checkActivityInputsRemoved():boolean{
    if(this.experimentService.currentExperiment?.activities.length === 0){
      const inputAliquots = this.experimentService.currentExperiment?.activityInputs?.map(s => s.aliquots) ?? [];
      const inputMaterials = this.experimentService.currentExperiment?.activityInputs?.map(s => s.materials) ?? [];
      const inputInstruments = this.experimentService.currentExperiment?.activityInputs?.map(s => s.instruments) ?? [];
      let inputInstrumentDeleted = true;
      let inputAliquotsDeleted = true;
      let inputMaterialsDeleted = true;

      inputInstruments.forEach(inputInstrument => {
        if(inputInstrument?.isRemoved === false){
          inputInstrumentDeleted = false;
        }
      });
      inputAliquots.forEach(inputAliquot => {
        inputAliquot?.forEach(aliquot => {
          if(aliquot.isRemoved === false)
              inputAliquotsDeleted = false;
        });
      });
      inputMaterials.forEach(materials =>{
        materials?.forEach(material=>{
          if(material.isRemoved === false)
              inputMaterialsDeleted = false;
        });
      });
      return inputAliquotsDeleted && inputInstrumentDeleted && inputMaterialsDeleted;
    }
    else {
      return false;
    }
  }

  checkChromatographyResultSetEmpty(): boolean{
    if(this.experimentService.currentExperiment?.activities.length === 0){
      const chromatographyResultSets = this.experimentService.currentExperiment?.activityOutputChromatographyResultSetsSummary;
    let areChromatographyResultSetsDeleted = true;
    chromatographyResultSets?.forEach(resultSet =>{
        if(resultSet.isDeleted === false)
          areChromatographyResultSetsDeleted = false
    })
    return areChromatographyResultSetsDeleted;
    }
    else{
      return false;
    }
  }

  setCurrentWorkflowTransitionAction(isCurrentUserCollaborator = false): void {
    this.currentWorkflowState =
      this.WorkflowStatusNames[this.experiment?.workflowState as ExperimentWorkflowState];
    this.currentWorkflowIcon =
      this.WorkflowStatusIcons[this.experiment?.workflowState as ExperimentWorkflowState];

    const workflowActions = [];
    if (this.isTransitionValidForStart()) {
      workflowActions.push({
        id: 'eln-btnStart',
        label: $localize`:@@StartExperimentButton:Start Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InProgress],
        command: () => this.confirmStartExperiment()
      });
    }
    if (this.isTransitionValidForCancel()) {
      const isCancelExperimentButtonEnabled = this.canPendingCancelFlag &&
        this.canPendingCancelFlag.raisedBy.value.toLocaleLowerCase() !==
        this.user.puid.toLocaleLowerCase() &&
        this.canCancelExperiment;

      workflowActions.push({
        id: 'eln-btnCancel',
        label: $localize`:@@CancelExperiment:Cancel Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Cancelled],
        disabled: !isCancelExperimentButtonEnabled,
        tooltipOptions: {
          tooltipLabel: $localize`:@@cancelExperimentTooltipMessage:Only a Supervisor who did not raise the Pending Cancel flag can Cancel an Experiment`,
          disabled: isCancelExperimentButtonEnabled
        },
        command: () => this.confirmCancelExperiment()
      });
    }
    if (
      this.experiment &&
      ((this.experiment.workflowState === ExperimentWorkflowState.InProgress &&
        this.canSendExperimentForReviewFromInProgressFlag) ||
        (this.experiment.workflowState === ExperimentWorkflowState.InCorrection &&
          this.canSendExperimentForReviewFromCorrectionFlag))
    ) {
      workflowActions.push({
        id: 'eln-btnSendForReview',
        label: $localize`:@@ExperimentSendForReview:Send to Review`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InReview],
        command: () => this.confirmSendForReview(),
        disabled: this.checkExperimentEmpty(),
        tooltipOptions: {
          tooltipLabel: $localize`:@@sendEmptyExperimentForReviewTooltipMessage:Empty experiments cannot be send for review`,
          disabled: !this.checkExperimentEmpty()
        },
      });
    }
    if (
      this.experiment &&
      ((this.experiment.workflowState === ExperimentWorkflowState.InReview &&
        this.canSendExperimentForCorrectionFromReviewFlag) ||
        (this.experiment.workflowState === ExperimentWorkflowState.Authorized &&
          this.canSendExperimentForCorrectionFromAuthorizedStateFlag))
    ) {
      workflowActions.push({
        id: 'eln-btnSendForCorrection',
        label: $localize`:@@ExperimentSendForCorrection:Send for Correction`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InCorrection],
        command: () => this.confirmSendForCorrection()
      });
    }
    if (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.InReview
    ) {
      workflowActions.push(this.getExperimentAuthorizeMenuOption(isCurrentUserCollaborator));
    }
    if (this.isTransitionValidForRestore()) {
      workflowActions.push({
        id: 'eln-btnRestore',
        label: $localize`:@@RestoreExperiment:Restore Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Restored],
        command: () => this.confirmRestoreExperiment()
      });
    }
    this.outputEmpowerService.setStateForEmpowerOperations(
      this.experiment?.workflowState as ExperimentWorkflowState,
      this.userService.hasAnalystRights(this.userService.currentUser.puid),
      this.userService.hasReviewerRights(this.userService.currentUser.puid),
      this.userService.hasSupervisorRights(this.userService.currentUser.puid)
    );
    this.workflowOptions = workflowActions;
  }

  onContextMenuClick(event: any) {
    const activityReferenceKey = event.target.innerText;
    const activity = this.experimentService.currentActivity;
    if (activityReferenceKey !== activity?.activityReferenceNumber && activityReferenceKey !== activity?.itemTitle) {
      const currentClickedContextActivity = this.experiment?.activities.find(act => act.itemTitle === activityReferenceKey || act.activityReferenceNumber === activityReferenceKey);
      this.currentContextActivityId = currentClickedContextActivity?.activityId;
    } else {
      this.currentContextActivityId = this.experimentService.currentActivityId;
    }
  }

  isTransitionValidForCancel() {
    return (
      this.experiment &&
      (this.experiment.workflowState === ExperimentWorkflowState.InProgress ||
        this.experiment.workflowState === ExperimentWorkflowState.Setup ||
        this.experiment.workflowState === ExperimentWorkflowState.InCorrection)
    );
  }

  isTransitionValidForStart() {
    return (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.Setup &&
      this.canStartExperimentFlag
    );
  }
  isTransitionValidForRestore() {
    return (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.Cancelled &&
      this.canRestoreExperiment
    );
  }
  closeExperiment() {
    if (window.opener?.location?.pathname?.startsWith('/bookshelf')) {
      window.close();
      window.opener.focus();
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  getErrorMessage(message: string): Message {
    return {
      severity: 'error',
      summary: $localize`:@@error:Error`,
      detail: message
    };
  }

  /** This notification for template added by self */
  private watchTemplateAppliedNotification() {
    this.experimentTemplateEventService.TemplateApplied.subscribe((response) => {
      // Loads the new node subtree indirectly by reloading the whole tree. This makes some sense to observing browser. (But see #)
      // Even the browser that applied the template won't have or see the cloned subtree with its generated nodeIds until then.
      this.reloadExpt(response.templateAppliedEventNotification.templateTitle);
      if ((response.templateAppliedEventNotification.activityReference?.templateReferenceId ===
        this.experiment?.reservedInstances[0]?.instanceId ||
        Object?.values(response.templateAppliedEventNotification.addedInstanceIds)?.some(
          (s) => s === this.experiment?.reservedInstances[0]?.instanceId)) &&
        this.experimentService.currentExperiment?.activityInputs &&
        this.experimentService.currentExperiment?.activityInputs.length > 0
      ) {
        this.messageService.add({
          key: 'notification',
          severity: 'warn',
          summary: '',
          detail: $localize`:@@experimentInputOutputMovedToActivity:Input/Output items moved to the activity`,
          sticky: false
        });
      }
    });
  }

  private watchRecipeAppliedNotification() {
    this.subscriptions.push(this.experimentTemplateEventService.RecipeApplied.subscribe(response => {
      // Loads the new node subtree indirectly by reloading the whole tree. This makes some sense to observing browser. (But see #)
      // Even the browser that applied the template won't have or see the cloned subtree with its generated nodeIds until then.
      this.reloadExpt(response.recipeAppliedEventNotification.recipeName, true);
    }));
  }
  loadInsertOptionsForExperiment() {
    this.insertOptions = ExperimentTemplateApplyService.loadInsertOptionsForTemplateLoader(this.experiment as Experiment);
  }

  private reloadExpt(templateTitle: string, isRecipe = false): void {
    this.loadingAppliedTemplate = true;
    this.loadExperiment(
      this.experimentService.currentExperiment?.experimentNumber as string,
      true,
      templateTitle,
      isRecipe
    );
  }

  private showTemplateAppliedSuccessMessage(templateTitle: string, isRecipe = false) {
    this.successMessages.push({
      severity: 'success',
      summary: $localize`:@@success:Success`,
      detail: !isRecipe ? $localize`:@@templateApplySuccess:Template: ${templateTitle} Loaded successfully` :
        $localize`:@@RecipeApplySuccess:Recipe: ${templateTitle} Loaded successfully`

    });
  }

  addTemplateEvent(): void {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.addTemplate();
    }
  }

  addTemplate(): void {
    this.successMessages = [];
    this.showBarcodeSlider = false;
    this.showCrossReferenceSlider = false;
    this.showTemplateLoader = true;
  }
  addLabItemsConsumable(): void {
    this.hideAddConsumableButton = true;
    this.experimentService.addLabItemsConsumable.next();
  }

  showCrossReferencesSliderEvent(): void {
    this.showCrossReferenceSlider = true;
  }

  addDocumentsTableEvent(): void {
    this.applyReferenceTemplate(ReferenceTemplateType.Documents);
  }

  addCompendiaTableEvent(): void {
    this.applyReferenceTemplate(ReferenceTemplateType.Compendia);
  }

  openBarcodeSlider(barcodeScannerOpenMode: ActivityInputType, instrumentType?: InstrumentType): void {
    if (this.scannerOpenMode === barcodeScannerOpenMode && this.showBarcodeSlider) return;
    this.instrumentType = instrumentType;
    this.showBarcodeSlider = false;
    setTimeout(() => {
      this.showBarcodeSlider = true;
    }, 100)

    this.scannerOpenMode = barcodeScannerOpenMode;
  }
  closeBarcodeSlider(isManualTrigger = true): void {
    this.showBarcodeSlider = false;
    if (isManualTrigger && this.instrumentType === InstrumentType.phMeter) {
      this.experimentService.barcodeScanned.next({
        scanStatus: ActivityInputItemState.Warning,
        scannerMode: this.scannerOpenMode as ActivityInputType,
        instrumentType: this.instrumentType as InstrumentType
      });
    }
  }

  specificationValueChanged(newValue: SpecificationValue): void {
    this.specificationEditorContext?.onChange.next(newValue);
  }

  closeSpecificationInputSlider(): void {
    this.specificationEditorContext?.onChange.complete();
    this.specificationEditorContext?.onClose?.next(undefined as never);
    this.specificationEditorContext?.onClose?.complete();
    this.specificationEditorContext = undefined; // destroys slider; ready to create a new one when needed.
  }

  crossReferenceSliderClosed(): void {
    this.showCrossReferenceSlider = false;
  }

  templateSelectionChanged(_templateLoaderResult: SelectedTemplate): void {
    this.templateApplyService.templateSelectionChanged(_templateLoaderResult);
  }

  templateLoaderFilterChanged(filterData: TemplateLoaderFilterChangedEvent) {
    const clients = filterData._filters.find((f) => f.columnName === 'client')?.values || [];
    const projects = this.projectLogLoaderService.fetchProjectsLinkedToClients(clients);
    ExperimentTemplateApplyService.templateLoaderAlterFilter(
      filterData.selectedInsertOption,
      this.searchControls,
      filterData.fieldName,
      projects,
      this.clientOptions
    );
    const recipeTypes = filterData._filters.find((c) => c.columnName === 'recipeTypes');

    if (recipeTypes) {
      const recipeTypesValue =
        (this.searchControls.find((c) => c.fieldName === 'recipeTypes')?.value as string[]) || [];
      recipeTypes.values = recipeTypesValue;
    }
  }

  templateLoaderFilterCleared(insertLocationOptions: string) {
    this.searchControls = ExperimentTemplateApplyService.constructFilters(insertLocationOptions, this.subBusinessUnits, this.clientOptions)
  }

  templateSelected(selectedTemplateInformation: SelectedTemplateCommand) {
    const activityId = this.experiment?.activities.length === 0 ? this.experiment?.reservedInstances[0].instanceId : selectedTemplateInformation.rootActivityId;
    selectedTemplateInformation.rootActivityId = activityId;
    this.templateApplyService.applySelectedTemplate(selectedTemplateInformation, true);
  }

  templateLoaderInsertOptionChanged(insertOption: string) {
    this.searchControls = ExperimentTemplateApplyService.constructFilters(insertOption, this.subBusinessUnits, this.clientOptions);
    const sourceTemplateType = ExperimentTemplateApplyService.MapperToSourceTemplateTypeBasedOnInsertOption[insertOption]
    this.insertLocationOptions = this.templateApplyService.prepareInsertLocationOptionsBySourceTemplateType(sourceTemplateType);
    this.loaderSearchCriteria.templateSearchCriteria = this.templateApplyService.createTemplateOptionsQuery(insertOption);
    this.loaderSearchCriteria.recipeSearchCriteria = this.templateApplyService.createRecipeOptionsQuery(insertOption);
  }

  createTemplateOptionsQuery() {
    this.loaderSearchCriteria.templateSearchCriteria = {
      getLatestVersion: true,
      templateTypes: `${TemplateType.Activity},${TemplateType.Module},${TemplateType.Form},${TemplateType.Table}`,
      consumingLabsiteCodes: this.user.labSiteCode
    };
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeCriteriaSearch;
  }

  updateTemplateSelection(_templateLoaderResult: TemplateLoaderResult<SelectedTemplate>): void {
    this.showTemplateLoader = false;
  }

  getBarcodeScannerIcon(): ToolbarIcon[] {
    return [
      {
        icons: [
          {
            label: $localize`:@@BarcodeScanner:Barcode scanner`,
            text: $localize`:@@Barcode:Barcode`,
            icon: 'pi pi-qrcode',
            command: () => this.openBarcodeSlider(ActivityInputType.Aliquot)
          }
        ],
        position: 'left'
      }
    ];
  }

  private loadExperiment(
    experimentNumber: string,
    notifyActiveTemplate = false,
    appliedTemplateName = '',
    isRecipe = false
  ) {
    this.experimentService
      .loadExperiment(experimentNumber)
      .pipe(take(1))
      .subscribe((experiment) => {
        this.experiment = experiment;
        this.loadMenu();
        this.loading = false;
        this.isInvalid = !experiment;
        if (notifyActiveTemplate) {
          this.experimentTemplateEventService.ExperimentHasRefreshed(this.experiment as Experiment);
          this.showTemplateAppliedSuccessMessage(appliedTemplateName, isRecipe);
        }
        if (this.isInvalid) {
          this.errorMessages = [
            this.getErrorMessage(
              $localize`:@@noExperimentFound:No Experiment found for ${this.experimentNumber}`
            )
          ];
        }
        this.experimentFlags = this.experimentService.currentExperimentResponse?.experimentFlag.raisedFlags ?? [];
        this.setUpExperimentFlags();
        this.evaluateCurrentWorkflowTransitionActions();
        if (this.experiment) {
          this.loadInsertOptionsForExperiment();
          this.setStateOfAddReferenceButtons(this.experiment.workflowState);
          this.setStateOfAddTemplateButton(this.experiment.workflowState);
          this.setStateOfAddInstrumentButton(this.experiment.workflowState);
          this.setStateOfAddConsumableButton(this.experiment.workflowState);
          this.initializeActivityCompletionInfo(this.experiment.activities);
          this.initializeLabItemsCompletionInfo(this.experiment.activityLabItems);
          this.setupBarcodeScanner();
          this.showConnectionIcon = this.experiment?.activities.length !== 0;
          if (this.experiment.activityInputs) {
            this.barcodeScannerHelper.refreshInputs.next(true);
          }
        }
        this.experimentUserPreferenceService.loadExperimentUserPreferences();
        this.experimentNotificationService.refreshWarnings();
        this.populateExperimentHeaders();
      });
  }

  initializeActivityCompletionInfo(activityArray: Activity[]) {
    if (!this.experiment) return;

    for (const activity of activityArray) {
      // Note: modules have no data of their own today, so considering only their children
      // lab items is processed separately see updateLabItemsCompletionInfo
      this.experiment.experimentCompletionStatus.push({
        title: [activity.itemTitle, ActivityReferencesPseudoModuleTitle, $localize`:@@CrossReferences:Cross References`].join(),
        id: `${activity.activityId}/crossReferences`,
        isComplete: ExperimentService.isCrossReferencesComplete(activity.activityReferences.crossReferences),
      });
      for (const module of activity.dataModules) {
        // Note: modules have no data of their own today, so considering only their children
        for (const item of module.items) {
          if (item.itemType === NodeType.Table) {
            this.experiment.experimentCompletionStatus.push({
              title: item.itemTitle,
              id: (item as Table).tableId,
              isComplete: this.experimentService.isTableComplete(item as Table)
            });
          } else if (item.itemType === NodeType.Form) {
            this.experiment.experimentCompletionStatus.push({
              title: item.itemTitle,
              id: (item as Form).formId,
              isComplete: this.experimentService.isFormComplete(item as FormWithFieldDefinitionsResponse)
            });
          }
        }
      }
    }
  }

  initializeLabItemsCompletionInfo(labItemsNodes: ActivityLabItemsNode[]) {
    if (!this.experiment) return;

    for (const labItem of labItemsNodes) {
      this.experiment.experimentCompletionStatus.push({
        title: this.titleOfConsumableAndSupplyTable,
        id: this.consumableId,
        isComplete: this.experimentService.isConsumableComplete(labItem as any)
      });
    }
  }

  private setStateOfAddTemplateButton(data: ExperimentWorkflowState) {
    this.disableAddTemplateButton =
      this.userService.hasOnlyReviewerRights() ||
      ExperimentService.isExperimentAuthorizedOrCancelled(data) ||
      data === ExperimentWorkflowState.InReview;
    this.disableAddTemplateButton = this.disableAddTemplateButton || !this.canAddTemplate;
  }

  private setStateOfAddInstrumentButton(data: ExperimentWorkflowState) {
    this.disableAddInstrumentEvent = this.userService.hasOnlyReviewerRights() ||
      ExperimentService.isExperimentAuthorizedOrCancelled(data) || this.doesInstrumentEventExistsForActivityInput() ||
      ExperimentService.isExperimentInReview(data);
    //This has created regression issues for labitems and hence commented. Ideally, ActivityId should never be set to empty programmatically
    //this.experimentService.currentActivityId = '';
  }

  private setStateOfAddConsumableButton(data: ExperimentWorkflowState) {
    this.disableAddConsumable = this.userService.hasOnlyReviewerRights() ||
      (data !== ExperimentWorkflowState.Setup &&
        data !== ExperimentWorkflowState.InProgress &&
        data !== ExperimentWorkflowState.InCorrection);
  }

  private setStateOfAddReferenceButtons(state: ExperimentWorkflowState) {
    this.disableAddReferences =
      this.userService.hasOnlyReviewerRights()
      || state === ExperimentWorkflowState.InReview
      || state === ExperimentWorkflowState.Authorized
      || state === ExperimentWorkflowState.Cancelled;
  }

  private loadMenu() {
    if (this.experiment) {
      const menuNodes: MainMenuItem[] = [];
      // Cover is always available, and is shown first
      const coverItem: MainMenuItem = {
        label: $localize`:@@coverPageTitle:Cover`,
        routerLink: 'cover',
        id: 'eln-menu-cover',
        contextMenuItems: this.buildCoverPageContextMenuItems(),
        tooltipOptions: {
          tooltipLabel: $localize`:@@coverPageTitle:Cover`
        },
        icon: 'pi pi-book'
      };
      menuNodes.push(coverItem);
      const activityItems = this.experiment.activities.map((activity) => {
        // For older experiments when the activityReferenceNumber is "N/A"
        // we use Activity's ItemTitle for routing and data fetching in children components
        const activityReferenceKey = activity.activityReferenceNumber === NA ? activity.itemTitle : activity.activityReferenceNumber;
        const activityMenuItem: MainMenuItem = {
          label: activity.itemTitle,
          icon: 'far fa-file-alt',
          id: `eln-menu-activity-${activity.itemTitle}`,
          tooltipOptions: {
            tooltipLabel: activityReferenceKey
          },
          items: this.buildActivitySubMenu(activity),
          command: () => {
            this.navigateRouteToModule(activityReferenceKey, 'Modules');
          }
        } as MainMenuItem;
        activityMenuItem.contextMenuItems = this.buildActivityContextMenuItems(activity, activityMenuItem);
        return activityMenuItem;
      });
      menuNodes.push(...activityItems);
      const inputsMenu = this.checkForInputsWithoutTemplate();
      if (inputsMenu) menuNodes.push(inputsMenu);
      const outputsMenu = this.checkForOutputsWithoutTemplate();
      if (outputsMenu) {
        this.isOutputsEnabled = this.featureService.isEnabled(ELNFeatureFlags.ActivityOutputsEnabled);
        menuNodes.push(outputsMenu);
      }
      const dataPackage: MainMenuItem = {
        label: $localize`:@@DataPackage:Data Package`,
        id: 'eln-menu-dataPackage',
        command: () => {
          this.loadDataPackageSlider();
        },
        tooltipOptions: {
          tooltipLabel: $localize`:@@DataPackage:Data Package`
        },
        icon: 'icon-outline-briefcase',
        contextMenuItems: this.buildDataPackageContextMenuItems()
      };
      menuNodes.push(dataPackage);
      // We'll include Views last, even though we may not needed it
      // Add viewsItem to menuItems below to include views once more.
      // Removed because currently not implemented as per Bug 2890929: Remove Views button appearing on left nav
      const viewsItem: MainMenuItem = {
        label: $localize`:@@viewsPageTitle:Views`,
        routerLink: 'views',
        icon: 'far fa-eye',
        id: 'eln-menu-views',
        tooltipOptions: {
          tooltipLabel: $localize`:@@viewsPageTitle:Views`
        }
      };
      this.menuItems = menuNodes;
    } else {
      this.menuItems = [];
    }
  }

  private navigateRouteToModule(activityReferenceKey: string, routingName: string) {
    this.router.navigateByUrl(`/experiment/${this.experimentNumber}/${elnEncodeSpecialChars(activityReferenceKey)}/${routingName}`,
      {
        state: {
          activityReferenceKey: activityReferenceKey
        }
      });
  }

  checkForInputsWithoutTemplate(): MainMenuItem | void {
    if (this.experiment && this.experiment.activities.length === 0 &&
      this.experiment.reservedInstances?.length > 0) {
      const inputsMenu: MainMenuItem = {
        id: `eln-menu-activityInput-${this.experiment?.reservedInstances[0]?.instanceId}`,
        label: $localize`:@@activityInputPageTitle:Inputs`,
        routerLink: `${this.experiment.experimentNumber}/Inputs`,
        styleClass: this.activityMenuStyleClass,
        tooltipOptions: {
          tooltipLabel: $localize`:@@activityInputPageTitle:Inputs`
        },
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-activity-${replace('No Title', / /g, '')}`,
            command: () => {
              this.assignCurrentContextActivityId(this.experiment?.reservedInstances[0]?.instanceId ?? '');
              this.experimentService.inputImpactAssessmentAuditHistoryFlag = true;
              this.loadActivityInputAuditHistory(this.experiment?.reservedInstances[0]?.instanceId ?? '');
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsInputs',
            command: () => {
              this.loadInternalCommentsForInputLevel();
            }
          }
        ],
      };
      return inputsMenu;
    }
  }

  checkForOutputsWithoutTemplate(): MainMenuItem | void {
    if (this.experiment && this.experiment.activities.length === 0 &&
      this.experiment.reservedInstances?.length > 0) {
      const outputsMenu: MainMenuItem = {
        id: `eln-menu-activityOutput-${this.experiment?.reservedInstances[0]?.instanceId}`,
        label: $localize`:@@outputs:Outputs`,
        routerLink: `${this.experiment.experimentNumber}/Outputs`,
        styleClass: this.activityMenuStyleClass,
        tooltipOptions: {
          tooltipLabel: $localize`:@@outputs:Outputs`
        },
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-outputs-${replace(this.experiment?.reservedInstances[0]?.instanceId, / /g, '')}`,
            command: () => {
              this.assignCurrentContextActivityId(this.experiment?.reservedInstances[0]?.instanceId ?? '');
              this.experimentService.outputImpactAssessmentAuditHistoryFlag = true;
              this.loadActivityOutputAuditHistory(this.experiment?.reservedInstances[0]?.instanceId ?? '');
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsOutputs',
            command: () => {
              this.loadInternalCommentsForOutputLevel();
            }
          }
        ]
      };
      return outputsMenu;
    }
  }

  loadDataPackageSlider() {
    this.dataPackageService.openSliderPanel(this.experiment?.activities);
  }

  private buildActivityContextMenuItems(activity: Activity, activityMenuItem: MainMenuItem) {
    // For older experiments when the activityReferenceNumber is "N/A"
    // we use Activity's ItemTitle for routing and data fetching in children components
    const activityReferenceKey = activity.activityReferenceNumber === NA ? activity.itemTitle : activity.activityReferenceNumber;
    return [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: this.activityIconClass,
        id: `eln-context-ic-activity-${activityReferenceKey}`,
        command: () => {
          this.loadInternalCommentsForActivityLevel();
        }
      },
      {
        label: $localize`:@@history:History`,
        icon: this.historyIcon,
        id: `eln-context-activity-${activityReferenceKey}`,
        command: () => {
          this.loadActivityAuditHistory(activity?.activityId ?? '', activity?.itemTitle);
        }
      },
      this.experimentNodeReTitleService.bindRetitleActivityMenuItemContext(activity, activityMenuItem)
    ];
  }

  private buildCoverPageContextMenuItems(): MenuItem[] | undefined {
    return [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: this.activityIconClass,
        id: 'eln-contextInternalCommentsCover',
        command: () => {
          this.loadInternalCommentsForExperimentLevel();
        }
      },
      {
        label: $localize`:@@history:History`,
        icon: this.historyIcon,
        id: 'eln-contextAuditHistoryCover',
        command: () => {
          this.loadCoverAuditHistory($localize`:@@coverPageTitle:Cover`);
        }
      }
    ];
  }

  private buildDataPackageContextMenuItems(): MenuItem[] | undefined {
    return [
      {
        label: $localize`:@@history:History`,
        icon: this.historyIcon,
        id: 'eln-contextAuditHistoryCover',
        command: () => {
          this.loadDataPackageAuditHistory($localize`:@@DataPackage:Data Package`);
        }
      }
    ];
  }

  historyIconLabel: { [key: string]: string } = {
    label: $localize`:@@history:History`, "icon": 'fas fa-history',
  }

  activityMenuItemStyleClass: { [key: string]: string } = {
    styleClass: this.activityMenuStyleClass
  }

  buildActivitySubMenu(act: Activity) {
    const items = [];
    // For older experiments when the activityReferenceNumber is "N/A"
    // we use Activity's ItemTitle for routing and data fetching in children components
    const activityReferenceKey = act.activityReferenceNumber === NA ? act.itemTitle : act.activityReferenceNumber;

    if (this.featureService.isEnabled(ELNFeatureFlags.CanShowReferences)) {
      items.push({
        id: `eln-menu-references-${act.activityId}`,
        label: $localize`:@@references:References`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-activity-${activityReferenceKey}`,
            command: () => {
              this.loadReferencesAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsCover',
            command: (() => {
              this.loadInternalCommentsForReferencesLevel();
            })
          }
        ],
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'References');
        }
      });
    }

    items.push(...[
      {
        id: `eln-menu-activityInput-${act.activityId}`,
        label: $localize`:@@activityInputPageTitle:Inputs`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-activity-${activityReferenceKey}`,
            command: () => {
              this.assignCurrentContextActivityId(act.activityId);
              this.experimentService.inputImpactAssessmentAuditHistoryFlag = true;
              this.loadActivityInputAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsInputs',
            command: () => {
              this.loadInternalCommentsForInputLevel();
            }
          }
        ],
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'Inputs');
        }
      },
      {
        id: `eln-menu-activity-labitems-${act.activityId}`,
        label: $localize`:@@activityLabItemsPageTitle:Lab Items`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-labItems-${activityReferenceKey}`,
            command: () => {
              this.assignCurrentContextActivityId(act.activityId);
              this.loadLabItemsAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: `eln-context-internal-comments-${activityReferenceKey}`,
            state: {
              targetId: act.activityId
            },
            command: (event: { item: MainMenuItem }) => {
              this.loadInternalCommentsForLabItemLevel(event.item.state?.targetId);
            }
          }
        ],
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'LabItems');
        }
      },
      {
        //TODO Below will be updated once this PBI is done: 3221407: Invalid HTML to select an ID
        id: `eln-menu-activityPreparation-${act.activityId}`,
        label: $localize`:@@preparations:Preparations`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        escape: false,
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-preparations-${activityReferenceKey}`,
            command: () => {
              this.assignCurrentContextActivityId(act.activityId);
              this.loadPreparationAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: `eln-context-internal-comments-${activityReferenceKey}`,
            state: {
              targetId: act.activityId
            },
            command: () => {
              this.loadInternalCommentsForPreparationLevel();
            }
          }
        ],
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'Preparations');
        }
      },
      {
        id: 'eln-menu-activityModule' + act.itemTitle,
        label: act.isActivityComplete ?
          $localize`:@@ActivityModulePageTitle:Modules` :
          $localize`:@@ActivityModulePageTitle:Modules` + `<span class="icon-exclamation-mark icon-s icon-navbar"></span>`,
        tooltipOptions: !act.isActivityComplete ? {
          tooltipLabel: $localize`:@@ActivityIncomplete:There is a module with incomplete items`,
          tooltipPosition: 'top',
          positionTop: 10,
          positionLeft: 95
        } : undefined,
        styleClass: this.activityMenuStyleClass,
        escape: false,
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'Modules');
        }
      }
    ]);
    this.isOutputsEnabled = this.featureService.isEnabled(ELNFeatureFlags.ActivityOutputsEnabled);
    if (this.isOutputsEnabled) {
      const outputItem = {
        id: `eln-menu-activityOutput-${elnEncodeSpecialChars(activityReferenceKey)}`,
        label: $localize`:@@outputs:Outputs`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-outputs-${activityReferenceKey}`,
            command: () => {
              this.assignCurrentContextActivityId(act.activityId);
              this.experimentService.outputImpactAssessmentAuditHistoryFlag = true;
              this.loadActivityOutputAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsOutputs',
            command: () => {
              this.loadInternalCommentsForOutputLevel();
            }
          }
        ],
        command: () => {
          this.navigateRouteToModule(activityReferenceKey, 'Outputs');
        }
      };
      items.push(outputItem);
    }

    return items;
  }

  assignCurrentContextActivityId(activityId: string) {
    this.experimentService.currentContextMenuActivityId = activityId;
  }

  /** "loadInternalCommentsForActivityInputs" */
  loadInternalCommentsForInputLevel() {
    this.openInternalComments(
      this.experimentService.currentActivityId,
      [this.experimentService.currentActivityId, CommentContextType.ActivityInput],
      CommentContextType.Activity
    );
  }

  loadInternalCommentsForOutputLevel() {
    this.openInternalComments(
      this.experimentService.currentActivityId,
      [this.experimentService.currentActivityId, CommentContextType.OutputsInstrumentEvent],
      CommentContextType.Activity
    );
  }

  loadInternalCommentsForActivityLevel() {
    const activity = this.experiment?.activities.find(
      (act) => act.activityId === this.currentContextActivityId
    );
    if (activity) {
      this.openInternalComments(
        this.experiment!.id,
        [activity?.activityId, CommentContextType.Activity],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForExperimentLevel() {
    if (this.experiment) {
      this.openInternalComments(
        this.experiment.id,
        [this.experiment.id, this.experiment.experimentNumber, 'Experiment'],
        CommentContextType.Experiment
      );
    }
  }

  loadInternalCommentsForReferencesLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, ActivityReferencesPseudoModuleId],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForLabItemLevel(targetId: string) {
    const activity = this.experiment?.activities.find(
      (act) => act.activityId === targetId
    );
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.LabItems],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForPreparationLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.Preparations],
        CommentContextType.Activity
      );
    }
  }

  openInternalComments(nodeId: string, path: string[], contextType: CommentContextType) {
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  loadCoverAuditHistory(menuLabel: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;

    this.auditHistoryService.loadExperimentAuditHistory(experimentId).subscribe((data) => {
      this.subscriptions.push(this.experimentService.areRecipeBlobDetailsFetched()
        .pipe(take(1))
        .subscribe({
          next: ((response) => {
            if (response) this.bindDataToAuditHistoryDialog(menuLabel, data);
          })
        }));
      this.experimentService.addRecipeAppliedEventBlobDetailsToCache(data);
    });
  }

  private bindDataToAuditHistoryDialog(menuLabel: string, data: AuditHistoryDataRecordResponse) {
    this.auditLoading = false;
    this.experimentService.loadAuditHistoryForActivityIds = this.experimentService.currentExperiment?.activities.flatMap(s => s.activityId) ?? [];
    this.loadAuditDialog(data.dataRecords.filter(item => item.eventContext.eventType !== 'experimentNodeOrderChanged'), menuLabel);
  }

  loadDataPackageAuditHistory(menuLabel: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    const activityIds = this.experimentService.currentExperiment?.activities.map(s => s.activityId) ?? [];
    this.auditHistoryService.loadDataPackageAuditHistory(experimentId, activityIds).subscribe((data) => {
      this.auditLoading = false;
      this.experimentService.loadAuditHistoryForActivityIds = this.experimentService.currentExperiment?.activities.flatMap(s => s.activityId) ?? [];
      const dataPackPageAuditRecords = data.dataRecords.filter(dataRecord =>
        dataRecord.eventContext.eventType === ExperimentEventType.DataPackageGenerated)
      this.loadAuditDialog(dataPackPageAuditRecords, menuLabel);
    });
  }

  loadActivityAuditHistory(activityId: string, activityTitle: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const response = this.experimentService.currentExperimentResponse;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    const currentActivity = response?.activities.find((a) => a.activityId === activityId);

    const moduleIds = currentActivity?.childOrder;
    const childOrders = response?.modules
      .filter((m) => moduleIds?.includes(m.moduleId))
      .map((m) => m.childOrder)
      .flat();
    const formIds = response?.forms
      .filter((f) => childOrders?.includes(f.formId))
      .map((f) => f.formId);
    const tableIds = response?.tables
      .filter((t) => childOrders?.includes(t.tableId))
      .map((t) => t.tableId);

    if (currentActivity?.activityReferences.documentReferencesTableId) {
      tableIds?.push(currentActivity?.activityReferences.documentReferencesTableId);
    }
    if (currentActivity?.activityReferences.compendiaReferencesTableId) {
      tableIds?.push(currentActivity?.activityReferences.compendiaReferencesTableId);
    }

    this.subscriptions.push(this.auditHistoryService
      .loadSelectedActivityOrModuleAuditHistory(experimentId, tableIds?.concat(moduleIds || []).concat(activityId).concat(formIds || []))
      .subscribe({
        next: (data) => {
          this.subscriptions.push(
            this.experimentService.areRecipeBlobDetailsFetched().pipe(take(1))
              .subscribe({
                next: (response: boolean) => {
                  if (response) {
                    const records = this.filterActivityDataRecords(data.dataRecords, activityId);
                    records.forEach((record: ExperimentDataRecordNotification) => {
                      if (record.eventContext.eventType === ExperimentEventType.ExperimentRecipeApplied) {
                        record.recordTypes['prompts'] = [ExperimentRecordType.New]
                      }
                    });
                    this.auditLoading = false;
                    this.experimentService.loadAuditHistoryForActivityIds = [activityId];
                    this.loadAuditDialog(records, activityTitle);
                  }
                }
              })
          );
          this.experimentService.addRecipeAppliedEventBlobDetailsToCache(data)
        }
      }));
  }

  private filterActivityDataRecords(dataRecords: ExperimentDataRecordNotification[], activityId: string) {
    return dataRecords.filter(item => item.eventContext.eventType !== ExperimentEventType.ExperimentNodeOrderChanged)
      .filter(dr => !(dr.eventContext.eventType === ExperimentEventType.ExperimentRecipeApplied &&
        objectCache[dr.eventContext.blobReference?.blobName ?? ''].promptDetails !== undefined &&
        objectCache[dr.eventContext.blobReference?.blobName ?? ''].promptDetails.prompts
          .find((prompt: ActivityPromptItem) => prompt.activityId === activityId) === undefined
      ));
  }

  loadReferencesAuditHistory(activityId: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = $localize`:@@references:References`;

    const experimentId = this.experiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    if (!activity || !experimentId) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => (
        r.eventContext.eventType === ExperimentEventType.RowsAdded
        || r.eventContext.eventType === ExperimentEventType.CellChanged
        || r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceAdded
        || r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceChanged
        || r.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated
        || r.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
      ))
      .filter(dr => (!('cfnNumber' in dr)) ||
        ((this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.CrossReference) &&
          dr.eventContext.eventType === ExperimentEventType.StatementApplied)
      );
    const filterActivityReferenceTemplateAppliedRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => (
        r.eventContext.eventType === ExperimentEventType.RowsAdded
        || r.eventContext.eventType === ExperimentEventType.CellChanged
        || r.eventContext.eventType === ExperimentEventType.ActivityReferenceTemplateApplied
      ));

    this.auditLoading = true;
    forkJoin({
      crossReferences: this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityId),

      documents: activity.activityReferences.documentReferencesTableId
        ? this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityReferences.documentReferencesTableId)
        : of(undefined),

      compendia: activity.activityReferences.compendiaReferencesTableId
        ? this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityReferences.compendiaReferencesTableId)
        : of(undefined)

    }).subscribe((data: {
      crossReferences: AuditHistoryDataRecordResponse,
      documents: AuditHistoryDataRecordResponse | undefined,
      compendia: AuditHistoryDataRecordResponse | undefined
    }) => {
      const dataRecords: ExperimentDataRecordNotification[] = [];
      if (data.crossReferences.dataRecords) {
        dataRecords.push(...filterDataRecords(data.crossReferences.dataRecords));
      }
      if (data.documents?.dataRecords) {
        dataRecords.push(...filterActivityReferenceTemplateAppliedRecords(data.documents.dataRecords));
      }
      if (data.compendia?.dataRecords) {
        dataRecords.push(...filterActivityReferenceTemplateAppliedRecords(data.compendia.dataRecords));
      }
      this.auditLoading = false;
      this.loadAuditDialog(dataRecords, title);
    });
  }

  loadPreparationAuditHistory(activityId: string) {
    const title = $localize`:@@preparations:Preparations`;
    const eventsToFilter = this.preparationEventsForAuditHistory;
    this.loadAuditHistoryForPreparation(activityId, title, eventsToFilter)
  }

  private loadAuditHistory(activityId: string, header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    if (!experimentId || !activity) return;

    this.auditLoading = true;
    this.auditHistoryService.loadLabItemsAuditHistory(experimentId, activity.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        this.subscriptions.push(
          this.experimentService.areRecipeBlobDetailsFetched().pipe(take(1)).subscribe({
            next: (response: boolean) => {
              if (response) {
                const dataRecords = this.filterLabItemsDataRecords(data.dataRecords ?? [], eventsToFilter, activity.activityId);
                dataRecords.forEach((record: ExperimentDataRecordNotification) => {
                  if (record.eventContext.eventType === ExperimentEventType.ExperimentRecipeApplied) {
                    record.recordTypes['prompts'] = [ExperimentRecordType.New]
                  }
                });
                this.auditLoading = false;
                this.loadAuditDialog(dataRecords, title);
              }
            }
          })
        )
        this.experimentService.addRecipeAppliedEventBlobDetailsToCache(data)
      });
  }

  private filterLabItemsDataRecords(dataRecords: ExperimentDataRecordNotification[], eventsToFilter: ExperimentEventType[], activityId: string) {
    return dataRecords
      .filter((r: ExperimentDataRecordNotification) => (
        eventsToFilter.includes(r.eventContext.eventType)
      )).filter(dr => !(dr.eventContext.eventType === ExperimentEventType.ExperimentRecipeApplied &&
        objectCache[dr.eventContext.blobReference?.blobName ?? ''].promptDetails !== undefined &&
        objectCache[dr.eventContext.blobReference?.blobName ?? ''].promptDetails.prompts
          .find((prompt: ActivityPromptItem) => prompt.activityId === activityId) === undefined
      )).filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.LabItems ||
          this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.LabItemsPreparation) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
            dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged))
      ).filter(dr => (!('cfnNumber' in dr)) ||
        ((this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.LabItems ||
          this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.LabItemsPreparation) &&
          dr.eventContext.eventType === ExperimentEventType.StatementApplied)
      );
  }

  private loadAuditHistoryForPreparation(activityId: string, header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    if (!experimentId || !activity) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => (eventsToFilter.includes(r.eventContext.eventType)))
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.Preparations) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated || dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged)))
      .filter(dr => (!('cfnNumber' in dr)) ||
        ((this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.Preparations) &&
          dr.eventContext.eventType === ExperimentEventType.StatementApplied)
      );

    this.auditLoading = true;
    this.subscriptions.push(this.auditHistoryService.loadPreparationsAuditHistory(experimentId, activity.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        const dataRecords = filterDataRecords(data.dataRecords ?? []);
        this.auditLoading = false;
        this.loadAuditDialog(dataRecords, title);
      }));
  }

  public lookupNote(number: number): ClientFacingNoteModel | undefined {
    return this.experimentService.currentExperiment?.clientFacingNotes.find(n => n.number === number);
  }

  loadLabItemsAuditHistory(activityId: string) {
    const title = $localize`:@@labItems:LabItems`;
    const eventsToFilter = this.includeOnlyLabItemsEventForAuditHistory;
    this.loadAuditHistory(activityId, title, eventsToFilter);
  }

  loadActivityInputAuditHistory(activityId: string) {
    const title = $localize`:@@activityInputPageTitle:Inputs`;
    const eventsToFilter = this.includeOnlyInputsEventForAuditHistory;
    this.loadAuditHistoryForInputs(activityId, title, eventsToFilter);
  }

  private loadAuditHistoryForInputs(activityId: string, header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    if (!experimentId) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => (
        eventsToFilter.includes(r.eventContext.eventType)
      ))
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.ActivityInput) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
            dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged))
      )
      .filter(dr => (!('cfnNumber' in dr)) ||
        ((this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.ActivityInput) &&
          dr.eventContext.eventType === ExperimentEventType.StatementApplied)
      );

    this.auditLoading = true;
    this.auditHistoryService.loadActivityInputsAuditHistory(experimentId, activity ? activity.activityId : this.experimentService.currentActivityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        const dataRecords: ExperimentDataRecordNotification[] = [];
        if (data.dataRecords) {
          dataRecords.push(...filterDataRecords(data.dataRecords));
        }
        this.auditLoading = false;
        this.loadAuditDialog(dataRecords, title);
      });
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditDialog(dataRecords: ExperimentDataRecordNotification[], menuLabel: string) {
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(dataRecords, menuLabel, NodeType.Activity);
  }

  loadActivityOutputAuditHistory(activityId: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    const currentActivityId = activity ? activity.activityId : this.experimentService.currentActivityId;

    this.auditHistoryService
      .loadActivityOrModuleAuditHistory(experimentId, [(currentActivityId)], [])
      .subscribe({
        next: data => {
          this.loadActivityCompleteOutputAuditHistory(experimentId, currentActivityId, [
            ...data.r1.dataRecords
              .filter(item => ((item.eventContext.eventType === 'clientFacingNoteCreated'
                || item.eventContext.eventType === 'clientFacingNoteChanged')
                && (item as ClientFacingNoteCreatedEventNotification).nodeId === currentActivityId
                && (item as ClientFacingNoteCreatedEventNotification).contextType !== 'labItems')
                || item.eventContext.eventType === ExperimentEventType.ActivityFilesAdded
                || item.eventContext.eventType === ExperimentEventType.ActivityFilesDeleted
                || item.eventContext.eventType === ExperimentEventType.StatementApplied)
              .filter(dr => (!('cfnNumber' in dr)) ||
                ((this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.LabItems &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.ActivityInput &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.Preparations &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.CrossReference) &&
                  dr.eventContext.eventType === ExperimentEventType.StatementApplied)
              ),
            ...data.r2.dataRecords.filter((item) => item.eventContext.eventType === 'clientFacingNoteCreated'
              || item.eventContext.eventType === 'clientFacingNoteChanged'
              && (item as ClientFacingNoteCreatedEventNotification).nodeId === currentActivityId
              && (item as ClientFacingNoteCreatedEventNotification).contextType !== 'labItems'
              || item.eventContext.eventType === ExperimentEventType.StatementApplied)
              .filter(dr => (!('cfnNumber' in dr)) ||
                ((this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.LabItems &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.ActivityInput &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.Preparations &&
                  this.lookupNote(dr.cfnNumber as number)?.contextType !== ClientFacingNoteContextType.CrossReference) &&
                  dr.eventContext.eventType === ExperimentEventType.StatementApplied)
              )
          ])
        }
      });
  }

  private loadActivityCompleteOutputAuditHistory(experimentId: string, activityId: string, clientFacingNoteDataRecords: ExperimentDataRecordNotification[]) {
    const activityOutputTitle = $localize`:@@outputs:Outputs`;
    this.auditHistoryService
      .loadActivityOutputsAuditHistory(experimentId, activityId)
      .subscribe({
        next: (data) => {
          this.auditLoading = false;
          const history = [...data.dataRecords, ...clientFacingNoteDataRecords]
          this.loadAuditDialog(history, activityOutputTitle);
        }
      });
  }

  private processResponse(
    obs: Observable<any>,
    acknowledgment: (response: any, experiment: Experiment) => void
  ) {
    obs
      .pipe(
        first(),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: (data: any) => {
          acknowledgment(data, this.experiment!);
        },
        error: () => {
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received following error from server`;
        }
      });
  }

  private canEnableBarcodeScannerIcon(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT_ACTIVITIES);
    const visibility = this.clientStateService.getClientStateVisibility(ClientState.EXPERIMENT_ACTIVITIES);
    const labItemsAccessFeatures = LabItemsFeatureManager.EvaluateUserPermissionsOnLabItems(this.experiment?.workflowState as ExperimentWorkflowState, featureFlags);

    return (
      this.experiment?.workflowState !== ExperimentWorkflowState.InReview &&
      !this.userService.hasOnlyReviewerRights() &&
      visibility === AccessibilityTypes.ReadWrite && this.currentClientState === ClientState.EXPERIMENT_ACTIVITIES &&
      labItemsAccessFeatures[ActivityInputType.Material].PermittedWorkflowStatesToAddItems
    );
  }

  public setupBarcodeScanner() {
    this.showBarcodeScanner = this.canEnableBarcodeScannerIcon();
  }

  private initialize() {
    this.toolbarIcons = this.getBarcodeScannerIcon();
    const featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    const commonFeatureFlags = this.clientStateService.getFeatureFlags(ClientState.BOOKSHELF);
    this.canStartExperimentFlag =
      featureFlags.find(
        data => JSON.parse(data).CanStartExperiment && JSON.parse(data).CanStartExperiment === true
      ) !== undefined;

    this.canCancelExperiment =
      featureFlags.find(
        data => JSON.parse(data).CanCancelExperiment && JSON.parse(data).CanCancelExperiment === true
      ) !== undefined;

    this.canAddTemplate =
      commonFeatureFlags.find(
        data => JSON.parse(data).CanAddTemplate && JSON.parse(data).CanAddTemplate === true
      ) !== undefined;

    this.canRestoreExperiment =
      featureFlags.find(
        data => JSON.parse(data).CanRestoreExperiment && JSON.parse(data).CanRestoreExperiment === true
      ) !== undefined;

    this.canSendExperimentForReviewFromInProgressFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForReviewFromInProgress === true
      ) !== undefined;

    this.canSendExperimentForReviewFromCorrectionFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForReviewFromCorrection === true
      ) !== undefined;

    this.canSendExperimentForReviewFromCorrectionFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForCorrectionFromReview === true
      ) !== undefined;

    this.canSendExperimentForCorrectionFromReviewFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForCorrectionFromReview === true
      ) !== undefined;

    this.canSendExperimentForCorrectionFromAuthorizedStateFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForCorrectionFromAuthorizedState === true
      ) !== undefined;

    this.canSendExperimentForAuthorizationFlag =
      featureFlags.find(
        data => JSON.parse(data).CanSendExperimentForAuthorization === true
      ) !== undefined;

    this.setUpExperimentFlags();
  }

  private setUpExperimentFlags() {
    const visibility = this.clientStateService.getClientStateVisibility(ClientState.EXPERIMENT);
    const isExperimentCompleted = ExperimentService.isExperimentAuthorizedOrCancelled(this.experiment?.workflowState as ExperimentWorkflowState)

    if (visibility) {
      if (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview) {
        this.canRaiseExperimentFlags = this.userService.hasSupervisorOrReviewerRights();
      } else {
        this.canRaiseExperimentFlags = !isExperimentCompleted;
      }
    }

    this.canPendingCancelFlag = this.experimentFlags.find(
      flag => flag.flag.toLocaleLowerCase() === ExperimentFlagType.PendingCancel.toLocaleLowerCase()
    );
  }

  private confirmWorkflowStateTransition(message: string, acceptCallback: () => void) {
    this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@Yes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@No:No`,
      closeOnEscape: true,
      dismissableMask: false,
      accept: acceptCallback
    });
  }

  private createSuccessNotificationMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  updateMenuItems(activity: Activity) {
    const moduleId = 'eln-menu-activityModule';
    const menuItemIndex = this.menuItems.findIndex((m) => m.label === activity.itemTitle || m.label === activity.activityReferenceNumber);
    this.menuItems[menuItemIndex].items?.forEach((item) => {
      if (item.id === moduleId + activity.itemTitle) {
        if (!activity.isActivityComplete) {
          item.label =
            $localize`:@@activityModulePageTitle:Modules` + '<span class="icon-exclamation-mark icon-s icon-navbar"></span>';
          item.tooltipOptions = {
            tooltipLabel: $localize`:@@activityIncomplete:There is a module with incomplete items`,
            tooltipPosition: 'top',
            positionTop: 10,
            positionLeft: 95
          };
        } else {
          item.label = $localize`:@@activityModulePageTitle:Modules`;
          item.tooltipOptions = {
            tooltipLabel: ''
          };
        }
      }
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
    UnsubscribeAll(this.activeSubscriptions); // duplicate property, either is fine, both is fine too.
    UnsubscribeAll(this.reOrderSubscription);
    this.expWorkflowSubscription.forEach(subscription => subscription.unsubscribe());
  }

  startTimer(): void {
    this.inactiveTimeOut = window.setTimeout(() => {
      this.confirmSignalRDisconnect();
    }, this.userInactiveTimeOut);
  }

  confirmSignalRDisconnect() {
    const confirmationTimer = window.setTimeout(() => {
      this.confirmationService.close();
      this.disconnectUser();
    }, this.confirmationTimeOut);

    const message =
      $localize`:@@signalRDisconnectMessage:You will be disconnected from this Experiment in 1 min due to inactivity. If you would like to remain connected then click "OK" below.`;
    this.confirmationService.confirm({
      message,
      header: $localize`:@@confirmationHeader:Confirmation`,
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: false,
      acceptLabel: $localize`:@@Ok:OK`,
      rejectLabel: $localize`:@@cancel:Cancel`,
      accept: () => {
        clearTimeout(confirmationTimer);
        this.startTimer();
      },
      reject: () => {
        this.disconnectUser();
      }
    });
  }

  private disconnectUser() {
    clearTimeout(this.inactiveTimeOut);
    this.stopTimer = true;
    this.showReconnectDialog = true;
    this.experimentNotificationService.disconnectUser();
  }

  reConnect() {
    this.ngZone.run(() => {
      this.showConnecting = true;
    });
    this.experimentNotificationService.joinAnExperiment();
  }

  doesInstrumentEventExistsForActivityInput() {
    if (this.experimentService.currentExperiment &&
      this.experimentService.currentExperiment.activities.length === 0 &&
      this.experimentService.currentExperiment.activityInputs &&
      this.experimentService.currentExperiment.activityInputs?.length > 0) {
      return this.experimentService.currentExperiment?.activityInputs?.some(a => a.instruments?.isRemoved === false);
    } else {
      const instrument = this.experimentService.currentExperiment?.activityInputs
        ?.filter((a: any) => a.activityId === this.experimentService.currentActivityId)
        ?.find(a => !a.instruments?.isRemoved)?.instruments;
      return instrument as any;
    }
  }

  doesConsumableExistsForLabItem() {
    const currentActivityId = this.experimentService.currentActivity?.activityId;
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) =>
        labItemNode.nodeId === currentActivityId
    );
    return !!activityLabItemNode?.consumables && activityLabItemNode?.consumables?.length > 0;
  }

  @HostListener('mousedown')
  @HostListener('keydown')
  resetTimer() {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.inactiveTimeOut);
      if (!this.stopTimer) {
        this.startTimer();
      }
    });
  }

  activitiesReOrdered(e: BptReoderedEvent<any>) {
    if (this.experimentService.currentExperiment?.activities.length === 1) return;

    const activity = this.experimentService.currentExperiment?.activities.find(a => a.itemTitle === e.item[0].label || a.activityReferenceNumber === e.item[0].label);
    if (!activity) return;

    this.experimentNodeReOrderService.changeNodeReorder(NodeType.Activity, activity.activityId, activity.experimentId, e.newPosition, activity.itemTitle, activity.activityId);
  }

  @HostListener('window:beforeunload')
  unload() {
    this.isReload = true;
    this.DisconnectPhMeterOnRefresh();
  }

  private DisconnectPhMeterOnRefresh() {
    const isPhMeterConnectedInCurrentTab = this.pHMeterService.getPhMeterActiveTab();
    if (
      isPhMeterConnectedInCurrentTab
      && +isPhMeterConnectedInCurrentTab
      && this.instrumentConnectionHelper.phName
      && localStorage.getItem('instrumentType') === InstrumentType.phMeter
    ) {
      this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper.phName, InstrumentType.phMeter);
    }
  }

  applyReferenceTemplate(type: ReferenceTemplateType) {
    const finalizeAdding = () => this.experimentService.isLoadingDocumentsOrCompendia = false;

    this.experimentService.isLoadingDocumentsOrCompendia = true;
    this.addReferenceTemplate(type).subscribe({
      complete: finalizeAdding,
      error: finalizeAdding
    });
  }

  addReferenceTemplate(type: ReferenceTemplateType): Observable<void> {
    // defend against a thing that can't happen
    const undefinedActivityErrorMsg = 'LOGIC ERROR: Cannot add a reference to an undefined activity';
    if (!this.currentActivity) return throwError(() => Error(undefinedActivityErrorMsg));

    const command: ApplyReferenceTemplateCommand = {
      experimentId: this.currentActivity.experimentId,
      activityId: this.currentActivity.activityId,
      type,
    };
    return this.activityReferenceEventsService.activityReferencesApplyReferenceTemplatePost$Json({ body: command }).pipe(map(
      (response: ReferenceTemplateAppliedResponse) => {
        if (!this.currentActivity) throw undefinedActivityErrorMsg;
        const tableId = response.tableId;
        switch (type) {
          case ReferenceTemplateType.Documents:
            this.currentActivity.activityReferences.documentReferencesTableId = tableId;
            this.experimentService.referenceTypeAdded.next(ReferenceGridType.Documents);
            this.experimentService._isCurrentUserCollaboratorSubject$.next(true);
            break;
          case ReferenceTemplateType.Compendia:
            this.currentActivity.activityReferences.compendiaReferencesTableId = tableId;
            this.experimentService.referenceTypeAdded.next(ReferenceGridType.Compendia);
            this.experimentService._isCurrentUserCollaboratorSubject$.next(true);
            break;
        }

        this.experimentService.renderAppliedTemplate(
          this.currentActivity, {
          type: command.type,
          tableId: response.tableId,
          templateId: response.templateId,
          activityId: command.activityId,
          experimentId: command.experimentId,
          notifications: response.notifications
        }
        )
      }
    ));
  }

  /** To Support the PE environment until FAMS enabled there. */
  reauthBeforeExperimentTransition(transitionCallback: () => void): void {
    if (!this.featureService.isEnabled(ELNFeatureFlags.EnableESignatureForExperimentTransition)
      || !ConfigurationService.isLimsHosted) {
      transitionCallback();
      return;
    }
    (window as any).Master?.oidcTokenManager?.checkFamsAndGetWindowPopupForReauth();
    const request = {
      successCallback: (authResult: AuthResult) => {
        if (authResult && authResult.status === AuthStatusType.Success && authResult.token) {
          transitionCallback();
        }
      },
      messageForUnauthorizedCredentials: $localize`:@@TransitionInvalidCredentials:Credentials are incorrect and the status update was not successful.`
    };
    this.authenticationHelperService.handleReauthenticationResponse(request);
  }

  evaluateCurrentWorkflowTransitionActions(experimentLoad = false): void {
    if (
      this.experiment && (this.experiment.workflowState === ExperimentWorkflowState.InReview ||
        this.experiment.workflowState === ExperimentWorkflowState.InProgress ||
        this.experiment.workflowState === ExperimentWorkflowState.Setup ||
        this.experiment.workflowState === ExperimentWorkflowState.InCorrection
      )
      && this.canSendExperimentForAuthorizationFlag
    ) {
      this.experimentService.amICollaborator(experimentLoad).subscribe({
        next: (amICollaborator) => {
          if (amICollaborator) this.isCollaborator = amICollaborator;
          this.setCurrentWorkflowTransitionAction(amICollaborator);
        }
      });
      return;
    }
    this.setCurrentWorkflowTransitionAction();
  }

  private getExperimentAuthorizeMenuOption(isCurrentUserCollaborator: boolean) {
    return {
      id: 'eln-btnAuthorize',
      label: $localize`:@@AuthorizeExperiment:Authorize`,
      icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Authorized],
      disabled: !this.canSendExperimentForAuthorizationFlag || isCurrentUserCollaborator,
      tooltipOptions: {
        tooltipLabel:
          isCurrentUserCollaborator ?
            $localize`:@@collaboratorsNotAuthorized:Collaborators are not allowed to Authorize the experiment`
            : $localize`:@@authorizationTooltipMessage:Only a Reviewer can Authorize an Experiment`,
        disabled: this.canSendExperimentForAuthorizationFlag && !isCurrentUserCollaborator
      },
      command: () => this.confirmAuthorization()
    }
  }
}
