import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { AddRowsForEachCommand, AddRowsForEachTable, MaterialAliquot, NodeType } from '../../../../../api/data-entry/models';
import { Activity, Module, Table, TableValueRow } from '../../../../../model/experiment.interface';
import { ActivityInputInstrumentEvent } from '../../../../model/activity-input-instrument-event';
import { ActivityInputSamples } from '../../../../model/activity-input-samples';
import { ActivityInputStudyActivity } from '../../../../model/activity-input-study-activity';
import { ActivityInputType, Instrument, ModifiableDataValue } from '../../../../../api/models';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';
import { DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { hasConsumedRepeatForEachPlaceholderRows, TableDataService } from '../../table-data.service';
import { DataValueService } from '../../../../services/data-value.service';
import { StringValue } from '../../../../../api/cookbook/models';
import { ClientValidationDetails } from '../../../../../model/client-validation-details';

@Component({
  selector: 'app-repeat-group-table-input-selector',
  templateUrl: './repeat-group-table-input-selector.component.html',
  styleUrls: ['./repeat-group-table-input-selector.component.scss']
})
export class RepeatGroupTableInputSelectorComponent implements OnInit {
  @HostBinding('class.app-repeat-group-table-input-container') applyStyle = true;
  activity?: Activity;
  table?: Table;
  validation!: ClientValidationDetails;

  private _activityInputs: RepeatGroupActivityInputs | undefined;
  public get activityInputs(): RepeatGroupActivityInputs | undefined {
    return this._activityInputs;
  }
  public set activityInputs(value: RepeatGroupActivityInputs | undefined) {
    this._activityInputs = value;
  }

  public treeData: BptTreeNode[] = [];
  public selectedTreeData: BptTreeNode[] = [];

  constructor(@Inject(DynamicDialogComponent) parent: DynamicDialogComponent, private readonly dynamicDialogConfig: DynamicDialogConfig<RepeatGroupInputSelectorDialogData>,
    public readonly dynamicDialogRef: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.activityInputs = this.dynamicDialogConfig.data?.activityInputs;
    const targetNode = this.dynamicDialogConfig.data?.targetNode;

    if (!this.activityInputs || !targetNode) return;

    if (targetNode.itemType === NodeType.Activity) {
      this.activity = targetNode as Activity;
      this.treeData = [new RepeatGroupActivityViewModel(this.activity, this.activityInputs)];
    }

    if (targetNode.itemType === NodeType.Table) {
      this.table = targetNode as Table;
      this.treeData = [new RepeatGroupTableViewModel(this.table, this.activityInputs)];
    }

    this.treeData.forEach((node: BptTreeNode) => this.selectNodeAndChildren(node));
    this.setValidationMessage();
  }

  selectNodeAndChildren(node: BptTreeNode) {
    this.selectedTreeData.push(node);
    node.children?.forEach((child: BptTreeNode) => this.selectNodeAndChildren(child));
  }

  deselectNodeAndChildren(node: BptTreeNode) {
    this.selectedTreeData = this.selectedTreeData.filter(n => n !== node);
    node.children?.forEach((child: BptTreeNode) => this.deselectNodeAndChildren(child));
  }

  public cancel() {
    this.dynamicDialogRef.close();
  }

  nodeSelected(e: any) {
    if (!this.selectedTreeData.includes(e.node)) this.selectNodeAndChildren(e.node);
  }

  nodeUnselected(e: any) {
    this.deselectNodeAndChildren(e.node);
  }

  /** Gather results and return to caller */
  public addRows() {
    if (!this.activityInputs) throw new Error('LOGIC ERROR: Should not be able to get to the point of adding rows if activity has no inputs');
    const experimentId = this.activity?.experimentId ?? this.table?.experimentId;
    if (!experimentId) throw new Error('LOGIC ERROR: Should have either table or activity');

    const tables = (this.activity
      ? this.activity.dataModules.flatMap(m => m.items.filter(i => i.itemType === NodeType.Table))
      : [this.table]) as Table[];
    const result: AddRowsForEachCommand = {
      activityId: this.activityInputs.activityId,
      experimentId,
      tables: tables.map(t => this.getTableCommandData(t))
    };
    // filter out tables with no inputs
    result.tables = result.tables.filter(t => t.samples.length || t.materials.length || t.instruments.length);
    this.dynamicDialogRef.close(result);
  }

  private getTableCommandData(table: Table): AddRowsForEachTable {
    const selectedItemsForThisTable = this.selectedTreeData.filter(i => i instanceof RepeatGroupActivityInputItemViewModel)
      .map(i => i as RepeatGroupActivityInputItemViewModel)
      .filter(i => i.tableId === table.tableId);
    return {
      tableId: table.tableId,
      samples: selectedItemsForThisTable.filter(i => i.activityInputType === ActivityInputType.Aliquot).map(s => s.label),
      materials: selectedItemsForThisTable.filter(i => i.activityInputType === ActivityInputType.Material).map(m => m.label),
      instruments: selectedItemsForThisTable.filter(i => i.activityInputType === ActivityInputType.Instrument).map(i => i.label),
    };
  }

  private setValidationMessage() {
    this.validation = new ClientValidationDetails();

    if (!this.hasAllInputs(this.getPlaceholderRows())) {
      this.validation.warnings.push(
        $localize`:@@repeatForEachNoInputsWarning:
        There are repeat for each row configurations where the activity does not currently contain a corresponding input type.
        Proceeding without inputs for the corresponding input type will remove the placeholders for the corresponding repeat for each group.`
      );
    }
  }

  getPlaceholderRows(): PlaceholderRow[] {
    if (this.table) return this.table?.value.filter((row): row is PlaceholderRow => TableDataService.rowHasARepeatForEachSetting(row) && !TableDataService.rowIsRemoved(row)) ?? [];

    const tables = this.activity?.dataModules.flatMap(m => m.items.filter(mi => mi.itemType === NodeType.Table)).filter((mi): mi is Table => !!mi);
    return tables?.flatMap(
      t => t?.value.filter(
        (row): row is PlaceholderRow => TableDataService.rowHasARepeatForEachSetting(row) && !TableDataService.rowIsRemoved(row))) ?? [];
  }

  hasAllInputs(placeholders: PlaceholderRow[]): boolean {
    const placeholderTypes = placeholders.map((r) => r[TableDataService.repeatForField]?.value).filter((r): r is StringValue => !!r)
      // Get a list of types for each RFE row, sample to aliquot to match ActivityInputType enum
      .map(strval => strval.value === 'sample' ? ActivityInputType.Aliquot : strval.value);
    const hasSamples = !placeholderTypes.includes(ActivityInputType.Aliquot)
      || placeholderTypes.includes(ActivityInputType.Aliquot) && (this.activityInputs?.sampleAliquots?.filter(s => !s.isRemoved).length ?? 0) > 0;
    const hasMaterials = !placeholderTypes.includes(ActivityInputType.Material)
      || placeholderTypes.includes(ActivityInputType.Material) && (this.activityInputs?.materialAliquots?.filter(m => !m.isRemoved).length ?? 0) > 0;
    const hasInstruments = !placeholderTypes.includes(ActivityInputType.Instrument)
      || placeholderTypes.includes(ActivityInputType.Instrument) && (this.activityInputs?.instrumentEvents?.filter(i => !i.isRemoved).length ?? 0) > 0;
    return hasSamples && hasMaterials && hasInstruments;
  }
}

export function getWarningNode(inputType: ActivityInputType): BptTreeNode {
  let label;
  switch (inputType) {
    case ActivityInputType.Aliquot: // meaning sample aliquot
      label = $localize`:@@SampleAliquots:Sample Aliquots`;
      break;
    case ActivityInputType.Material: // sourced from the "Study Activities" activity input table.
      label = $localize`:@@materialAliquots:Material Aliquots`;
      break;
    case ActivityInputType.Instrument: // one per activity, but we keep it as an array for consistency's sake.
      label = $localize`:@@instrumentEvents:Instrument Events`;
      break;
    default:
      throw new Error('LOGIC ERROR: Unsupported Activity Input');
  }

  const tooltip = $localize`:@@currentlyNoScannedInputs:There are currently no scanned ${label} in this activity, and rows configured to repeat for each ${label} will not repeat.`

  return {
    label,
    icon: 'pi pi-exclamation-triangle',
    tooltip,
    selectable: false,
    styleClass: 'leaf-warning'
  };
}

export type RepeatGroupInputSelectorDialogData = {
  activityInputs: RepeatGroupActivityInputs
  targetNode: Table | Activity
}

export class RepeatGroupActivityViewModel implements BptTreeNode {
  label: string;
  children?: RepeatGroupModuleViewModel[];

  constructor(activity: Activity, activityInputs: RepeatGroupActivityInputs) {
    this.label = activity.itemTitle;
    this.children = activity.dataModules.map(dm => new RepeatGroupModuleViewModel(dm, activityInputs));
    // Remove modules that don't have any children
    this.children = this.children.filter(c => c.children?.length);
  }
}

export class RepeatGroupModuleViewModel implements BptTreeNode {
  label: string;
  children?: RepeatGroupTableViewModel[];

  constructor(module: Module, activityInputs: RepeatGroupActivityInputs) {
    this.label = module.moduleName;
    const tables = module.items.filter(mi => mi.itemType === NodeType.Table).map(table => table as Table);
    this.children = tables.filter(t => !hasConsumedRepeatForEachPlaceholderRows(t)).map(table => new RepeatGroupTableViewModel(table, activityInputs));
  }
}

export class RepeatGroupTableViewModel implements BptTreeNode {
  label: string;
  children: BptTreeNode[] = [];

  constructor(table: Table, activityInputs: RepeatGroupActivityInputs) {
    this.label = table.itemTitle;
    const sampleAliquots = activityInputs.sampleAliquots.filter(ai => !ai.isRemoved);
    if (sampleAliquots.length > 0 && this.hasPlaceholderFor(table, ActivityInputType.Aliquot)) {
      this.children.push(new RepeatGroupActivityInputViewModel(table.tableId, ActivityInputType.Aliquot, sampleAliquots));
    } else if (sampleAliquots.length <= 0 && this.hasPlaceholderFor(table, ActivityInputType.Aliquot)) {
      this.children.push(getWarningNode(ActivityInputType.Aliquot));
    }

    const materialAliquots = activityInputs.materialAliquots.filter(ai => !ai.isRemoved);
    if (materialAliquots.length > 0 && this.hasPlaceholderFor(table, ActivityInputType.Material)) {
      this.children.push(new RepeatGroupActivityInputViewModel(table.tableId, ActivityInputType.Material, materialAliquots));
    } else if (materialAliquots.length <= 0 && this.hasPlaceholderFor(table, ActivityInputType.Material)) {
      this.children.push(getWarningNode(ActivityInputType.Material));
    }

    const instrumentEvents = activityInputs.instrumentEvents.filter(ai => !ai.isRemoved);
    if (instrumentEvents.length > 0 && this.hasPlaceholderFor(table, ActivityInputType.Instrument)) {
      this.children.push(new RepeatGroupActivityInputViewModel(table.tableId, ActivityInputType.Instrument, instrumentEvents));
    } else if (instrumentEvents.length <= 0 && this.hasPlaceholderFor(table, ActivityInputType.Instrument)) {
      this.children.push(getWarningNode(ActivityInputType.Instrument));
    }
  }

  private hasPlaceholderFor(table: Table, activityInputType: ActivityInputType): boolean {
    const sampleToAliquotFix = activityInputType === ActivityInputType.Aliquot ? 'sample' : activityInputType;
    return table.value.some(r => {
      const nonRemovedPlaceholder = TableDataService.rowIsPlaceholder(r) && !TableDataService.rowIsRemoved(r);
      return nonRemovedPlaceholder && DataValueService.getStringFromStringValue(r[TableDataService.repeatForField].value as StringValue) === sampleToAliquotFix;
    });
  }
}

export class RepeatGroupActivityInputViewModel implements BptTreeNode {
  label: string;
  activityInputType: ActivityInputType;
  tableId: string;
  children?: RepeatGroupActivityInputItemViewModel[];

  constructor(tableId: string, activityInputType: ActivityInputType, activityInputItems: RepeatGroupActivityInputItem) {
    this.activityInputType = activityInputType;
    this.tableId = tableId;
    switch (this.activityInputType) {
      case ActivityInputType.Aliquot: // meaning sample aliquot
        this.label = $localize`:@@SampleAliquots:Sample Aliquots`;
        this.children = (activityInputItems as ActivityInputSamples[]).map(ai =>
          new RepeatGroupActivityInputItemViewModel(ai.aliquotNumber, ai.id ?? ai.aliquotNumber, tableId, activityInputType));
        break;
      case ActivityInputType.Material: // sourced from the "Study Activities" activity input table.
        this.label = $localize`:@@materialAliquots:Material Aliquots`;
        this.children = (activityInputItems as ActivityInputStudyActivity[]).map(ai =>
          new RepeatGroupActivityInputItemViewModel(ai.code, ai.id ?? ai.code, tableId, activityInputType));
        break;
      case ActivityInputType.Instrument: // one per activity, but we keep it as an array for consistency's sake.
        this.label = $localize`:@@instrumentEvent:Instrument Event`;
        this.children = (activityInputItems as ActivityInputInstrumentEvent[]).map(ai =>
          new RepeatGroupActivityInputItemViewModel(ai.instrumentNumber, ai.instrumentNumber, tableId, activityInputType));
        break;
      default:
        throw new Error('Unsupported Activity Input');
    }
    this.children.sort((lhs: RepeatGroupActivityInputItemViewModel, rhs: RepeatGroupActivityInputItemViewModel) => lhs.label.localeCompare(rhs.label));
  }
}

export class RepeatGroupActivityInputItemViewModel implements BptTreeNode {
  label: string;
  activityInputType: ActivityInputType;
  groupId: string;
  tableId: string;

  constructor(label: string, id: string, tableId: string, activityInputType: ActivityInputType) {
    this.label = label;
    this.groupId = id;
    this.tableId = tableId;
    this.activityInputType = activityInputType;
  }
}

type RepeatGroupActivityInputItem = ActivityInputSamples[] | MaterialAliquot[] | Instrument[];

type RepeatFields = typeof TableDataService.repeatForField | typeof TableDataService.repeatWithField;
type PlaceholderRow = TableValueRow & Partial<Record<RepeatFields, ModifiableDataValue>>;

export type RepeatGroupActivityInputs = {
  activityId: string;
  sampleAliquots: ActivityInputSamples[];
  materialAliquots: MaterialAliquot[]; // only material aliquots that are linked to study activities are valid here.
  instrumentEvents: Instrument[];
}
