import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { RecipeService } from '../../api/cookbook/services';
import { RecipePreLoadDataTransformType, ScaledActivity, ScaledModule, ScaledPreparation } from '../../api/cookbook/models';
import { RecipeRecord } from '../../api/search/models';
import { ScalableRecipeConstants } from './scalable-recipe-constants';
import { BptGridComponent, BptGridMode, BptGridRowActionConfiguration, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { ExperimentTemplateApplyService } from '../../template-loader/experiment-template-load/services/experiment-template-apply.service';
import { MessageService } from 'primeng/api';
import { IRowNode } from 'ag-grid-community';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { StringValue } from '../../api/models/string-value';
import { UnitLoaderService } from '../../services/unit-loader.service';

@Component({
  selector: 'app-scalable-recipe-slider',
  templateUrl: './scalable-recipe-slider.component.html',
  styleUrls: ['./scalable-recipe-slider.component.scss']
})
export class ScalableRecipeSliderComponent implements OnInit, OnDestroy {
  @ViewChildren('specificationGrid') specificationGrid!: QueryList<BptGridComponent>;
  @ViewChildren('preparationGrid') preparationGrid!: QueryList<BptGridComponent>;
  @Input() recipe: RecipeRecord | undefined;
  @Input() set templateLoadResult(value: boolean | undefined) {
    if (value !== undefined) this.handleTemplateLoadResult(value);
  }
  @Output() closingScalableRecipeSlider = new EventEmitter<boolean>();
  @Output() applyRecipe = new EventEmitter<void>();

  loadingMessage = $localize`:@@fetchScalingData:Loading Scaling Details...`;
  loading = true;
  scalableRecipeDetails: {
    name: string;
    sbu: string;
    version: string;
    publishedOn: string | undefined;
  } = {
    name: '',
    sbu: '',
    version: '',
    publishedOn: ''
  };

  scalableRecipeSliderOptions: {
    visible: boolean;
    closeOnEscape: boolean;
    primaryButtonLabel: string;
  } = {
    visible: true,
    closeOnEscape: true,
    primaryButtonLabel: $localize`:@@load:Load`
  }

  sbuLabel = $localize`:@@sbu:SBU`;
  versionLabel = $localize`:@@versionLabel:Version`;
  publishedOnLabel = $localize`:@@publishedOnLabel:Published On`;
  scalableTableLabel = $localize`:@@scalableTable:Scalable Table`;
  scalableFormLabel = $localize`:@@scalableForm:Scalable Form`;
  preparationColumnDefs!: ColumnDefinition[]
  specificationColumnDefs!: ColumnDefinition[]
  specificationForFormColumnDefs!: ColumnDefinition[]
  showRecipeScalableSlider = true;
  activities: ScaledActivity[] = [];
  gridMode = BptGridMode.dataEntry;
  gridActions!: BptGridRowActionConfiguration;
  private readonly subscriptions: Subscription[] = [];

  dataSourceMap: { [key: string]: any[] } = {};
  preparationSourceMap: { [key: string]: any[]} = {};
  dataSourceMapForForms: { [key: string]: any[] } = {};

  constructor(
    private readonly recipeService: RecipeService,
    private readonly messageService: MessageService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly experimentTemplateApplyService: ExperimentTemplateApplyService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
  ) {
    this.preparationColumnDefs = ScalableRecipeConstants.getColumnDefinitions(this.messageService).preparation;
    this.specificationColumnDefs = ScalableRecipeConstants.getColumnDefinitions(this.messageService).specification;
    this.specificationForFormColumnDefs = ScalableRecipeConstants.getColumnDefinitions(this.messageService).specificationForForm;
  }

  ngOnInit(): void {
    this.initializeScalableRecipeDetails();
    this.loadScalableRecipe();
    this.watchRecipeApplyService();
  }

  watchRecipeApplyService() {
    this.subscriptions.push(this.experimentTemplateEventService.RecipeApplied.subscribe((_response) => {
      this.showRecipeScalableSlider = false;
      this.scalableRecipeSliderOptions.visible = false;
      this.closeSlider(false);
    }));
  }

  private initializeScalableRecipeDetails(): void {
    if (this.recipe) {
      this.scalableRecipeDetails.name = this.recipe.name;
      this.scalableRecipeDetails.sbu = this.recipe.subBusinessUnits.length > 0 ? this.recipe.subBusinessUnits[0].code : '';
      this.scalableRecipeDetails.version = this.recipe.version;
      if (this.recipe.publishedOn)
        this.scalableRecipeDetails.publishedOn = formatInstant(this.recipe?.publishedOn, DateAndInstantFormat.date);
    }
  }

  loadScalableRecipe() {
    if (this.recipe?.recipeId) {
      const params = {
        recipeId: this.recipe.recipeId
      };
      this.recipeService.recipesRecipeIdWithScalingGet$Json(params).subscribe((activities: ScaledActivity[]) => {
        this.activities = activities;
        this.mapPreparationDataSource(activities);
        this.mapSpecificationDataSource(activities);
        })
        .add(() => (this.loading = false));
    }
  }

  mapPreparationDataSource(activities: ScaledActivity[]): void {
    this.preparationSourceMap = activities.reduce((map, activity) => {
      activity.preparations.forEach((preparation, index) => {
        const scalingFlag = this.getScalingFlag(preparation.transformTypes);
        const unit =  this.unitLoaderService.getUnit(preparation.originalQuantity?.unit ?? '')?.abbreviation;
        map[preparation.preparationId] = [{
          index: index + 1,
          rowId: preparation.preparationId,
          name: (preparation?.name?.value as StringValue).value ?? '',
          scalingFlag: scalingFlag,
          base: `${preparation.originalQuantity?.value ?? ''} ${unit}`,
          factor: "1"
        }];
      });
      return map;
    }, {} as { [key: string]: any[] });
  }

  getScalingFlag(transformTypes: Array<RecipePreLoadDataTransformType>): string {
    const flags = transformTypes.map(type => {
      if (type === RecipePreLoadDataTransformType.ScaleUp) return 'Scaling up';
      if (type === RecipePreLoadDataTransformType.ScaleDown) return 'Scaling down';
      return '';
    }).filter(flag => flag !== '');

    return flags.join(', ');
  }

  getPreparationDataSource(preparations: ScaledPreparation[]): any[] {
    return preparations.flatMap(preparation => this.preparationSourceMap[preparation.preparationId] || []);
  }

  mapSpecificationDataSource(activities: ScaledActivity[]): void {
    this.dataSourceMap = activities.reduce((map, activity) => {
      activity.modules.forEach(module => {
        const moduleId = module.moduleId;
        map[moduleId] = module.tables.map((table, index) => ({
          index: index + 1,
          tableId: table.tableId,
          tableName: table.itemTitle,
          factor: "1"
        }));
      });
      return map;
    }, {} as { [key: string]: any[] });

    this.dataSourceMapForForms = activities.reduce((map, activity) => {
      activity.modules.forEach(module => {
        const moduleId = module.moduleId;
        map[moduleId] = module.forms.map((form, index) => ({
          index: index + 1,
          formId: form.formId,
          formName: form.itemTitle,
          factor: "1"
        }));
      });
      return map;
    }, {} as { [key: string]: any[] });
  }

  hasScalablePreparations(preparations: ScaledPreparation[]): boolean {
    return preparations.length > 0
  }

  hasScalableTables(modules: ScaledModule[]): boolean {
    return modules.some(module => module.tables.length > 0);
  }

  hasScalableForms(modules: ScaledModule[]): boolean {
    return modules.some(module => module.forms.length > 0);
  }

  onCancel() {
    this.showRecipeScalableSlider = false;
    this.scalableRecipeSliderOptions.visible = false;
    this.closeSlider(false);
  }

  closeSlider(isClosed: boolean) {
    this.closingScalableRecipeSlider.emit(isClosed);
  }

  getScaledFactors(): { [key: string]: number } {
    const scaledFactors: { [key: string]: number } = {};
    populateScalingFactors(this.dataSourceMap, 'tableId');
    populateScalingFactors(this.preparationSourceMap, 'rowId');
    return scaledFactors;

    function populateScalingFactors(data: { [key: string]: any[] }, identifier: string) {
      for (const id in data) {
        data[id].forEach((row) => {
          const factor = row.factor;
          if (!!factor && factor.toString() !== '1') {
            if (!!scaledFactors[row[identifier]])
              throw new Error(`scaledFactors has duplicate identifier: ${row[identifier]}`);
            scaledFactors[row[identifier]] = factor;
          }
        });
      }
    }
  }

  onLoad() {
    const scaledFactors = this.getScaledFactors();
    this.experimentTemplateApplyService.setScaledFactors(scaledFactors);
    this.applyRecipe.emit();
  }

  handleTemplateLoadResult(value: boolean): void {
    if (value) {
      this.onCancel();
    } else {
      this.setAllFactorsToOne(this.specificationGrid);
      this.setAllFactorsToOne(this.preparationGrid);
    }
  }

  setAllFactorsToOne(grid: QueryList<BptGridComponent>,factorColumnId = 'factor'): void {
    grid.forEach(grid => {
      const rowNodes = grid.gridApi.getRenderedNodes();
      rowNodes.forEach((node: IRowNode<any>) => {
        node.setDataValue(factorColumnId, 1);
      });
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions)
  }
}