import { Injectable, OnDestroy } from '@angular/core';
import { ColumnType, CrossReference } from '../../api/models';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Observable, Subject, Subscription } from 'rxjs';
import { CrossReferenceRow, CrossReferencesColumns, ModifiablePropertiesKey } from './cross-references/cross-references.component';
import {
  CrossReferenceAddedEventNotification,
  CrossReferenceChangedEventNotification,
  CrossReferenceRemovedEventNotification,
  CrossReferenceRestoredEventNotification,
  CrossReferenceType, ModifiableDataValue,
  RemoveCrossReferenceCommand,
  RestoreCrossReferenceCommand,
  ValueState
} from '../../api/data-entry/models';
import { ActivityReferenceEventsService } from '../../api/data-entry/services';
import { ExperimentService } from '../services/experiment.service';
import { DataValueService } from '../services/data-value.service';
import { DataRecordService } from '../services/data-record.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ActivityReferencesPseudoModuleTitle } from './references.component';
import { TableDataForCompletionTracking } from '../model/cell-data-for-completion-tracking.interface';
import { Activity } from '../../model/experiment.interface';
import { CompletionTrackingService } from '../../services/completion-tracking.services';
import { v4 as uuid} from 'uuid';
import { ChangeReasonService } from '../services/change-reason.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService implements OnDestroy {
  /** Dictionary of Cross References keyed by Activity ID */
  crossReferencesByActivity: { [key: string]: CrossReferenceRow[] } = {};

  public readonly crossReferenceRemoved = new Subject<CrossReference>();
  public readonly crossReferenceRestored = new Subject<CrossReference>();
  private readonly crossReferenceRefreshNotification = new Subject<void>();

  public crossReferenceRefresh: Observable<void> = this.crossReferenceRefreshNotification.asObservable();

  crossReferenceDeleteActionId = 'bpt-cross-reference-delete-row';
  crossReferenceRestoreActionId = 'bpt-cross-reference-restore-row';
  private readonly rowActionItems = [this.crossReferenceDeleteActionId, this.crossReferenceRestoreActionId]

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly activityReferenceEventsService: ActivityReferenceEventsService,
    private readonly experimentService: ExperimentService,
    private readonly messageService: MessageService,
    private readonly completionTrackingService: CompletionTrackingService,
    private readonly dataValueService: DataValueService,
  ) { }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };

  styleClassPropertiesForCollaboratorWarning: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button'
  };

  /** Updates experiment and percent completion */
  applyActivityCrossReferenceAdded(event: CrossReferenceAddedEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find((a) => a.activityId === event.activityId);
    if (!activity) return;

    const initialCompletionPercentage = this.calculateCompletionPercentage(activity.activityReferences.crossReferences);

    const reference = this.convertToCrossReference(event);
    activity.activityReferences.crossReferences.push(reference);
    this.crossReferenceRefreshNotification.next();

    this.sendCompletionStatus(activity, initialCompletionPercentage.percent);
  }

  /** Updates experiment, crossReferencesByActivity cache and percent completion */
  applyActivityCrossReferenceChanged(event: CrossReferenceChangedEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find((a) => a.activityId === event.activityId);
    if (!activity) return;

    const reference = activity.activityReferences.crossReferences.find((r) => r.id === event.crossReferenceId);
    if (!reference) return;

    const initialCompletionPercentage = this.calculateCompletionPercentage(activity.activityReferences.crossReferences);

    const property: ModifiablePropertiesKey = event.property as ModifiablePropertiesKey;
    const oldValue: ModifiableDataValue | undefined = reference[property];
    reference[property] = DataRecordService.getModifiableDataValue(event.propertyValue, oldValue);
    const cachedRow = this.crossReferencesByActivity[activity.activityId].find(r => r.id === event.crossReferenceId);
    if (cachedRow) cachedRow[property] = this.dataValueService.getPrimitiveValue(ColumnType.String, reference[property]);
    this.crossReferenceRefreshNotification.next();

    this.sendCompletionStatus(activity, initialCompletionPercentage.percent);
  }

  /** Updates experiment and percent completion */
  applyActivityCrossReferenceRemoved(event: CrossReferenceRemovedEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find((a) => a.activityId === event.activityId);
    if (!activity) return;

    const reference = activity.activityReferences.crossReferences.find((r) => r.id === event.crossReferenceId);
    if (!reference) return;

    const initialCompletionPercentage = this.calculateCompletionPercentage(activity.activityReferences.crossReferences);

    reference.isRemoved = true;
    this.crossReferenceRefreshNotification.next();

    this.sendCompletionStatus(activity, initialCompletionPercentage.percent);
  }

  /** Updates experiment and percent completion */
  applyActivityCrossReferenceRestored(event: CrossReferenceRestoredEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find((a) => a.activityId === event.activityId);
    if (!activity) return;

    const reference = activity.activityReferences.crossReferences.find((r) => r.id === event.crossReferenceId);
    if (!reference) return;

    const initialCompletionPercentage = this.calculateCompletionPercentage(activity.activityReferences.crossReferences);

    reference.isRemoved = false;
    this.crossReferenceRefreshNotification.next();

    this.sendCompletionStatus(activity, initialCompletionPercentage.percent);
  }

  /**
   * Cross references completion percentage prior to application of change record. If there are no locations that could have data (denominator is 0), then 100%
   */
  calculateCompletionPercentage(crossReferences: CrossReference[]): { percent: number; totalCells: number; emptyCellsCount: number; } {
    const columnsToBeCompleted = 1; /* Known constant. purpose */
    const activeRows = crossReferences.filter(r => !r.isRemoved);
    const totalCells = columnsToBeCompleted * activeRows.length;
const emptyCellsCount = activeRows.filter(r => this.isEmptyValue(r.purpose)).length;
    return { percent: totalCells === 0 ? 100 : Math.floor((totalCells - emptyCellsCount) / totalCells * 100), totalCells, emptyCellsCount };
  }

  public confirmThenRemoveCrossReference(item: CrossReferenceRow) {
    this.confirmationService.confirm({
      message: $localize`:@@removeCrossReferenceConfirmationMessage:Are you sure that you want to remove "${
        item.reference
      }" from cross references? The removed reference can be restored using the "View Removed Rows" option.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRemoveCrossReference(item);
      }
    });
  }

  public confirmThenRestoreCrossReference(item: CrossReferenceRow) {
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreCrossReference(item);
      },
    });
  }

  static crossReferenceIsComplete(row: CrossReference): boolean {
    const isEmpty = (x: any): boolean => DataValueService.isEmpty(x);
    return CrossReferencesColumns
      .filter((c) => c.editable !== false) // comparing with false because it could be a function for sometime editable
      .every((c) => c.field && !isEmpty(row[c.field as keyof CrossReference]));
  }

  isEmptyValue(value: ModifiableDataValue | undefined): boolean {
    return !value || value.value.state === ValueState.Empty;
  }


  private sendRequestToRemoveCrossReference(item: CrossReferenceRow) {
    if (!this.experimentService.currentExperiment) throw new Error('LOGIC ERROR: Expected currentExperiment to be defined')

    const removeCrossReferenceCommand: RemoveCrossReferenceCommand = {
      experimentId: this.experimentService.currentExperiment.id,
      activityId: this.experimentService.currentActivityId,
      crossReferenceId: item.id,
      changeReasonId: uuid()
    };
    ChangeReasonService.oldValue = undefined;
    this.activityReferenceEventsService
      .activityReferencesCrossReferenceRemovePost$Json({
        body: removeCrossReferenceCommand
      })
      .subscribe({
        next: (response) => {
          this.processRemovedCrossReference(item, true);
        }
      });
  }

  private sendRequestToRestoreCrossReference(item: CrossReferenceRow) {
    if (!this.experimentService.currentExperiment) throw new Error('LOGIC ERROR: Expected currentExperiment to be defined')

    const restoreCrossReferenceCommand: RestoreCrossReferenceCommand = {
      experimentId: this.experimentService.currentExperiment.id,
      activityId: this.experimentService.currentActivityId,
      crossReferenceId: item.id,
      changeReasonId: uuid()
    };
    ChangeReasonService.oldValue = undefined;
    this.activityReferenceEventsService
      .activityReferencesCrossReferenceRestorePost$Json({
        body: restoreCrossReferenceCommand
      })
      .subscribe({
        next: () => this.processRestoredCrossReference(item),
      });
  }

  private processRemovedCrossReference(item: CrossReferenceRow, pushMessage: boolean) {
    const crossReference = this.experimentService.currentExperiment?.activities.flatMap(
      a => a.activityReferences.crossReferences
    ).find(c => c.id === item.id);
    if (!crossReference) return;

    crossReference.isRemoved = true;
    this.crossReferenceRefreshNotification.next();
    this.crossReferenceRemoved.next(crossReference);
    if (pushMessage) {
      this.removedSuccessMessage(item);
    }
  }

  private processRestoredCrossReference(item: CrossReferenceRow) {
    const crossReference = this.experimentService.currentExperiment?.activities.flatMap(
      a => a.activityReferences.crossReferences
    ).find(c => c.id === item.id);
    if (!crossReference) return;

    crossReference.isRemoved = false;
    this.crossReferenceRefreshNotification.next();
    this.crossReferenceRestored.next(crossReference);
    this.restoredSuccessMessage(item);
  }

  private removedSuccessMessage(itemReference: CrossReferenceRow) {
    const successMessage = $localize`:@@crossReferenceRemovedSuccessMessage:The Cross Reference ${itemReference.reference} has been removed successfully.`;
    this.createNotificationMessage(successMessage, '');
  }

  private restoredSuccessMessage(itemReference: CrossReferenceRow) {
    const successMessage =  $localize`:@@crossReferenceRestoredSuccessMessage:The Cross Reference ${itemReference.reference} has been restored successfully.`;
    this.createNotificationMessage(successMessage, '');
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private convertToCrossReference(event: CrossReferenceAddedEventNotification): CrossReference {
    return {
      ...event,
      rowIndex: { isModified: false, value: event.rowIndex },
      isRemoved: false,
      purpose: (event.type === CrossReferenceType.Experiment && event?.purpose) ?
        { isModified: false, value: { state: event.purpose?.state, value: event.purpose?.value, type: event.purpose?.type } } : undefined,
      additionalDetails: undefined
    };
  }

  /**
   * Updates completion percentage for this activity's cross references and sends status change to/from 100%.
   *
   * Note: CompletionTrackingService is not the only story. There are also functions that compute top-down if a tree is complete
   * (e.g. checkActivityCompletion, setActivityCompletionStatus).
   *
   * @param initialCompletionPercent Completion before the change being processed. If there are no locations that could have data (denominator is 0), then 100
   */
  sendCompletionStatus(activity: Activity, initialCompletionPercent: number) {
    const tableInfo: TableDataForCompletionTracking = {
      tableId: `${activity.activityId}/crossReferences`,
      tableTitle: [ activity.itemTitle,  ActivityReferencesPseudoModuleTitle, $localize`:@@CrossReferences:Cross References` ].join(),
    };
    const { totalCells, emptyCellsCount } = this.calculateCompletionPercentage(activity.activityReferences.crossReferences);

    this.completionTrackingService.calculateCrossReferencesCompletionPercentage(totalCells, emptyCellsCount, initialCompletionPercent, tableInfo)
  }
}
