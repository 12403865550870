export class ChangeReasonConstants {
  public static get ReferencesHeader() {
    return $localize`:@@ReferenceModuleHeader:References`;
  }

  public static get DocumentsTableHeader() {
    return $localize`:@@DocumentsLabel:Documents`;
  }

  public static get CompendiaTableHeader() {
    return $localize`:@@CompendiaLabel:Compendia`;
  }

  public static get CrossReferencesHeader() {
    return $localize`:@@CrossReferences:Cross References`;
  }

  public static get ImpactAssessmentHeader() {
    return $localize`:@@instrumentEventImpactAssessment:Instrument Event Impact Assessment`;
  }

  public static get OutputsHeader() {
    return $localize`:@@outputsHeader:Activity Outputs`;
  }

  public static get NonRoutineIssueHeader() {
    return $localize`:@@nonRoutineIssueEncountered:Non-Routine Issue Encountered`;
  }

  public static get ReturnToServiceHeader() {
    return $localize`:@@returnedToService:Returned to Service`;
  }

  public static get PurposeColumnHeader() {
    return $localize`:@@purpose:Purpose`;
  }

  public static get crossReferenceNodeHeader() {
    return '-crossReference';
  }
}