import { Injectable } from "@angular/core";
import { ExperimentDataRecordNotification } from "../../api/data-entry/models";
import { ChangeReasonContext } from "../../model/change-reason-context";
import { AuditHistory } from "./audit-history.interface";
import { User } from "../../api/models/user";
import { ExperimentService } from "./experiment.service";
import { Experiment, Module } from "../../model/experiment.interface";

@Injectable({
  providedIn: 'root'
})

export class DataRecordUtilityService {

  public allModulesInExperiment: Array<Module> = [];

  usersList!: User[];

  get experiment(): Experiment {
    /*
      Tests break this rule so skipping until they can be changed.
      if (!this.experimentService.currentExperiment) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperiment as Experiment;
  }

  constructor(private readonly experimentService: ExperimentService) {

  }

  public getHistory(
    experimentRecord: ExperimentDataRecordNotification,
    fullContextExp: string,
    recordType: string,
    contextExp: string,
    actualContextExp: string,
    description: string,
    name?: string,
    changeReason?: ChangeReasonContext
  ): AuditHistory {
    const fullName = this.usersList?.find(
      u => u.puid.toLowerCase() === experimentRecord.eventContext.puid.toLowerCase()
    )?.fullName;
    return {
      Source: experimentRecord,
      Time: experimentRecord.eventContext.eventTime,
      Context: fullContextExp,
      RecordType: recordType,
      ContextType: contextExp,
      Name: name ?? contextExp,
      Description: description,
      PerformedBy: typeof fullName === 'undefined' ? experimentRecord.eventContext.puid : fullName,
      RecordVersion: 1,
      ActualContext: actualContextExp,
      ChangeReason: changeReason
    };
  }

  /**
   * Gets the history-style formatted context of a activity (current title etc)
   */
  public getActivityContext(nodeId: string): { fullPath: string, title: string } {
    const activityTitle = this.experiment?.activities.find((f) => f.activityId === nodeId)?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    return { fullPath: `${activityTitle ?? $localize`:@@NoTitle:No Title`}`, title: activityTitle };
  }

}