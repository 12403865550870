import { v4 as uuid } from 'uuid';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ModifiableDataValue } from '../../../api/models/modifiable-data-value';
import { ActivatedRoute, Router } from '@angular/router';
import { BptGridCellValueChangedEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-value-changed-event.interface';
import { BptGridRowsAddedEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-rows-added-event.interface';
import { BptGridValuesPastedEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-values-pasted-event.interface';
import { ClientValidationDetails } from 'model/client-validation-details';
import { User } from 'model/user.interface';
import { Subject, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ClientStateService } from 'services/client-state.service';
import { UserService } from 'services/user.service';
import { BaseComponent } from '../../../../app/base/base.component';
import { TableService } from '../../../api/data-entry/services';
import {
  Activity,
  ColumnSpecification,
  Experiment,
  Module,
  SpecificationType,
  SpecificationValue,
  Table,
  TableValueRow
} from 'model/experiment.interface';
import {
  BptGridComponent,
  BptGridPreferences,
  CellObjectCallback,
  CellPasteObjectCallback,
  ColumnDefinition,
  ColumnType,
  DropdownCellEditorParamsDefaults,
  EditorComponentNames,
  FrameworkComponents,
  GridContextMenuItem,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated,
} from 'bpt-ui-library/bpt-grid';
import { DataRecordService } from '../../services/data-record.service';
import { Logger } from 'services/logger.service';
import {
  AddRowEventNotification,
  CellChangedEventNotification,
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  ExperimentDataRecordNotification,
  ExperimentEventType,
  Row,
  AddRowResponse,
  Cell,
  ChangeCellCommand,
  AddRowsCommand,
  ValueType,
  RowRemovedEventNotification,
  RowRestoredEventNotification,
  RowsRenumberedEventNotification,
  StatementAppliedEventNotification,
  StatementContentDetails,
  TableCell,
  RowsAddedForEachEventNotification,
  AddRowsForEachResponse,
} from '../../../api/data-entry/models';
import {
  ClientFacingNoteContextType,
  ExperimentDataValue,
  ExperimentWorkflowState,
  NodeType,
  RuleEvents,
  ValueState,
  NumberValue,
  ColumnType as APIColumnType,
  SpecType,
  Unit,
  StringValue,
  KeyColumnType,
  SpecComparisonOperator,
  SingleValueSpec,
  InstrumentReadingValue,
  StatementContextType,
  ActivityInputNode,
  ExperimentDataSource
} from '../../../api/models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { cloneDeep, difference, first, forIn, isEqual, mapValues, omit, omitBy, remove, sortBy } from 'lodash-es';
import { DataValue, DataValueService, FieldOrColumnType, isSerializablyEqual } from '../../services/data-value.service';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import {
  CellDoubleClickedEvent,
  CellRange,
  Column,
  ColumnApi,
  EditableCallback,
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
  IRowNode,
  RowNode,
  RowPosition
} from 'ag-grid-community';
import { CellLock, LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ExperimentService, SpecificationEditorContext } from '../../services/experiment.service';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { ExperimentCollaboratorsService } from 'services/experiment-collaborators.service';
import { DataValidationsService } from '../../services/data-validations.service';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import {
  ShowClientFacingNotesEventData,
  TableCellClientFacingNoteContext
} from '../../comments/client-facing-note/client-facing-note-event.model';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { AuditHistoryDataRecordResponse, ExperimentRecordType } from '../../../api/audit/models';
import { ClientFacingNoteModel } from '../../comments/client-facing-note/client-facing-note.model';
import { RuleHandler } from '../../../rule-engine/rule-handler';
import {
  RuleActionNotificationService,
  SetValueNotificationEvent
} from '../../../rule-engine/action-notification/rule-action-notification.service';
import { RuleActionNotification } from '../../../rule-engine/actions/rule-action-notification';
import { RuleActionObjectResult } from '../../../rule-engine/actions/rule-action-result';
import {
  CommentContextType,
  CommentResponse,
  CommentsResponse,
  InternalCommentStatus
} from '../../../api/internal-comment/models';
import { AccessibilityTypes } from '../../../app.states';
import { CommentDetails } from '../../comments/comment.model';
import { CommentService } from '../../comments/comment.service';
import { MenuItem, MessageService, OverlayOptions } from 'primeng/api';
import { Keys, NA, Quantity } from 'bpt-ui-library/shared';
import { ISeverityIndicatorConfig } from 'bpt-ui-library/bpt-grid/model/column-definition.interface';
import { ExperimentNodeRetitleService } from '../../services/experiment-node-re-title.service';
import { ExperimentUserPreferenceService } from '../../services/experiment-user-preference.service';
import { UnitLoaderService } from '../../../services/unit-loader.service';
import { AddRowEventParams } from 'bpt-ui-library/bpt-grid/model/add-row-event-params.interface';
import { KeyColumnService } from '../../services/key-column.service';
import { CompletionTrackingService } from '../../../services/completion-tracking.services'
import { TableDataForCompletionTracking } from '../../model/cell-data-for-completion-tracking.interface';
import { SpecificationService } from '../../../shared/specification-input/specification.service';
import { NumericService } from 'bpt-ui-library/services';
import { FillWithNaGridHelper, NaGridTableIdentifierData, NaIdentifier } from '../../../services/fill-with-na-grid-helper';
import { ChangeRecipeCellCommand } from '../../../api/cookbook/models';
import { InstrumentConnectionHelper } from '../../instrument-connection/shared/instrument-connection-helper';
import { OverlayPanel } from 'primeng/overlaypanel';
import { InstrumentConfigUnits } from '../../instrument-connection/shared/instrument-config-units';
import { InstrumentType } from '../../instrument-connection/shared/instrument-type';
import { hasConsumedRepeatForEachPlaceholderRows, TableDataService } from './table-data.service';
import { SequentialReadingShiftModalService } from './sequential-reading-shift-modal.service';
import { SequentialReadingShiftModal } from './sequential-reading-shift-modal';
import { InstrumentConfigMethods } from '../../instrument-connection/shared/instrument-config-methods';
import { InstrumentNotificationService } from '../../instrument-connection/shared/instrument-notification-service';
import { SequentialReadingLockModal } from './sequential-reading-lock.modal';
import { PhMeterService } from '../../services/ph-meter.service';
import { InstrumentConfigurationKeys } from '../../instrument-connection/shared/instrument-configuration-keys';
import { PhMeterMode } from '../../model/instrument-connection/ph-meter-modes';
import { StatementsService } from '../../comments/statements/statements.service';
import { PathDetails, StatementModel } from '../../comments/statements/models/statement-model';
import { EllipsisMenuBuilderHelper } from '../../../shared/ellipsis-menu-builder-helper';
import { keyColumnField } from '../../../template-designer/column-properties/column-properties.component';
import { RecipeTableComponent } from '../../../recipe/data/table/recipe-table.component';
import { ChangeReasonService } from '../../services/change-reason.service';
import {
  RepeatGroupInputSelectorDialogData,
  RepeatGroupTableInputSelectorComponent
} from './repeat-for-each/repeat-group-table-input-selector/repeat-group-table-input-selector.component';
import { RepeatTargetModalComponent } from './repeat-for-each/repeat-target-modal/repeat-target-modal.component';
import { ExperimentRecordTypesHelper } from '../../services/experiment-data-record-types-helper';
import { InstrumentDetailsDto } from '../../../api/instrument-admin/models';

export type CellFullContext = {
  rowId: string;
  gridRowNode: IRowNode;
  tableRow: { [key: string]: ModifiableDataValue };
  columnDefinition: ColumnSpecification;
  fieldId: string;
  valid: boolean;
};

const missingGridIdMessage = 'Logic error: When a grid is used to render a table, gridId must be set to tableId but gridId is missing.';
const missingRowIdMessage = 'Logic error: When a grid creates a row, it must set rowId but rowId is missing.';
const missingFieldMessage = 'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing.';
const missingFieldNameMessage = 'Logic error: When a grid event has a cell value the field property must be set but is missing.';
const missingColumnMessage = 'Logic error: When a grid event has a cell value the field property must identify a column but its definition is missing.';

const rightClickButtonEventNumber = 2;
const rowIndexField = 'rowIndex';
const rowNumberText = $localize`:@@RowNumber:Row Number`;

type CellChange = {
  source: string | undefined;
  gridId: string;
  rowId: string;
  row: TableValueRow;
  column: ColumnSpecification;
  field: string;
  newValue: ExperimentDataValue;
  oldValue: ExperimentDataValue;
};

/** Policies to optionally exclude, when needed, from a cell editability determination. */
type CellEditablePolicies = {
  balanceReadingDialogPolicy?: boolean;
  phReadingDialogPolicy?: boolean;
  columnTypeSpecificationPolicy?: boolean;
  rowEditablePolicy?: boolean;
};

@Component({
  selector: 'app-data-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() table!: Table;
  @Input() greatestTabOrder!: number;
  @Input() parentNodeId: string[] = [];

  @ViewChild('Grid') grid!: BptGridComponent;
  @ViewChild('op') op!: OverlayPanel;

  public collaborativeEditSuppressKeys = ['Tab', 'Enter', 'Escape', 'ArrowRight', 'ArrowUp', 'ArrowLeft', 'ArrowDown'];
  readonly emptyString: StringValue = { type: ValueType.String, state: ValueState.Empty };
  readonly naString: StringValue = { type: ValueType.String, state: ValueState.NotApplicable };
  readonly setString: (value: any) => StringValue = (value) => this.dataValueService.getExperimentDataValue(APIColumnType.String, value);
  readonly emptyQuantity = new Quantity(ValueState.Empty);

  referencesTableUrlPath = '/References';
  validation!: ClientValidationDetails;
  user!: User;
  isLoading = false;
  cell!: Cell;

  /** Apparently for white-box testing. Do not use state internally */
  addRowsCommand!: AddRowsCommand;

  copiedValue!: ChangeCellCommand;
  combinedReadOnly = false;
  experimentId!: string;
  itemTitle?: string;
  suppressContextMenu = false;
  displayEllipseMenu = false;
  numberOfRows = 0;
  columnDataFieldIds: string[] = [];
  menuItems: MenuItem[] = [];
  completionPercent = 0;
  canEditExperimentInReview!: boolean;

  /**
   * Indicates cells in column are editable at all during Setup (via containsObservableData). Quantity may have limitations per numberEditableInSetup.
   * Also see gridOptionsContext and isCellEditable for various other conditional limitations. @see editableInSetupChanged comments in column-properties.component.ts
   */
  editableDuringSetup: (column: { containsObservableData?: boolean }) => boolean = (column) => !(column.containsObservableData ?? true);

  allowAddRow = true;
  allowMultipleAddRow = true;
  experiment!: Experiment;
  items!: MenuItem[];
  currentContextMenuTableId!: string;
  private readonly subscriptions: Subscription[] = [];
  dynamicDialogRef?: DynamicDialogRef;
  lockTimeOut = 0;
  public pristineColumnDefinitions: ColumnDefinition[] = [];
  private readonly columnsToPopulateByAddRowResponse = ['rowIndex'];
  readonly backgroundColor = 'background-color';
  internalCommentData?: CommentDetails;
  samples: { label: string, value: string }[] = [];
  materials: { label: string, value: string }[] = [];
  preparations: { label: string, value: string }[] = [];
  readonly cellObjectCallbacks: { [colType: string]: CellObjectCallback };
  readonly cellPasteObjectCallbacks: { [colType: string]: CellPasteObjectCallback };
  readonly referencesParentTitle = $localize`:@@references:References`;
  readonly clientFacingNotes = $localize`:@@clientFacingNoteHeader:Client-facing Notes`;
  overlayVisibleBalance = false;
  isSequentialReadingInProgress = false;
  /**
   * if in progress, we started when sequential readings were enabled, so they are still enabled until no longer in progress.
   */
  get sequentialReadingEnabled(): boolean {
    return this.isSequentialReadingInProgress || this.sequentialReadingsSupportedForAllSelectedCells();
  }
  overlayColumn?: string;
  overlayRow?: string;
  selectedColumn!: ColumnDefinition;
  unitIdForBalance?: string;
  sequentialReadingsLocker: SequentialReadingLockModal[] = [];
  columnUnitsForReading: Unit[] = [];

  /**
   * 1 - This reserved the tab order index for table. currently it reserves for audit history & count defined in BPT Grid.
   * This count needs to increase when ever a new options are introduced to table from ELN side.
   */
  public static readonly TabOrderReservationCount = 1 + BptGridComponent.TabOrderReservationCount;

  maxNumericValue: number | undefined;
  minNumericValue: number | undefined;
  overlayCellEditable: boolean | EditableCallback | undefined;
  /** Row has a specification compatible with an instrument reading to be shown on the reading dialog. See targetValue. */
  showTarget = true;
  /** Target to be shown on a reading dialog. See showTarget */
  targetValue!: NumberValue;
  isUnitMvAllowed = false;
  isSelfWitnessing = false;

  /** node id of parent's parent */
  get activityId(): string {
    return this.parentNodeId[0];
  };
  /** node id of parent */
  get moduleId(): string {
    return this.parentNodeId[1];
  }

  /** columnDefinitions for bpt-grid, which are slightly different than the columnDefinitions of the Table. */
  get columnDefinitions(): ColumnDefinition[] {
    return this.table.columnDefinitions as ColumnDefinition[];
  }

  /** value of table in a format and type compatible with the cell editors in this component's usage of bpt-grid */
  primitiveValue!: { [key: string]: any }[];

  /** primitiveValue with rows marked as removed filtered out */
  get nonRemovedData(): { [key: string]: any }[] {
    return this.primitiveValue?.filter((row: { [key: string]: any }) => !TableDataService.rowIsRemoved(row)) ?? [];
  }

  /** fields from column definitions in this component's usage of bpt-grid. (Not "names". Not necessarily fields in the table rows.) */
  get fields(): string[] {
    return this.columnDefinitions.map(c => c.field).filter((f): f is string => !!f);
  }

  get AdditionalCountOfTabOrderReservation(): number {
    return TableComponent.TabOrderReservationCount;
  }

  public get RuleHandler(): RuleHandler {
    return this._ruleHandler;
  }

  get isInstrumentConnected(): boolean {
    return this.instrumentConnectionHelper.isInstrumentConnected;
  }

  get isInstrumentConnectionAvailable(): boolean {
    return this.instrumentConnectionHelper.isInstrumentConnectionAvailable;
  }

  private isValid(x: any): boolean {
    return x !== undefined && x !== null && x !== '';
  }

  /**
   * Check whether to apply Special Steps Table powers.
   * Not implemented in recipes because it only applies when editing observable data.
   */
  public get isStepsTable(): boolean {
    return Object.keys(this.experiment.variablesNode.variables)
      .filter(key => this.experiment.variablesNode.variables[key].nodeId === this.table.nodeId)
      .some(key => key === `steps:${this.table.nodeId}`);
  }

  get containsRemovedRows(): boolean {
    return this.table.value.some(TableDataService.rowIsRemoved);
  }

  get canRepeatForEach(): boolean {
    const hasRepeatForEach = this.table.value.some(TableDataService.rowHasARepeatForEachSetting);
    return [ExperimentWorkflowState.Setup, ExperimentWorkflowState.InProgress].includes(this.experiment.workflowState)
      && hasRepeatForEach && !hasConsumedRepeatForEachPlaceholderRows(this.table);
  }

  private _ruleHandler!: RuleHandler;
  retitleEnabled = false;
  nodeType = NodeType;
  gridPaginationOverlayOptions: OverlayOptions = {
    appendTo: 'body',
    baseZIndex: 2000
  };
  setCollaboratorForCurrentWindow = false;

  /** 2 - pH Integration Related Properties
  * These properties are related to the pH measurement functionality in the table component.
  * The properties manage connection status and UI elements related to pH measurements.
  */
  overlayVisiblePh = false;
  fillWithNATriggeredForCurrentModule = false;

  /**
   * Sole, permanent object for this.grid.gridOptions.context.
   * Note: it won't be attached to grid until the grid is ready. But, values can be set before that.
   */
  private readonly gridOptionsContext = {
    /** Column is editable in experiment. key: column.field. */
    editableInExperiment: new Map<string, boolean>(),
    /** Column is set up for balance reading. key: column.field */
    setUpForBalanceReading: new Map<string, boolean | undefined>(),
    /** Column is set up for pH reading. key: column.field */
    setUpForPhReading: new Map<string, boolean | undefined>(),
    /** Cell is locked by collaborative editing. key is the string sent in the lock */
    locked: new Map<string, boolean | undefined>(),
  }

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }

  constructor(
    @Inject('ExperimentTableDataService') private readonly tableDataService: TableDataService<'experiment'>,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly commentService: CommentService,
    private readonly dataRecordService: DataRecordService,
    private readonly dataValidationsService: DataValidationsService,
    private readonly dataValueService: DataValueService,
    private readonly dialogService: DialogService,
    private readonly elementRef: ElementRef,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly experimentUserPreferenceService: ExperimentUserPreferenceService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly fillWithNaGridHelper: FillWithNaGridHelper,
    private readonly ellipsisMenuBuilderHelper: EllipsisMenuBuilderHelper,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly logger: Logger,
    private readonly numericService: NumericService,
    private readonly pHmeterService: PhMeterService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly specificationService: SpecificationService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly userService: UserService,
    public readonly clientStateService: ClientStateService,
    public readonly completionTrackingService: CompletionTrackingService,
    public readonly instrumentNotificationService: InstrumentNotificationService,
    public readonly keyColumnService: KeyColumnService,
    public readonly messageService: MessageService,
    public readonly tableService: TableService,
    route: ActivatedRoute,
    public readonly statementsService: StatementsService
  ) {
    super(clientStateService, route);
    this.cellObjectCallbacks = {
      specification: (_rawData, rowDefinition, columnDefinition) => {
        const row = this.table.value.find(row => row.id === rowDefinition.id);
        if (!row) throw new Error('Row ID not in table ' + rowDefinition.id);
        if (!columnDefinition.field) throw new Error('Field not not defined for column');

        const value = row[columnDefinition.field].value;
        return { ...value, toString: () => this.specificationService.getDisplayString(value) };
      }
    };
    this.cellPasteObjectCallbacks = this.getCellPasteObjectCallbacks();
    this.validation = new ClientValidationDetails();
    this.watchRuleActions();
    this.subscriptions.push(
      this.instrumentConnectionHelper.goNextClicked.subscribe((selectedMethod: InstrumentConfigMethods) => {
        this.instrumentConnectionHelper.selectedMethod = selectedMethod;
      }),
      this.dataRecordService.addRowsDataRecordReceiver.subscribe(data => this.applyAddRowsDataRecord(data)),
      this.dataRecordService.rowsAddedForEachDataRecordReceiver.subscribe(data => this.applyRowsAddedForEachDataRecord(data)),
      this.experimentService.experimentWorkFlowState.subscribe(() => this.onWorkflowStateChange()),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe(() => this.onWorkflowStateChange()),
      this.dataRecordService.rowRemovedDataRecordReceiver.subscribe(data => this.applyRowRemovedDataRecord(data)),
      this.dataRecordService.rowRestoredDataRecordReceiver.subscribe(data => this.applyRowRestoredDataRecord(data)),
      this.dataRecordService.cellChangedDataRecordReceiver.subscribe(data => this.applyCellChangedDataRecord(data)),
      this.experimentNotificationService.inputLockReceiver.subscribe(lock => this.applyCellLock(lock)),
      this.experimentService.clientFacingNoteEvents.subscribe(note => this.onClientFacingNoteEvent(note)),
      this.statementsService.statementAdded.subscribe((statementModel) => this.onStatementsAdded(statementModel)),
      this.keyColumnService.samples$.subscribe(samples => {
        this.samples = this.sortData(samples);
        this.updateKeyColumn();
      }),
      this.keyColumnService.materials$.subscribe(materials => {
        this.materials = this.sortData(materials);
        this.updateKeyColumn();
      }),
      this.keyColumnService.preparations$.subscribe(preparations => {
        this.preparations = this.sortData(preparations);
        this.updateKeyColumn();
      }),
      this.instrumentConnectionHelper.instrumentConnectionSuccess.subscribe(() => {
        this.instrumentConnectionHelper.setInstrumentConnectedStatus(true);
      }),
      this.instrumentConnectionHelper.instrumentDisconnectedSuccessfully.subscribe(() => {
        if (this.sequentialReadingEnabled) {
          this.closeOverlay();
        }
        this.instrumentConnectionHelper.instrumentType = undefined;
      }),
      this.instrumentConnectionHelper.phMeterDisconnectedSuccessfully.subscribe(() => {
        if (this.isSequentialReadingInProgress) {
          this.closeOverlay();
        }
        this.instrumentConnectionHelper.instrumentType = undefined;
      }),
      this.tableDataService.rowRemoved.subscribe(() => this.refreshDataSource()),
      this.tableDataService.rowRestored.subscribe(() => this.refreshDataSource()),
      this.dataRecordService.rowsRenumberedDataRecordReceiver.subscribe(renumbered => this.handleRowRenumber(renumbered)),
      this.watchSequentialReadingSessionTermination(),
      this.experimentService.changeReasonSliderDisplayDetails.subscribe({
        next: this.refreshDataSource.bind(this)
      }),
    );
    this.watchCellValueChange();
    this.watchExperimentWorkflowStateChanges();
  }

  private onStatementsAdded(statementModel: StatementAppliedEventNotification) {
    statementModel.contentDetails.forEach(element => {
      const context = element.path;
      if (!context) return;
      const rowId = context[0];
      const col = context[1];
      if (statementModel.nodeId !== this.table.tableId) return;
      this.refreshCell(rowId, col, true);
    });
  }

  private onWorkflowStateChange() {
    this.setRepeatColumnHiddenState();
    this.refreshDataSource();
  }

  private getCellPasteObjectCallbacks(): { [colType: string]: CellPasteObjectCallback } {
    return {
      specification: (clipboardValue, oldValue, row, column) => {
        //making changes to source to prevent recipe styling to be replicated when recipe data is copy pasted
        if (clipboardValue.type === ValueType.Specification && clipboardValue?.source === ExperimentDataSource.Recipe) {
          clipboardValue.source = undefined;
        }
        if (!column.field || !row.id) throw new Error("LOGIC ERROR Something is very wrong if we don't have both column.field and row.id");
        const readOnly = !this.isCellEditableImpl(column.field, column, row.id, { columnTypeSpecificationPolicy: false });
        if (readOnly) return undefined;
        if (clipboardValue === NA) return { cellValue: NA, eventValue: { type: ValueType.Specification, state: ValueState.NotApplicable } };

        const couldBeSpec = clipboardValue.type === ValueType.Specification || !clipboardValue || clipboardValue === NA;
        if (!couldBeSpec) {
          // Give wrong value so we get a second chance for right value
          return { cellValue: oldValue, eventValue: oldValue };
        }

        const columnSpec = this.columnDefinitions.find(c => c.field === column.field) as ColumnSpecification | undefined;
        if (!columnSpec) throw new Error('Could not find ColumnSpecification');

        if (clipboardValue.specType && !columnSpec?.allowedSpecTypes?.includes(clipboardValue.specType)) return undefined;
        if (!this.unitsAreAllowed(clipboardValue, columnSpec.allowedUnits)) return undefined;

        const cellValue = clipboardValue
          ? this.specificationService.getDisplayString(clipboardValue)
          : undefined;

        return { cellValue, eventValue: clipboardValue };
      }
    };
  }

  private unitsAreAllowed(clipboardValue: any, allowedUnits: Unit[] | undefined): boolean {
    switch (clipboardValue.specType) {
      case SpecType.SingleValue:
      case SpecType.SingleValueRange:
        if (!allowedUnits?.some(u => u.id === clipboardValue.value?.unit)) return false;
        break;
      case SpecType.TwoValueRange:
        if (!allowedUnits?.some(u => u.id === clipboardValue.lowerValue?.unit)) return false;
        if (!allowedUnits?.some(u => u.id === clipboardValue.upperValue?.unit)) return false;
        break;
    }
    return true;
  }

  private handleRowRenumber(renumbered: RowsRenumberedEventNotification) {
    if (this.table.tableId !== renumbered.tableId) return;
    this.refreshDataSource();
  }

  private applyRowRemovedDataRecord(data: RowRemovedEventNotification): void {
    if (data.tableId !== this.table.tableId) return;

    const row = this.table.value.find(r => r.id === data.rowId);
    if (!row) {
      this.logger.logWarning(`rowRemovedDataRecordReceiver with tableId ${data.tableId} rowId ${data.rowId} doesn't match table locally`);
      // shouldn't matter: could happen if row removed is received/processed before row added or similar
      return;
    }

    this.refreshDataSource();
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@rowRemovedMessage:Row ${(row.rowIndex.value as NumberValue).value} Removed Successfully`,
      detail: $localize`:@@byTheUser:by the user ${data.eventContext.puid}`
    });
  }

  private applyRowRestoredDataRecord(data: RowRestoredEventNotification): void {
    if (data.tableId !== this.table.tableId) return;

    const row = this.table.value.find(r => r.id === data.rowId);
    if (!row) {
      this.logger.logWarning(`applyRowRestoredDataRecord with tableId ${data.tableId} rowId ${data.rowId} doesn't match table locally`);
      // shouldn't matter: could happen if row restored is received/processed before row added or similar
      return;
    }

    this.refreshDataSource();
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@rowRestoredMessage:Row ${(row.rowIndex.value as NumberValue).value} Restored Successfully`,
      detail: $localize`:@@byTheUser:by the user ${data.eventContext.puid}`
    });
  }

  private watchSequentialReadingSessionTermination() {
    return this.tableDataService.terminateSequentialReadingSession.subscribe((result: boolean) => {
      if (result) {
        this.isSequentialReadingInProgress = false;
      }
    });
  }

  private isPhMeterActiveTab() {
    let isPhMeterActiveExperiment = this.pHmeterService.getPhMeterActiveTab();
    if (isPhMeterActiveExperiment === undefined) {
      this.instrumentConnectionHelper.detectInstrumentConnectionChanged.next(true);
    }
    isPhMeterActiveExperiment = this.pHmeterService.getPhMeterActiveTab();
    return isPhMeterActiveExperiment && +isPhMeterActiveExperiment;
  }

  ngOnDestroy(): void {
    this.dynamicDialogRef?.close();
    UnsubscribeAll(this.subscriptions);
  }

  onContextMenu() {
    this.currentContextMenuTableId = this.table.tableId;
  }

  onDoubleClick() {
    if (this.userService.hasOnlyReviewerRights() ||
      this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview) {
      return;
    }
    this.retitleEnabled = true;
  }

  ngOnInit(): void {
    if (this.parentNodeId?.length !== 2 || !this.parentNodeId[0] || !this.parentNodeId[1]) {
      throw new Error('LOGIC ERROR: parentNodeId must be an array of [ activityId, moduleId ]');
    };
    this.setModuleId(this.parentNodeId[1]);
    this.watchTablePreferences();
    this.buildContextMenuItems();
    this.buildEllipseMenuItem();
    this.watchFillWithNaTrigger();
    this.initializeRuleHandler();
    this.user = { ...this.userService.currentUser };
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.experimentId = this.table.experimentId;
    this.columnDefinitions.push(RecipeTableComponent.createRepeatColumn());
    this.setColumnProperties();
    this.populateKeyColumn();
    this.itemTitle = this.table.itemTitle;
    this.updateFeatureFlags();
    this.setPermissionsBasedOnWorkflowState(this.experiment.workflowState);
    this.setPermissionsBasedOnRoles();
    this.readOnly = this.clientStateService.getClientStateVisibility(this.clientState) !== AccessibilityTypes.ReadWrite;
    this.combinedReadOnly = this.readOnly || this.table.allowReadOnly;
    /*
     * Tom:
     * But why should one column definition saying suppress context menu cause it to be suppressed for the whole grid?
     * Is this a design flaw in the column definitions and/or bpt-grid? Please explain in a code comment.
     *
     * Garima:
     * Yes, it is a design flaw which is already discussed with Chris,
     * we will be moving the SuppressContextMenu to table properties as part of next PBI
    */
    this.suppressContextMenu = this.table.columnDefinitions?.some(c => c.suppressContextMenu) ?? false;
    this.pristineColumnDefinitions = JSON.parse(JSON.stringify(this.columnDefinitions));
    this.refreshDataSource();
    this.numberOfRows = this.table.value.length;
    // Note: for c.field template validation prevents undefined
    this.columnDataFieldIds = this.columnDefinitions.map((c: ColumnDefinition) => c.field as string) ?? [];
    this.trackCompletion();
    this.internalCommentsChanged();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.table.tableId);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.table.itemTitle);
  }

  /**
   * Processes value that's targeted for a step value cell to determine whether to paste and what.
   * Note: Called once for text format from clipboard and possibly again for the custom BPT clipboard formatted. (Yeah, we can't see what's on the clipboard the first time.)
   * @returns undefined if pasting is rejected; otherwise the primitive cell value and the change event value.
   */
  private processStepValueFromClipboard(clipboardValue: any, stepType: string | undefined): ReturnType<CellPasteObjectCallback> {
    switch (stepType) {
      case NA: return this.processStepValueForNaStepType(clipboardValue);
      case 'Instruction': return this.processStepValueForInstructionStepType(clipboardValue);
      case 'Observation': return this.processStepValueForObservationStepType(clipboardValue);
      case 'Measurement': return this.pasteStepValueForMeasurementStepType(clipboardValue);
      default: return this.processStepValueForEmptyStepType(clipboardValue);
    }
  }

  private processStepValueForEmptyStepType(clipboardValue: any): ReturnType<CellPasteObjectCallback> {
    return this.processStepValueForNaStepType(clipboardValue);
  }

  private processStepValueForNaStepType(clipboardValue: any): ReturnType<CellPasteObjectCallback> {
    if (!this.isValid(clipboardValue)) return { cellValue: undefined, eventValue: undefined };
    if (clipboardValue?.state === ValueState.Empty) return { cellValue: undefined, eventValue: undefined };
    if (clipboardValue === NA) return { cellValue: NA, eventValue: NA };
    if (clipboardValue?.state === ValueState.NotApplicable) return { cellValue: NA, eventValue: NA };

    return undefined; // don't paste into the current cell; leave it.
  }

  private processStepValueForObservationStepType(clipboardValue: any): ReturnType<CellPasteObjectCallback> {
    if (!this.isValid(clipboardValue)) return { cellValue: undefined, eventValue: undefined };
    if (clipboardValue?.state === ValueState.Empty) return { cellValue: undefined, eventValue: undefined };
    if (clipboardValue === NA) return { cellValue: NA, eventValue: NA };
    if (clipboardValue?.state === ValueState.NotApplicable) return { cellValue: NA, eventValue: NA };

    if (typeof clipboardValue === 'string') {
      return { cellValue: clipboardValue, eventValue: clipboardValue };
    }

    return undefined;
  }

  private parseAndValidateAsQuantity(clipboardValue: any): Quantity | undefined {
    if (typeof clipboardValue !== 'string') return undefined;

    // Note: parseQuantity assumes the string is parsable so can't be used by itself.
    // Need to reject garbage: anything not matching optional number then optional space then optional unit.
    const parts = this.numericService.splitNumberAndUnit(clipboardValue);
    const parsable = parts[0] !== undefined || parts[1] !== undefined; // has number and/or supposed unit
    if (!parsable) return undefined;

    return this.numericService.parseQuantity(clipboardValue, this.unitLoaderService.allUnits);
  }

  private pasteStepValueForMeasurementStepType(clipboardValue: any): ReturnType<CellPasteObjectCallback> {
    if (!this.isValid(clipboardValue)) return { cellValue: this.emptyQuantity, eventValue: this.emptyQuantity };

    if (clipboardValue === NA) return { cellValue: new Quantity(ValueState.NotApplicable), eventValue: new Quantity(ValueState.NotApplicable) };

    const stringQuantity = this.parseAndValidateAsQuantity(clipboardValue);
    if (stringQuantity) return { cellValue: stringQuantity, eventValue: stringQuantity.valueOf() };

    if (clipboardValue?.type === ValueType.Number) {
      if (clipboardValue.state === ValueState.Set && clipboardValue.unitDetails?.id) { // a "bptValue" (passed by 2nd processing) will have a Quantity.toJson() structure
        if (!this.unitLoaderService.allUnits.find(u => u.id === clipboardValue.unit)) return undefined;
        const quantity = new Quantity(clipboardValue.state, clipboardValue.value, clipboardValue.unitDetails, clipboardValue.sigFigs, clipboardValue.exact);
        return quantity ? { cellValue: quantity, eventValue: quantity.valueOf() } : undefined;
      }
      return { cellValue: clipboardValue, eventValue: clipboardValue };
    }
    return undefined;
  }

  private processStepValueForInstructionStepType(clipboardValue: any): ReturnType<CellPasteObjectCallback> {
    if (!this.isValid(clipboardValue)) return { cellValue: undefined, eventValue: undefined };
    if (clipboardValue?.state === ValueState.Empty) return { cellValue: undefined, eventValue: undefined };

    if (clipboardValue === NA) return { cellValue: NA, eventValue: NA };
    if (clipboardValue?.state === ValueState.NotApplicable) return { cellValue: NA, eventValue: NA };

    return clipboardValue === 'Completed' ? { cellValue: clipboardValue, eventValue: clipboardValue } : undefined;
  }

  /**
   * "Re"-Implementation of "normal" behavior for pasting into a string column, which is needed due to use of cellPasteObjectCallbacks on ColumnType.String
   */
  private processValueForStringColumn(clipboardValue: any) {
    const value = (clipboardValue ?? '').toString().trim() || undefined;
    return { cellValue: value, eventValue: value };
  }

  /**
     * Sets up special hard-coded behavior for a table _known_ to be a "Steps Table".
     * These are small specializations from TableComponent's general usage:
     *   * dynamicEditor for Step Value column based on Step Type value in same row.
     *   * set up callback for Step Value pasting
     * Not implemented in recipes because it only applies when editing observable data.
  */
  private setUpStepsTable() {
    const targetColumn = this.columnDefinitions.find((colDef) => colDef.field === 'StepType');
    const dynamicColumn = this.columnDefinitions.find((colDef) => colDef.field === 'Value');

    if (targetColumn && dynamicColumn?.columnType) {
      this.grid.cellPasteObjectCallbacks ??= {};
      this.grid.cellPasteObjectCallbacks[dynamicColumn.columnType] = (clipboardValue: any, _oldValue: any, row: IRowNode, column: ColumnDefinition) => {
        if (column.field === 'Value') return this.processStepValueFromClipboard(clipboardValue, row.data.StepType ?? undefined);
        return this.processValueForStringColumn(clipboardValue);
      };

      dynamicColumn.cellClass = 'dynamic-cell-icon';
      dynamicColumn.dynamicEditor = {
        targetColumn,
        deferredEditor: value => {
          switch (value) {
            case 'Instruction':
              return { component: FrameworkComponents.Editors[EditorComponentNames.dropdownEditor], params: { options: ['Completed', NA] }, hideDropdownIndicator: false };
            case 'Measurement':
              return {
                component: FrameworkComponents.Editors[EditorComponentNames.quantityEditor],
                params: { allowedUnits: this.unitLoaderService.allUnits, noUnit: false, allowNA: true, allowNegative: true, allowDecimal: true },
                hideDropdownIndicator: false
              };
            case 'Observation':
              return { component: FrameworkComponents.Editors[EditorComponentNames.addRowsGenericEditor], params: {}, hideDropdownIndicator: true };
            case NA:
              return { component: FrameworkComponents.Editors[EditorComponentNames.addRowsGenericEditor], params: {}, hideDropdownIndicator: true };
            default:
              return { component: FrameworkComponents.Editors[EditorComponentNames.readonlyEditor], params: {}, hideDropdownIndicator: true };
          }
        }
      };
      this.grid.updateColumnDefinitions(dynamicColumn);
    }
  }

  setModuleId(moduleId: string) {
    this.fillWithNaGridHelper.currentModuleId = moduleId;
  }

  private buildEllipseMenuItem() {
    this.menuItems = this.ellipsisMenuBuilderHelper.buildEllipseMenuItem(this.table.tableId, true, true);
    this.displayEllipseMenu = this.menuItems.length > 0 && !this.isCrossReference(this.table.tableId, this.experimentService.currentActivity);
  }

  loadInternalCommentForTableTitleLevel() {
    const activity = this.experiment.activities.find(
      (act) => act.activityId === this.experimentService.currentActivityId
    );
    const module = (activity?.dataModules ?? []).find(
      (mod) => mod.moduleId === this.moduleId
    );
    if (this.router.url.endsWith(this.referencesTableUrlPath)) {
      const referenceContext = activity?.activityReferences.compendiaReferencesTableId === this.currentContextMenuTableId ?
        CommentContextType.Compendia : CommentContextType.Documents;
      this.openInternalComments(
        this.experiment.id,
        [activity?.activityId, this.currentContextMenuTableId, referenceContext],
        CommentContextType.Table
      );
    } else {
      this.openInternalComments(
        this.experiment.id,
        [activity?.activityId, module?.moduleId, this.currentContextMenuTableId, CommentContextType.Module],
        CommentContextType.Table
      );
    }
  }

  openInternalComments(nodeId: string, path: any, contextType: CommentContextType) {
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  private initializeRuleHandler(): void {
    this._ruleHandler = new RuleHandler(
      this.table.tableId,
      this.table.templateId,
      this.table.rules || [],
      this.ruleActionNotificationService
    );
  }

  private watchExperimentWorkflowStateChanges(): void {
    this.subscriptions.push(
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        this.setPermissionsBasedOnWorkflowState(data.state);
        this.buildContextMenuItems();
      })
    );
    this.subscriptions.push(this.fillWithNaGridHelper.fillWithNATriggeredForModule.subscribe((moduleId: string) => {
      if (moduleId === this.moduleId) {
        this.fillWithNATriggeredForCurrentModule = true;
        this.fillGridWithNA(true);
      }
    }));
    this.subscriptions.push(this.fillWithNaGridHelper.forceFillWithNATriggeredForModule.subscribe((moduleId: string) => {
      if (moduleId === this.moduleId) {
        this.fillWithNATriggeredForCurrentModule = true;
        this.fillGridWithNA();
      }
    }));
    this.subscriptions.push(
      this.experimentService.experimentWorkFlowState.subscribe((experimentWorkflowState) => {
        this.setPermissionsBasedOnWorkflowState(experimentWorkflowState);
        this.buildContextMenuItems();
        this.buildEllipseMenuItem();
      })
    );
  }

  private watchCellValueChange(): void {
    this.subscriptions.push(
      this.tableDataService.actualValueAdded.subscribe((data) => {
        this.onInstrumentReadingsReceived(data, true);
        SequentialReadingShiftModalService.shiftModalToNextCell();
      })
    );
  }

  private watchFillWithNaTrigger() {
    this.subscriptions.push(
      this.fillWithNaGridHelper.fillWithNAOnTable.subscribe({
        next: (naIdentifier: NaIdentifier) => {
          this.fillWithNaOnCurrentTable(naIdentifier);
        }
      })
    );
  }

  fillWithNaOnCurrentTable(naIdentifier: NaIdentifier) {
    if (this.table.tableId === naIdentifier.id) {
      this.fillGridWithNA(naIdentifier.isEmpty);
    }
  }

  loadCellLocks() {
    const cellLocks = this.experimentNotificationService.inputLocks.filter(
      item => item.lockType === LockType.lock && (item as CellLock).tableId === this.table.tableId
    );

    this.applyCellLock(cellLocks as CellLock[]);
  }

  private watchTablePreferences() {
    this.table.preferencesReady?.subscribe({
      next: this.applySavedPreferences.bind(this)
    });
  }

  private applySavedPreferences(savedPreferences?: BptGridPreferences) {
    if (this.grid && savedPreferences && this.numberOfRows > 0) {
      this.grid.savedPreferences = savedPreferences;
      this.grid.applyPreviousSelectedPreference();
    }
  }

  saveNewPreference($event: PreferenceAdded) {
    this.experimentUserPreferenceService.saveNewPreference(
      $event,
      this.table.savedPreferences ?? { enabled: true, preferences: [] },
      this.grid,
      this.table.tableId
    );
  }

  deletePreference($event: PreferenceDeleted) {
    this.experimentUserPreferenceService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated) {
    this.experimentUserPreferenceService.updatePreference($event, this.table.tableId);
  }

  changeDefaultPreference($event: PreferenceSelectionChanged) {
    this.experimentUserPreferenceService.changeDefaultPreference($event, this.table.tableId);
  }

  private applyCellLock(lockList: CellLock[]) {
    lockList.forEach(lock => {
      if (this.table.tableId === lock.tableId) {
        this.gridOptionsContext.locked.set(lock.key, lock.lockType === LockType.lock);

        const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
          lock.experimentCollaborator.connectionId
        );
        lock.experimentCollaborator = lockOwner ?? lock.experimentCollaborator;
        const cell = document.querySelector(
          `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"]`
        );
        if (lock.lockType === LockType.lock) {
          const name = lockOwner?.fullName ?? lock.experimentCollaborator.firstName;
          const borderColor = lockOwner?.backgroundColor ?? 'blue';
          this.renderer.setAttribute(cell, 'title', name);
          this.renderer.setAttribute(cell, 'locked', LockType.lock);
          this.renderer.setStyle(cell, this.backgroundColor, '#F9F9F9');
          this.renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
        } else {
          this.renderer.removeStyle(cell, 'border');
          this.renderer.removeStyle(cell, this.backgroundColor);
          this.renderer.removeAttribute(cell, 'title');
          this.renderer.removeAttribute(cell, 'locked');
        }
      }
    });
  }

  private applyAddRowsDataRecord(data: AddRowEventNotification) {
    if (data.rows && data.tableId === this.table.tableId) {
      const mapValue: (value: any) => ModifiableDataValue = (value) => ({
        isModified: false,
        value
      });
      const newRows = data.rows.map((r: any) => ({
        id: r.id,
        ...mapValues(this.unpackRowData(r), mapValue)
      }));

      if (newRows.length > 0 && this.grid.gridApi.getRowNode(newRows[0].id)) return;

      const result = this.grid.gridApi.applyTransaction({
        add: this.tableDataService.getPrimitiveDataValueRows({ ...this, table: { ...this.table, value: newRows } })
      });
      if (result) {
        const addedData = result.add.map(a => a.data);
        this.grid.dataSource.push(...addedData);
        this.grid.gridApi.flashCells({ rowNodes: [...result.add] });
        this.table.value.push(...newRows);
        this.numberOfRows += newRows.length;
        this.trackCompletion();
        this.grid.gridApi.refreshClientSideRowModel('sort');
      } else {
        this.logger.logErrorMessage(
          `Applying Add Rows data on table ${data.tableId} record failed.`
        );
      }
    }
  }

  private applyRowsAddedForEachDataRecord(data: RowsAddedForEachEventNotification) {
    this.refreshDataSource();
    const newRowsIds = data.tables.flatMap(t => t.rows.map(r => r.rowId));
    const rowNodes = newRowsIds.map(r => this.grid.gridApi.getRowNode(r)).filter((n): n is IRowNode => !!n);
    this.grid.gridApi.flashCells({ rowNodes });
    this.trackCompletion();
  }

  private unpackRowData(row: Row): { [key: string]: ExperimentDataValue } {
    const dataValues: { [key: string]: ExperimentDataValue } = {};
    row.data.forEach((cell) => {
      dataValues[cell.propertyName] = cell.propertyValue;
    });
    return dataValues;
  }

  onGridReady() {
    this.grid.gridOptions.context = this.gridOptionsContext;
    this.setRepeatColumnHiddenState();
    if (this.isStepsTable) { //Not implemented in recipes because it only applies when editing observable data.
      this.setUpStepsTable();
    }
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.setValidationStyles();

    const colDefs = this.grid.colDefs;
    colDefs.forEach(colDef => {
      const original = colDef.suppressKeyboardEvent;
      colDef.suppressKeyboardEvent = (params) => {
        // if not editing with with a cell-editor and delete key is pressed, say we handled it if we don't want it deleted
        if (!params.editing && params.event.code === Keys.DELETE.key && TableDataService.rowIsPlaceholder(params.data)) return true;

        if (!original) return false; // declared default interpretation
        return original(params);
      }
    });
    this.grid.colDefs = colDefs; // reprocess to see new suppressKeyboardEvent functions

    this.grid.gridApi.setColumnsPinned(this.table.columnDefaults.leftPinnedColumns, 'left');
    this.grid.gridApi.setColumnsPinned(this.table.columnDefaults.rightPinnedColumns, 'right');
    this.grid.gridApi.setRowGroupColumns(this.table.columnDefaults.groupedColumns);
    this.experimentService.nodeCompletionStatus.next({
      title: this.table.itemTitle,
      id: this.table.tableId,
      isComplete: this.completionPercent === 100
    });
    this.applySavedPreferences(this.table.savedPreferences);
  }

  private setRepeatColumnHiddenState() {
    const consumedHidden = ![ExperimentWorkflowState.Setup, ExperimentWorkflowState.InProgress].includes(this.experiment.workflowState)
      || hasConsumedRepeatForEachPlaceholderRows(this.table);
    TableDataService.setRepeatColumnHiddenState(this.table, this.grid, consumedHidden);
  }

  onFirstDataRendered(_e: any) {
    this.loadCellLocks();
  }

  private augmentColumnsWithCornerFlagProviderForCells(): void {
    // common provider for all corner flags, for all cells in this table.
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ) => {
      if (flag === 'top-right') {
        const { note, enabled } = this.canEnableCfnOrStatementFlag(rowId, field)
        return {
          enabled,
          color: ELNAppConstants.ClientFacingNoteFlagColor,
          hoverText: note?.indicatorText ?? '', // by policy for notes, empty is not allowed
          onClick: () => this.showClientFacingNotes(rowId, field)
        };
      } else if (flag === 'bottom-right') {
        const internalComments = this.experiment.internalComments?.comments.find(
          (c: CommentResponse) =>
            c.path.includes(rowId) &&
            c.path.includes(field) &&
            c.path.includes(this.table.tableId) && // canNOT depend on rowId being globally unique (see above)
            c.status !== InternalCommentStatus.Removed
        );
        return {
          enabled: !!internalComments,
          color: ELNAppConstants.InternalCommentFlagColor,
          hoverText: this.getHoverOverText(internalComments?.content),
          isHollow: internalComments?.status === InternalCommentStatus.Pending,
          onClick: () => this.tableDataService.showInternalComments(rowId, field, this.renderer, this.buildInternalComments.bind(this))
        };
      }
      // default. Future: could use the 'bottom-right' flag for, say, internal comments.
      return { enabled: false, color: 'orange', hoverText: 'not used today', onClick: () => { } };
    };

    this.columnDefinitions.forEach(
      (c: ColumnDefinition) => (c.flagConfigProvider = flagConfigProvider)
    );
  }

  public severityIndicatorConfig: () => ISeverityIndicatorConfig = () => ({
    getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
  });

  public setValidationStyles = (): void => {
    this.columnDefinitions.forEach((columnDefinition: ColumnDefinition) => {
      // columnDefinition.editable false ⇒ never. But columnDefinition.editable function ⇒ maybe.
      if (columnDefinition.editable) { // presumably the branching should be on never vs maybe
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfig;
        this.grid.updateColumnDefinitions(columnDefinition);
      }
    });
  };

  private canEnableCfnOrStatementFlag(rowId: string, field: string) {
    // canNOT depend on rowId being globally unique until this is done: PBI 3218251: ELN Tables - change Row ID to be globally unique
    const note = this.experiment.clientFacingNotes.find(
      n => isEqual(n.path, [rowId, field]) && n.nodeId === this.table.tableId
    );
    const statement = this.experiment.statements?.find(
      n => isEqual(n.path, [rowId, field]) && n.nodeId === this.table.tableId
    );
    const enabled = !!note || !!statement
    if (note) return { note, enabled }
    return { statement, enabled }
  }

  private readonly getSeverityIndicator = (params: ICellRendererParams) => {
    const changeReasonsNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    return this.dataValidationsService.getSeverityIndicatorDefinition(this.table.value, params, changeReasonsNode);
  }

  private applyCellChangedDataRecord(data: CellChangedEventNotification, skipFlash = false) {
    if (data.tableIds?.includes(this.table.tableId)) {
      const nodes: IRowNode[] = new Array();
      const columns: string[] = new Array();

      data.rowIds?.forEach((rowId: string) => {
        const gridRow = this.grid.gridApi.getRowNode(rowId);
        const tableRow = this.table.value.find((r) => r.id === rowId) as {
          [key: string]: ModifiableDataValue;
        }; // all relevant cells are ModifiableDataValue
        if (!gridRow || !tableRow) {
          const number = tableRow?.id?.value as NumberValue;
          // intent of CellChangedEvent is strange but test covers this case like it's normal.
          console.info(
            `Cell changed record does not apply to any row in this table. rowId: ${rowId}. tableId: ${this.table.tableId} ` +
            ` gridRow find result id: ${gridRow?.id} tableRow find result id: ${number?.value} `
          );
          return;
        }

        nodes.push(gridRow);
        data.columnValues?.forEach((column: Cell) => {
          const propertyValue = DataRecordService.getModifiableDataValue(
            column.propertyValue,
            tableRow[column.propertyName]
          );
          const columnType = this.table.columnDefinitions.find(c => c.field === column.propertyName)?.columnType;
          if (column.propertyName && columnType) {
            gridRow.setDataValue(
              column.propertyName,
              this.dataValueService.getPrimitiveValue(columnType, propertyValue),
              'collaborativeEdit'
            );
            columns.push(column.propertyName);
            tableRow[column.propertyName] = propertyValue;
            this.trackCompletion();
          }
        });
      });
      if (!skipFlash) this.grid.gridApi.flashCells({ rowNodes: nodes, columns });
    }
    this.trackCompletion();
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    if (note.contextType !== ClientFacingNoteContextType.TableCell) return;
    const context = note.context as TableCellClientFacingNoteContext;
    if (context.tableId !== this.table.tableId) return;

    this.refreshCell(context.rowId, context.columnField, true);
  }

  /**
   * Invokes refreshCells for a single cell.
   *
   * @param force Pass true to invoke the cell's renderer even though its data value might be unchanged. This is useful for cell flags.
   */
  refreshCell(rowId: string, columnField: string, force = false): void {
    const rowNode = this.grid.gridApi.getRowNode(rowId);
    if (!rowNode) return;

    const column = this.grid.gridApi.getColumn(columnField);
    if (!column) return;

    this.grid.gridApi.refreshCells({ force, rowNodes: [rowNode], columns: [column] });
  }

  private static normalizeColumnDefinition(columnDefinition: ColumnDefinition) {
    if (!isNaN(columnDefinition.width as number)) {
      columnDefinition.width = parseInt(columnDefinition.width as string);
    }
  }

  private setPermissionsBasedOnWorkflowState(experimentWorkflowState: ExperimentWorkflowState) {
    if (
      ExperimentService.isExperimentAuthorizedOrCancelled(experimentWorkflowState) ||
      (experimentWorkflowState === ExperimentWorkflowState.InReview &&
        this.canEditExperimentInReview !== true)
    ) {
      this.table.allowRowAdd = false;
      this.table.allowMultipleRows = false;
      this.table.allowRowRemoval = false;
    }
  }

  private setPermissionsBasedOnRoles() {
    if (this.userService.hasOnlyReviewerRights()) {
      this.table.allowRowAdd = false;
      this.table.allowMultipleRows = false;
      this.table.allowRowRemoval = false;
    }
  }

  private updateFeatureFlags() {
    this.canEditExperimentInReview = this.clientStateService.getFeatureFlags(this.clientState)
      .map((data) => JSON.parse(data))
      .some((data) => data.CanEditExperimentInReviewState === true);
  }

  private sortData(data: { label: string, value: string }[]): { label: string, value: string }[] {
    return [...data].sort((a, b) =>
      a.value.localeCompare(b.value, undefined, { numeric: true, sensitivity: 'base' })
    );
  }

  populateKeyColumn() {
    const samples = this.keyColumnService.getKeys(
      this.experimentService.currentActivityId,
      KeyColumnType.Samples
    );
    const materials = this.keyColumnService.getKeys(
      this.experimentService.currentActivityId,
      KeyColumnType.Materials
    );
    const preparations = this.keyColumnService.getKeys(
      this.experimentService.currentActivityId,
      KeyColumnType.Preparations
    );

    const sortedSamples = this.sortData(samples);
    const sortedMaterials = this.sortData(materials);
    const sortedPreparations = this.sortData(preparations);

    this.columnDefinitions.forEach(column => {
      if (column.field === keyColumnField) {
        this.setDropdownEditorConfigurationForKeyColumns(column, sortedSamples, sortedMaterials, sortedPreparations);
      }
    });
  }

  updateKeyColumn() {
    if (!this.table) return;

    this.columnDefinitions.forEach(column => {
      if (column.field === keyColumnField) {
        this.setDropdownEditorConfigurationForKeyColumns(column, this.samples, this.materials, this.preparations);
        if (this.grid) this.grid.updateColumnDefinitions(column);
      }
    });
  }

  handleCellDoubleClickForInstrumentReading(e: CellDoubleClickedEvent) {
    if (!e.colDef.field) throw new Error(missingFieldMessage);
    const column = this.columnDefinitions.find(col => col.field === e.colDef.field);
    if (!column) throw new Error(missingColumnMessage);

    if (!this.isCellEditable(e, { balanceReadingDialogPolicy: false, phReadingDialogPolicy: false })) return;
    if (this.isCellLocked(e.type, e.event?.target)) return; // check it another way, because we can
    if (!this.experimentWarningService.isUserAllowedToEdit) return; // and another

    const useDialog = this.useReadingDialogFor(e.data.id, e.colDef.field);
    if (!useDialog) return;

    const cellElement = e.event?.target;
    this.overlayColumn = e.column.getId();
    this.overlayRow = e.data.id;
    this.selectedColumn = column;
    this.columnUnitsForReading = useDialog.allowedUnits;

    switch (useDialog.type) {
      case InstrumentType.balance:
        this.setTargetForBalanceReading();
        this.instrumentConnectionHelper.selectedMethod = undefined;
        this.overlayVisibleBalance = true;
        this.op.show(e.event, cellElement);
        this.op.cd.detectChanges();
        break;

      case InstrumentType.phMeter:
        this.overlayVisiblePh = true;
        this.instrumentConnectionHelper.phMeterReadingSessionActive = true;
        this.openOverlay(e.event, cellElement); // COde review observation: this is fire and forget
        break;
    }
  }

  private isCellLocked(eventtype: string, element?: any): boolean {
    return eventtype === 'cellDoubleClicked' && (element?.parentNode?.attributes?.getNamedItem('locked') ||
      element?.parentNode?.parentNode?.attributes?.getNamedItem('locked') ||
      element?.parentNode?.parentNode?.parentNode?.attributes?.getNamedItem('locked'));
  }

  setTargetForBalanceReading() {
    this.showTarget = false; // hypothesis
    const specificationColumns = this.table.columnDefinitions.filter(column => column.columnType === ColumnType.specification);
    if (specificationColumns.length === 1) {
      const specificationField = specificationColumns[0].field;
      const tableRow = this.table.value.find((r) => r.id === this.overlayRow);
      if (tableRow && specificationField) {
        const rowSpecificationValue = this.getSpecificationValueCompatibleForBalanceReading(tableRow[specificationField].value);
        if (rowSpecificationValue) {
          this.showTarget = true;
          this.targetValue = rowSpecificationValue;
        }
      }
    }
  }

  getSpecificationValueCompatibleForBalanceReading(value: DataValue): NumberValue | undefined {
    if (value.state !== ValueState.Set) return undefined;
    if (value.type !== ValueType.Specification) return undefined;
    const specification = value as SpecificationType;
    if (specification.specType !== SpecType.SingleValue) return undefined;
    const singleValueSpecification = specification as SingleValueSpec;
    if (!singleValueSpecification.value) return undefined;
    const gUnit = this.unitLoaderService.allUnits.find(unit => unit.abbreviation === InstrumentConfigUnits.g);
    const mgUnit = this.unitLoaderService.allUnits.find(unit => unit.abbreviation === InstrumentConfigUnits.mg);
    const compatible = singleValueSpecification.sourceToValueOperator === SpecComparisonOperator.EqualTo
      && [gUnit?.id, mgUnit?.id].includes(singleValueSpecification.value.unit);
    if (!compatible) return undefined;
    return singleValueSpecification.value;
  }

  onInstrumentReadingsReceived(actualValue: InstrumentReadingValue, committedFromInstruments: boolean) {
    if (this.sequentialReadingEnabled && !committedFromInstruments) {
      return;
    }

    if (this.overlayRow && this.overlayColumn) {
      const gridRow = this.grid.gridApi.getRowNode(this.overlayRow);
      if (gridRow) {
        gridRow.setDataValue(this.overlayColumn, actualValue);
      }

      const actualReadingValue = actualValue.instrumentMetaData?.actual?.toString() ?? '';
      const sigFigsCount = this.countSigFigs(actualReadingValue);

      const changeCellCommand: ChangeCellCommand = {
        experimentId: this.experimentId,
        rowIds: [this.overlayRow],
        tableIds: [this.table.tableId],
        columnValues: [
          {
            propertyName: this.selectedColumn.field ?? '',
            propertyValue: {
              type: ValueType.Number,
              state: ValueState.Set,
              value: actualReadingValue,
              exact: false,
              sigFigs: sigFigsCount,
              unit: this.unitIdForBalance,
              instrumentReading: {
                category: actualValue.category,
                equipmentId: actualValue.equipmentId,
                instrumentName: actualValue.instrumentName,
                instrumentType: actualValue.instrumentType,
                manufacturer: actualValue.manufacturer,
                modelNumber: actualValue.modelNumber,
                serialNumber: actualValue.serialNumber,
                instrumentMetaData: {
                  ReadingMethod: actualValue.instrumentMetaData?.ReadingMethod,
                  ...actualValue.instrumentMetaData
                }
              }
            }
          }
        ],
        activityId: this.activityId
      };
      this.postChangeCellCommand(changeCellCommand, undefined);
      this.refreshDataSource();
    }
  }

  refreshDataSource(): void {
    this.primitiveValue = this.tableDataService.refreshDataSource(this);
    this.trackCompletion();
  }

  /**
 * Counts the number of significant figures in a measurement value.
 * This function is intended for measurements, which are inherently approximate,
 * and not for exact values. The implementation follows standard rules for significant
 * figures, considering decimals and zeros.
 *
 * @param {string} n - The measurement value as a string.
 * @returns {number} Count of significant figures in the value.
 */
  countSigFigs(n: string): number {
    if (n.startsWith('-')) {
      n = n.substring(1);
    }
    if (n.startsWith('0.')) {
      return n.replace(/0\.0*/, '').length;
    }
    const cleaned = n.replace(/^0+/, '');
    if (cleaned.includes('.')) {
      return cleaned.replace('.', '').length;
    } else {
      return cleaned.replace(/0+$/, '').length;
    }
  }

  async openOverlay(event: any, element?: EventTarget | null) {
    await this.instrumentNotificationService.updateSequentialReadingProgress(true);
    this.op.show(event, element);
    this.op.cd.detectChanges();
  }

  closeOverlay() {
    this.instrumentNotificationService.updateSequentialReadingProgress(false);
    SequentialReadingShiftModalService.terminateSequentialReading();
    this.overlayVisibleBalance = false;
    this.overlayVisiblePh = false;
    this.instrumentConnectionHelper.phMeterReadingSessionActive = false;
    if (!this.isSequentialReadingInProgress) {
      this.instrumentConnectionHelper.selectedMethod = undefined;
      this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit = undefined;
      this.handleSequentialReadingCellsUnlock();
    }
    this.op.hide();
    this.op.cd.detectChanges();
  }

  private handleSequentialReadingCellsUnlock() {
    const fieldLock: CellLock[] = [];
    if (this.sequentialReadingsLocker && this.sequentialReadingsLocker.length > 0) {
      this.sequentialReadingsLocker.forEach((sequentialReadingLockModal: SequentialReadingLockModal) => {
        window.clearTimeout(sequentialReadingLockModal.lockTimeout);
        fieldLock.push(new CellLock(
          this.experimentId,
          LockType.unlock,
          this.moduleId,
          this.activityId,
          this.experimentNotificationService.getCollaborator(),
          this.grid.gridId ?? '',
          sequentialReadingLockModal.rowId ?? '',
          sequentialReadingLockModal.colId ?? '',
          undefined,
          true
        ));
      });
      this.experimentNotificationService.sendInputControlStatus(fieldLock);
    }
  }

  setDropdownEditorConfigurationForKeyColumns(column: ColumnDefinition,
    samples: { label: string, value: string }[],
    materials: { label: string, value: string }[],
    preparations: { label: string, value: string }[]
  ) {
    const options = [];

    if ((column as ColumnSpecification).allowedKeyColumnTypes?.includes(KeyColumnType.Samples)) {
      options.push({ label: 'Samples', subOptions: samples });
    }

    if ((column as ColumnSpecification).allowedKeyColumnTypes?.includes(KeyColumnType.Materials)) {
      options.push({ label: 'Materials', subOptions: materials });
    }

    if ((column as ColumnSpecification).allowedKeyColumnTypes?.includes(KeyColumnType.Preparations)) {
      options.push({ label: 'Preparations', subOptions: preparations });
    }

    column.dropdownEditorConfiguration = {
      ...DropdownCellEditorParamsDefaults,
      allowCustomOptionsForDropdown: true,
      editable: true,
      allowNA: column.allowNA,
      groupLabelField: 'label',
      groupChildrenField: 'subOptions',
      labelField: 'label',
      valueField: 'value',
      group: true,
      options,
      dropdownVisible: (e: any) => {
        this.dropDownVisible(e);
      }
    };
  }

  private setColumnProperties() {
    this.columnDefinitions.forEach((column) => {
      if (!column.field) throw new Error(missingFieldMessage);

      if (column.field === TableDataService.repeatField) this.setRepeatColumnProperties(column);

      TableComponent.normalizeColumnDefinition(column);
      column.lockVisible = column.disableHiding;

      // capture editableInExperiment and setup editable callback for grid, if it hasn't been already
      const alreadyVisitedByTableComponent = Object.getOwnPropertyDescriptor(column, 'editable')?.writable === false;
      if (!alreadyVisitedByTableComponent) {
        if (typeof column.editable === 'function') throw new Error('LOGIC ERROR: column.editable should be boolean upon entering setColumnProperties');
        column.editable ??= true; // default editableInExperiment
        this.gridOptionsContext.editableInExperiment.set(column.field, column.editable);
        if (column.editable) column.editable = (params: EditableCallbackParams) => this.isCellEditable(params);
        Object.defineProperty(column, 'editable', {
          value: column.editable,
          writable: false, // ensure features aren't added incorrectly
        });
      }

      const predecessor = column.onCellDoubleClicked;
      column.onCellDoubleClicked = (e: CellDoubleClickedEvent) => {
        if (predecessor) predecessor(e);
        this.handleCellDoubleClickForInstrumentReading(e);
      }

      switch (column.columnType) {
        case ColumnType.list:
        case ColumnType.editableList:
          this.setPropertiesForListOrEditableList(column);
          break;
        case ColumnType.date:
          column.calendarPanelVisible = (e: any) => {
            this.calendarPanelVisible(e);
          };
          break;
        case ColumnType.quantity:
          column.onlyAllowUnit = () => {
            const inSetup = this.experiment.workflowState === ExperimentWorkflowState.Setup;
            return inSetup && !(column as ColumnSpecification).numberEditableInSetup;
          };
          break;
        case ColumnType.specification:
          this.setSpecificationColumnProperties(column);
          break;
        case ColumnType.stepNumber:
          column.filterType = 'string';
          break;
      }
      column.suppressMenu = (column as ColumnSpecification).suppressColumnMenu;
    });
  }

  private setRepeatColumnProperties(column: ColumnDefinition) {
    TableDataService.setRepeatColumnProperties(column, undefined);
  }

  private setSpecificationColumnProperties(column: ColumnDefinition) {
    const predecessor = column.onCellDoubleClicked;
    column.onCellDoubleClicked = (e: CellDoubleClickedEvent) => {
      if (predecessor) predecessor(e);

      const colSpec = column as ColumnSpecification;
      if (colSpec.columnType !== ColumnType.specification) return;

      if (!this.isCellEditable(e, { columnTypeSpecificationPolicy: false })) return;

      const rowId = e.node.id;
      const coldId = e.column.getId();
      const allowedSpecTypes = colSpec.allowedSpecTypes ?? [];
      const allowedUnits = (column.allowedUnits ?? []) as Unit[];
      const defaultUnit = ((column as ColumnSpecification).defaultUnit ?? undefined) as Unit;

      // Empty with N/A unit is not a valid state
      const unitToSet = defaultUnit?.id === this.unitLoaderService.naUnit.id ? undefined : defaultUnit;

      if (rowId) this.toggleSpecificationSlider(rowId, coldId, allowedSpecTypes, allowedUnits, unitToSet);
    };
  }

  toggleSpecificationSlider(
    rowId: string,
    field: string,
    allowedSpecTypes: SpecType[],
    allowedUnits: Unit[],
    defaultUnit?: Unit,
    readOnly = false
  ) {
    const tr = this.table.value.find((r) => r.id === rowId);
    const tableRow = tr as {
      [key: string]: ModifiableDataValue;
    };

    // If a value does not exist we want to make sure it does before we proceed.
    tableRow[field] ??= {
      isModified: false,
      value: { type: ValueType.Specification, state: ValueState.Empty }
    };

    if (tableRow[field].value.type !== ValueType.Specification) return; // shouldn't get here.

    const onClose = new Subject<never>();
    const onChange = new Subject<SpecificationValue>();
    onClose.subscribe({
      complete: () => {
        this.sendInputStatus(LockType.unlock, this.table.tableId, rowId, field);
        onClose.unsubscribe();
        onChange.unsubscribe();
      }
    });
    onChange.subscribe({
      next: (newValue) => {
        const gridRow = this.grid.gridApi.getRowNode(rowId);
        if (!gridRow) throw new Error('Grid row not found!');
        const modifiableDataValue = {
          isModified: true, // This assumption is invalid, but works because getPrimitiveValue doesn't care.
          value: newValue
        };
        const primitiveValue = this.dataValueService.getPrimitiveValue(
          APIColumnType.Specification,
          modifiableDataValue
        );
        gridRow.setDataValue(field, primitiveValue, 'specificationEdit');

        const e: BptGridCellValueChangedEvent = {
          type: 'cellValueChanged',
          oldValue: tableRow[field].value,
          newValue,
          field,
          rowId,
          gridId: this.table.tableId
        };
        this.cellValueEditing(e);
      }
    });

    this.sendInputStatus(LockType.lock, this.table.tableId, rowId, field);
    const context: SpecificationEditorContext = {
      id: uuid(),
      value: tableRow[field].value as SpecificationValue,
      readOnly,
      disabled: false,
      allowedSpecTypes,
      allowedUnits,
      defaultUnit,
      onChange,
      onClose
    };
    this.experimentService.beginEditSpecification.next(context);
  }

  private setPropertiesForListOrEditableList(column: ColumnDefinition) {
    // Note: See setDropdownEditorConfigurationForKeyColumns. It's called dynamically and can override below if column is a key column.

    const listSpecificConfig = () => {
      /* nothing special */
    };
    const editableListSpecificConfig = () => ({
      labelField: 'label',
      valueField: 'value',
      options: column.listValues ?? [],
      allowMultiSelect: column.allowMultiSelect ?? false,
      allowCustomOptionsForDropdown: column.allowCustomOptionsForDropdown ?? false
    });
    const typeSpecificConfig =
      column.columnType === ColumnType.list ? listSpecificConfig() : editableListSpecificConfig();

    column.dropdownEditorConfiguration = {
      ...DropdownCellEditorParamsDefaults,
      ...typeSpecificConfig,
      allowNA: column.allowNA,
      showHeader: column.showHeader ?? column.allowMultiSelect ?? false,
      showToggleAll: column.showToggleAll ?? column.allowMultiSelect ?? false,
      dropdownVisible: (e: any) => this.dropDownVisible(e)
    };
  }

  editorCriteria: { [type in InstrumentType]: (editor: EditorComponentNames | APIColumnType, allowedUnits: Unit[]) => boolean } = {
    [InstrumentType.balance]: (editor, allowedUnits) => {
      const requiredUnits = [InstrumentConfigUnits.g, InstrumentConfigUnits.mg];
      const missingUnits = difference(requiredUnits, allowedUnits.map(u => u.abbreviation)).length;
      return !missingUnits && (editor === APIColumnType.Quantity || editor === EditorComponentNames.quantityEditor);
    },
    [InstrumentType.phMeter]: (editor, _allowedUnits) => {
      const missingUnits = false; // no units are required
      return !missingUnits && (editor === APIColumnType.Quantity || editor === EditorComponentNames.quantityEditor);
    },
  };

  /**
   * Dynamically determines if a cell is compatible with each type of instrument reading
   *   * balance reading
   *   * pH reading
   *
   * If column has its dynamicEditor property set, calls it, otherwise uses static column type etc
   */
  private cellIsCompatibleWithInstrumentReadings(rowId: string, field: string): { type: InstrumentType, allowedUnits: Unit[] }[] { //NOSONAR S3776 Cognitive Complexity
    const columnDefinition = this.columnDefinitions.find((data) => data.field === field);
    if (!columnDefinition) return [];
    const columnSpecification = this.table.columnDefinitions.find((data) => data.field === field);
    if (!columnSpecification) return [];
    const row = this.grid.gridApi.getRowNode(rowId);
    if (!row) return [];

    const compatibles: { type: InstrumentType, allowedUnits: Unit[] }[] = [];
    const dynamicEditor = columnDefinition.dynamicEditor;
    const targetField = dynamicEditor?.targetColumn.field;
    const columnType = columnSpecification.columnType;

    let editor: EditorComponentNames | APIColumnType | undefined;
    let allowedUnits: Unit[];
    if (dynamicEditor && targetField) {
      const value = row.data[targetField];
      const dynamic = dynamicEditor.deferredEditor(value);
      const deferredEditor = 'component' in dynamic ? dynamic.component : dynamic;
      const isQuantity = deferredEditor === FrameworkComponents.Editors[EditorComponentNames.quantityEditor];

      if (isQuantity) {
        allowedUnits = 'params' in dynamic && dynamic.params?.allowedUnits || [];
        editor = EditorComponentNames.quantityEditor;
      }
    } else if (columnType) {
      allowedUnits = columnSpecification.allowedUnits || [];
      editor = columnType;
    }

    forIn(InstrumentType, (type) => {
      // editor is invariant in this loop but Karma gets confused with it pulled out
      if (editor && this.editorCriteria[type](editor, allowedUnits ?? [])) compatibles.push({ type, allowedUnits });
    });

    return compatibles;
  }

  /**
   * Returns the instrument type to use a reading dialog for data entry instead of letting the grid handle data entry, given:
   *   * current cell configuration (dynamicEditor or else columnDefinition),
   *   * instruments statuses, and
   *   * reading criteria.
   *
   * There is at most one appropriate instrument.
   */
  useReadingDialogFor(rowId: string, field: string): { type: InstrumentType, details?: InstrumentDetailsDto, allowedUnits: Unit[] } | undefined {
    const activeInstrument = this.instrumentConnectionHelper.activeInThisTab;
    if (!activeInstrument?.type) return undefined;
    const compatibles = this.cellIsCompatibleWithInstrumentReadings(rowId, field);
    const compatible = compatibles.find(c => c.type === activeInstrument.type);
    if (!compatible) return undefined;

    return { ...activeInstrument, allowedUnits: compatible.allowedUnits };
  }

  /**
   * Dynamically determine if cell is manually editable in place (e.g. via cell editor).
   * Called by bpg-grid via column.editable callback.
   * Callers other than bpt-grid can exclude selected policy considerations. Default is to consider each and all policies.
   * @param consider Policies to exclude. Default is no exclusions. Default for a missing property is no exclusion.
   */
  private isCellEditable(
    params: Pick<EditableCallbackParams, 'colDef' | 'node'>,
    consider: CellEditablePolicies = {}
  ): boolean {
    const field = params.colDef.field;
    const column = this.columnDefinitions.find((data) => data.field === field);
    const rowId = params.node.id;
    if (!field) return false;
    if (!column) return false;
    if (!rowId) return false;

    return this.isCellEditableImpl(field, column, rowId, consider);
  }

  /** Implementation for isCellEditable with less complexity and readability. */
  private isCellEditableImpl( //NOSONAR S3776 Refactor this function to reduce its Cognitive Complexity
    field: string,
    column: Pick<ColumnDefinition, 'columnType' | 'containsObservableData'>,
    rowId: string,
    consider: CellEditablePolicies
  ): boolean {
    if (this.gridOptionsContext.locked.get(CellLock.createKey(this.table.tableId, rowId, field))) return false;
    if (this.gridOptionsContext.editableInExperiment.get(field) === false) return false;
    const useReadingDialogType = this.useReadingDialogFor(rowId, field)?.type;
    if (consider.balanceReadingDialogPolicy !== false && useReadingDialogType === InstrumentType.balance) return false;
    if (consider.phReadingDialogPolicy !== false && useReadingDialogType === InstrumentType.phMeter) return false;
    if (consider.columnTypeSpecificationPolicy !== false && column.columnType === ColumnType.specification) return false;
    if (consider.rowEditablePolicy !== false && !this.isRowEditable(rowId)) return false;
    if (this.userService.hasOnlyReviewerRights()) return false;

    switch (this.experiment.workflowState) {
      case ExperimentWorkflowState.Setup: if (!this.editableDuringSetup(column)) return false; break;
      case ExperimentWorkflowState.InReview: if (!this.canEditExperimentInReview) return false; break;
      case ExperimentWorkflowState.Authorized: return false;
      case ExperimentWorkflowState.Cancelled: return false;
    }

    return true;
  }

  private isRowEditable(rowId: string) {
    const row = this.table.value.find((r) => r.id === rowId);
    return row && !TableDataService.rowIsPlaceholder(row);
  }

  showClientFacingNotes(rowId: string, field: string): void {
    const eventTarget: TableCellClientFacingNoteContext = {
      tableId: this.table.tableId,
      columnField: field,
      rowId
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.TableCell,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

  private handleAuditHistoryResponse(data: AuditHistoryDataRecordResponse, rowId: string, field: string) {
    const cellChangedRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter((d): d is CellChangedEventNotification => d.eventContext.eventType === ExperimentEventType.CellChanged)
      .filter((c) => c.rowIds[0] === rowId && c.columnValues[0]?.propertyName === field);
    const cellNoteRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter((d): d is ClientFacingNoteCreatedEventNotification | ClientFacingNoteChangedEventNotification =>
        d.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated || d.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
      )
      .filter((d) => {
        if (d.nodeId !== this.table.tableId) return false;

        const note = this.experiment.clientFacingNotes.find((n) => n.number === d.number);
        return note?.path[0] === rowId && note?.path[1] === field;
      });
    return cellChangedRecords.concat(cellNoteRecords);
  }

  private processStatementSelfWitnessing(auditRecords: AuditHistoryDataRecordResponse) {
    const cellRange = this.grid.gridApi.getCellRanges();
    if (cellRange) {
      if (cellRange[0].columns.length === 1 && cellRange[0].startRow?.rowIndex === cellRange[0].endRow?.rowIndex) {
        this.processSelfWitnessingForSingleCell(auditRecords);
      } else {
        this.processStatementSelfWitnessingForCellRange(cellRange[0], auditRecords);
      }
    }
  }

  private processSelfWitnessingForSingleCell(auditRecords: AuditHistoryDataRecordResponse) {
    const cell = this.grid.gridApi.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      const rowId = row?.id;
      const field = cell.column.getColDef()?.field;
      if (rowId && field) {
        this.handleAuditHistoryForStatement(rowId, field, auditRecords);
      }
    }
  }

  private processStatementSelfWitnessingForCellRange(cellRange: CellRange, auditRecords: AuditHistoryDataRecordResponse) {
    const startRowIndex = cellRange.startRow?.rowIndex as number;
    const endRowIndex = cellRange.endRow?.rowIndex as number;
    cellRange.columns.forEach(column => {
      for (let i = startRowIndex; i <= endRowIndex; i++) {
        const row = this.grid.gridApi.getDisplayedRowAtIndex(i);
        const rowId = row?.id;
        const field = column.getUserProvidedColDef()?.field;
        if (rowId && field) {
          this.handleAuditHistoryForStatement(rowId, field, auditRecords);
        }
      }
    });
  }

  private handleAuditHistoryForStatement(rowId: string, field: string, data: AuditHistoryDataRecordResponse): void {
    const currentUserPuid = this.userService.currentUser?.puid;

    const cellChangedRecords = this.handleAuditHistoryResponse(data, rowId, field);

    this.isSelfWitnessing = cellChangedRecords.some((cellChangedRecord: ExperimentDataRecordNotification) =>
      cellChangedRecord.eventContext.puid === currentUserPuid);

    this.restrictSelfWitnessing();
  }

  private restrictSelfWitnessing() {
    const addStatementMenuItem = Array.from(document.querySelectorAll('.ag-menu-option .ag-menu-option-text'))
      .find((element: Element) => element.textContent === $localize`:@@addStatement:Add Statement`);

    if (addStatementMenuItem) {
      const addStatementMenuItemContainer = addStatementMenuItem.closest('.ag-menu-option');
      if (addStatementMenuItemContainer) {
        if (this.isSelfWitnessing) {
          addStatementMenuItemContainer.classList.remove('ag-menu-option-active');
          addStatementMenuItemContainer.classList.add('ag-menu-option-disabled');
          (addStatementMenuItemContainer as any).style.pointerEvents = 'none';
        }
      }
    }
  }

  getContextMenu(): GridContextMenuItem[] {
    if (!this.grid?.gridApi) return []; // Can't do much without a grid API, but this is normal due to how it's binding

    const sequentialReadingMenuOption = this.sequentialReading();

    const clientFacingNoteMenuOption = {
      label: $localize`:@@clientFacingNoteHeader:Client-facing Notes`,
      action: () => {
        const cell = this.grid.gridApi?.getFocusedCell();
        if (cell) {
          const rowId = this.grid.gridApi?.getDisplayedRowAtIndex(cell.rowIndex)?.id;
          const field = cell.column.getColDef().field;
          if (rowId && field) this.showClientFacingNotes(rowId, field);
        }
      },
      disabled: this.userService.hasOnlyReviewerRights() || this.experiment.workflowState === ExperimentWorkflowState.InReview,
      icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon">'
    };
    const addStatement = this.getAddStatementContextMenu();
    const internalComments = {
      label: $localize`:@@internalComments:Internal Comments`,
      action: () => {
        const cell = this.grid.gridApi.getFocusedCell();
        if (cell) {
          const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
          const field = cell.column.getColDef().field as string;
          if (row) this.tableDataService.showInternalComments(row.id as string, field, this.renderer, this.buildInternalComments.bind(this));
        }
      },
      icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
    };

    const historyMenuOption = {
      label: $localize`:@@history:History`,
      action: () => this.showRowOrCellHistory(),
      icon: '<img src="assets/eln-assets/audit-history.svg" class="ag-icon ag-custom-icon">'
    };

    return this.tableDataService.getContextMenu(
      this,
      internalComments,
      sequentialReadingMenuOption,
      clientFacingNoteMenuOption,
      historyMenuOption,
      this.activityId,
      this.isCrossReference(this.table.tableId, this.experimentService.currentActivity) ? undefined : addStatement
    );
  }

  onTableContextMenu(event: MouseEvent): void {
    event.preventDefault();
    if (event.button === rightClickButtonEventNumber) {
      this.auditHistoryService
        .loadTableAuditHistory(this.table.experimentId, this.table.tableId)
        .subscribe((data) => {
          this.processStatementSelfWitnessing(data);
        });
    }
  }

  private getAddStatementContextMenu(): GridContextMenuItem | undefined {
    const puid = this.userService.currentUser?.puid;

    if (this.statementsService.canShowStatements(puid)) {
      return {
        label: $localize`:@@addStatement:Add Statement`,
        action: () => {
          this.openStatementSlider();
        },
        disabled: this.userService.hasOnlyReviewerRights(),
        icon: '<img src="assets/eln-assets/ic_add_notes-statement.svg" class="ag-icon ag-custom-icon" style="width: 16px;">'
      };
    } else if (this.clientStateService.isClientStateReadOnly) {
      return {
        label: $localize`:@@addStatement:Add Statement`,
        action: () => {
          this.openStatementSlider();
        },
        icon: '<img src="assets/eln-assets/ic_add_notes-statement.svg" class="ag-icon ag-custom-icon" style="width: 16px;">',
        disabled: this.disableAddStatement()
      }
    } else {
      return undefined;
    }
  }

  disableAddStatement(): boolean {
    return this.clientStateService.isClientStateReadOnly || this.userService.hasOnlyReviewerRights();
  }

  /** Generates an array of rowId, field tuples from the current grid selection */
  private getPathsFromCurrentGridSelection(): PathDetails[] {
    const pathDetails: PathDetails[] = []
    const cellR = this.grid.gridApi?.getCellRanges();
    if (!cellR) return pathDetails;
    const cellRange = cellR[0];
    const rowNodes: IRowNode[] = [];
    this.grid.gridApi.forEachNode(n => rowNodes.push(n));

    if (!cellRange?.startRow || !cellRange.endRow) return pathDetails;
    let startRowIndex = cellRange.startRow.rowIndex;
    let endRowIndex = cellRange.endRow.rowIndex;

    if (endRowIndex < startRowIndex) {
      [startRowIndex, endRowIndex] = [endRowIndex, startRowIndex];
    }
    for (let rowInd = startRowIndex; rowInd <= endRowIndex; rowInd++) {
      cellRange.columns.forEach(column => {
        const rowId = rowNodes[rowInd]?.id;
        const columnField = column.getColDef().field;
        if (!rowId || !columnField) return;
        pathDetails.push({
          path: [rowId, columnField]
        });
      });
    }

    return pathDetails;
  }

  private getPathDetailsForStatementApply(): PathDetails[] {
    const pathDetails: PathDetails[] = []
    const cellR = this.grid.gridApi?.getCellRanges();
    if (!cellR) return pathDetails;
    const cellRange = cellR[0];
    const rowNodes: IRowNode[] = [];
    this.grid.gridApi.forEachNode(n => rowNodes.push(n));

    if (!cellRange?.startRow || !cellRange.endRow) return pathDetails;
    let startRowIndex = cellRange.startRow.rowIndex;
    let endRowIndex = cellRange.endRow.rowIndex;

    if (endRowIndex < startRowIndex) {
      [startRowIndex, endRowIndex] = [endRowIndex, startRowIndex];
    }
    for (let rowInd = startRowIndex; rowInd <= endRowIndex; rowInd++) {
      cellRange.columns.forEach(column => {
        const rowId = rowNodes[rowInd]?.id;
        const columnField = column.getColDef().field;
        if (!rowId || !columnField) return;
        pathDetails.push({
          path: [rowId, columnField]
        });
      });
    }

    return pathDetails;
  }

  openStatementSlider() {
    const statementDetails: StatementModel = {
      contextType: StatementContextType.TableCell,
      nodeId: this.table.tableId,
      pathDetails: this.getPathDetailsForStatementApply(),
      experimentId: this.experimentId,
      showBackButton: false
    };

    this.statementsService.openStatements(statementDetails);
  }

  startSequentialReading($event: any) {
    this.lockSequentialReadingCells();
    const sequentialShiftModal: SequentialReadingShiftModal = {
      $event,
      gridApi: this.grid.gridApi,
      renderer: this.renderer,
      columnDefinitions: this.columnDefinitions,
      setSelectedColumn: (column: ColumnDefinition, cell: Element) => {
        this.selectedColumn = column;
        const cellElement = cell.querySelector('.ag-cell-value[data-row]') ?? undefined;
        this.overlayRow = cellElement?.getAttribute('data-row') ?? undefined;
        this.overlayColumn = cellElement?.getAttribute('data-col') ?? undefined;
        const instrumentType = this.instrumentConnectionHelper.getInstrumentTypeFromLocalStorage;
        if (instrumentType === InstrumentType.balance) {
          this.setTargetForBalanceReading();
        }
      },
      openOverlayModal: (event, cell) => {
        const instrumentType = this.instrumentConnectionHelper.getInstrumentTypeFromLocalStorage;
        const isBalanceInstrument = instrumentType === InstrumentType.balance;
        this.overlayVisibleBalance = isBalanceInstrument;
        this.overlayVisiblePh = !isBalanceInstrument;
        this.openOverlay(event, cell);
      },
      closeOverlayModal: isSequentialReadingInProgress => {
        this.isSequentialReadingInProgress = isSequentialReadingInProgress;
        this.closeOverlay();
      }
    };
    SequentialReadingShiftModalService.startSequentialReading(sequentialShiftModal);
  }

  private lockSequentialReadingCells() {
    const fieldLock: CellLock[] = [];
    const cells = this?.grid?.gridApi?.getCellRanges()?.[0]
    if (cells) {
      this.sequentialReadingsLocker = [];
      const rowNodes: RowNode[] = [];
      const columnValues: { [key: string]: any } = {};
      const startRowIndex = cells?.startRow?.rowIndex as number;
      const endRowIndex = cells?.endRow?.rowIndex ?? startRowIndex;
      for (let rowIndex = Math.min(startRowIndex, endRowIndex); rowIndex <= Math.max(startRowIndex, endRowIndex); rowIndex++) {
        rowNodes.push(this.grid.gridApi.getDisplayedRowAtIndex(rowIndex) as RowNode);
        const rowId = rowNodes[rowNodes.length - 1].id as string;
        columnValues[rowId] = {};
        cells?.columns.forEach((column: Column) => {
          const colId = column.getColId();
          fieldLock.push(new CellLock(
            this.experimentId,
            LockType.lock,
            this.moduleId,
            this.activityId,
            this.experimentNotificationService.getCollaborator(),
            this.grid.gridId ?? '',
            rowId ?? '',
            colId ?? '',
            undefined,
            true));
          this.sequentialReadingsLocker.push(new SequentialReadingLockModal(rowId, colId, 0));
        });
      };
      this.experimentNotificationService.sendInputControlStatus(fieldLock);
    }
  }

  isMenuItemDisabled(): boolean {
    return this.userService.hasOnlyReviewerRights()
      || this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview
  }

  fillGridWithNA(emptyCells = false) {
    const args: NaGridTableIdentifierData = {
      isCellEditable: (params: EditableCallbackParams) => this.isCellEditable(params, { columnTypeSpecificationPolicy: false }),
      getCell: this.getCellExperimentDataValue.bind(this),
      postChangeCellCommand: this.postChangeCellCommand.bind(this),
      grid: this.grid,
      table: this.table,
      containerId: this.experimentId,
      columnDefinitions: this.columnDefinitions,
      activityId: this.activityId,
      isDisabled: this.isMenuItemDisabled()
    };

    const displayedColumns = this.grid.gridApi.getColumns();
    const startRow = this.grid.gridApi.getFirstDisplayedRow();
    const endRow = this.grid.gridApi.getLastDisplayedRow();
    if (displayedColumns) {
      const cellRange: CellRange = {
        columns: displayedColumns,
        startRow: {
          rowIndex: startRow
        } as RowPosition,
        endRow: {
          rowIndex: endRow
        } as RowPosition,
        startColumn: displayedColumns[0]
      };
      this.fillWithNaGridHelper.updateNAForGrid(cellRange, args, emptyCells);
    }
  }

  fillWithNaMenuItem(): GridContextMenuItem | undefined {
    const args: NaGridTableIdentifierData = {
      isCellEditable: (params) => this.isCellEditable(params, { columnTypeSpecificationPolicy: false }),
      getCell: this.getCellExperimentDataValue.bind(this),
      postChangeCellCommand: this.postChangeCellCommand.bind(this),
      grid: this.grid,
      table: this.table,
      containerId: this.experimentId,
      columnDefinitions: this.columnDefinitions,
      activityId: this.activityId,
      isDisabled: this.isMenuItemDisabled()
    };
    return this.fillWithNaGridHelper.getContextMenuOptionsOfFillWithNa(args);
  }
  /**
   * Checks that more than one cell is selected and that all selected cell are configured to accept an instrument reading for the non-paused connected instrument
   * and all are empty.
   *
   * (Only one (first) cell range is considered.)
   */
  private sequentialReadingsSupportedForAllSelectedCells(): boolean {
    const selectedCells = this.tableDataService.getSelectedCellValues(this.grid.gridApi, this.table.value)[0]; // Assumes only one contiguous range possible
    if (!selectedCells) return false;
    // require multiple cells so either multiple rows or multiple columns
    const rowIds = Object.keys(selectedCells);
    if (rowIds.length < 1) return false;
    const multiRows = rowIds.length > 1;
    const multiCells = selectedCells[rowIds[0]].length > 1;
    if (!multiRows && !multiCells) return false;

    const cellHavingValue = Object.values(selectedCells)?.some(c => c.some(c => c.propertyValue?.state !== ValueState.Empty));
    if (cellHavingValue) return false;

    const cellLocations = this.getPathsFromCurrentGridSelection();
    return !cellLocations.some(c => !c.path || !this.sequentialReadingsSupportedForCell(c.path[0], c.path[1]));
  }

  private sequentialReadingsSupportedForCell(rowId: string, field: string): boolean {
    const instrumentType = this.instrumentConnectionHelper.instrumentType ?? (localStorage.getItem('instrumentType') ?? undefined) as InstrumentType | undefined;
    if (!instrumentType) return false;

    return this.cellIsCompatibleWithInstrumentReadings(rowId, field).some(r => r.type === instrumentType);
  }

  private enableSequentialReading(): boolean {
    const selectedCell = this.tableDataService.getSelectedCellValues(this.grid.gridApi, this.table.value)[0];
    if (!selectedCell) return false;

    const cellHavingValue = Object.values(selectedCell)?.some(c => c.some(c => c.propertyValue?.state !== ValueState.Empty));
    if (!this.grid?.gridApi || !selectedCell || cellHavingValue) return false;
    const cellRanges = this.grid.gridApi.getCellRanges();
    const cells = [
      ...new Set([
        ...(cellRanges ?? []).flatMap((cell) => cell.columns.map((col) => col.getColId()))
      ])
    ];
    return (
      (cells.length > 1 ||
        Object.keys(selectedCell).length > 1) &&
      cells.reduce((sofar, colId) => {
        const targetColumnIndex = this.columnDefinitions.findIndex(
          (colDef) => colDef.field === colId
        );
        if (targetColumnIndex === -1) return false;
        return (
          this.handleSequentialReadings(sofar, targetColumnIndex)
        );
      }, true)
    );
  }

  private handleSequentialReadings(sofar: boolean, targetColumnIndex: number): boolean {
    let canEnableSequentialReading;
    if (sofar && this.columnDefinitions[targetColumnIndex].columnType === ColumnType.quantity) {
      const instrumentType = this.instrumentConnectionHelper.instrumentType ?? localStorage.getItem('instrumentType');
      switch (instrumentType) {
        case InstrumentType.balance:
          if (this.columnDefinitions[targetColumnIndex].allowedUnits !== undefined) {
            canEnableSequentialReading = this.columnDefinitions[targetColumnIndex].allowedUnits?.some(unit => unit.abbreviation === InstrumentConfigUnits.g)
              && this.columnDefinitions[targetColumnIndex].allowedUnits?.some(unit => unit.abbreviation === InstrumentConfigUnits.mg);
          }
          break;
        case InstrumentType.phMeter: {
          const selectedUnit = localStorage.getItem(InstrumentConfigurationKeys.unit) as PhMeterMode ?? PhMeterMode.pH;
          if (selectedUnit === PhMeterMode.mV) {
            canEnableSequentialReading = this.columnDefinitions[targetColumnIndex].allowedUnits?.some(unit => unit.abbreviation === InstrumentConfigUnits.mV);
          } else {
            canEnableSequentialReading = true;
          }
          break;
        }
      }
    }
    return Boolean(canEnableSequentialReading);
  }

  sequentialReading(): GridContextMenuItem | undefined {
    if (this.isInstrumentConnected && this.isInstrumentConnectionAvailable && this.enableSequentialReading()) {
      return {
        label: $localize`:@@sequentialReadingMenuOption:Sequential Readings`,
        icon: '<span class="icon-s icon-connection-ic"/>',
        id: `eln-context-sequential-readings-${this.table.itemTitle.replace(/ /g, '')}`,
        action: () => {
          this.instrumentNotificationService.updateSequentialReadingProgress(true);
          this.startSequentialReading(event);
        },
        disabled: this.userService.hasOnlyReviewerRights() || this.experiment.workflowState === ExperimentWorkflowState.InReview,
        tooltip: $localize`:@@sequentialReadingMenuOptionTooltip:Steps through selected fields, left to right, top to bottom,
                 auto-committing readings as required values are documented.
                 Assumes same reading method/mode for subsequent readings as indicated in first reading.
                 Closing a field's reading input box will cancel the reading sequence.`,
      };
    }
    return undefined;
  }

  getViewSpecMenuItem() {
    const cell = this.grid?.gridApi?.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      if (row) {
        const colId = cell.column.getColId();
        const colDef = this.columnDefinitions.find((c) => c.field === colId);
        const params: EditableCallbackParams = {
          node: row,
          data: undefined,
          column: cell.column,
          colDef: cell.column.getColDef(),
          api: this.grid.gridApi,
          columnApi: new ColumnApi(this.grid.gridApi),
          context: undefined
        };
        const readOnly = !this.isCellEditable(params, { columnTypeSpecificationPolicy: false });

        if (colDef?.columnType === APIColumnType.Specification && readOnly) {
          return {
            label: $localize`:@@ViewSpec:View Specification`,
            action: () => {
              const rowId = row.id;
              const coldId = colId;
              const colSpec = colDef as ColumnSpecification;
              const allowedSpecTypes = colSpec.allowedSpecTypes ?? [];
              const allowedUnits = (colDef.allowedUnits ?? []) as Unit[];
              const defaultUnit = (colSpec.defaultUnit ?? undefined) as Unit;
              if (rowId) {
                this.toggleSpecificationSlider(
                  rowId,
                  coldId,
                  allowedSpecTypes,
                  allowedUnits,
                  defaultUnit,
                  true
                );
              }
            },
            icon: '<img class="pi pi-search" />'
          };
        }
      }
    }
    return undefined;
  }

  private buildInternalComments(rowId: string | undefined, field: string) {
    const activity = this.experiment.activities.find(
      (act) => act.activityId === this.experimentService.currentActivityId
    );
    const columnLabel = this.table.columnDefinitions.find((c) => c.field === field)?.label;
    const cell = this.grid?.gridApi.getFocusedCell();
    const module = (activity?.dataModules ?? []).find(
      (mod) => mod.moduleId === this.moduleId
    );
    if (cell) {
      const rowIndex = this.grid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
      if (rowIndex !== undefined && rowIndex !== null && activity && columnLabel && rowId) {
        const isCrossReference = this.isCrossReference(this.table.tableId, activity);
        this.openInternalComments(
          this.table.tableId,
          [
            activity?.activityId,
            isCrossReference ? this.referencesParentTitle : module?.moduleId,
            rowId,
            field,
            columnLabel,
            this.table.tableId,
            (rowIndex + 1).toString(),
            isCrossReference ? CommentContextType.ActivityCrossReferences : CommentContextType.Module
          ],
          CommentContextType.TableCell
        );
      }
    }
  }

  /**
   * Shows history for cell context menu. If context menu is for the RowIndex cell, the scope is the row's history.
   */
  private showRowOrCellHistory(): void {
    const cell = this.grid.gridApi.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      if (row) {
        const field = cell.column.getColDef()?.field as string;
        const rowId = row.id as string;
        this.loadTableCellHistoryDialog(rowId, field);
      }
    }
  }

  confirmAddRow = (params: AddRowEventParams, addRowCallback: (params: AddRowEventParams) => void) => {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.tableDataService.refreshBeforeAddRow(this, this.grid, params, addRowCallback);
    }
  };

  rowsAdded(e?: BptGridRowsAddedEvent) {
    if (e === undefined) return;
    if (!e.gridId) throw new Error(missingGridIdMessage);

    const rows = this.tableDataService.rowsAdded(this, e);

    this.addRowsCommand = {
      experimentId: this.experimentId,
      tableId: e.gridId,
      rows,
      activityId: this.activityId
    };
    // add row event missing event source when it's added in bpt library
    // the undefined will be changed to e.eventSource
    this.postAddRowsCommandAndUpdateTableCompletion(this.addRowsCommand, e.source);
    this.applySavedPreferences(this.table.savedPreferences);
  }

  pasteValues(e: BptGridValuesPastedEvent) {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.valuesPasted(e);
    }
  }

  valuesPasted(e?: BptGridValuesPastedEvent) {
    if (!e) return;

    // nominally, bpt-grid filters for actual change but ELN considers various values as equivalent to empty and also empty with unit would not be the same as empty without a unit
    const isCompletelyEmpty = (v: any) => !this.isValid(v) || (v.state === ValueState.Empty && !v.value?.unit);
    const isActuallyChanged = (change: BptGridCellValueChangedEvent) => {
      const row = this.table.value.find((row: TableValueRow) => row.id === change.rowId);
      if (!change.field) throw new Error('LOGIC ERROR: Received a paste event that did not specify field');
      if (!row) throw new Error('LOGIC ERROR: Received a paste event onto a row not in the table');

      const oldValue = row[change.field].value;
      return !isSerializablyEqual(change.newValue, oldValue) && !(isCompletelyEmpty(change.newValue) && isCompletelyEmpty(oldValue));
    };
    e.cellChangedEvents = e.cellChangedEvents.filter(isActuallyChanged);
    if (e.cellChangedEvents.length === 0) return;

    const rowIds = e.cellChangedEvents
      .map((event: BptGridCellValueChangedEvent) => {
        if (!event.rowId) {
          this.logger.logErrorMessage(missingRowIdMessage);
          throw new Error(missingRowIdMessage);
        }
        return event.rowId;
      })
      .filter((value, index, self) => self.indexOf(value) === index);
    const unionValue = first(e.cellChangedEvents)?.newValue;
    const containsNonUnionValue = e.cellChangedEvents.some(
      (cellChangedEvent: BptGridCellValueChangedEvent) => cellChangedEvent.newValue !== unionValue
    );
    if (containsNonUnionValue) {
      rowIds.forEach(rowBeingProcessed => {
        this.rowWisePastedData(e, [rowBeingProcessed]);
      });
    } else {
      this.rowWisePastedData(e, rowIds);
    }
  }

  private rowWisePastedData(e: BptGridValuesPastedEvent, rowIdReferences: string[]): void {
    const tableIds = e.cellChangedEvents
      .filter((cellChangeEvent: BptGridCellValueChangedEvent) =>
        rowIdReferences.includes(cellChangeEvent.rowId as string)
      )
      .map((event: BptGridCellValueChangedEvent) => {
        if (!event.gridId) {
          this.logger.logErrorMessage(missingGridIdMessage);
          throw new Error(missingGridIdMessage);
        }
        return event.gridId;
      })
      .filter((value, index, self) => self.indexOf(value) === index);
    this.copiedValue = {
      rowIds: rowIdReferences,
      tableIds,
      experimentId: this.experimentId,
      activityId: this.activityId,
      columnValues: e.cellChangedEvents
        .filter((cellChangeEvent: BptGridCellValueChangedEvent) =>
          rowIdReferences.includes(cellChangeEvent.rowId as string)
        )
        .map((event: BptGridCellValueChangedEvent) => {
          if (!event.field) {
            this.logger.logErrorMessage(missingFieldNameMessage);
            return { propertyName: '', propertyValue: event.newValue };
          }
          // event.newValue is either what the grid gave us (primitive) or what we gave ourselves via a callback.
          // We need an ExperimentDataValue, which NumberValue and SpecificationValue already are; otherwise,
          //   we need to convert the primitive value (probably based on the column type)
          return [ValueType.Number, ValueType.Specification].includes(event.newValue?.type) ? { propertyName: event.field, propertyValue: event.newValue }
            : this.getCellExperimentDataValue(event.field, event.newValue);
        })
    };
    const _tableValue = cloneDeep(this.table.value);
    const oldRowData: TableValueRow[] = [];
    e.cellChangedEvents.forEach(cellChangedEvent => {
      const row = _tableValue.find(row => row.id === cellChangedEvent.rowId);
      row && oldRowData.push(row);
    });
    this.postChangeCellCommand(this.copiedValue, undefined, oldRowData);
    this.grid.gridApi.refreshCells({ force: true }); // indirectly invokes cssRules, which picks up the empty and missing states from table.value.
  }

  /**
   * Computes cell change values and context from a cell value change event.
   * Note: values might be equal, e.g. not an actual change.
   * @returns values and context if retrievable, otherwise undefined.
   * @throws error if the impossible happens
   */
  private getCellChange(e: BptGridCellValueChangedEvent): CellChange | undefined {
    if (!e.gridId) throw new Error(missingGridIdMessage);
    if (!e.rowId) throw new Error(missingRowIdMessage);
    if (!e.field) throw new Error(missingFieldMessage);

    const row = this.getChangedRow(e);
    if (!row) return; // This would be okay if the row wasn't here yet, if that's possible.

    const column = this.table.columnDefinitions.find(c => c.field === e.field);
    if (!column) throw new Error(missingColumnMessage);

    // For Specification, it's possible to get into here where it's trying to paste a non-spec (like a string) into a spec column
    // If that happens then this method will be hit a second time and contain the correct data (if the user in fact copied a spec from ELN)
    if (column.columnType === APIColumnType.Specification && e.newValue && !e.newValue?.type) return undefined;

    // Re omitBy null: No ExperimentDataValue subtype allows null properties so removing them doesn't change the type
    const newValue = this.dataValueService.getExperimentDataValue(column.columnType, e.newValue);

    let oldValue: ExperimentDataValue;
    if (e.source?.includes('ruleId')) {
      oldValue = omitBy(e.oldValue, v => v === null) as ExperimentDataValue
    } else {
      oldValue = omitBy(row[e.field].value, v => v === null) as ExperimentDataValue;
    }
    return { source: e.source, gridId: e.gridId, rowId: e.rowId, row, column, field: e.field, newValue, oldValue };
  }

  cellValueEditing(e: BptGridCellValueChangedEvent) {
    if (this.fillWithNATriggeredForCurrentModule) this.fillWithNATriggeredForCurrentModule = false;
    const change = this.getCellChange(e);
    if (!change) return;

    const { source, field, newValue, oldValue } = change;
    if (isSerializablyEqual(omit(newValue, ['source']), omit(oldValue, ['source']))) return; // not actually a value change
    if (
      source === 'collaborativeEdit' ||
      this.experimentWarningService.isUserAllowedToEdit ||
      this.columnsToPopulateByAddRowResponse.includes(field)
    ) {
      this.cellValueChanged(change);
    }
  }

  /**
   * Handle cell value changed due to reason indicated by e.source:
   *   * grid event
   *   * collaborativeEdit (which means we don't send a change command)
   *   * specificationEdit (which means we don't send a change command)
   *   * systemFields ???
   */
  private cellValueChanged(e: CellChange) {
    if (!e.row) return; // This would be okay if the row wasn't here yet, if that's possible.
    if (!e.column) throw new Error(missingColumnMessage);

    // For Specification, it's possible to get into here where it's trying to paste a non-spec (like a string) into a spec column
    // If that happens then this method will be hit a second time and contain the correct data (if the user in fact copied a spec from ELN)
    if (e.column.columnType === APIColumnType.Specification && e.newValue && !e.newValue?.type) return;
    const row = e.row;
    if (this.isStepsTable && e.field === 'Value' && 'StepType' in row && (row.StepType.value as StringValue).value === 'Measurement' && 'value' in e.newValue) {
      // Not implemented in recipes because it only applies when editing observable data.
      this.stepsTableCellValueChanged(e);
    } else {
      this.performCellValueChanged(e);
    }
    if (e.field === keyColumnField) {
      this.refreshDataSource();
      this.updateKeyColumn();
    }
  }

  /**
   * Special Steps Table power for cell value changes.
   * Not implemented in recipes because it only applies when editing observable data.
   */
  private stepsTableCellValueChanged(e: CellChange) {
    const processNewValue = (cellChange: CellChange) =>
      ('value' in cellChange.newValue && typeof cellChange.newValue.value === 'object' && cellChange.newValue.value instanceof Quantity)
        ? this.performCellValueChanged({ ...cellChange, newValue: processQuantity(cellChange.newValue.value) })
        : undefined;

    const processQuantity = (quantity: Quantity) =>
      quantity.isNA
        ? { type: ValueType.Number, state: ValueState.NotApplicable } as Quantity
        : quantity.valueOf();
    processNewValue(e);
  }

  private performCellValueChanged(e: CellChange) {
    const oldRow = cloneDeep(e.row);
    const oldCellValue: ModifiableDataValue = cloneDeep(oldRow[e.field]);
    const newCellValue = DataRecordService.getModifiableDataValue(e.newValue, oldCellValue);
    if (isSerializablyEqual(newCellValue, oldCellValue)) return;

    if (newCellValue && !newCellValue.value) throw Error('Expected newCellValue to be a ModifiableDataValue');

    if (e.source !== 'specificationEdit') e.row[e.field] = newCellValue;

    if (
      e.source === 'collaborativeEdit' ||
      e.newValue === undefined ||
      e.source === 'systemFields' ||
      e.source === 'specificationEdit'
    ) {
      return;
    }

    if (e.newValue instanceof Quantity) DataValueService.pruneQuantity(e.newValue);

    const command = {
      experimentId: this.experimentId,
      rowIds: [e.rowId],
      columnValues: [{ propertyName: e.field, propertyValue: e.newValue }],
      tableIds: [e.gridId],
      activityId: this.activityId
    };
    this.postChangeCellCommand(command, e.source, [oldRow]);
  }

  private getChangedRow(e: BptGridCellValueChangedEvent) {
    e.rowId = this.handleModifiableRowId(e.rowId);
    if (e.newValue instanceof Quantity) DataValueService.pruneQuantity(e.newValue);

    return this.table.value.find((row: TableValueRow) => row.id === e.rowId);
  }

  handleModifiableRowId(rowId: any): string {
    if (rowId.hasOwnProperty('isModified')) console.error('LOGIC ERROR: Code or data is junk because row is not just a string.');
    return rowId.hasOwnProperty('isModified')
      ? ((rowId as ModifiableDataValue).value as StringValue).value
      : rowId;
  }

  private getCellExperimentDataValue(propertyName: string, newValue: any): Cell {
    const column = this.table.columnDefinitions.find((col) => col.field === propertyName);

    if (!column) throw new Error(missingFieldMessage);

    return {
      propertyName,
      propertyValue: this.dataValueService.getExperimentDataValue(column.columnType, newValue)
    };
  }

  getCellForRuleAction(propertyName: string, primitiveOrDataValue: any): Cell {
    const column = this.table.columnDefinitions.find((col) => col.field === propertyName);
    if (!column) throw new Error(missingFieldMessage);
    if (primitiveOrDataValue?.state) {
      // Need to check if any specific data record to be created for other than value change.
      return {
        propertyName,
        propertyValue: primitiveOrDataValue
      };
    } else {
      return {
        propertyName,
        propertyValue: this.dataValueService.getExperimentDataValue(
          column.columnType,
          primitiveOrDataValue
        )
      };
    }
  }

  /**
   * Posts change command. Updates Table Model, including completion tracking.
   */
  private postChangeCellCommand(
    commandValues: ChangeCellCommand | ChangeRecipeCellCommand,
    eventSource: string | undefined,
    oldCellValue?: TableValueRow[]
  ) {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.handleUserEditingConfirmation(commandValues as ChangeCellCommand, eventSource, oldCellValue);
      this.tableDataService.postChangeCellCommand(this, commandValues, this.activityId);
    }
  }

  private handleUserEditingConfirmation(commandValues: ChangeCellCommand, eventSource: string | undefined, oldCellValue?: TableValueRow[]) {
    if (!this.grid?.gridApi) return;

    this.updateTableModel(commandValues, oldCellValue ?? [])
    this.grid.gridApi.refreshCells({ force: true });
    if (!this.fillWithNATriggeredForCurrentModule) {
      this.cellChangedEventRuleEvaluation(commandValues, eventSource);
    }
    commandValues.ruleContext = this.RuleHandler.getRuleCommandContext(eventSource);
    this.tableService
      .tableEventsChangeCellsPost$Json({ body: cloneDeep(commandValues) })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.validation.successes.push(
            $localize`:@@TableRowEditedSuccessfully:Row Edited successfully`
          );
        },
        error: (error: any) => {
          console.log(JSON.stringify(error));
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received the following error from server`;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    this.trackCompletion();
  }

  trackCompletion() {
    const tableInfo: TableDataForCompletionTracking = {
      tableId: this.table.tableId,
      tableTitle: this.table.itemTitle
    };
    const rows = this.table.value.filter(row => !TableDataService.rowIsRemoved(row) && !TableDataService.rowIsPlaceholder(row));
    this.completionPercent = this.completionTrackingService.populateCompletionPercentage(
      rows,
      this.completionPercent,
      this.columnDataFieldIds,
      tableInfo,
      true
    );
  }

  private watchRuleActions() {
    this.watchRuleActionsOfSetCellValue();
    this.watchRuleActionsOfAddBlankRow();
  }

  private watchRuleActionsOfSetCellValue() {
    this.subscriptions.push(
      this.ruleActionNotificationService.SetCellValueActionNotification.subscribe({
        next: this.applyRuleCellValue.bind(this)
      })
    );
  }

  private watchRuleActionsOfAddBlankRow() {
    this.subscriptions.push(
      this.ruleActionNotificationService.AddNewRowActionNotification.subscribe({
        next: this.addingNewRowInstructionFromRule.bind(this)
      })
    );
  }

  private addingNewRowInstructionFromRule(
    notification: RuleActionNotification<SetValueNotificationEvent>
  ) {
    console.log('Rule Notification', notification);
    if (this.table.tableId !== notification.ruleContext.templateInstanceId) {
      return;
    }
    this.grid.addRows(new Event(JSON.stringify(notification.ruleContext)));
  }

  private findRowFromTableAndGrid(rowId: string, fieldId: string): CellFullContext {
    const gridRowNode = this.grid.gridApi.getRowNode(rowId);
    const tableRow = this.table.value.find((r) => r.id === rowId) as {
      [key: string]: ModifiableDataValue;
    };
    const columnDefinition = this.table.columnDefinitions?.find((c) => c.field === fieldId);
    let valid = false;
    if (gridRowNode && columnDefinition?.columnType) {
      valid = true;
    }
    return {
      rowId,
      gridRowNode: gridRowNode as IRowNode,
      tableRow,
      columnDefinition: columnDefinition as ColumnSpecification,
      fieldId,
      valid
    };
  }

  private setRulePrimitiveValue(
    notification: RuleActionNotification<SetValueNotificationEvent>,
    cellContext: CellFullContext,
    changeCellCommand: ChangeCellCommand
  ) {
    const ruleAction = notification.action as RuleActionObjectResult;

    const parsedDataValue = ruleAction.Value?.state ? ruleAction.Value : this.dataValueService.getExperimentDataValue(cellContext.columnDefinition?.columnType, ruleAction.Value);
    const primitiveValue = this.dataValueService.getPrimitiveValue(
      cellContext.columnDefinition.columnType,
      { isModified: true, value: parsedDataValue }
    );
    this.updateTableModel(changeCellCommand, []);
    cellContext.gridRowNode?.setDataValue(
      cellContext.fieldId,
      primitiveValue,
      JSON.stringify(notification.ruleContext)
    );

    this.trackCompletion();
  }

  public applyRuleCellValue(notification: RuleActionNotification<SetValueNotificationEvent>) {
    console.log('Rule Notification', notification);

    if (this.table.tableId !== notification.ruleContext.templateInstanceId) {
      return;
    }
    const ruleAction = notification.action as RuleActionObjectResult;
    const cellContext = this.findRowFromTableAndGrid(
      notification.sourceEvent.currentRow.id as string,
      notification.action.Target
    );

    try {
      if (cellContext.valid) {
        const changeCellCommand: ChangeCellCommand = {
          experimentId: this.experimentId,
          rowIds: [cellContext.rowId],
          columnValues: [this.getCellForRuleAction(cellContext.fieldId, ruleAction.Value)],
          tableIds: [this.table.tableId],
          activityId: this.activityId
        };
        if (
          cellContext.gridRowNode?.data[cellContext.fieldId] ===
          (ruleAction.Value.state ? ruleAction.Value.value : ruleAction.Value)
        ) {
          this._ruleHandler.continueOnCorrelatedRulesEvaluation(
            RuleEvents.CellChanged,
            { changeCellCommand, rows: this.table.value },
            JSON.stringify(notification.ruleContext)
          );
        } else {
          this.setRulePrimitiveValue(notification, cellContext, changeCellCommand);
        }
      }
    } catch (error) {
      console.error(
        'ELN-RuleEngine: could not set value on cell',
        notification.action,
        cellContext.tableRow,
        error
      );
    }
  }

  private cellChangedEventRuleEvaluation(
    commandValues: ChangeCellCommand,
    eventSource: string | undefined
  ): string | undefined {
    return this._ruleHandler.cellChanged(
      commandValues.columnValues[0].propertyName,
      // `as unknown` !!! See TableValueRow docs
      {
        changeCellCommand: commandValues,
        rows: this.table.value as unknown as { [key: string]: ModifiableDataValue & string }[]
      },
      eventSource
    )?.correlationId;
  }

  private rowAddedEventRuleEvaluation(
    rowAddedResponse: AddRowResponse,
    eventSource: string | undefined
  ): string | undefined {
    return this._ruleHandler.rowAdded(
      RuleEvents.RowAdded,
      // `as unknown` !!! See TableValueRow docs
      {
        rowAddedResponse,
        rows: this.table.value as unknown as { [key: string]: ModifiableDataValue & string }[]
      },
      eventSource
    )?.correlationId;
  }

  private updateTableModel(commandValues: ChangeCellCommand, oldRowImage: TableValueRow[]): void {
    if (oldRowImage.length === 0) {
      oldRowImage = this.table.value.filter(row => commandValues.rowIds.includes(row.id));
    }
    commandValues.rowIds.forEach(rowId => {
      const oldRow = oldRowImage.find(row => row.id === rowId);
      const newRow = this.table.value.find(row => row.id === rowId);
      for (const { propertyName, propertyValue } of commandValues.columnValues) {
        if (newRow && oldRow) {
          newRow[propertyName] = DataRecordService.getModifiableDataValue(
            propertyValue,
            oldRow[propertyName]
          );
          this.checkForChangeReason(newRow[propertyName], oldRow[propertyName], commandValues);
        }
      }
    });
  }

  private checkForChangeReason(newValue: ModifiableDataValue, oldValue: ModifiableDataValue, command: ChangeCellCommand) {
    if (newValue.isModified) {
      const isSingleCellChange = command.columnValues.length === 1 && command.rowIds.length === 1;
      ChangeReasonService.oldValue = isSingleCellChange ? oldValue.value : undefined;
      ChangeReasonService.changeReasonId = uuid();
      command.changeReasonId = ChangeReasonService.changeReasonId;
    }
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    this.lockTimeOut = window.setTimeout(() => {
      this.sendInputStatus(LockType.lock, e.gridId ?? '', e.rowId ?? '', e.field ?? '');
    }, 3000);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.unlock, e.gridId ?? '', e.rowId ?? '', e.field ?? '');
  }

  cellKeyDownEvent(e: BptGridCellEditEvent) {
    // 1. Skipping sending input status while key down for Tab, Enter and Escape keys triggered
    // as these events denotes edit is stopped for the cell and cellEditStoppedEvent is called before cellKeyDownEvent
    // cellEditStoppedEvent sends unlock notification for the cell so cell would be unlocked
    // when cellKeyDownEvent is hit after cellEditStoppedEvent, we are skipping sending lock notification for the cell which is already unlocked
    // 2. Skipping sending lock status when moving around table cells using keyboard navigation keys
    // where there is no real edit on the cells but cell lock were getting triggered
    if (e.keyCode !== undefined && !this.collaborativeEditSuppressKeys.includes(e.keyCode)) {
      window.clearTimeout(this.lockTimeOut);
      this.sendInputStatus(LockType.lock, this.table.tableId ?? '', e.rowId ?? '', e.field ?? '');
    }
  }

  dropDownVisible(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.lock, this.table.tableId ?? '', e.rowId ?? '', e.field ?? '');
  }

  calendarPanelVisible(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.lock, this.table.tableId ?? '', e.rowId ?? '', e.field ?? '');
  }

  sendInputStatus(lockType: LockType, tableId: string, rowId: string, field: string, multiSelectCells = false) {
    const fieldLock = new CellLock(
      this.experimentId,
      lockType,
      this.moduleId,
      this.activityId,
      this.experimentNotificationService.getCollaborator(),
      tableId,
      rowId,
      field,
      undefined,
      multiSelectCells
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  private postAddRowsCommandAndUpdateTableCompletion(commandValues: AddRowsCommand, eventSource: string | undefined) {
    const nonDataColumns = [TableDataService.rowSelectedField, TableDataService.repeatField, TableDataService.repeatForField, TableDataService.repeatWithField];
    commandValues.rows.forEach(row => remove(row.data, v => nonDataColumns.includes(v.propertyName)));
    commandValues.ruleContext = this.RuleHandler.getRuleCommandContext(eventSource);
    this.tableService
      .tableEventsAddRowsPost$Json({ body: commandValues })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (rowAddedResponse) => {
          this.applyAddRowResponse(rowAddedResponse);
          this.rowAddedEventRuleEvaluation(rowAddedResponse, eventSource);
          this.validation.successes.push(
            $localize`:@@TableRowAddedSuccessfully:Row Added successfully`
          );
        },
        error: () => {
          //error is handled by ExceptionInterceptor
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    this.numberOfRows++;
  }

  /**
   * Upon request for row or cell history, '
   * * Queries for table, statement, and client-facing note events.
   * * Filters for row or cell events
   * * Displays dialog
   */
  loadTableCellHistoryDialog(rowId: string, field: string) {
    this.isLoading = true;
    this.auditHistoryService
      .loadTableAuditHistory(this.table.experimentId, this.table.tableId)
      .subscribe({
        next: (response) => this.bindDataToAuditHistoryDialog(rowId, field, response.dataRecords)
      });
  }

  private bindDataToAuditHistoryDialog(rowId: string, field: string, dataRecords: ExperimentDataRecordNotification[]) {
    const wholeRow = field === rowIndexField;
    const row = this.table.value.find(r => r.id === rowId);

    const cellChangedRecords: ExperimentDataRecordNotification[] = dataRecords
      .filter((d): d is CellChangedEventNotification => d.eventContext.eventType === ExperimentEventType.CellChanged)
      .filter((c) => c.rowIds[0] === rowId && (wholeRow || c.columnValues[0]?.propertyName === field));

    if (wholeRow) {
      // Once a field exists, it doesn't go away so can iterate over fields currently in row
      for (const key of Object.keys(row ?? {})) {
        if (key === rowIndexField) continue;

        this.checkForPreInitializedData(rowId, key, dataRecords, cellChangedRecords);
      }
    } else {
      this.checkForPreInitializedData(rowId, field, dataRecords, cellChangedRecords);
    }

    // Concatenating records puts them in the wrong order. So, reorder, assuming that the records with the same eventTime are in the right order (sortBy is stable)
    const cellHistoryRecords = sortBy(
      [
        ...cellChangedRecords,
        ...this.getClientFacingNoteRecords(dataRecords, rowId, field, wholeRow),
        ...this.getStatementRecords(dataRecords, rowId, field, wholeRow)
      ],
      r => r.eventContext.eventTime
    );

    this.isLoading = false;
    this.dynamicDialogRef?.close();
    const title = this.generateTitleForRowOrCell(row, field, wholeRow);
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(cellHistoryRecords, title, NodeType.Table, this.table.tableId);
  }

  getStatementRecords(dataRecords: ExperimentDataRecordNotification[], rowId: string, field: string, wholeRow: boolean): ExperimentDataRecordNotification[] {
    return dataRecords
      .filter((d): d is StatementAppliedEventNotification => d.eventContext.eventType === ExperimentEventType.StatementApplied)
      .filter((d: StatementAppliedEventNotification) => {
        if (d.nodeId !== this.table.tableId) return false;
        const matchedContentDetails: StatementContentDetails[] = d?.contentDetails.filter(c => c.path[0] === rowId && (wholeRow || c?.path[1] === field));
        d.contentDetails = matchedContentDetails;
        return true;
      });
  }

  getClientFacingNoteRecords(dataRecords: ExperimentDataRecordNotification[], rowId: string, field: string, wholeRow: boolean): ExperimentDataRecordNotification[] {
    return dataRecords
      .filter((d): d is ClientFacingNoteCreatedEventNotification | ClientFacingNoteChangedEventNotification =>
        d.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
        d.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
      )
      .filter(d => {
        if (d.nodeId !== this.table.tableId) return false;

        const note = this.experiment.clientFacingNotes.find(n => n.number === d.number);
        return note?.path[0] === rowId && (wholeRow || note?.path[1] === field);
      });
  }

  /**
   * Generates a formatted title for, say, a History dialog, for a cell or a whole row. Include table title and row index.
   * Examples:
   *   * Steps → Row Number 12 - History
   *   * Steps → Row Number 12 → Compliance - History
   */
  private generateTitleForRowOrCell(row: TableValueRow | undefined, field: string, wholeRow: boolean) {
    const rowNumber = (row?.rowIndex?.value as StringValue | undefined)?.value;
    const column = this.columnDefinitions.find(c => c.field === field);
    // Not sure of the reason for ever displaying field and not sure of the reason to distort it by prettying it up
    const label = wholeRow ? undefined : column?.label ?? field.split('_').map(c => c.replace(/^./, cp => cp.toLocaleUpperCase())).concat(' ').join(' ').trimEnd();
    return [this.table.itemTitle, rowNumber && rowNumberText + ELNAppConstants.WhiteSpace + rowNumber, label]
      .filter(s => s).join(`${ELNAppConstants.WhiteSpace}→${ELNAppConstants.WhiteSpace}`);
  }

  /**
   * Prepends synthesized CellChangedEventNotification for field in row for the 0 or 1 data record that added the row if it contains a non-empty value.
   */
  private checkForPreInitializedData(
    rowId: string,
    field: string,
    dataRecords: ExperimentDataRecordNotification[],
    cellChangedRecords: ExperimentDataRecordNotification[]
  ) {
    // The cell is in a row that was added by some 1 record of some type. It could have brought a non-empty value with it.
    // If so, synthesize a CellChangedEventNotification.
    const projectedRowAdds = dataRecords
      .filter((record): record is RowsAddedForEachEventNotification => record.eventContext.eventType === ExperimentEventType.RowsAddedForEach)
      .flatMap(record => record.tables
        .flatMap(table => table.rows
          .flatMap(row => ({
            insertAfterRowId: row.insertAfterRowId,
            tableId: table.tableId,
            rows: [row.data],
            experimentId: record.eventContext.experimentId,
            ...record
          }))
        )
      );
    const rowAddedRecord = dataRecords
      .filter((record): record is AddRowEventNotification => record.eventContext.eventType === ExperimentEventType.RowsAdded)
      .concat(projectedRowAdds)
      .find(record => record.rows.find(r => r.id === rowId));

    if (!rowAddedRecord) return;

    const row = rowAddedRecord?.rows.find(row => row.id === rowId);
    const nonEmptyCell = row?.data.find(cell => cell.propertyName === field && cell.propertyValue.state !== ValueState.Empty);

    if (nonEmptyCell) {
      cellChangedRecords.unshift(this.createCellChangedRecord(nonEmptyCell, rowAddedRecord, rowId));
    }
  }

  private createCellChangedRecord(
    cellData: TableCell,
    dataRecord: AddRowEventNotification,
    rowId: string
  ): CellChangedEventNotification {
    const defaultRecordTypes = dataRecord.recordTypes[ExperimentRecordTypesHelper.DefaultKeyForRecordType];
    const useDefaultRecordTypes = dataRecord.eventContext.eventType === ExperimentEventType.RowsAddedForEach && cellData.propertyName !== rowIndexField;
    const recordTypes = useDefaultRecordTypes ? defaultRecordTypes : [ExperimentRecordType.New];
    if (cellData.propertyValue.source === ExperimentDataSource.Recipe && !recordTypes.includes(ExperimentRecordType.Recipe)) recordTypes.push(ExperimentRecordType.Recipe);
    const eventContext = cloneDeep(dataRecord.eventContext);
    eventContext.eventType = ExperimentEventType.CellChanged;
    return {
      columnValues: [cellData],
      eventContext,
      notifications: {
        notifications: []
      },
      recordTypes: { [`${rowId}:${cellData.propertyName}`]: recordTypes },
      ruleContext: dataRecord.ruleContext,
      rowIds: [rowId],
      tableIds: [this.table.tableId]
    };
  }

  loadAuditHistoryDialogForThisTable() {
    this.isLoading = true;
    this.auditHistoryService.loadTableAuditHistory(this.table.experimentId, this.table.tableId).subscribe({
      next: (data) => {
        this.getRecipeBlobsDetails(data);
      }
    });
  }

  /**
   * Gets called to get recipe blob details before loading audit history dialog
   */
  private getRecipeBlobsDetails(records: AuditHistoryDataRecordResponse) {
    this.subscriptions.push(this.experimentService.areRecipeBlobDetailsFetched()
      .pipe(take(1))
      .subscribe({
        next: ((response) => {
          if (response) this.bindDataToTableAuditHistory(records);
        })
      }));
    this.experimentService.addRecipeAppliedEventBlobDetailsToCache(records);
  }

  private bindDataToTableAuditHistory(data: AuditHistoryDataRecordResponse) {
    this.isLoading = false;
    const dataRecords = data.dataRecords.filter(
      (dr) =>
        dr.eventContext.eventType !== ExperimentEventType.ActivityReferenceTemplateApplied &&
        dr.eventContext.eventType !== ExperimentEventType.ExperimentNodeOrderChanged
    );
    this.dynamicDialogRef?.close();
    const context = this.table.itemTitle.concat(ELNAppConstants.WhiteSpace) + $localize`:@@Table:Table`;
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(dataRecords, context, NodeType.Table, this.table.tableId);
  }

  /**
   * Updates table.value by adding the new row as id string and other fields as ModifiableDataValue (unmodified since this is a new row).
   * And, update grid row for certain field value that the service generates such as RowIndex (See columnsToPopulateByAddRowResponse)
   */
  private applyAddRowResponse(rowAddedResponse: AddRowResponse) {
    const newlyAddedRows: any[] = [];
    const toModifiableDataValue: (value: ExperimentDataValue) => ModifiableDataValue = (value) => ({
      isModified: false, // value in cell would not be modified since this is new row and therefore this can't be a change from non-empty to a different value
      value
    });
    // Always a one row can be added at a time. TIME BOMB for Product Backlog Item 3194846: Tables - Enable in ELN - Add x number of rows at once
    rowAddedResponse.message.values.forEach((newlyAddedRow) => {
      const newRow: TableValueRow = {
        // id value is a string
        ...mapValues(newlyAddedRow, toModifiableDataValue),
        id: newlyAddedRow.id
      };
      newlyAddedRows.push(newRow);
      this.table.value.push(newRow);
    });

    newlyAddedRows.forEach((newRow) => {
      Object.keys(newRow)
        .filter((columnId) => this.columnsToPopulateByAddRowResponse.includes(columnId))
        .forEach((columnId) => {
          const columnDefinition = this.table.columnDefinitions?.find(
            (c) => c.field === columnId
          ) as ColumnDefinition;
          const primitiveValue = this.dataValueService.getPrimitiveValue(
            columnDefinition.columnType as FieldOrColumnType,
            newRow[columnId] as ModifiableDataValue
          );
          const gridRow = this.getGridApi().getRowNode(newRow.id as string);
          if (!gridRow) {
            return;
          } else {
            gridRow.setDataValue(columnId, primitiveValue, 'systemFields');
          }
        });
    });

    this.grid.gridApi.refreshClientSideRowModel('sort');
    this.grid.gridApi.refreshCells({ force: true });
    this.trackCompletion();
  }

  public getGridApi(): GridApi {
    return this.grid.gridApi;
  }

  public shortContent(message: string): string {
    const limit = 30;
    return message.length <= limit ? message : message.substring(0, limit) + '…';
  }

  public getHoverOverText(message = ''): string {
    return (
      $localize`:@@internalCommentsClickToView:Internal Comments:${this.shortContent(
        message
          .replace(/<[^>]*>/g, ' ')
          .replace(/\s{2,}/g, ' ')
          .replace(/&nbsp;/g, '')
      )}(click to view)`
    );
  }

  internalCommentsChanged() {
    this.subscriptions.push(
      this.commentService.refreshInternalComment.subscribe((currentContext: CommentsResponse) => {
        if (!this.grid?.gridApi) {
          return;
        }
        this.experiment.internalComments = currentContext;
        this.grid.gridApi.refreshCells({ force: true });
      })
    );
  }

  private buildContextMenuItems(): void {
    this.items = [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: 'pi pi-comments',
        id: `eln-context-table-title-${this.table.itemTitle.replace(/ /g, '')}`,
        command: (_event$) => {
          this.loadInternalCommentForTableTitleLevel();
        }
      },
      {
        label: $localize`:@reTitle:Retitle`,
        icon: 'icon-pencil',
        id: `eln-context-table-retitle-${this.table.itemTitle.replace(/ /g, '')}`,
        disabled: this.isRetitleOptionDisabled(),
        command: (_event$) => {
          this.retitleEnabled = true;
        }
      }
    ];
  }

  private isRetitleOptionDisabled(): boolean {
    return this.userService.hasOnlyReviewerRights() ||
      !ExperimentNodeRetitleService.HasPermissionToEditTitle ||
      ExperimentNodeRetitleService.NotAllowedWorkflowStates.includes(
        this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
      );
  }
  isCrossReference(tableId: string, activity: Activity | undefined) {
    return activity?.activityReferences.compendiaReferencesTableId === tableId ||
      activity?.activityReferences.documentReferencesTableId === tableId;
  }

  onUnitChange(unitId: string) {
    this.unitIdForBalance = unitId;
  }

  loadRemovedRowsDialog() {
    this.tableDataService.loadRemovedRowsDialog(this, this.activityId);
  }

  public loadRepeatForEachDialog() {
    const activity = this.experiment.activities.find(
      (act) => act.activityId === this.experimentService.currentActivityId
    );

    const currentActivityInputs = this.experiment.activityInputs?.find(ai => ai.activityId === activity?.activityId);

    if (!activity) return;

    const tableCount = activity?.dataModules.reduce((sum: number, m: Module) => (sum + m.items.filter(itm => itm.itemType === NodeType.Table).length), 0);

    if (tableCount > 1) {
      const dialogData = {
        table: this.table,
        activity
      };
      this.dialogService.open(RepeatTargetModalComponent, {
        width: '50%',
        height: '40%',
        autoZIndex: true,
        closeOnEscape: true,
        data: dialogData,
        styleClass: 'eln-repeat-dialog',
        resizable: false,
        maximizable: false
      }).onClose.subscribe({
        next: (targetNode: Table | Activity | undefined) => {
          if (!targetNode) return;
          this.loadRepeatForEachSelectionDialog(targetNode, activity.activityId, currentActivityInputs);
        }
      });
    } else {
      this.loadRepeatForEachSelectionDialog(activity, activity.activityId, currentActivityInputs);
    }
  }

  /** Everything in here applies to 1 or more tables, possibly _not including this table_ */
  private loadRepeatForEachSelectionDialog(targetNode: Table | Activity, currentActivityId: string, currentActivityInputs?: ActivityInputNode) {
    const data: RepeatGroupInputSelectorDialogData = {
      targetNode,
      activityInputs: {
        activityId: currentActivityId,
        sampleAliquots: currentActivityInputs?.aliquots ?? [],
        materialAliquots: currentActivityInputs?.materials ?? [],
        instrumentEvents: currentActivityInputs?.instruments ? [currentActivityInputs?.instruments] : [],
      }
    };

    this.dialogService.open(RepeatGroupTableInputSelectorComponent, {
      header: $localize`:@@repeatHeader:Select Samples to Repeat`,
      width: '70%',
      height: '70%',
      closeOnEscape: true,
      data,
      styleClass: 'eln-repeat-dialog',
      resizable: true,
      maximizable: true
    }).onClose.subscribe({
      next: (addRowsData: any) => {
        if (addRowsData) {
          // Send command to the API.
          this.tableService
            .tableEventsAddRowsForEachPost$Json({ body: addRowsData })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
              next: (response: AddRowsForEachResponse) => {
                this.onRowsAddedForEachInput(response.eventNotification);
                this.setRepeatColumnHiddenState();
              },
              error: (error: any) => {
                console.log(JSON.stringify(error));
                this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received the following error from server`;
              },
              complete: () => {
                this.isLoading = false;
              }
            });
        }
      }
    });
  }

  private onRowsAddedForEachInput(data: RowsAddedForEachEventNotification) {
    this.experimentNotificationService.dataRecordReceiver.next(data);
    this.validation.successes.push(
      $localize`:@@rowsAddedSuccessfully:Rows Added successfully`
    );
    this.dataRecordService.applyStepRenumberPostRowsAddedForEach(data.activityId, data.tables.map(t => t.tableId));
  }
}
