import { Injectable } from "@angular/core";
import { ExperimentDataRecordNotification, ExperimentRecipeAppliedEventNotification, ExperimentRecordType } from "../../api/data-entry/models";
import { NodeType, PromptType } from "../../api/models";
import { objectCache } from "../../shared/rx-js-helpers";
import { AuditHistory } from "./audit-history.interface";
import { ExperimentRecordTypesHelper } from "./experiment-data-record-types-helper";
import { DataRecordUtilityService } from "./data-record-utility.service";

@Injectable({
  providedIn: 'root'
})
export class RecipeDataRecordService {

  private readonly cover = $localize`:@@Cover:Cover`;
  private readonly labItemsTitle = $localize`:@@activityLabItemsPageTitle:Lab Items`;
  private readonly promptsTitle = $localize`:@@prompts:prompts`;

  constructor(private readonly dataRecordUtilityService: DataRecordUtilityService) {
  }

  public getRecipeAppliedRecord(experimentRecord: ExperimentDataRecordNotification, nodeType?: NodeType, nodeId?: string | undefined) {
    const promptTableValues: { [key: string]: string } = {
      invalid: $localize`:@@invalid:invalid`,
      materials: $localize`:@@materialPrompts: Materials Prompts`,
      instruments: $localize`:@@instrumentPrompts:Instruments Prompts`,
      columns: $localize`:@@columnPrompts:Columns Prompts`,
      consumablesAndSupplies: $localize`:@@consumablesAndSuppliesPrompts:Consumables And Supplies Prompts`,
      preparations: $localize`:@@preparationsPrompts:Preparations Prompts`
    }

    const record = experimentRecord as ExperimentRecipeAppliedEventNotification;
    const recordType = $localize`:@@recordType-recipe:${ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.Recipe]}`;
    const recipeApplied = $localize`:@@recipeApplied:Recipe Applied`;
    let recipeNumber = '';
    let nodeTitle = ''
    let promptDetails = {
      prompts: []
    };
    const auditHistory = [];
    if (record.eventContext.blobReference?.blobName) {
      const recipe = objectCache[record.eventContext.blobReference?.blobName];
      recipeNumber = recipe?.recipeNumber;
      promptDetails = recipe?.promptDetails;
      nodeTitle = recipe?.recipeFormAndTableTitles[nodeId ?? ''];
    }

    if (promptDetails && promptDetails.prompts.length > 0) {
      promptDetails.prompts.forEach((activityPrompt: any) => {
        const auditDisplayContext = this.dataRecordUtilityService.getActivityContext(activityPrompt.activityId);
        const tableValue = promptTableValues[activityPrompt.type ?? PromptType.Invalid];
        auditHistory.unshift(this.dataRecordUtilityService.getHistory(
          record,
          `${auditDisplayContext.fullPath} > ${this.labItemsTitle} > ${this.promptsTitle} > ${tableValue}`,
          $localize`:@@promptRecipe:prompt, recipe`,
          $localize`:@@Table:Table`,
          `${auditDisplayContext.fullPath} > Lab Items > prompts > ${tableValue} | promptAdded`,
          $localize`:@@promptAddedMessage:Added prompt for: ${tableValue}`,
          tableValue
        ));
      })
    }

    switch (nodeType) {
      case NodeType.Table:
        const tableAddedEvents = this.getTableNodeAddedEventHistory(recipeNumber, experimentRecord, nodeId, nodeTitle);
        auditHistory.unshift(tableAddedEvents);
        break;
      case NodeType.Form:
        const formAddedEvents = this.getFormNodeAddedEventHistory(recipeNumber, experimentRecord, nodeId, nodeTitle);
        auditHistory.unshift(formAddedEvents);
        break;
      default:
        const contextType = `${recipeNumber}, ${record.recipeName}`;
        if (!experimentRecord.recordTypes['prompts']) {
          auditHistory.push(this.dataRecordUtilityService.getHistory(
            experimentRecord,
            `${this.cover} > ${recipeApplied}`,
            recordType,
            contextType,
            `${this.cover} > ${recipeApplied}`,
            contextType,
            recordType
          ));
        }
    }

    return auditHistory;
  }

  private getFormOrTableContextForRecipe(nodeId: string | undefined, title: string): string {
    const module = this.dataRecordUtilityService.allModulesInExperiment.find(m => m.items.find(i => i.nodeId === nodeId));
    const moduleTitle = module?.moduleLabel as string;
    const activityTitle = this.dataRecordUtilityService.experiment.activities.find(a => a.dataModules.some(m => m.nodeId === module?.nodeId))?.itemTitle ?? $localize`:@@noTitle:No Title`;
    return `${activityTitle} > ${moduleTitle} > ${title}`;
  }

  private getTableNodeAddedEventHistory(recipeNumber: string, experimentRecord: ExperimentDataRecordNotification,
    nodeId: string | undefined, nodeTitle: string): AuditHistory {

    const recordType = $localize`:@@recordType-recipe:${ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.Recipe]}`;
    const context = this.getFormOrTableContextForRecipe(nodeId, nodeTitle);

    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      context,
      recordType,
      $localize`:@@Table:Table`,
      context,
      $localize`:@@TableLoadedFrom:Table loaded from ${recipeNumber}`,
      $localize`:@@TableLoadedFrom:Table loaded from ${recipeNumber}`
    );
  }

  private getFormNodeAddedEventHistory(recipeNumber: string, experimentRecord: ExperimentDataRecordNotification,
    nodeId: string | undefined, nodeTitle: string): AuditHistory {

    const recordType = $localize`:@@recordType-recipe:${ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.Recipe]}`;
    const context = this.getFormOrTableContextForRecipe(nodeId, nodeTitle);

    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      context,
      recordType,
      $localize`:@@Form:Form`,
      context,
      $localize`:@@FormLoadedFrom:Form loaded from ${recipeNumber}`,
      $localize`:@@FormLoadedFrom:Form loaded from ${recipeNumber}`,
    );
  }

}