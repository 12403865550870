import { Injectable, Renderer2 } from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { BptGridCellValueChangedEvent } from "bpt-ui-library/bpt-grid";
import { mapValues } from "lodash-es";
import { MessageService } from "primeng/api";
import { ActivityInputEventsService } from "../../../api/data-entry/services";
import { ActivityInputType, ColumnType, Instrument, ModifiableDataValue, StringValue } from "../../../api/models";
import { CellLock, LockType } from "../../../model/input-lock.interface";
import { ExperimentCollaboratorsService } from "../../../services/experiment-collaborators.service";
import { ExperimentNotificationService } from "../../../services/experiment-notification.service";
import { DataValueService } from "../../services/data-value.service";
import { ExperimentService } from "../../services/experiment.service";
import { DataRecordService } from "../../services/data-record.service";
import {
  ActivityInputCell,
  ActivityInputCellChangedEventNotification,
  ActivityInputRowRefreshedEventNotification,
  ActivityInputRowRemovedEventNotification,
  ActivityInputRowRestoredEventNotification,
  Aliquot,
  AliquotRowRefreshedDetails,
  ExperimentDataRecordNotification,
  ExperimentDataValue,
  ExperimentEventType,
  InstrumentDateRemovedChangedEventNotification,
  InstrumentDescriptionChangedEventNotification,
  InstrumentRemovedFromServiceChangedEventNotification,
  MaintenanceEventSelectedEventNotification,
  MaterialAliquot,
  MaterialRowRefreshedDetails,
  SampleTestAddedEventNotification,
  StudyActivitySelectedEventNotification
} from "../../../api/data-entry/models";
import { Subject } from "rxjs";
import { BarcodeScannerHelper } from "../../../services/barcode-scanner-helper";
import { ActivityInputSamples } from "../../model/activity-input-samples";
import { NA } from "bpt-ui-library/shared";
import { ActivityInputStudyActivity } from "../../model/activity-input-study-activity";
import { StudyActivities } from "../../../model/study-activities";
import { SampleTests } from "../../model/sample-tests";

@Injectable({
  providedIn: 'root'
})
export class ActivityInputHelper {
  private readonly backgroundColorString = 'background-color';
  private readonly pointerEventsAttr = 'pointer-events';
  private readonly colorString = 'color';
  readonly rowDeleteIdentifier = 'activity-input-delete-row';
  readonly rowRefreshIdentifier = 'activity-input-refresh-row';
  readonly rowRestoreIdentifier = 'activity-input-restore-row';
  private readonly additionalInformation = 'AdditionalInformation';
  private readonly rowIndex = 'rowIndex';
  activityInputTypes: ActivityInputType[] = [];
  currentActivityInputId?:string;

  readonly materialAliquotRestored = new Subject<string>();
  readonly studyActivitySelected = new Subject<StudyActivities>();
  readonly aliquotRestored = new Subject<string>();
  readonly aliquotRemoved = new Subject<string>();
  readonly saveTestSelection = new Subject<SampleTests>();

  // data record service events
  readonly updateSampleAliquotTableDataSource = new Subject<ActivityInputRowRemovedEventNotification>();
  readonly updateStudyActivityTableDataSource = new Subject<ActivityInputRowRemovedEventNotification>();
  readonly updateInstrumentEventDataSource = new Subject<ActivityInputRowRemovedEventNotification>();
  readonly refreshSampleTableDataSource = new Subject<ActivityInputRowRefreshedEventNotification>();
  readonly refreshStudyActivityTableDataSource = new Subject<ActivityInputRowRefreshedEventNotification>();
  readonly refreshInstrumentEventDataSource = new Subject<ActivityInputRowRefreshedEventNotification>();
  readonly updateSampleTableCellChangedDataSource = new Subject<ActivityInputCellChangedEventNotification>();
  readonly updateStudyActivityTableCellChangedDataSource = new Subject<ActivityInputCellChangedEventNotification>();
  readonly updateSampleTestDataSource = new Subject<SampleTestAddedEventNotification>();
  readonly updateStudyActivitySelectedDataSource = new Subject<StudyActivitySelectedEventNotification>();
  readonly updateMaintenanceEventDataSource = new Subject<MaintenanceEventSelectedEventNotification>();
  readonly updateInstrumentDescriptionDataSource = new Subject<InstrumentDescriptionChangedEventNotification>();
  readonly updateInstrumentDateRemovedDataSource = new Subject<InstrumentDateRemovedChangedEventNotification>();
  readonly updateInstrumentRemovedFromServiceDataSource = new Subject<InstrumentRemovedFromServiceChangedEventNotification>();

  private readonly rowActionItems = [ this.rowDeleteIdentifier, this.rowRefreshIdentifier, this.rowRestoreIdentifier ];
  constructor(
    private readonly experimentNotificationService: ExperimentNotificationService,
    public readonly experimentService: ExperimentService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly dataValueService: DataValueService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly messageService: MessageService,
    private readonly dataRecordService: DataRecordService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper) {
      this.handleSubscriptions();
      this.activityInputTypes = Object.values(ActivityInputType);
  }

  loadCellLocks(gridOptions: GridOptions, renderer: Renderer2) {
    const cellLocks = this.experimentNotificationService.inputLocks.filter(
      (item) =>
        item.lockType === LockType.lock &&
        (item as CellLock).activityId === this.experimentService.currentActivity?.activityId
    );

    this.applyCellLock(cellLocks as Array<CellLock>, gridOptions, renderer);
  }

  applyCellLock(lockList: CellLock[], gridOptions: GridOptions, renderer: Renderer2) {
    lockList.forEach((lock) => {
      if ((!this.experimentService.currentActivity && lock.activityId === this.experimentService.currentActivityId) ||
          (this.experimentService.currentActivity?.activityId === lock.activityId)) {
            if (!gridOptions?.context?.inputLocks)
              gridOptions.context = { inputLocks: {} };

            gridOptions.context.inputLocks[lock.rowId + lock.columnName] =
              lock.lockType === LockType.lock;

            if(this.rowActionItems.includes(lock.columnName)) {
              this.lockActionItems(lock, renderer);
              return;
            }
            this.lockEditableCells(lock, renderer)
      }
    });
  }

  sendInputStatus(lockType: LockType, tableId: string, rowId: string, columnName: string) {
    const fieldLock = new CellLock(
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id,
      lockType,
      this.experimentService.currentModuleId,
      this.experimentService.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      tableId,
      rowId,
      columnName
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  getPrimitiveDataValueRows(
    incomingRows: { [key: string]: ModifiableDataValue }[]
  ): { [key: string]: any }[] {
    return incomingRows.map((row) =>
      mapValues(row, (value: ModifiableDataValue, field: string) => {
        return this.dataValueService.getPrimitiveValue(
          field === 'rowIndex' ? ColumnType.Number : ColumnType.String,
          value
        );
      })
    );
  }

  cellValueChanged(e: BptGridCellValueChangedEvent, activityInputType: ActivityInputType) {
    const missingGridIdMessage =
      'Logic error: When a grid is used to render a table, gridId must be set to tableId but gridId is missing.';
    const missingFieldMessage =
      'Logic error: When a grid is used to render a table, each column definition must have a field value but at one is missing.';
    if (
      e.source === 'collaborativeEdit' ||
      e.newValue === undefined ||
      e.source === 'systemFields'
    ) {
      return;
    }

    if (!e.gridId) {
      throw new Error(missingGridIdMessage);
    }
    if (!e.field) {
      throw new Error(missingFieldMessage);
    }

    if (!e.rowId) {
      throw new Error('Missing Row Id');
    }

    const cellChangedValues = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: e.rowId,
      activityInputType: activityInputType,
      experimentId: typeof this.experimentService.currentExperiment?.id === 'undefined'
      ? ''
      : this.experimentService.currentExperiment?.id,
      propertyName: e.field,
      propertyValue: this.dataValueService.getExperimentDataValue(ColumnType.String, e.newValue)
    };
    this.activityInputEventsService
      .activityInputEventsChangeCellValuePost$Json({
        body: cellChangedValues
      })
      .subscribe({
        next: () =>
          this.messageService.add({
            key: 'notification',
            severity: 'success',
            summary: $localize`:@@TableRowEditedSuccessfully:Row Edited successfully`
          }),
        error: () =>
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: $localize`:@@ChangingCellValueFailed: Changing Cell Value Failed for this sample`,
            detail: `${cellChangedValues.activityInputReference}`
          })
      });
  }

  getHoverOverText(message = ''): string {
    return (
      $localize`:@@internalComments:Internal Comments` +
      `: ${this.shortContent(
        message.replace(/<[^>]*>/g, ' ').replace(/\s{2,}/g, ' ').replace(/\&nbsp;/g,'')
      )} (click to view)`
    );
  }

  setAndUpdateModifiableDataField(modifiedDataFields: any, modifiableFieldName: string,  newValue: StringValue): void {
    mapValues(modifiedDataFields, (value: ModifiableDataValue) => {
      mapValues(value, (dataValue: any, fieldName: string) => {
        if (fieldName === modifiableFieldName) {
          dataValue.value = newValue;
          dataValue.isModified = true; // This is always true since there are some modified fields from lims after refresh
        }
      });
    });
  }

  private shortContent(message: string): string {
    const limit = 30;
    return message.length <= limit ? message : message.substring(0, limit) + '…';
  }

  private lockEditableCells(lock: CellLock, renderer: Renderer2) {
    const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
      lock.experimentCollaborator.connectionId
    ) ?? lock.experimentCollaborator;
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"] `
    );
    if (!cell) return;

    if (lock.lockType === LockType.lock) {
      const borderColor = lockOwner?.backgroundColor ?? 'blue';
      renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      renderer.setStyle(cell, this.backgroundColorString, '#F9F9F9');
      renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
      renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      renderer.removeStyle(cell, 'border');
      renderer.removeStyle(cell, this.backgroundColorString);
      renderer.removeAttribute(cell.parentElement, 'title');
      renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }

  private lockActionItems(lock: CellLock, renderer: Renderer2) {
    const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
      lock.experimentCollaborator.connectionId
    ) ?? lock.experimentCollaborator;
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${lock.rowId}"] [id="${lock.columnName}"] `
    )
    if(!cell) return;
    if (lock.lockType === LockType.lock) {
      renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      renderer.setStyle(cell, this.colorString, '#999999');
      renderer.setStyle(cell, 'opacity', `0.5`);
      renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      renderer.removeStyle(cell, this.colorString);
      renderer.removeAttribute(cell.parentElement, 'title');
      renderer.setStyle(cell, 'opacity', `1`);
      renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }

  getSampleAliquotByAliquotNumber(aliquotNumber: string): Aliquot | undefined {
    const aliquotsWithoutActivities = this.checkAliquotsWithoutActivities(aliquotNumber);
    if (aliquotsWithoutActivities) return aliquotsWithoutActivities;
    const aliquots = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === this.experimentService.currentActivity?.activityId
    )?.aliquots;
    return aliquots?.find((a) => a.aliquotNumber === aliquotNumber);
  }

  checkAliquotsWithoutActivities(aliquotNumber: string): Aliquot | void {
    if (
      this.experimentService.currentExperiment &&
      this.experimentService.currentExperiment.activities &&
      this.experimentService.currentExperiment.activities?.length === 0 &&
      this.experimentService.currentExperiment.activityInputs &&
      this.experimentService.currentExperiment.activityInputs?.length > 0
    ) {
      const aliquot = this.experimentService.currentExperiment?.activityInputs[0]?.aliquots;
      return aliquot?.find((a) => a.aliquotNumber === aliquotNumber);
    }
  }

  getMaterialByAliquotNumber(aliquotNumber: string): MaterialAliquot | undefined {
    if (
      this.experimentService.currentExperiment &&
      this.experimentService.currentExperiment.activities &&
      this.experimentService.currentExperiment.activities?.length === 0 &&
      this.experimentService.currentExperiment.activityInputs &&
      this.experimentService.currentExperiment.activityInputs?.length > 0
    ) {
      const aliquot = this.experimentService.currentExperiment?.activityInputs[0]?.materials;
      return aliquot?.find((a) => a.code === aliquotNumber);
    }
    const materialAliquots = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === this.experimentService.currentActivity?.activityId
    )?.materials;
    return materialAliquots?.find((a) => a.code === aliquotNumber);
  }

  getInstrumentByActivityId(activityId: string): Instrument | undefined {
    return this.experimentService.currentExperiment?.activityInputs?.filter(
      (a: any) => a.activityId === activityId
    )?.find(a => a.instruments?.isRemoved === false)?.instruments;
  }
  formatTestProperties(property: string) {
    return property === NA ? NA : `"${property}"`;
  }

  private getSampleDataSource(aliquots: Aliquot[]): ActivityInputSamples[] {
    const sampleTableDataSource: ActivityInputSamples[] = []
    aliquots?.forEach((row) => {
      const primitiveValue = this.getPrimitiveDataValueRows(row.modifiableFields);
      const aliquot: ActivityInputSamples = { ...row };
      aliquot.id = row.aliquotNumber;
      aliquot.rowIndex = primitiveValue.map((obj: any) => obj[this.rowIndex]).find((val: any) => val);
      aliquot.AdditionalInformation = primitiveValue.map((row: any) => row[this.additionalInformation])[0];

      aliquot.aliquotTests = row.aliquotTests ?? [];
      aliquot.testCode = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testCode)).join(', ');
      aliquot.testKey = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testKey)).join(', ');
      aliquot.testName = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testName)).join(', ');
      aliquot.testReportableMethodReference = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testReportableMethodReference)).join(', ');
      aliquot.testReportableName = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testReportableName)).join(', ');
      aliquot.testStatus = aliquot.aliquotTests.map(t => this.formatTestProperties(t.testStatus)).join(', ');
      sampleTableDataSource.push(aliquot);
    });

    return sampleTableDataSource;
  }

  private addStudyActivityDataSource(aliquots: MaterialAliquot[]) {
    const studyActivitiesTableDataSource: ActivityInputStudyActivity[] = [];
    aliquots?.forEach((row) => {
      const primitiveValue = this.getPrimitiveDataValueRows(row.modifiableFields);
      const material: ActivityInputStudyActivity = {
        ...row,
        accountCode: row.studyActivities.map(t => this.formatTestProperties(t.accountCode)).join(', '),
        accountName: row.studyActivities.map(t => this.formatTestProperties(t.accountName)).join(', '),
        studyActivityName: row.studyActivities.map(t => this.formatTestProperties(t.studyActivityName)).join(', '),
        projectName: row.studyActivities.map(t => this.formatTestProperties(t.projectName)).join(', '),
        studyActivityCode: row.studyActivities.map(t => this.formatTestProperties(t.code)).join(', '),
        studyGroupName: row.studyActivities.map(t => this.formatTestProperties(t.studyGroupName)).join(', '),
        studyCode: row.studyActivities.map(t => this.formatTestProperties(t.studyCode)).join(', '),
        studyActivityStatus: row.studyActivities.map(t => this.formatTestProperties(t.studyActivityStatus)).join(', '),
        customerAnalyticalProjectCode: row.studyActivities.map(t => this.formatTestProperties(t.customerAnalyticalProjectCode)).join(', '),
        studyName: row.studyActivities.map(t => this.formatTestProperties(t.studyName)).join(', '),
        isSelected: true,
        id: row.code,
        AdditionalInformation: primitiveValue.map((x: any) => x[this.additionalInformation])[0],
      }
      material.id = row.code;
      material.rowIndex = primitiveValue.map((obj: any) => obj[this.rowIndex]).find((val: any) => val);
      material.studySelectionModified = row.studySelectionModified;
      material.AdditionalInformation = primitiveValue.map((x: any) => x[this.additionalInformation])[0];
      studyActivitiesTableDataSource.push(material);
    });
    return studyActivitiesTableDataSource;
  }

  getSampleAliquots(isRemoved = false): ActivityInputSamples[] {
    var activityId = this.experimentService.currentActivity?.activityId ?? this.experimentService.currentExperiment?.reservedInstances[0].instanceId;
    if (!activityId) return [];
    const aliquots =  this.getActivityInputNode(activityId)?.aliquots.filter(a => a.isRemoved === isRemoved);
    if (!aliquots) return [];
    return this.getSampleDataSource(aliquots);
  }

  getMaterialAliquots(isRemoved = false): ActivityInputStudyActivity[] {
    var aliquotId =this.experimentService.currentActivity?.activityId ?? this.experimentService.currentExperiment?.reservedInstances[0].instanceId;
    if(!aliquotId) return [];
    const aliquots =  this.getActivityInputNode(aliquotId)?.materials.filter(m => m.isRemoved === isRemoved);
    if (!aliquots) return [];
    return this.addStudyActivityDataSource(aliquots);
  }

  removeAliquotFromSampleSource(aliquotNumber: string, activityId: string) {
    const aliquot = this.getActivityInputNode(activityId)?.aliquots?.find(a => a.aliquotNumber === aliquotNumber);
    if (aliquot) aliquot.isRemoved = true;
    this.barcodeScannerHelper.aliquotUpdateEvent.next(activityId);
  }

  restoreAliquotFromSampleSource(aliquotNumber: string, activityId: string) {
    const aliquot = this.getActivityInputNode(activityId)?.aliquots?.find(a => a.aliquotNumber === aliquotNumber);
    if (aliquot) aliquot.isRemoved = false;
    this.barcodeScannerHelper.aliquotUpdateEvent.next(activityId);
  }

  removeAliquotFromStudyActivitySource(aliquotNumber: string, activityId: string) {
    const aliquot = this.getActivityInputNode(activityId)?.materials?.find(m => m.code === aliquotNumber);
    if (aliquot) aliquot.isRemoved = true;
    this.barcodeScannerHelper.studyActivityUpdateEvent.next(activityId);
  }

  restoreAliquotFromStudyActivitySource(aliquotNumber: string, activityId: string) {
    const aliquot = this.getActivityInputNode(activityId)?.materials?.find(m => m.code === aliquotNumber);
    if (aliquot) aliquot.isRemoved = false;
    this.barcodeScannerHelper.studyActivityUpdateEvent.next(activityId);
  }

  removeInstrumentFromDataSource(activityId: string) {
    const instrument = this.getInstrumentByActivityId(activityId);
    if (instrument) instrument.isRemoved = true;
  }

  private getActivityInputNode(activityId: string) {
    return this.experimentService.currentExperiment?.activityInputs?.find(a => a.activityId === activityId);
  }

  private removeSampleDataSource(notification: ActivityInputRowRemovedEventNotification): void {
    this.removeAliquotFromSampleSource(notification.activityInputReference, notification.activityId);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@aliquotRemoved:Aliquot Removed Successfully ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateSampleAliquotTableDataSource.next(notification);
  }

  private removeStudyActivityDataSource(notification: ActivityInputRowRemovedEventNotification): void {
    this.removeAliquotFromStudyActivitySource(notification.activityInputReference, notification.activityId);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@aliquotRemoved:Aliquot Removed Successfully ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateStudyActivityTableDataSource.next(notification);
  }

  private removeInstrumentEventDataSource(notification: ActivityInputRowRemovedEventNotification): void {
    const instrument = this.getInstrumentByActivityId(notification.activityId);
    if (instrument) instrument.isRemoved = true
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@instrumentRemoved: Instrument removed successfully ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateInstrumentEventDataSource.next(notification);
  }

  private restoreSampleDataSource(notification: ActivityInputRowRestoredEventNotification): void {
    this.restoreAliquotFromSampleSource(notification.activityInputReference, notification.activityId);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@aliquotRestored:Aliquot successfully restored ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateSampleAliquotTableDataSource.next(notification);
  }

  private restoreStudyActivityDataSource(notification: ActivityInputRowRestoredEventNotification): void {
    this.restoreAliquotFromStudyActivitySource(notification.activityInputReference, notification.activityId);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@aliquotRestored:Aliquot successfully restored ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateStudyActivityTableDataSource.next(notification);
  }

  private refreshSampleActivityNode(notification: ActivityInputRowRefreshedEventNotification) {
    notification.aliquotsDetails.forEach((detail: AliquotRowRefreshedDetails) => {
      const aliquotRowData: any = this.getActivityInputNode(notification.activityId)?.aliquots.find((row) =>
        row.aliquotNumber === detail.activityInputReference)

      if (!aliquotRowData) return;
      mapValues(detail.modifiedFields, (item: ActivityInputCell) => {
        mapValues(Object.keys(aliquotRowData), (propertyName: string) => {
          if (propertyName.toLowerCase() === item.propertyName.toLowerCase()) {
            aliquotRowData[propertyName] = (item.propertyValue as { value?: any })?.value;
          }
        });
      });
      aliquotRowData.aliquotTests = detail.aliquotTests;
    })
  }

  private refreshSampleDataSource(notification: ActivityInputRowRefreshedEventNotification): void {
    if (!this.isDataUpdated(notification)) return;
    this.refreshSampleActivityNode(notification);
    const messageData = this.getRowRefreshedMessageData(notification);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@AliquotRefreshed:Aliquots refreshed Successfully: ${messageData}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.refreshSampleTableDataSource.next(notification);
  }

  private refreshStudyActivityNode(notification: ActivityInputRowRefreshedEventNotification): void {
    notification.materialsDetails.forEach((detail: MaterialRowRefreshedDetails) => {
      const aliquotRowData: any = this.getActivityInputNode(notification.activityId)?.materials.find((row) =>
        row.code === detail.activityInputReference)

      if (!aliquotRowData) return;
      mapValues(detail.modifiedFields, (item: ActivityInputCell) => {
        mapValues(Object.keys(aliquotRowData), (propertyName: string) => {
          if (propertyName.toLowerCase() === item.propertyName.toLowerCase()) {
            aliquotRowData[propertyName] = (item.propertyValue as { value?: any })?.value;
          }
        });
      });
      aliquotRowData.studyActivities = detail.studyActivities;
    })
  }

  private refreshStudyActivityDataSource(notification: ActivityInputRowRefreshedEventNotification): void {
    if (!this.isDataUpdated(notification)) return;
    this.refreshStudyActivityNode(notification);
    const messageData = this.getRowRefreshedMessageData(notification);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@AliquotRefreshed:Aliquots refreshed Successfully: ${messageData}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.refreshStudyActivityTableDataSource.next(notification);
  }

  private updatedRefreshedDataFromLims(notification: ActivityInputRowRefreshedEventNotification) {
    if (notification?.instrumentDetails) {
      const dataValue = notification.instrumentDetails.modifiedFields.find(f => f.propertyName.toLowerCase() === "name")?.propertyValue;
      const dataSource = this.getInstrumentByActivityId(notification.activityId);
      if (!dataSource) return;
      dataSource.maintenanceEvents = notification.instrumentDetails.maintenanceEvents;
      if (dataValue)
        dataSource.name = this.dataValueService.getPrimitiveValue(ColumnType.String, { value: dataValue, isModified: true });
    }
  }

  private refreshInstrumentEventDataRecord(notification: ActivityInputRowRefreshedEventNotification): void {
    this.updatedRefreshedDataFromLims(notification);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@InstrumentRefreshed: Instrument refreshed successfully`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.refreshInstrumentEventDataSource.next(notification);
  }

  private isDataUpdated(data: ActivityInputRowRefreshedEventNotification): boolean {
    return (
      data.aliquotsDetails.find((x: any) => x.modifiedFields.length === 0) === undefined
    );
  }

  private getRowRefreshedMessageData(data: ActivityInputRowRefreshedEventNotification) {
    if (data.aliquotsDetails) return data.aliquotsDetails?.map((a: any) => a.activityInputReference).join(',');
    return data.materialsDetails?.map((m: any) => m.activityInputReference).join(',');
  }

  private updateCellChangeFromSampleDataSource(notification: ActivityInputCellChangedEventNotification) {
    const aliquot = this.getActivityInputNode(notification.activityId)?.aliquots.find(a => a.aliquotNumber === notification.activityInputReference);
    if (aliquot) this.setAndUpdateModifiableDataField(aliquot.modifiableFields, this.additionalInformation,notification.propertyValue);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@ActivityInputCellUpdated:${notification.propertyName} Updated Successfully`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateSampleTableCellChangedDataSource.next(notification);
  }

  private updateCellChangeFromStudyActivityDataSource(notification: ActivityInputCellChangedEventNotification) {
    const aliquot = this.getActivityInputNode(notification.activityId)?.materials.find(m => m.code === notification.activityInputReference);
    if (aliquot) this.setAndUpdateModifiableDataField(aliquot.modifiableFields, this.additionalInformation,notification.propertyValue);

    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@ActivityInputCellUpdated:${notification.propertyName} Updated Successfully`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateStudyActivityTableCellChangedDataSource.next(notification);
  }

  private updateTestSelectionChangeFromSampleDataSource(notification: SampleTestAddedEventNotification) {
    const aliquot = this.getActivityInputNode(notification.activityId)?.aliquots.find(a =>
      a.aliquotNumber === notification.activityInputReference);
    if (aliquot) aliquot.aliquotTests = notification.aliquotTests;
  }

  private updateSampleTestDataRecord(notification: SampleTestAddedEventNotification) {
    this.updateTestSelectionChangeFromSampleDataSource(notification);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@SampleTestAdded:Test Successfully Added For the Sample ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateSampleTestDataSource.next(notification);
  }

  private updateStudyActivitySelectedDataRecord(notification: StudyActivitySelectedEventNotification) {
    const aliquot = this.getActivityInputNode(notification.activityId)?.materials.find((a) =>
      a.code === notification.activityInputReference);
    if (aliquot) aliquot.studyActivities = notification.studyActivities;
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@SampleTestAdded:Test Successfully Added For the Sample ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateStudyActivitySelectedDataSource.next(notification);
  }

  private handleRowRemovedNotification(notification: ActivityInputRowRemovedEventNotification) {
    switch(Number(notification.activityInputType)) {
      case this.activityInputTypes.indexOf(ActivityInputType.Aliquot):
        this.removeSampleDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Material):
        this.removeStudyActivityDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Instrument):
        this.removeInstrumentEventDataSource(notification);
        break;
    }
  }

  private handleRowRestoredNotification(notification: ActivityInputRowRestoredEventNotification) {
    switch(Number(notification.activityInputType)) {
      case this.activityInputTypes.indexOf(ActivityInputType.Aliquot):
        this.restoreSampleDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Material):
        this.restoreStudyActivityDataSource(notification);
        break;
    }
  }

  private handleRefreshNotification(notification: ActivityInputRowRefreshedEventNotification) {
    switch(Number(notification.activityInputType)) {
      case this.activityInputTypes.indexOf(ActivityInputType.Aliquot):
        this.refreshSampleDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Material):
        this.refreshStudyActivityDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Instrument):
        this.refreshInstrumentEventDataRecord(notification);
        break;
    }
  }

  private handleCellChangeNotification(notification: ActivityInputCellChangedEventNotification) {
    switch(Number(notification.activityInputType)) {
      case this.activityInputTypes.indexOf(ActivityInputType.Aliquot):
        this.updateCellChangeFromSampleDataSource(notification);
        break;
      case this.activityInputTypes.indexOf(ActivityInputType.Material):
        this.updateCellChangeFromStudyActivityDataSource(notification);
        break;
    }
  }

  getModifiableDataValue(newValue: ExperimentDataValue, oldValue: ModifiableDataValue){
    return DataRecordService.getModifiableDataValue(
      newValue,
      oldValue
    );
  }

  private updateMaintenanceEventDataRecord(notification: MaintenanceEventSelectedEventNotification) {
    const existingInstrument = this.getInstrumentByActivityId(notification.activityId);
    if (!existingInstrument) return;
    existingInstrument.nameDescription = this.getModifiableDataValue(notification.nameDescription, existingInstrument.nameDescription);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@MaintenanceEventSelectedSuccessfully: Maintenance Event Selected Successfully to
      ${(existingInstrument.nameDescription.value as any).value} for this instrument ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateMaintenanceEventDataSource.next(notification);
  }

  private updateInstrumentDescriptionDataRecord(notification: InstrumentDescriptionChangedEventNotification) {
    const existingInstrument = this.getInstrumentByActivityId(notification.activityId);
    if (!existingInstrument) return;
    existingInstrument.description = this.getModifiableDataValue(notification.description, existingInstrument.description);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@InstrumentDescriptionChanged:Instrument Description Changed Successfully to
      ${(existingInstrument.description.value as any).value} for this instrument ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateInstrumentDescriptionDataSource.next(notification);
  }

  private updateInstrumentDateRemovedRecord(notification: InstrumentDateRemovedChangedEventNotification) {
    const existingInstrument = this.getInstrumentByActivityId(notification.activityId);
    if (!existingInstrument) return;
    existingInstrument.dateRemoved = this.getModifiableDataValue(notification.dateRemoved, existingInstrument.dateRemoved);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@InstrumentDateRemovedChanged: Instrument Date Removed Changed Successfully to
      ${(existingInstrument.dateRemoved.value as any).value} for this instrument ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateInstrumentDateRemovedDataSource.next(notification);
  }

  private updateInstrumentRemovedFromServiceDataRecord(
    notification: InstrumentRemovedFromServiceChangedEventNotification
  ) {
    const existingInstrument = this.getInstrumentByActivityId(notification.activityId);
    if (!existingInstrument) return;
    existingInstrument.removedFromService = this.getModifiableDataValue(notification.removedFromService, existingInstrument.removedFromService);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@InstrumentRemoveFromServiceChanged:Instrument Remove From Service Changed Successfully to
      ${(existingInstrument.removedFromService.value as any).value} for this instrument ${notification.activityInputReference}`,
      detail: $localize`:@@collaborativeUserInfo: by the user ${notification.eventContext.puid}`
    });
    this.updateInstrumentRemovedFromServiceDataSource.next(notification);
  }

  handleActivityInputDataRecordEvents(notification: ExperimentDataRecordNotification): void {
    switch (notification.eventContext.eventType) {
      case ExperimentEventType.ActivityInputRowRemoved:
        this.handleRowRemovedNotification(notification as ActivityInputRowRemovedEventNotification);
        break;
      case ExperimentEventType.ActivityInputRowRestored:
        this.handleRowRestoredNotification(notification as ActivityInputRowRestoredEventNotification);
        break;
      case ExperimentEventType.ActivityInputRowRefreshed:
        this.handleRefreshNotification(notification as ActivityInputRowRefreshedEventNotification);
        break;
      case ExperimentEventType.ActivityInputCellChanged:
        this.handleCellChangeNotification(notification as ActivityInputCellChangedEventNotification);
        break;
    }
  }

  private handleSubscriptions(): void {
    this.dataRecordService.activityInputRowRemovedDataRecordReceiver.subscribe({
      next: this.handleActivityInputDataRecordEvents.bind(this)
    });

    this.dataRecordService.activityInputRowRestoredDataRecordReceiver.subscribe({
      next: this.handleActivityInputDataRecordEvents.bind(this)
    });

    this.dataRecordService.activityInputRowRefreshedDataRecordReceiver.subscribe({
      next: this.handleActivityInputDataRecordEvents.bind(this)
    });

    this.dataRecordService.activityInputCellChangedDataRecordReceiver.subscribe({
      next: this.handleActivityInputDataRecordEvents.bind(this)
    });

    this.dataRecordService.sampleTestAddedDataRecordReceiver.subscribe({
      next: this.updateSampleTestDataRecord.bind(this)
    });

    this.dataRecordService.studyActivitySelectedDataRecordReceiver.subscribe({
      next: this.updateStudyActivitySelectedDataRecord.bind(this)
    });

    this.dataRecordService.maintenanceEventSelectedDataRecordReceiver.subscribe({
      next: this.updateMaintenanceEventDataRecord.bind(this)
    });

    this.dataRecordService.instrumentDescriptionChangedDataRecordReceiver.subscribe({
      next: this.updateInstrumentDescriptionDataRecord.bind(this)
    });

    this.dataRecordService.instrumentDateRemovedChangedDataRecordReceiver.subscribe({
      next: this.updateInstrumentDateRemovedRecord.bind(this)
    });

    this.dataRecordService.instrumentRemovedFromServiceChangedDataRecordReceiver.subscribe({
      next: this.updateInstrumentRemovedFromServiceDataRecord.bind(this)
    });
  }
}
