import { ActivityInputType, User } from '../../../app/api/models';
import { first, nth } from 'lodash-es';
import { CommentCollection, Comment } from 'bpt-ui-library/bpt-comments';
import { CommentDetails } from './comment.model';
import { AddThread, CommentContextType, CommentResponse, CommentsResponse, InternalCommentStatus } from '../../api/internal-comment/models';
import { MenuItem } from 'primeng/api';
import { Activity, Experiment, Module, ModuleItem, Table } from '../../model/experiment.interface';
import { Renderer2 } from '@angular/core';
import { References, Inputs, LabItemsModuleHeader, ReferenceModuleHeader, ActivityInputPageTitle, PreparationModuleHeader, CompendiaTitle, DocumentsTitle, CrossReferences, SampleAliquotsTitle, StudyActivitiesTitle, LabItemsMaterialsTableTitle, LabItemsInstrumentsTableTitle, ConsumablesTableTitle, Compendia, Documents, Modules, LabItems, LabItemsColumnsTableTitle, OutPutsHeader, rowAbbr, Preparations } from './internal-comments-constants';

export class InternalCommentHelper {
  static UserRoleAnalyst = 'ELN Analyst';
  static UserRoleReviewer = 'ELN Data Reviewer';
  static UserRoleSupervisor = 'ELN Supervisor';
  static UserRoleViewer = 'ELN Viewer';
  static CurrentUserRole = 'ELN Viewer';
  static Icons = {
    edit: 'pi pi-user-edit',
    trash: 'pi pi-trash',
    check: 'pi pi-check',
    confirmIcon: 'pi pi-exclamation-triangle',
    rejectButtonStyleClass: 'p-button-outlined'
  }
  static GlowComment = 'glow-comment';
  static sortComments(builtCommentsAndReplies: CommentCollection<string>): Comment<string>[] {
    return builtCommentsAndReplies.Comments.sort(function (commentA, commentB) {
      return commentA.Collapsed === commentB.Collapsed ? 0 : commentA.Collapsed ? -1 : 1;
    });
  }

  static getCollapsedStatus(internalCommentDefaultDetails: CommentDetails, path: string[]): boolean {
    if (path?.length > 0) {
      if (path.length > internalCommentDefaultDetails.path.length) {
        return path.every((currentPath: string) => internalCommentDefaultDetails.path.some((storedPath: string) => storedPath === currentPath));
      } else {
        return internalCommentDefaultDetails.path.every((currentPath: string) => path.some(storedPath => storedPath === currentPath));
      }
    }
    return false;
  }

  static getCommentedBy(users: User[], puid: string): string {
    return users.find((user: { puid: string; }) => user.puid.localeCompare(puid, undefined, {
      sensitivity: 'base'
    }) === 0)?.fullName ?? '';
  }

  static isCommentResolved(internalCommentsResponse: CommentsResponse, commentId: string): boolean {
    if (internalCommentsResponse.comments !== null) {
      return first(internalCommentsResponse.comments.filter(
        (com: CommentResponse) => com.commentId === commentId))?.status === InternalCommentStatus.Resolved
    }
    return false;
  }
  static getEditAction(e: { item: MenuItem }) {
    if (e.item.state && e.item.state.context) {
      e.item.state.context.InEditMode = !e.item.state.context.InEditMode;
    }
  }

  static isSameUser(currentUserPuid: string, puid: string) {
    return puid?.localeCompare(currentUserPuid, undefined, {
      sensitivity: 'base'
    }) === 0;
  }

  static addToCommentDetails(internalCommentDefaultDetails: CommentDetails, newComment: Comment<string>) {
    return {
      commentId: newComment.Id,
      content: newComment.Content,
      contextType: this.buildComment(internalCommentDefaultDetails, newComment).contextType,
      createdBy: newComment.CommentedBy,
      createdOn: newComment.CommentedOn,
      displayLabel: newComment.Header ?? '',
      lastModified: newComment.CommentedOn,
      nodeId: first(this.buildComment(internalCommentDefaultDetails, newComment).path),
      path: this.buildComment(internalCommentDefaultDetails, newComment).path,
      replies: [],
      status: InternalCommentStatus.Pending
    } as CommentResponse;
  }

  static buildComment(internalCommentDefaultDetails: CommentDetails, newComment: Comment<string>): AddThread {
    return {
      content: newComment.Content,
      contextType: internalCommentDefaultDetails?.contextType,
      nodeId: newComment.Id,
      path: internalCommentDefaultDetails?.path || []
    };
  }

  static getActivityName(currentExperiment: Experiment | undefined, activityId: string | undefined) {
    return currentExperiment?.activities.find(activity => activity.activityId === activityId)?.itemTitle;
  }

  static highlightContext(renderer: Renderer2, cell: Element) {
    /* dummy timeout to make sure browser
    sees animation as none before adding it again */
    setTimeout(() => {
      renderer.addClass(
        cell, InternalCommentHelper.GlowComment
      );
      cell.parentNode?.parentElement?.scrollIntoView();
    }, 0);
  }

  static getActivityTitle(commentContext: CommentContextType | ActivityInputType | undefined) {
    switch (commentContext) {
      case CommentContextType.Module:
        return Modules
      case CommentContextType.ActivityCrossReference:
      case CommentContextType.ActivityCrossReferences:
        return References
      case ActivityInputType.InstrumentDetails:
      case ActivityInputType.Consumable:
      case ActivityInputType.Material:
      case ActivityInputType.InstrumentColumn:
        return LabItems
      case CommentContextType.SampleAliquot:
      case CommentContextType.StudyActivity:
        return Inputs
      case CommentContextType.Preparations:
        return Preparations
      default:
        throw Error('LOGIC ERROR: Unknown comment context type was passed for activity title level');
    }
  }

  static getCell(path: string[]) {
    return document.querySelector(
      `ag-grid-angular [row-id="${path[2]}"] [col-id="${path[3]}"]`
    );
  }

  static getHeaderStatus(contextType: CommentContextType): boolean {
    return contextType === CommentContextType.TableCell;
  }

  /* Construct display label for left nav items
    Return just the title of the activity if user opens/creates comment from left nav Activity menu
    Return 'activityTitle > left nav menu item' for all other left nav items that come under the main activity nav item
  */
  static getDynamicDisplayLabelForLeftNavItems(path: string[], title: string | undefined): string {
    let navItem = '';
    const contextType = nth(path, -1);
    switch (contextType) {
      case CommentContextType.Activity:
        return `${title}`;
      case CommentContextType.LabItems:
        navItem = LabItemsModuleHeader;
        break;
      case CommentContextType.ActivityReferences:
        navItem = ReferenceModuleHeader;
        break;
      case CommentContextType.ActivityInput:
        navItem = ActivityInputPageTitle;
        break;
      case CommentContextType.Preparations:
        navItem = PreparationModuleHeader;
        break;
      case CommentContextType.OutputsInstrumentEvent:
        navItem = OutPutsHeader;
        break;
      default:
        throw Error('LOGIC ERROR: Unknown context type was passed for activity')
    }
    return `${title ?? 'No Title'} > ${navItem}`
  }

  /* Construct display label for module level */
  static getDynamicDisplayLabelForModule(title: string | undefined, moduleLabel: string | undefined): string {
    return `${title} > ${moduleLabel}`
  }

  /* Construct display label for table title level */
  static getDynamicDisplayLabelForTable(title: string | undefined, module: Module | undefined, path: string[]): string {
    let tableName: string;
    let contextLabel = ReferenceModuleHeader;
    const contextType = nth(path, -1);
    switch (contextType) {
      case CommentContextType.Compendia:
        tableName = CompendiaTitle;
        break;
      case CommentContextType.Documents:
        tableName = DocumentsTitle;
        break;
      case CommentContextType.CrossReferences:
        tableName = CrossReferences;
        break;
      case CommentContextType.Module:
        const tableId = path[2];
        tableName = module?.items.find((module: ModuleItem) => (module as Table).tableId === tableId)?.itemTitle ?? '';
        contextLabel = module?.moduleLabel ?? '';
        break;
      default:
        throw Error('LOGIC ERROR: Unknown context type was passed for table level')
    }
    return `${title} > ${contextLabel} > ${tableName}`;
  }

  /* Construct display label for table cell level */
  static getDynamicDisplayLabelForTableCell(title: string | undefined, tableTitle: string| undefined, module: Module | undefined, path: string[], activity: Activity | undefined): string {
    const rowIndex = nth(path, -2);
    let fieldName = path[1];
    switch (nth(path, -1)) {
      case CommentContextType.Module:
        fieldName = path[4];
        return `${title} > ${module?.moduleLabel} > ${tableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case CommentContextType.SampleAliquot:
        return `${title ?? 'No Title'} > ${ActivityInputPageTitle} > ${SampleAliquotsTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case CommentContextType.StudyActivity:
        return `${title ?? 'No Title'} > ${ActivityInputPageTitle} > ${StudyActivitiesTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case ActivityInputType.Material:
        return `${title} > ${LabItemsModuleHeader} > ${LabItemsMaterialsTableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case ActivityInputType.InstrumentDetails:
        return `${title} > ${LabItemsModuleHeader} > ${LabItemsInstrumentsTableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case ActivityInputType.Consumable:
        return `${title} > ${LabItemsModuleHeader} > ${ConsumablesTableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case ActivityInputType.InstrumentColumn:
        return `${title} > ${LabItemsModuleHeader} > ${LabItemsColumnsTableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case CommentContextType.ActivityCrossReference:
        //comes here for Cross References table
        fieldName = path[4];
        return `${title} > ${ReferenceModuleHeader} > ${CrossReferences} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case CommentContextType.ActivityCrossReferences:
        //comes here for Documents and Compendia
        fieldName = path[4];
        const referenceTableId = nth(path, -3);
        tableTitle = activity?.activityReferences.compendiaReferencesTableId === referenceTableId ?
          Compendia : Documents;
        return `${title} > ${ReferenceModuleHeader} > ${tableTitle} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      case CommentContextType.Preparations:
        return `${title} > ${PreparationModuleHeader} > ${rowAbbr} ${rowIndex} > ${fieldName}`;
      default:
        throw Error('LOGIC ERROR: Unknown context type was passed for table cell level');
    }
  }

  /* Construct display label for form title level */
  static getDynamicDisplayLabelForForm(title: string | undefined, formTitle: string | undefined, moduleLabel: string | undefined): string {
    return `${title} > ${moduleLabel} > ${formTitle}`
  }

  /* Construct display label for form field level */
  static getDynamicDisplayLabelForFormField(title: string | undefined, formTitle: string | undefined, moduleLabel: string | undefined, path: string[]): string {
    const fieldName = nth(path, -2);
    const contextType = nth(path, -1);
    if (contextType === CommentContextType.OutputsInstrumentEvent) {
      formTitle = path[1];
      return `${title ?? 'No Title'} > ${OutPutsHeader} > ${formTitle} > ${fieldName}`
    }
    return `${title} > ${moduleLabel} > ${formTitle} > ${fieldName}`;
  }
}