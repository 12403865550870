import { CellRange, Column } from "ag-grid-community";
import { SequentialReadingShiftModal } from "./sequential-reading-shift-modal";

export class SequentialReadingShiftModalService {
  private static sequentialReadingColumnIndex = 0;
  private static sequentialReadingRowIndex = 0;
  private static selectedCellsRangeForReading: CellRange;
  private static selectedCellRangeColumns: Column[] = [];
  private static sequentialShiftModal: SequentialReadingShiftModal;

  public static shiftModalToNextCell()  {
    this.sequentialShiftModal.closeOverlayModal?.call(this, true);
    setTimeout(() => {
      this.sequentialReadingColumnIndex = this.sequentialReadingColumnIndex + 1;
      if(this.sequentialReadingColumnIndex > (this.selectedCellRangeColumns.length - 1)) {
        this.sequentialReadingColumnIndex = 0;
        this.sequentialReadingRowIndex = this.sequentialReadingRowIndex + 1;
      }
      this.showOverlayForReading(this.sequentialReadingRowIndex, this.selectedCellRangeColumns[this.sequentialReadingColumnIndex]);
    });
  }

  public static startSequentialReading(sequentialShiftModal: SequentialReadingShiftModal) {
    this.sequentialShiftModal = sequentialShiftModal;
    const cellRange = sequentialShiftModal.gridApi?.getCellRanges();
    if(!cellRange) return;
    this.selectedCellsRangeForReading = cellRange[0];
    this.selectedCellRangeColumns = this.selectedCellsRangeForReading.columns;
    const column = this.selectedCellsRangeForReading.columns[0];
    if (this.selectedCellsRangeForReading.startRow) {
      this.sequentialReadingRowIndex = this.selectedCellsRangeForReading.startRow.rowIndex;
    }
    this.showOverlayForReading(this.sequentialReadingRowIndex, column);
  }

  public static terminateSequentialReading() {
    this.sequentialShiftModal?.gridApi?.clearFocusedCell();
  }

  private static showOverlayForReading(rowIndex: number, column: Column) {
    this.sequentialShiftModal?.gridApi?.setFocusedCell(rowIndex, column.getColId());
    if(this.selectedCellsRangeForReading.endRow && rowIndex > this.selectedCellsRangeForReading.endRow?.rowIndex) {
      this.sequentialShiftModal.closeOverlayModal?.call(this, false);
      return;
    }
    const cell = document.querySelector(
      `ag-grid-angular [row-index="${rowIndex}"] [col-id="${column.getColId()}"] `
    );
    if (!cell) return;
    this.sequentialShiftModal.openOverlayModal?.call(this, this.sequentialShiftModal.$event, cell);

    const selectedColumn = this.sequentialShiftModal.columnDefinitions?.find(cd => cd.label === column.getColDef().headerName);
    if (selectedColumn) {
      this.sequentialShiftModal.setSelectedColumn?.call(this, selectedColumn, cell);
    }
  }

  static setOrResetStyleForAllSelectedCells(styleName: string, styleColorToApply: string, isReset = false) {
    const startRowIndex = this.selectedCellsRangeForReading?.startRow?.rowIndex;
    const endRowIndex = this.selectedCellsRangeForReading?.endRow?.rowIndex;
    if (!startRowIndex || !endRowIndex) return;
    for(let row = startRowIndex; row <= endRowIndex; row++) {
      this.selectedCellRangeColumns.forEach(column => {
        const cell = document.querySelector(`ag-grid-angular [row-index="${row}"] [col-id="${column.getColId()}"] `);
        !isReset ? this.sequentialShiftModal.renderer?.setStyle(cell, styleName, styleColorToApply) :
        this.sequentialShiftModal.renderer?.removeStyle(cell, styleName);
      });
    }
  }
}
