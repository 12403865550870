import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ExperimentDataRecordNotification } from '../../api/audit/models';
import { AuditHistory } from '../services/audit-history.interface';
import { UserService } from '../../../app/api/services/user.service';
import { User } from '../../../app/api/models';
import { DataRecordService } from '../services/data-record.service';
import { NodeType } from '../../api/data-entry/models';
import { ChangeReasonRendererComponent } from './change-reason-renderer.component';
import { filterComparator } from '../../shared/date-time-helpers';
import { AuditHistoryEventsService } from './audit-history-events.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';

/**
 * Presentation component inside a History dialog
 */
@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit, OnDestroy {
  private readonly cellStyleClass = "grid-cell-align";
  columnDefs!: ColumnDefinition[];
  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  message!: string;
  dataSource: AuditHistory[] = [];
  usersList!: User[];
  isValid = false;
  dataRecords!: ExperimentDataRecordNotification[];
  nodeType?: NodeType = undefined;
  nodeId?: string = undefined;
  private readonly excelSheetName = 'demo-sheet';
  private readonly excelFileName = 'History';
  @ViewChild('auditGrid') grid!: BptGridComponent;
  bptGridMode!: BptGridMode;
  dataLoaded = false;
  subscriptions: Subscription[] = [];

  auditGridOptions = {
    allowRowAdd: false,
    includeRowNumberColumn: false,
    enableExport: true,
    useJsJodaTypes: true,
    debounceVerticalScrollbar: true
  }
  /**
   * @param dynamicDialogConfig To get the configurations
   */
  constructor(private readonly dynamicDialogConfig: DynamicDialogConfig, private readonly userService: UserService,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryEventsService: AuditHistoryEventsService)  {
      this.handleSubscriptions();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  ngOnInit() {
    this.message = $localize`:@@loadingHistory:Loading History...`;
    this.dataRecords = this.dynamicDialogConfig.data.records as ExperimentDataRecordNotification[];
    this.nodeType = this.dynamicDialogConfig.data.contextType;
    this.nodeId = this.dynamicDialogConfig.data.contextNodeId;
    this.setDialogData(this.dataRecords);
    this.bptGridMode = BptGridMode.dataView;
  }

  resultsGridReady() {
    this.grid.gridApi.setGridOption('domLayout', 'normal');
     setTimeout(() => { this.isValid = false; }, 1500);
  }

  /**
   * Sets the dialog with required data
   * @param _records Selected context can be  Experiment/Activities/Module/Form/Table
   */
  private async setDialogData(records: ExperimentDataRecordNotification[]) {
    this.isValid = true;
    await this.dataRecordService.createHistoryAsync(records, this.nodeType, this.nodeId).then((results) => {
      this.dataSource = results;
      this.dataLoaded = true;
    });
    this.columnDefs = [
      {
        field: this.keyNameOf<AuditHistory>('Time'),
        label: $localize`:@@Date&Time:Date & Time`,
        width: 'auto',
        cellClass: this.cellStyleClass,
        sortable: true,
        sort: 'desc',
        comparator: this.compareByTimestamp,
        filter: true,
        filterType: 'date',
        filterParams: {
          debounceMs: 500,
          comparator: filterComparator,
        }
      },
      {
        field: this.keyNameOf<AuditHistory>('Context'),
        label: $localize`:@@Context:Context`,
        width: 'auto',
        cellClass: this.cellStyleClass,
      },
      {
        field: this.keyNameOf<AuditHistory>('RecordType'),
        label: $localize`:@@RecordType:Record Type`,
        width: 'auto',
        sortable: true,
        cellClass: this.cellStyleClass,
      },
      {
        field: this.keyNameOf<AuditHistory>('ContextType'),
        label: $localize`:@@ContextType:Context Type`,
        width: 'auto',
        hidden: true,
        sortable: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('Name'),
        label: $localize`:@@Name:Name`,
        width: 'auto',
        hidden: true,
        sortable: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('Description'),
        label: $localize`:@@Description:Description`,
        width: 'auto',
        sortable: true,
        cellClass: `${this.cellStyleClass} preWhiteSpace`
      },
      {
        field: this.keyNameOf<AuditHistory>('ChangeReason'),
        label: $localize`:@@ChangeReason:Change Reason`,
        width: 'auto',
        maxWidth: 500,
        sortable: true,
        cellRenderer: ChangeReasonRendererComponent,
        cellClass: `${this.cellStyleClass} preWhiteSpace`
      },
      {
        field: this.keyNameOf<AuditHistory>('RecordVersion'),
        label: $localize`:@@RecordVersion:Record Version`,
        width: 'auto',
        hidden: true,
        sortable: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('PerformedBy'),
        label: $localize`:@@PerformedBy:Performed By`,
        width: 'auto',
        sortable: true,
        cellClass: this.cellStyleClass,
      }
    ];
  }

  exportDataToExcel() {
    const allColumns = this.grid.gridApi.getAllGridColumns();
    const params = {
      columnKeys: !allColumns ? [] : allColumns,
      fileName: this.excelFileName,
      sheetName: this.excelSheetName,
    };
    this.grid.gridApi.exportDataAsExcel(params);
  }

  private handleSubscriptions(): void {
    this.subscriptions.push(this.auditHistoryEventsService.updateAuditHistoryDataSource.subscribe({
      next: () => {
        this.dataLoaded = false;
        setTimeout(() => {
          this.setDialogData(this.dataRecords);
        }, 200);
      }
    }));
  }

  compareByTimestamp(_valueA: any, _valueB: any, rowA: { data: EventTimeInAuditHistory }, rowB: { data: EventTimeInAuditHistory }, _isDescending: boolean) {
    // strangely ag-grid already accounts for isDescending so if we do it, we'll be undoing what it does â˜¹
   return rowA.data.Source.eventContext.eventTime.localeCompare(rowB.data.Source.eventContext.eventTime);
  }
}

type EventTimeInAuditHistory = { Source: { eventContext: { eventTime: string } } }
  | AuditHistory; // Just need to demand the deep property eventTime, but let TypeScript try to help us if we refactor AuditHistory.
