import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormContextMenuItem } from 'bpt-ui-library/bpt-context-menu';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { remove } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { ClientStateService } from 'services/client-state.service';
import { AuditHistoryDataRecordResponse } from '../../../api/audit/models';
import {
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  CreateClientFacingNoteResponse,
  ExperimentEventType
} from '../../../api/data-entry/models';
import { ClientFacingNoteService } from '../../../api/data-entry/services/client-facing-note.service';
import { ClientFacingNoteContextType, ExperimentWorkflowState, Statement, StatementContextType, ValueState, ValueType } from '../../../api/models';
import { BaseComponent } from '../../../base/base.component';
import { PreparationConstants } from '../../../preparation/preparation-constants';
import { UserService } from '../../../services/user.service';
import { DateAndInstantFormat, formatInstant } from '../../../shared/date-time-helpers';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { ExperimentService } from '../../services/experiment.service';
import { CommentsComponent } from '../comments.component';
import { StatementModel } from '../statements/models/statement-model';
import { StatementsService } from '../statements/statements.service';
import { FormFieldClientFacingNoteContext } from './client-facing-note-event.model';
import { ClientFacingNoteModel } from './client-facing-note.model';
import { ContextReference } from './context-reference.model';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';

/**
 * A card to view and/or edit a client-facing component.
 *
 * N.B. CREATED ONCE PER PAGE LOAD BY ITS PARENT. So, be careful about state initialize on creation if it needs to be updated later.
 */
@Component({
  selector: 'app-client-facing-note',
  templateUrl: './client-facing-note.component.html',
  styleUrls: ['./client-facing-note.component.scss']
})
export class ClientFacingNoteComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() fieldLabel = '';
  @Input() fieldPlaceholder = '';
  @Input() isCancelButtonDisabled = true;
  @Input() visible = true;
  @Input() statements: Statement[] = [];
  @Input() clientFacingNote?: ClientFacingNoteModel;
  @Input() isNewClientFacingNote = false;
  @Input()
  get isSubmitButtonDisabled(): boolean {
    return !this.isBeingEdited || !this.commentText;
  }

  @Output() whenCommentAdded: EventEmitter<ClientFacingNoteModel> = new EventEmitter();
  @Output() whenCommentUpdated: EventEmitter<ClientFacingNoteModel> = new EventEmitter();
  @Output() whenCommentCancelled: EventEmitter<any> = new EventEmitter();

  @ViewChild('noteInput', { read: ElementRef }) noteInputElement!: ElementRef<HTMLElement>;
  @ViewChild('noteInput') noteInput!: BptTextInputComponent;

  errorMessage = 'Unexpected submission attempt, conditions are not valid!';
  focusAfterInit?: boolean;
  isLoading = false;
  outputFormFieldIdentifiers = ['Non-Routine Issue Encountered', 'Exception Reference', 'Justification', 'Returned to Service'];
  menuItems: MenuItem[] = [];

  get isBeingEdited(): boolean {
    return (this.isNewClientFacingNote || this.clientFacingNote?.isBeingEdited) ?? false;
  }
  set isBeingEdited(value: boolean) {
    if (value && this.isNotAllowedToAddOrEditClientFacingNote()) this.isBeingEdited = false;
    if (!this.clientFacingNote) return;

    this.clientFacingNote.isBeingEdited = value;
    this.isCancelButtonDisabled = !value;

    // Close button
    if (this.isNewClientFacingNote) {
      if (this.parent.newClientFacingNote) this.parent.newClientFacingNote.isBeingEdited = value;
    } else {
      const found = this.parent.clientFacingNotes.find(
        (c) => c.number === this.clientFacingNote?.number
      );
      if (found) found.isBeingEdited = value;
    }
    this.parent.refreshCloseButtonEnablement();
  }

  getStatementAppliedToCfn() {
    return this.statements.filter((statement: Statement) =>
      statement.cfnNumber === this.clientFacingNote?.number
      && (statement.path[0] === this.clientFacingNote?.path[0] &&
        statement.path[1] === this.clientFacingNote?.path[1]
        && statement.contextType === StatementContextType.ClientFacingNote
      ));
  }
  readonly parent: CommentsComponent;

  get contextReference(): ContextReference {
    const context = this.clientFacingNote?.context;
    if (!context) throw Error('Expected client-facing note context to be defined');
    if (!this.parent.parentExperiment.experiment) throw Error('Expected experiment to be defined');
    return new ContextReference(this.parent.parentExperiment.experiment, context, this.experimentService);
  }

  public get lastEditedBy(): string {
    if (!this.clientFacingNote?.lastEditedBy) return '';

    const user = this.parent.findUser(this.clientFacingNote?.lastEditedBy.value);
    return user ? user.fullName : '';
  }

  public get lastEditedOn(): string {
    return this.clientFacingNote?.lastEditedOn
      ? formatInstant(this.clientFacingNote.lastEditedOn, DateAndInstantFormat.dateTimeToMinute)
      : '';
  }

  public get commentText(): string {
    return this.clientFacingNote?.currentComment ?? '';
  }
  public set commentText(value: string) {
    if (this.clientFacingNote?.currentComment !== undefined) {
      this.clientFacingNote.currentComment = value;
    }
  }

  public get headerText(): string {
    if (!this.clientFacingNote) return '';

    let headerText = this.isNewClientFacingNote ? '' : this.clientFacingNote.number + ': ';
    switch (this.clientFacingNote?.contextType) {
      case ClientFacingNoteContextType.TableCell:
        const col = $localize`:@@columnAbbr:COL`;
        const row = $localize`:@@rowAbbr:ROW`;
        headerText += `${col}: ${this.contextReference.columnLabel} | ${row}: ${this.contextReference.rowIndex}`;
        break;
      case ClientFacingNoteContextType.FormField:
        const ctl = $localize`:@@controlAbbr:CTL`;
        const fieldContext = (this.contextReference.context) as FormFieldClientFacingNoteContext
        headerText += this.outputFormFieldIdentifiers.includes(fieldContext.fieldIdentifier) ?
          `${ctl}: ${fieldContext.fieldIdentifier}` :
          `${ctl}: ${this.contextReference.formLabel}`;
        break;
      case ClientFacingNoteContextType.ActivityInput: {
        const colA = $localize`:@@columnAbbr:COL`;
        const rowA = $localize`:@@rowAbbr:ROW`;
        headerText += `${colA}: ${this.contextReference.columnLabelForActivityInput} | ${rowA}: ${this.contextReference.rowIndexForActivityInput}`;
        break;
      }
      case ClientFacingNoteContextType.CrossReference: {
        const colA = $localize`:@@columnAbbr:COL`;
        const rowA = $localize`:@@rowAbbr:ROW`;
        headerText += `${colA}: ${this.contextReference.columnLabelForCrossReference} | ${rowA}: ${this.contextReference.rowIndexForCrossReference}`;
        break;
      }
      case ClientFacingNoteContextType.LabItems:
        const colL = $localize`:@@columnAbbr:COL`;
        const rowL = $localize`:@@rowAbbr:ROW`;
        headerText += `${colL}: ${this.contextReference.columnLabelForLabItem} | ${rowL}: ${this.contextReference.rowIdForLabItem}`;
        break;
      case ClientFacingNoteContextType.Preparations:
        const currentPreparationColumn = $localize`:@@columnAbbr:COL`;
        const currentPreparationRow = $localize`:@@rowAbbr:ROW`;
        headerText += `${currentPreparationColumn}: ${this.contextReference.columnLabelForPreparations} | ${currentPreparationRow}: ${this.contextReference.rowIndexForPreparations}`;
        break;
      case ClientFacingNoteContextType.LabItemsPreparation:
        const currentLabItemPreparationColumn = $localize`:@@columnAbbr:COL`;
        const currentLabItemPreparationRow = $localize`:@@rowAbbr:ROW`;
        headerText += `${currentLabItemPreparationColumn}: ${this.contextReference.columnLabelForLabItemsPreparations} | ${currentLabItemPreparationRow}: ${this.contextReference.rowIndexForLabItemsPreparations}`;
        break;
      // implement other node-type header descriptions here!
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default:
        break;
    }
    return headerText;
  }

  public get headerTooltip(): string {
    if (!this.clientFacingNote) return '';

    const act = $localize`:@@activityAbbr:ACT`;
    const mod = $localize`:@@moduleAbbr:MOD`;
    const inp = $localize`:@@moduleAbbr:INP`;
    const out = $localize`:@@outputsAbbr:OUT`;
    const refs = $localize`:@@referencesAbbr:REFS`;
    const crossReferences = $localize`:@@CrossReferences:Cross References`;
    const compendiumReferences = $localize`:@@compendiumReferences:Compendium References`;
    const documentReferences = $localize`:@@documentReferences:Document References`;
    const activity = this.contextReference.activity?.itemTitle;
    const module = this.contextReference.module?.moduleLabel;

    switch (this.clientFacingNote.contextType) {
      case ClientFacingNoteContextType.TableCell: {
        const tbl = $localize`:@@tableAbbr:TBL`;
        const table = this.contextReference.table?.itemTitle;
        if (module) {
          return `${act}: ${activity} | ${mod}: ${module} | ${tbl}: ${table}`;
        } else if (this.contextReference.table?.tableId === this.contextReference.activity?.activityReferences.compendiaReferencesTableId) {
          return `${act}: ${activity} | ${refs}: ${compendiumReferences}`;
        } else if (this.contextReference.table?.tableId === this.contextReference.activity?.activityReferences.documentReferencesTableId) {
          return `${act}: ${activity} | ${refs}: ${documentReferences}`;
        }
        return `${act}: ${activity} | ${tbl}: ${table}`;
      }
      case ClientFacingNoteContextType.ActivityInput: {
        const tbl = $localize`:@@tableAbbr:TBL`;
        const tableA = (this.contextReference.context as any).tableTitle;
        const inputI = $localize`:@@inputModuleHeader:Activity Inputs`;
        return `${act}: ${activity} | ${inp}: ${inputI} | ${tbl}: ${tableA}`;
      }
      case ClientFacingNoteContextType.CrossReference:
        return `${act}: ${activity} | ${refs}: ${crossReferences}`;
      case ClientFacingNoteContextType.LabItems:
        const actL = $localize`:@@labItem:Lab Item`;
        const labItemType = this.contextReference.moduleLabelForLabItem;
        return `${act}: ${activity} | ${actL}: ${labItemType}`;
      case ClientFacingNoteContextType.Preparations:
        const preparationsTitle = PreparationConstants.preparationsTitle;
        return `${act}: ${activity} | ${preparationsTitle}`;
      case ClientFacingNoteContextType.LabItemsPreparation:
        const labItemPreparation = $localize`:@@labItem:Lab Item`;
        const labItemPreparationsTitle = PreparationConstants.preparationsTitle;
        return `${act}: ${activity} | ${labItemPreparation}: ${labItemPreparationsTitle}`;
      case ClientFacingNoteContextType.FormField:
        const fieldContext = (this.contextReference.context) as FormFieldClientFacingNoteContext;
        const frm = $localize`:@@formAbbr:FRM`;
        if (this.outputFormFieldIdentifiers.includes(fieldContext.fieldIdentifier)) {
          const outputHeader = $localize`:@@outputsHeader:Activity Outputs`;
          const formHeader = $localize`:@@instrumentEventImpactAssessment:Instrument Event Impact Assessment`;
          const activity = this.contextReference.experiment.activities.find(activity => activity.activityId === fieldContext.formId)?.itemTitle;
          return `${act}: ${activity} | ${out}: ${outputHeader} | ${frm}: ${formHeader}`;
        }
        const form = this.contextReference.form?.itemTitle;
        return `${act}: ${activity} | ${mod}: ${module} | ${frm}: ${form}`;
      // implement other node-type header descriptions here!
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default:
        return '';
    }
  }

  constructor(
    comments: CommentsComponent,
    public readonly clientFacingNotesService: ClientFacingNoteService,
    private readonly experimentService: ExperimentService,
    public clientStateService: ClientStateService,
    public activatedRoute: ActivatedRoute,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly statementsService: StatementsService,
    private readonly userService: UserService
  ) {
    super(clientStateService, activatedRoute);
    this.parent = comments;
    this.activeSubscriptions.push(
      this.experimentService.experimentWorkFlowState.subscribe((experimentWorkflowState) => {
        this.manageStatementMenuItem();
      })
    );
  }

  private manageStatementMenuItem() {
    const addStatement = this.getStatementMenuItem();
    if (addStatement) {
      this.menuItems.push(addStatement);
    } else {
      this.menuItems = [];
    }
  }

  public ngOnInit() {
    this.manageStatementMenuItem();
  }

  isNotAllowedToAddOrEditClientFacingNote(): boolean {
    // For client-facing notes, do not consider canEditExperimentInReviewState even if present in default clientstate's clientstate-permissions' feature flags.
    return (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview)
      || (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.Authorized)
      || (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.Cancelled)
      || this.readOnly;
  }

  ngOnDestroy(): void {
    this.onKeydownHandler = function (_event: KeyboardEvent): void { };
    UnsubscribeAll(this.activeSubscriptions);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(_event: KeyboardEvent) {
    if (!this.isBeingEdited) return;
    this.cancel();
  }

  public noteInputClicked(_event: Event) {
    this.isBeingEdited = !this.isNotAllowedToAddOrEditClientFacingNote();
    this.refreshEditState(this.commentText);
  }

  cancel() {
    if (!this.clientFacingNote) return;

    this.clientFacingNote.discardChanges();
    this.whenCommentCancelled.emit({});

    if (!this.isNewClientFacingNote) {
      this.isBeingEdited = false;
      this.refreshEditState(this.commentText);
    }
  }

  public submit(): void {
    if (this.isNotAllowedToAddOrEditClientFacingNote()) return;

    if (!this.isNewClientFacingNote) {
      this.submitChange();
    } else {
      this.submitNew();
    }
  }

  private submitNew(): void {
    const experimentId = this.parent.parentExperiment.experiment?.id;
    const submissionContext: SubmissionContext = this.getSubmissionContext();
    const nodeId = submissionContext.nodeId;
    const path = submissionContext.path;
    const content = {
      type: ValueType.String,
      state: ValueState.Set,
      value: this.clientFacingNote?.currentComment
    };
    if (!(experimentId && nodeId && path[0] && content && this.clientFacingNote)) {
      throw new Error(this.errorMessage);
    }
    if (this.clientFacingNote.contextType === ClientFacingNoteContextType.TableCell && !path[1]) {
      // special condition for table cells - path has 2 entries.
      throw new Error(this.errorMessage);
    }
    const contextType = this.clientFacingNote?.contextType ?? ClientFacingNoteContextType.Invalid;
    this.isBeingEdited = false;
    this.clientFacingNotesService
      .clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json({
        experimentId,
        contextType,
        nodeId,
        path,
        body: content
      })
      .subscribe({
        next: (response) => this.processCreateResponse(response),
        error: () => (this.isBeingEdited = true),
        complete: () => this.refreshEditState(this.commentText)
      });
  }

  public processCreateResponse(response: CreateClientFacingNoteResponse) {
    //service invocation successful, notify parent component
    if (!this.clientFacingNote) throw Error('Expected client-facing note to exist');

    const note = this.clientFacingNote;
    note.saveChanges();
    note.number = response.number;
    note.lastEditedBy = response.lastEditedBy;
    note.lastEditedOn = response.lastEditedOn;

    this.whenCommentAdded.emit(note);

    this.parent.refreshCloseButtonEnablement();

    if (!this.parent.parentExperiment.experiment)
      throw new Error('Expected experiment to be defined.');
  }

  public focus(): void {
    // Using click to trigger associated button behavior and whatnot
    this.noteInputElement?.nativeElement.click();
    setTimeout(() => this.noteInput?.focus(), 0); // Get in the back of the line; otherwise something else would shift focus afterwards.
  }

  private submitChange(): void {
    const experimentId = this.parent.parentExperiment.experiment?.id;
    const submissionContext: SubmissionContext = this.getSubmissionContext();
    const nodeId = submissionContext.nodeId;
    const number = this.clientFacingNote?.number;
    const content = {
      type: ValueType.String,
      state: ValueState.Set,
      value: this.commentText
    };
    if (!(this.clientFacingNote && experimentId && nodeId && number && content))
      throw new Error(this.errorMessage);

    // This should be set once when the component instance is associated with the model
    if (!this.clientFacingNote.isDirty()) return;

    this.clientFacingNotesService
      .clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json({
        experimentId,
        number,
        nodeId,
        body: content
      })
      .subscribe((response) => {
        if (!this.clientFacingNote)
          throw new Error('Unable to process change to Client Facing Note.');

        const note = this.clientFacingNote;
        note.saveChanges();
        note.lastEditedBy = response.lastEditedBy;
        note.lastEditedOn = response.lastEditedOn;
        this.whenCommentUpdated.emit(note);

        this.isBeingEdited = false;
        this.refreshEditState(note.currentComment);
      });
  }

  private getSubmissionContext(): SubmissionContext {
    switch (this.clientFacingNote?.contextType) {
      case ClientFacingNoteContextType.TableCell:
        const tableContext = this.clientFacingNote?.getTableCellContext();
        if (!tableContext) throw Error('Expected table cell context to be found');
        return {
          path: [tableContext.rowId, tableContext.columnField],
          nodeId: tableContext.tableId
        };

      case ClientFacingNoteContextType.ActivityInput:
        const activityInputContext = this.clientFacingNote?.getActivityInputContext();
        if (!activityInputContext) throw Error('Expected activity input context to be found');
        return {
          path: [activityInputContext.rowId, activityInputContext.columnField, activityInputContext.activityId, activityInputContext.tableTitle, activityInputContext.label],
          nodeId: activityInputContext.activityId
        };

      case ClientFacingNoteContextType.CrossReference: {
        const context = this.clientFacingNote?.getCrossReferenceCellContext();
        if (!context) throw Error('Expected cross reference cell context to be found');

        return {
          path: [context.rowId, context.columnField],
          nodeId: context.activityId
        };
      }

      case ClientFacingNoteContextType.LabItems:
        const labItemContext = this.clientFacingNote?.getLabItemCellContext();
        if (!labItemContext) throw Error('Expected lab item context to be found');
        return {
          path: [labItemContext.rowId, labItemContext.columnField, labItemContext.labItemId, labItemContext.labItemType],
          nodeId: labItemContext.labItemId
        };

      case ClientFacingNoteContextType.Preparations:
        const preparationCellContext = this.clientFacingNote?.getPreparationsCellContext();
        if (!preparationCellContext) throw Error('Expected preparations context to be found');
        return {
          path: [preparationCellContext.rowId, preparationCellContext.columnField, preparationCellContext.nodeId, 'preparations'],
          nodeId: preparationCellContext.nodeId
        };
      case ClientFacingNoteContextType.LabItemsPreparation:
        const lpCellContext = this.clientFacingNote?.getLabItemPreparationsCellContext();
        if (!lpCellContext) throw Error('Expected  lab items preparations context to be found');
        return {
          path: [lpCellContext.rowId, lpCellContext.columnField, lpCellContext.nodeId, 'labItemsPreparations'],
          nodeId: lpCellContext.nodeId
        };
      case ClientFacingNoteContextType.FormField:
        const formFieldContext = this.clientFacingNote?.getFormFieldContext();
        if (!formFieldContext) throw Error('Expected form field context to be found');
        return {
          path: [formFieldContext.fieldIdentifier],
          nodeId: formFieldContext.formId
        };

      // implement other node-type submission context data objects here
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default:
        throw Error('Invalid submission context!');
    }
  }

  noteInputChange() {
    this.refreshEditState(this.commentText);
  }

  refreshEditState(comment: string | undefined) {
    const note = comment?.trim();

    if (this.isNewClientFacingNote) {
      this.isBeingEdited = !(note === undefined || note.length === 0);
    } else {
      this.menuItems = [];
      this.manageStatementMenuItem();
    }
  }

  showHistoryDialog() {
    if (!this.parent.parentExperiment.experiment?.id) {
      throw Error('Cannot determine experiment id');
    }
    if (!this.clientFacingNote?.number) {
      return;
    }
    this.isLoading = true;
    let notes;
    const contextType = this.clientFacingNote?.contextType;
    switch (contextType) {
      case ClientFacingNoteContextType.TableCell:
        const tableContext = this.clientFacingNote?.getTableCellContext();
        if (!tableContext) throw Error('Expected table cell context to be found');
        notes = this.auditHistoryService.loadClientFacingNoteHistory(
          this.parent.parentExperiment.experiment.id,
          [tableContext.tableId],
          contextType
        );
        break;
      case ClientFacingNoteContextType.FormField:
        const formFieldContext = this.clientFacingNote?.getFormFieldContext();
        if (!formFieldContext) throw Error('Expected form field context to be found');
        notes = this.auditHistoryService.loadClientFacingNoteHistory(
          this.parent.parentExperiment.experiment.id,
          [formFieldContext.formId],
          contextType
        );
        break;
      case ClientFacingNoteContextType.ActivityInput:
        const acContext = this.clientFacingNote?.getActivityInputContext();
        if (!acContext) throw Error('Expected activity input field context to be found');
        notes = this.auditHistoryService.loadClientFacingNoteHistory(
          this.parent.parentExperiment.experiment.id,
          [acContext.activityInputId],
          contextType
        );
        break;
      // implement other node-type submission context data objects here
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default:
        return;
    }
    if (notes) {
      this.activeSubscriptions.push(notes.subscribe((data) => {
        if (!this.clientFacingNote?.number) {
          return;
        }
        this.filterDataRecordsByNoteNumber(data, +this.clientFacingNote.number);
        this.isLoading = false;
        this.auditHistoryService.showAuditDialog(data.dataRecords, `${this.headerText}`);
      }));
    }
  }

  filterDataRecordsByNoteNumber(data: AuditHistoryDataRecordResponse, number: number) {
    remove(
      data.dataRecords,
      (rec) =>
        ((val) => {
          switch (val.eventContext.eventType) {
            case ExperimentEventType.ClientFacingNoteChanged:
              return val as ClientFacingNoteChangedEventNotification;
            case ExperimentEventType.ClientFacingNoteCreated:
              return val as ClientFacingNoteCreatedEventNotification;
            default:
              throw Error('Unexpected data record');
          }
        })(rec).number !== number
    );
  }

  getContextMenu(): FormContextMenuItem[] {
    if (this.isNewClientFacingNote) {
      return ['copy', 'paste'];
    } else {
      return [
        {
          label: $localize`:@@noteHistory:Note History`,
          action: () => this.showHistoryDialog(),
          icon: 'fas fa-history'
        }
      ];
    }
  }

  private getStatementMenuItem(): MenuItem | undefined {
    const currentLoggedInUserPuid = this.userService.currentUser?.puid;
    if (this.statementsService.canShowStatements(currentLoggedInUserPuid)) {
      const statementDetails = this.getStatementDetails();
      return {
        label: $localize`:@@addStatement:Add Statement`,
        icon: 'pi pi-plus',
        command: () => { this.statementsService.openStatements(statementDetails); },
        disabled: this.clientFacingNote?.lastEditedBy?.value === currentLoggedInUserPuid
      }
    } else if (this.clientStateService.isClientStateReadOnly) {
      return {
        label: $localize`:@@addStatement:Add Statement`,
        icon: 'pi pi-plus',
        disabled: this.clientStateService.isClientStateReadOnly
      }
    } else {
      return undefined;
    }
  }

  private getStatementDetails(): StatementModel {
    const submissionContext: SubmissionContext = this.getSubmissionContext();
    return {
      contextType: StatementContextType.ClientFacingNote,
      experimentId: this.parent.parentExperiment.experiment!.id,
      nodeId: submissionContext.nodeId,
      showBackButton: true,
      cfnNumber: this.clientFacingNote?.number,
      pathDetails: [{
        path: submissionContext.path
      }]
    };
  }
}

export type EventMode = 'clientFacing' | 'internal';

export type SubmissionContext = {
  path: string[];
  nodeId: string;
};
