import { Injectable, Renderer2 } from '@angular/core';
import { ExperimentService } from '../services/experiment.service';
import { MaterialAliquot as LabItemsMaterial } from '../../api/models/LabItemsELN/Bookshelf/Api/LabItems/material-aliquot';
import {
  ActivityInputType,
  ActivityLabItemsNode,
  AddExperimentScannedItemsCommand,
  ClientFacingNoteContextType,
  ColumnType,
  ExperimentDataValue,
  ExperimentPreparation,
  ExperimentWorkflowState,
  InstantValue,
  LabItemsInstrument,
  ModifiableDataValue,
  NodeType,
  NumberValue,
  PromptItem,
  SpecificationValue,
  StringArrayValue
} from '../../api/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  BarcodeScannerHelper,
  LabItemsColumnAddedNotification,
  LabItemsInstrumentAddedNotification,
  LabItemsMaterialAddedNotification
} from 'services/barcode-scanner-helper';
import { DataValueService } from '../services/data-value.service';
import { clone, isEmpty, isEqual, lowerFirst, mapValues } from 'lodash-es';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridRowActionClickEvent,
  BptGridPreferences,
  ColumnDefinition,
  GridContextMenuItem,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated
} from 'bpt-ui-library/bpt-grid';
import {
  BptControlSeverityIndicator,
  BptControlSeverityIndicators,
  NA,
  SeverityIndicatorType
} from 'bpt-ui-library/shared';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentEventType } from '../../api/audit/models';
import { AuditHistoryService } from '../audit-history/audit-history.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataRecordService } from '../services/data-record.service';
import {
  StringValue,
  LocalDateValue,
  LabItemsMaterialRemovedEventNotification,
  LabItemsMaterialRefreshedNotification,
  RemoveLabItemsMaterialCommand,
  RefreshLabItemsMaterialCommand,
  LabItemsMaterialRestoredEventNotification,
  RestoreLabItemsMaterialCommand,
  LabItemsCellChangedEventNotification,
  ValueState,
  RemoveLabItemsInstrumentCommand,
  LabItemsInstrumentRemovedEventNotification,
  LabItemsInstrumentRestoredEventNotification,
  RestoreLabItemsInstrumentCommand,
  LabItemsConsumableAddedNotification,
  RemoveLabItemsConsumableCommand,
  LabItemsConsumableRemovedEventNotification,
  LabItemsConsumableRestoredEventNotification,
  RestoreLabItemsConsumableCommand,
  TableRow,
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  RefreshLabItemsInstrumentCommand,
  LabItemsInstrumentRefreshedNotification,
  LabItemsInstrumentColumnRefreshedNotification,
  RefreshLabItemsInstrumentColumnCommand,
  RemoveLabItemsInstrumentColumnCommand,
  LabItemsInstrumentColumnRemovedEventNotification,
  LabItemsInstrumentColumnRemovedResponse,
  LabItemsInstrumentColumnRestoredResponse,
  LabItemsInstrumentColumnRestoredEventNotification,
  StatementAppliedEventNotification,
  PromptSatisfiedEventNotification
} from '../../api/data-entry/models';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ColDef, GridOptions, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { LabItemEventsService } from '../../api/data-entry/services';
import { LabItemsFeatureManager, LabItemWisePermissions } from './shared/lab-items-feature-manager';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { SpinnerOptions } from '../../eln-progress-spinner-module/spinner-options.interface';
import { AddLabItemsConsumableCommand } from '../../../app/api/data-entry/models/add-lab-items-consumable-command';
import { Consumable } from '../../../app/api/models/consumable';
import { CommentsResponse } from '../../api/internal-comment/models';
import { CommentService } from '../comments/comment.service';
import { ActivityInputService } from '../../api/services';
import { CellLock, LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ExperimentCollaboratorsService } from 'services/experiment-collaborators.service';
import { GridPreferenceService } from '../services/grid-preference.service';
import { LabItemConsumableDataSource } from '../model/lab-item-consumable-data-source';
import { ClientFacingNoteModel } from '../comments/client-facing-note/client-facing-note.model';
import { InstrumentColumn } from '../../api/models/LabItemsELN/Bookshelf/Api/LabItems/instrument-column';
import { StrictHttpResponse } from '../../api/strict-http-response';
import { convertDateToLocalDate } from '../../shared/date-time-helpers';
import {  PromptType } from '../../prompt/models/prompt.model';
import { PromptsService } from '../prompts/prompts.service';
import { ChangeReasonService } from '../services/change-reason.service';
import { v4 as uuid } from 'uuid';
import { DataValidationsService } from '../services/data-validations.service';

export type LabItemLabelsByType = {
  heading: string;
  singularHeading: string
  restoredTooltip: string;
  refreshTooltip: string;
  removedTooltip: string;
};

export type LabItemRefreshedContext = {
  rowId: string;
  changedColumns: string[];
  skipFlash: boolean;
  cellChangeToUpdate?: { propertyName: string; primitiveValue: string };
};

type labItemType = LabItemsInstrument | LabItemsMaterial | Consumable | InstrumentColumn;
@Injectable()
export class LabItemsService {
  private readonly columnCellChangedByOtherUser = new Subject<LabItemRefreshedContext>();
  private readonly columnRefreshNotification = new Subject<void>();
  private readonly consumableCellChangedByOtherUser = new Subject<LabItemRefreshedContext>();
  private readonly contextMenuNotification = new Subject<GridContextMenuItem[]>();
  private readonly instrumentCellChangedByOtherUser = new Subject<LabItemRefreshedContext>();
  private readonly instrumentColumnRemovedNotification = new Subject<void>();
  private readonly instrumentColumnRestoredNotification = new Subject<void>();
  private readonly instrumentRefreshNotification = new Subject<void>();
  private readonly internalCommentsNotification = new Subject<void>();
  private readonly materialCellChangedByOtherUser = new Subject<LabItemRefreshedContext>();
  private readonly materialRefreshNotification = new Subject<void>();
  public materialUpdateEvent = new Subject<string>();

  private readonly latestLabItemsPermissionsSubject = new BehaviorSubject<LabItemWisePermissions>(
    {}
  );
  private readonly consumableRefreshNotification = new Subject<void>();
  public readonly latestLabItemsPermissions = this.latestLabItemsPermissionsSubject.asObservable();

  public readonly MaterialRefreshedByOtherUser = new Subject<LabItemRefreshedContext>();
  public readonly InstrumentRefreshedByOtherUser = new Subject<LabItemRefreshedContext>();
  public readonly ColumnRefreshedByOtherUser = new Subject<LabItemRefreshedContext>();

  public readonly LabItemsFeatureAccessChange = new Subject<LabItemWisePermissions>();

  public readonly ColumnAdded = new Subject<InstrumentColumn>();
  public readonly ColumnUpdated = new Subject<InstrumentColumn>();
  public readonly ConsumableAdded = new Subject<Consumable>();
  public readonly ConsumableRemoved = new Subject<Consumable>();
  public readonly ConsumableRestored = new Subject<Consumable>();
  public readonly ConsumableUpdated = new Subject<Consumable>();
  public readonly InstrumentAdded = new Subject<LabItemsInstrument>();
  public readonly InstrumentColumnRemoved = new Subject<InstrumentColumn>();
  public readonly InstrumentColumnRestored = new Subject<InstrumentColumn>();
  public readonly InstrumentRemoved = new Subject<LabItemsInstrument>();
  public readonly InstrumentRestored = new Subject<LabItemsInstrument>();
  public readonly InstrumentUpdated = new Subject<LabItemsInstrument>();
  public readonly LockConsumableRemoveIcon = new Subject<BptGridRowActionClickEvent>();
  public readonly MaterialAdded = new Subject<LabItemsMaterial>();
  public readonly MaterialRemoved = new Subject<LabItemsMaterial>();
  public readonly MaterialRestored = new Subject<LabItemsMaterial>();
  public readonly MaterialUpdated = new Subject<LabItemsMaterial>();
  public materialShouldRefresh: Observable<void> = this.materialRefreshNotification.asObservable();
  public internalCommentsRefresh: Observable<void> =
    this.internalCommentsNotification.asObservable();
  public consumableRefresh: Observable<void> = this.consumableRefreshNotification.asObservable();

  public contextMenuAdded = this.contextMenuNotification.asObservable();
  public instrumentShouldRefresh: Observable<void> =
    this.instrumentRefreshNotification.asObservable();
  public columnShouldRefresh: Observable<void> =
    this.columnRefreshNotification.asObservable();
  public InstrumentColumnRemovedRefresh: Observable<void> =
  this.instrumentColumnRemovedNotification.asObservable();
  public InstrumentColumnRestoredRefresh: Observable<void> =
    this.instrumentColumnRestoredNotification.asObservable();
  public isLoading = false;
  public dynamicDialogRef!: DynamicDialogRef;
  public disableButton = false;
  public featuresByClientState: string[] = [];
  promptsNodeData?: PromptItem[];
  labItemsConsumableCollection: Array<Consumable> = [];
  consumableGridId = 'itemReference';
  consumableRemovedRowsGridId = 'labItemsRemovedConsumable';
  consumableDeleteActionId = 'bpt-consumable-delete-row';
  consumableRestoreActionId = 'bpt-consumable-restore-row';
  columnGridId = 'labItemsColumn';
  columnDeleteActionId = 'bpt-column-delete-row';
  columnRefreshActionId = 'bpt-column-refresh-row';
  columnRestoreActionId = 'bpt-column-restore-row';
  reloadGridTimeout = 200;
  private readonly backgroundColorString = 'background-color';
  private readonly colorString = 'color';
  private readonly pointerEventsAttr = 'pointer-events';
  private readonly rowActionItems = [
    this.consumableDeleteActionId,
    this.consumableRestoreActionId,
    this.columnDeleteActionId,
    this.columnRefreshActionId,
    this.columnRestoreActionId
  ];

  public readonly eventsForAuditHistory: {
    [itemType: string]: ExperimentEventType[];
  } = {
    material: [
      ExperimentEventType.LabItemsMaterialAdded,
      ExperimentEventType.LabItemsMaterialRemoved,
      ExperimentEventType.LabItemsMaterialRestored,
      ExperimentEventType.LabItemsMaterialRefreshed,
      ExperimentEventType.LabItemsCellChanged,
      ExperimentEventType.ClientFacingNoteCreated,
      ExperimentEventType.ClientFacingNoteChanged,
      ExperimentEventType.StatementApplied,
      ExperimentEventType.PromptSatisfied

    ],
    instrumentDetails: [
      ExperimentEventType.LabItemsInstrumentAdded,
      ExperimentEventType.LabItemsCellChanged,
      ExperimentEventType.LabItemsInstrumentRemoved,
      ExperimentEventType.LabItemsInstrumentRestored,
      ExperimentEventType.LabItemsInstrumentRefreshed,
      ExperimentEventType.ClientFacingNoteCreated,
      ExperimentEventType.ClientFacingNoteChanged,
      ExperimentEventType.ClientFacingNoteChanged,
      ExperimentEventType.StatementApplied,
      ExperimentEventType.PromptSatisfied
    ],
    instrumentColumn: [
      ExperimentEventType.InstrumentColumnAdded,
      ExperimentEventType.LabItemsCellChanged,
      ExperimentEventType.LabItemsInstrumentColumnRefreshed,
      ExperimentEventType.LabItemsInstrumentColumnRemoved,
      ExperimentEventType.LabItemsInstrumentColumnRestored,
      ExperimentEventType.StatementApplied,
      ExperimentEventType.PromptSatisfied
    ],
    consumable: [
      ExperimentEventType.LabItemsConsumableAdded,
      ExperimentEventType.LabItemsCellChanged,
      ExperimentEventType.LabItemsConsumableRemoved,
      ExperimentEventType.LabItemsConsumableRestored,
      ExperimentEventType.StatementApplied,
      ExperimentEventType.PromptSatisfied
    ]
  };
  private labItemWiseClientFacingCreatedNotesIndex: {
    [itemType: string]: number[];
  } = {};

  public readonly auditDataRecordsFilterByEventType: {
    [itemType: string]: (
      dataRecord: ExperimentDataRecordNotification,
      labItemType: ActivityInputType
    ) => boolean;
  } = {
    clientFacingNoteCreated: (
      dataRecord: ExperimentDataRecordNotification,
      labItemType: ActivityInputType
    ) =>
      this.filterClientFacingNoteCreatedDataRecords(
        dataRecord as ClientFacingNoteCreatedEventNotification,
        labItemType
      ),
    clientFacingNoteChanged: (
      dataRecord: ExperimentDataRecordNotification,
      labItemType: ActivityInputType
    ) =>
      this.filterClientFacingNoteChangedDataRecords(
        dataRecord as ClientFacingNoteChangedEventNotification,
        labItemType
      )
  };

  public filterClientFacingNoteCreatedDataRecords(
    clientFacingNoteRecord: ClientFacingNoteCreatedEventNotification,
    labItemType: ActivityInputType
  ): boolean {
    if (clientFacingNoteRecord.contextType !== ClientFacingNoteContextType.LabItems) {
      return false;
    }
    const labItemContext = ClientFacingNoteModel.getContextByTypeAndPath(
      clientFacingNoteRecord.nodeId,
      clientFacingNoteRecord.contextType,
      clientFacingNoteRecord.path
    );
    if (labItemContext.labItemType === labItemType) {
      this.labItemWiseClientFacingCreatedNotesIndex[labItemType] = this.labItemWiseClientFacingCreatedNotesIndex[labItemType] ?? [];
      this.labItemWiseClientFacingCreatedNotesIndex[labItemType].push(clientFacingNoteRecord.number);
      return true;
    }
    return false;
  }

  public filterClientFacingNoteChangedDataRecords(
    clientFacingNoteRecord: ClientFacingNoteChangedEventNotification,
    labItemType: ActivityInputType
  ): boolean {
    return this.labItemWiseClientFacingCreatedNotesIndex[labItemType]?.includes(clientFacingNoteRecord.number);
  }

  private static readonly AuditHistoryLoadingMessage: SpinnerOptions = {
    message: $localize`:@@loadingHistory:Loading History...`,
    i18nMessage: `@@loadingHistory`
  };

  /** Nullable Date fields do not store empty hence need special attention to update N/A when it's applicable. */
  public static readonly FieldsNeedsAnNAAssessment = [
    'nextQualificationDate',
    'nextMaintenanceDate',
    'inUseExpirationDate',
    'expirationDate',
    'AdditionalInformation'
  ];

  public readonly getItemHandler: {
    [itemType: string]: (
      code: string
    ) => labItemType | undefined;
  } = {
    material: this.getMaterialByCode.bind(this),
    instrumentDetails: this.getInstrumentByCode.bind(this),
    instrumentColumn: this.getColumnByCode.bind(this),
    consumable: this.getConsumableByCode.bind(this)
  };

  public readonly getItemByActivityHandler: {
    [itemType: string]: (
      code: string,
      activityId: string
    ) => labItemType | undefined;
  } = {
    material: this.getMaterialByCodeAndActivityId.bind(this),
    instrumentDetails: this.getInstrumentByCodeAndActivityId.bind(this),
    instrumentColumn: this.getColumnByCodeAndActivityId.bind(this),
    consumable: this.getConsumableByCodeAndActivityId.bind(this)
  };

  public readonly refreshHandler: {
    [itemType: string]: (item: LabItemsInstrument | LabItemsMaterial ) => Observable<boolean>;
  } = {
    material: this.getLatestMaterialInformation.bind(this),
    instrumentDetails: this.getLatestInstrumentInformation.bind(this)
  };

  public readonly restoreHandler: {
    [itemType: string]: (item: labItemType) => void;
  } = {
    material: this.confirmThenRestoreMaterial.bind(this),
    instrumentDetails: this.confirmThenRestoreInstrument.bind(this),
    consumable: this.confirmThenRestoreConsumable.bind(this),
    instrumentColumn:  this.confirmThenRestoreInstrumentColumn.bind(this)
  };

  public columnRefreshHandler(item: InstrumentColumn, renderer: Renderer2) {
    this.getLatestColumnInformation(item, renderer);
  }

  public readonly cellChangedNotificationByItemType: {
    [itemType: string]: Subject<LabItemRefreshedContext>;
  } = {
    material: this.materialCellChangedByOtherUser,
    instrumentDetails: this.instrumentCellChangedByOtherUser,
    instrumentColumn: this.columnCellChangedByOtherUser,
    consumable:this.consumableCellChangedByOtherUser,
  };

  constructor(
    private readonly activityInputService: ActivityInputService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly commentService: CommentService,
    private readonly dataRecordService: DataRecordService,
    private readonly dataValueService: DataValueService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly gridPreferenceService: GridPreferenceService,
    public readonly barcodeScanService: BarcodeScannerHelper,
    public readonly confirmationService: ConfirmationService,
    public readonly experimentService: ExperimentService,
    public readonly labItemEventsService: LabItemEventsService,
    public readonly messageService: MessageService,
    private readonly promptsService: PromptsService,
    private readonly dataValidationService: DataValidationsService
  ) {
    this.watchActivitySelectionChanges();
    this.watchMaterialAddedNotifications();
    this.watchMaterialRestoredNotifications();
    this.watchCellChangedNotifications();
    this.watchInstrumentAddedNotifications();
    this.watchMaterialRemovedNotification();
    this.watchLatestMaterialRefreshedNotification();
    this.watchLatestColumnRefreshedNotification();
    this.watchLatestInstrumentRefreshedNotification();
    this.watchWorkFlowStateChanges();
    this.watchInstrumentRemovedNotification();
    this.watchInstrumentRestoredNotification();
    this.watchConsumableAddedNotification();
    this.watchInternalCommentsNotification();
    this.watchConsumableRemovedNotification();
    this.watchConsumableRestoredNotification();
    this.watchColumnAddedNotification();
    this.watchLatestColumnRemovedNotification();
    this.watchLatestColumnRestoredNotification();
  }

  private watchColumnAddedNotification() {
    this.barcodeScanService.labItemColumnAddedNotification.subscribe({
      next: this.processAddedColumn.bind(this)
    });
  }
  private watchInternalCommentsNotification() {
    this.commentService.refreshInternalComment.subscribe((_currentContext: CommentsResponse) => {
      this.internalCommentsNotification.next();
    });
  }

  private watchMaterialAddedNotifications() {
    this.barcodeScanService.labItemMaterialAliquotAddedNotification.subscribe({
      next: this.processAddedMaterial.bind(this)
    });
  }

  private watchMaterialRestoredNotifications() {
    this.dataRecordService.labItemsMaterialRestoredEventNotificationReceiver.subscribe({
      next: this.processRestoredMaterial.bind(this)
    });
  }
  private watchCellChangedNotifications() {
    this.dataRecordService.labItemsCellChangedEventNotificationReceiver.subscribe({
      next: this.processLabItemsCellChanged.bind(this)
    });
  }

  private watchInstrumentAddedNotifications() {
    this.barcodeScanService.labItemInstrumentAddedNotification.subscribe({
      next: this.processAddedInstrument.bind(this)
    });
  }

  private watchMaterialRemovedNotification() {
    this.dataRecordService.labItemsMaterialRemovedEventNotificationReceiver.subscribe({
      next: (notification) => this.processRemovedMaterial(notification, true)
    });
  }

  private watchInstrumentRemovedNotification() {
    this.dataRecordService.labItemsInstrumentRemovedEventNotificationReceiver.subscribe({
      next: this.processRemovedInstrument.bind(this)
    });
  }

  private watchInstrumentRestoredNotification() {
    this.dataRecordService.labItemsInstrumentRestoredEventNotificationReceiver.subscribe({
      next: this.processRestoredInstrument.bind(this)
    });
  }

  private watchLatestMaterialRefreshedNotification() {
    this.dataRecordService.labItemsRefreshedMaterialNotification.subscribe({
      next: (notification) => this.applyLatestMaterialInformation(notification, true)
    });
  }

  private watchLatestColumnRefreshedNotification() {
    this.dataRecordService.labItemsRefreshedColumnNotification.subscribe({
      next: (notification) => this.applyLatestColumnInformation(notification, true)
    });
  }

  private watchLatestColumnRemovedNotification() {
    this.dataRecordService.labItemsRemovedColumnNotification.subscribe({
      next: (notification) => this.processRemovedInstrumentColumn(notification)
    });
  }

  private watchLatestColumnRestoredNotification() {
    this.dataRecordService.labItemsRestoredColumnNotification.subscribe({
      next: (notification) => this.processRestoredInstrumentColumn(notification)
    });
  }

  private watchLatestInstrumentRefreshedNotification() {
    this.dataRecordService.labItemsRefreshedInstrumentNotification.subscribe({
      next: (notification) => this.applyLatestInstrumentInformation(notification, true)
    });
  }

  private watchConsumableAddedNotification() {
    this.dataRecordService.labItemsConsumableAddedEventNotificationReceiver.subscribe({
      next: this.processAddedConsumable.bind(this)
    });
  }

  private watchConsumableRemovedNotification() {
    this.dataRecordService.labItemsConsumableRemovedEventNotificationReceiver.subscribe({
      next: this.processRemovedConsumable.bind(this)
    });
  }

  private watchConsumableRestoredNotification() {
    this.dataRecordService.labItemsConsumableRestoredEventNotificationReceiver.subscribe({
      next: this.processRestoredConsumable.bind(this)
    });
  }


  private watchWorkFlowStateChanges() {
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => this.evaluateUserPermissionsOnLabItems(state)
    });
    this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe({
      next: (notification) => this.evaluateUserPermissionsOnLabItems(notification.state)
    });
  }

  private processAddedMaterial(notification: any) {
    let activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) =>
        labItemNode.nodeId === notification.activityId
    );
    activityLabItemNode = activityLabItemNode || this.createActivityLabItemNodeBy(notification);
    notification.materialAliquotNotificationDetails.activityInputType = ActivityInputType.Material;
    notification = LabItemsService.changeMaterialFieldNamesToPascalCase(notification);
    if (activityLabItemNode) {
      activityLabItemNode.materials.push(notification.materialAliquotNotificationDetails as LabItemsMaterial);
      this.materialRefreshNotification.next();
      this.MaterialAdded.next(notification.materialAliquotNotificationDetails as LabItemsMaterial);
    }
  }

  private processRemovedMaterial(
    notification: LabItemsMaterialRemovedEventNotification,
    pushMessage: boolean
  ) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const material = activityLabItemNode.materials.find(
        (i) => i.code === notification.itemReference
      );
      if (material) {
        material.isRemoved = true;
        this.materialRefreshNotification.next();
        this.MaterialRemoved.next(material);
        this.promptsService.promptUnSatisfied.next(material.code);
        this.materialUpdateEvent.next(this.experimentService.currentActivityId);
        if (notification.studyLinkIdentified) {
          this.MaterialRemovedDueToStudyActivityPresentMessage(
            notification.itemReference,
            ActivityInputType.Material
          );
        } else if (pushMessage) {
          this.removedSuccessMessage(notification.itemReference, ActivityInputType.Material);
        }
      }
    }
  }

  private processAddedConsumable(notification: LabItemsConsumableAddedNotification) {
    let activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    activityLabItemNode =
      activityLabItemNode || this.createActivityLabItemNodeForNonScannedItemsBy(notification);
    if (activityLabItemNode) {
      activityLabItemNode.consumables.push(notification as Consumable);
      this.ConsumableAdded.next(notification as Consumable);
    }
  }

  private processAddedInstrument(notification: any) {
    let activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) =>
        labItemNode.nodeId === notification.activityId
    );
    activityLabItemNode = activityLabItemNode || this.createActivityLabItemNodeBy(notification);
    notification = LabItemsService.changeInstrumentFieldNamesToPascalCase(notification);
    notification.instrumentAddedNotificationDetails.activityInputType = ActivityInputType.InstrumentDetails;
    if (activityLabItemNode) {
      notification.instrumentAddedNotificationDetails.tableData =
        notification.instrumentAddedNotificationDetails.cellData;
      activityLabItemNode.instruments.push(notification.instrumentAddedNotificationDetails as LabItemsInstrument);
      this.instrumentRefreshNotification.next();
      this.InstrumentAdded.next(notification.instrumentAddedNotificationDetails as LabItemsInstrument);
    }
  }

  private processRemovedInstrument(notification: LabItemsInstrumentRemovedEventNotification) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const instrument = activityLabItemNode.instruments.find(
        (i) => i.code === notification.itemReference
      );
      if (instrument) {
        instrument.isRemoved = true;
        this.instrumentRefreshNotification.next();
        this.promptsService.promptUnSatisfied.next(instrument.code);
        this.InstrumentRemoved.next(instrument);
        this.removedSuccessMessage(notification.itemReference, ActivityInputType.InstrumentDetails);
      }
    }
  }

  private processRestoredInstrument(notification: LabItemsInstrumentRestoredEventNotification) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const instrument = activityLabItemNode.instruments.find(
        (i) => i.code === notification.itemReference
      );
      if (instrument) {
        instrument.isRemoved = false;
        this.instrumentRefreshNotification.next();
        this.InstrumentRestored.next(instrument);
        this.restoredSuccessMessage(
          notification.itemReference,
          ActivityInputType.InstrumentDetails
        );
      }
    }
  }

  private processRestoredInstrumentColumn(notification: LabItemsInstrumentColumnRestoredEventNotification) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const instrumentColumn = activityLabItemNode.instrumentColumns.find(
        (i) => i.code === notification.itemReference
      );
      if (instrumentColumn) {
        instrumentColumn.isRemoved = false;
        this.instrumentColumnRestoredNotification.next();
        this.instrumentColumnRemovedNotification.next();
        this.InstrumentColumnRestored.next(instrumentColumn);
        this.restoredSuccessMessage(
          notification.itemReference,
          ActivityInputType.InstrumentColumn
        );
      }
    }
  }

  private processAddedColumn(notification: any) {
    let activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) =>
        labItemNode.nodeId === notification.activityId
    );
    activityLabItemNode = activityLabItemNode || this.createActivityLabItemNodeBy(notification);
    notification = LabItemsService.changeColumnFieldNamesToPascalCase(notification);
    notification.instrumentColumnAddedNotificationDetails.activityInputType = ActivityInputType.InstrumentColumn;
    if (activityLabItemNode) {
      activityLabItemNode.instrumentColumns.push(notification.instrumentColumnAddedNotificationDetails as InstrumentColumn);
      this.columnRefreshNotification.next();
      this.ColumnAdded.next(notification.instrumentColumnAddedNotificationDetails as InstrumentColumn);
    }
  }

  private processRestoredConsumable(notification: LabItemsConsumableRestoredEventNotification) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const consumable = activityLabItemNode.consumables.find(
        (i) => i.itemReference === notification.itemReference
      );
      if (consumable) {
        consumable.isRemoved = false;
        this.consumableRefreshNotification.next();
        this.ConsumableRestored.next(consumable);
        this.restoredSuccessMessage(notification.itemReference, ActivityInputType.Consumable);
      }
    }
  }

  private static changeInstrumentFieldNamesToPascalCase(
    notification: LabItemsInstrumentAddedNotification
  ) {
    notification.instrumentAddedNotificationDetails.cellData.forEach((row) => {
      this.changeFieldNameToPascalCase(row);
    });
    return notification;
  }

  private static changeColumnFieldNamesToPascalCase(
    notification: LabItemsColumnAddedNotification
  ) {
    notification.instrumentColumnAddedNotificationDetails.tableData.forEach((row) => {
      this.changeFieldNameToPascalCase(row);
    });
    return notification;
  }

  private static changeMaterialFieldNamesToPascalCase(
    notification: LabItemsMaterialAddedNotification
  ) {
    notification.materialAliquotNotificationDetails.tableData.forEach((row) => {
      this.changeFieldNameToPascalCase(row);
    });
    return notification;
  }

  private processRemovedConsumable(notification: LabItemsConsumableRemovedEventNotification) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const consumable = activityLabItemNode.consumables.find(
        (i) => i.itemReference === notification.itemReference
      );
      if (consumable) {
        consumable.isRemoved = true;
        this.consumableRefreshNotification.next();
        this.promptsService.promptUnSatisfied.next(consumable.itemReference);
        this.ConsumableRemoved.next(consumable);
        this.removedSuccessMessage(notification.itemReference, ActivityInputType.Consumable);
      }
    }
  }

  private static changeFieldNameToPascalCase(row: { [key: string]: ModifiableDataValue }) {
    Object.keys(row).forEach((fieldName) => {
      row[fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = clone(row[fieldName]);
      delete row[fieldName];
    });
  }

  private createActivityLabItemNodeBy(
    notification: any
  ): ActivityLabItemsNode {
    const activityLabItemNode = {
      activityLabItemNodeId: notification.activityId,
      nodeId: notification.activityId,
      experimentId: this.experimentService.currentExperiment?.id as string,
      itemType: NodeType.LabItems,
      instruments: [],
      instrumentColumns: [],
      materials: [],
      consumables: [],
      preparations: [],
      id: notification.activityId,
      _ts: 0
    };
    this.experimentService.currentExperiment?.activityLabItems.push(activityLabItemNode);
    return activityLabItemNode;
  }

  private createActivityLabItemNodeForNonScannedItemsBy(
    notification: LabItemsConsumableAddedNotification
  ): ActivityLabItemsNode {
    const activityLabItemNode = {
      activityLabItemNodeId: notification.activityId,
      nodeId: notification.activityId,
      experimentId:
        typeof this.experimentService.currentExperiment?.id === 'undefined'
          ? ''
          : this.experimentService.currentExperiment?.id,
      itemType: NodeType.LabItems,
      instruments: [],
      instrumentColumns: [],
      materials: [],
      consumables: [],
      preparations: [],
      id: notification.activityId,
      _ts: 0
    };
    this.experimentService.currentExperiment?.activityLabItems.push(activityLabItemNode);
    return activityLabItemNode;
  }

  public watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    route.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (activity) {
        this.barcodeScanService.currentActivityId = activity.activityId;
        this.experimentService.currentActivityId = activity.activityId;
        this.activitySelectionChanged(activity.activityId);
      }
    });
  }

  private getLatestMaterialInformation(
    item: LabItemsInstrument | LabItemsMaterial
  ): Observable<boolean> {
    const refreshStatus = new Subject<boolean>();
    const command = this.createRefreshLabItemsMaterialCommand(item as LabItemsMaterial);
    this.labItemEventsService
      .labItemEventsExperimentIdRefreshMaterialPost$Json({
        experimentId: this.experimentService.currentExperiment?.id as string,
        body: command
      })
      .subscribe({
        next: (response) => this.refreshedMaterialResponse(response, refreshStatus),
        error: (_error) => refreshStatus.next(false)
      });
    return refreshStatus.asObservable();
  }

  private getLatestColumnInformation(
    item:  InstrumentColumn,
    renderer: Renderer2
  ): Observable<boolean> {
    const refreshStatus = new Subject<boolean>();
    const command = this.createRefreshLabItemsColumnCommand(item);
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${item.code}"] [id="${this.columnRefreshActionId}"] `
    )
    const pendingImg = 'pending-img';
    renderer.addClass(cell, pendingImg);
    this.sendInputStatus(
      LockType.lock,
      this.columnGridId,
      item.code,
      this.columnRefreshActionId
    );
    this.labItemEventsService
      .labItemEventsExperimentIdRefreshInstrumentColumnPost$Json({
        experimentId: this.experimentService.currentExperiment?.id as string,
        body: command
      })
      .subscribe({
        next: (response) => {
          this.refreshedColumnResponse(response, refreshStatus);
          renderer.removeClass(cell, pendingImg);
          this.sendInputStatus(
            LockType.unlock,
            this.columnGridId,
            item.code,
            this.columnRefreshActionId
          );
        },
        error: (_error) => {
          refreshStatus.next(false);
          renderer.removeClass(cell, pendingImg);
          this.sendInputStatus(
            LockType.unlock,
            this.columnGridId,
            item.code,
            this.columnRefreshActionId
          );
        }
      });
    return refreshStatus.asObservable();
  }

  private getLatestInstrumentInformation(
    item: LabItemsInstrument | LabItemsMaterial
  ): Observable<boolean> {
    const refreshStatus = new Subject<boolean>();
    const command = this.createRefreshLabItemsInstrumentCommand(item as LabItemsInstrument);
    this.labItemEventsService
      .labItemEventsExperimentIdRefreshInstrumentPost$Json({
        experimentId: this.experimentService.currentExperiment?.id as string,
        body: command
      })
      .subscribe({
        next: (response) => this.refreshedInstrumentResponse(response, refreshStatus),
        error: (_error) => refreshStatus.next(false)
      });
    return refreshStatus.asObservable();
  }

  private refreshedMaterialResponse(
    response: LabItemsMaterialRefreshedNotification,
    acknowledgement: Subject<boolean>
  ): void {
    if (isEmpty(response.notifications.notifications)) {
      acknowledgement.next(true);
      if (response.labItemsMaterialRemovedEventNotification) {
        this.applyLatestMaterialInformation(response, false, false);
        this.HandleLabItemsMaterialRemoveWithRefresh(response, ActivityInputType.Material);
      } else {
        this.applyLatestMaterialInformation(response);
      }
    } else {
      acknowledgement.next(false);
      this.failedToRefreshMessage(response.itemReference, ActivityInputType.Material);
    }
  }

  private refreshedColumnResponse(
    response: LabItemsInstrumentColumnRefreshedNotification,
    acknowledgement: Subject<boolean>
  ): void {
    if (isEmpty(response.notifications.notifications)) {
      acknowledgement.next(true);
      this.applyLatestColumnInformation(response);
    } else {
      acknowledgement.next(false);
      this.failedToRefreshMessage(response.itemReference, ActivityInputType.InstrumentColumn);
    }
  }

  private refreshedInstrumentResponse(
    response: LabItemsInstrumentRefreshedNotification,
    acknowledgement: Subject<boolean>
  ): void {
    if (isEmpty(response.notifications.notifications)) {
      acknowledgement.next(true);
      this.applyLatestInstrumentInformation(response);
    } else {
      acknowledgement.next(false);
      this.failedToRefreshMessage(response.itemReference, ActivityInputType.InstrumentDetails);
    }
  }

  private HandleLabItemsMaterialRemoveWithRefresh(
    response: LabItemsMaterialRemovedEventNotification,
    activityInputType: ActivityInputType
  ) {
    this.processRemovedMaterial(response, false);
    this.MaterialRemovedDueToStudyActivityPresentMessage(
      response.itemReference,
      ActivityInputType.Material
    );
    const isPresentInStudyActivity = this.checkForStudyActivityInActivityInputs(
      response.itemReference
    );
    if (!isPresentInStudyActivity) {
      this.addMaterialToActivityInputs(response, activityInputType);
    }
  }

  private addMaterialToActivityInputs(
    response: LabItemsMaterialRemovedEventNotification,
    activityInputType: ActivityInputType
  ) {
    const inputItemCommand = {
      activityIds: [response.activityId],
      activityInputReference: response.itemReference,
      activityInputType: activityInputType,
      experimentId: response.eventContext.experimentId
    } as AddExperimentScannedItemsCommand;
    this.activityInputService.activityInputAddExperimentScannedItemsPost$Response({ body: inputItemCommand }).subscribe();
  }

  private applyLatestMaterialInformation(
    refreshedMaterialInformation: LabItemsMaterialRefreshedNotification,
    sendNotificationOut = false,
    pushMessage = true
  ): void {
    const item = this.getMaterialByCodeAndActivityId(
      refreshedMaterialInformation.itemReference,
      refreshedMaterialInformation.activityId
    ) as LabItemsMaterial;
    if (item) {
      const fieldsToRefreshData = Object.keys(refreshedMaterialInformation.refreshedDataValues);
      if (isEmpty(fieldsToRefreshData) && pushMessage) {
        this.upToDateMessage(
          refreshedMaterialInformation.itemReference,
          ActivityInputType.Material
        );
      } else {
        const changedColumns: string[] = [];
        fieldsToRefreshData.forEach((fieldName) => {
          const camelCaseFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
          (item as any)[camelCaseFieldName] =
            (refreshedMaterialInformation.refreshedDataValues[fieldName] as StringValue | NumberValue ).value;
          changedColumns.push(camelCaseFieldName);
        });
        this.updateNAToEmptyFields(item, ActivityInputType.Material);
        this.materialRefreshNotification.next();
        if (sendNotificationOut) {
          this.MaterialRefreshedByOtherUser.next({
            rowId: refreshedMaterialInformation.itemReference,
            changedColumns,
            skipFlash: false
          });
        }
        if (pushMessage) {
          this.refreshedSuccessMessage(
            refreshedMaterialInformation.itemReference,
            ActivityInputType.Material
          );
        }
      }
    }
  }

  private applyLatestInstrumentInformation(
    refreshedInstrumentInformation: LabItemsInstrumentRefreshedNotification,
    sendNotificationOut = false,
    pushMessage = true
  ): void {
    const item = this.getInstrumentByCodeAndActivityId(
      refreshedInstrumentInformation.itemReference,
      refreshedInstrumentInformation.activityId
    ) as LabItemsInstrument;
    if (item) {
      const fieldsToRefreshData = Object.keys(refreshedInstrumentInformation.refreshedDataValues);
      if (isEmpty(fieldsToRefreshData) && pushMessage) {
        this.upToDateMessage(
          refreshedInstrumentInformation.itemReference,
          ActivityInputType.InstrumentDetails
        );
      } else {
        const changedColumns: string[] = [];
        fieldsToRefreshData.forEach((fieldName) => {
          const camelCaseFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
          (item as any)[camelCaseFieldName] =
          (refreshedInstrumentInformation.refreshedDataValues[fieldName] as StringValue | NumberValue).value;
          changedColumns.push(camelCaseFieldName);
        });
        this.updateNAToEmptyFields(item, ActivityInputType.InstrumentDetails);
        this.instrumentRefreshNotification.next();
        if (sendNotificationOut) {
          this.InstrumentRefreshedByOtherUser.next({
            rowId: refreshedInstrumentInformation.itemReference,
            changedColumns,
            skipFlash: false
          });
        }
        if (pushMessage) {
          this.refreshedSuccessMessage(
            refreshedInstrumentInformation.itemReference,
            ActivityInputType.InstrumentDetails
          );
        }
      }
    }
  }

  private applyLatestColumnInformation(
    refreshedColumnInformation: LabItemsInstrumentColumnRefreshedNotification,
    sendNotificationOut = false,
    pushMessage = true
  ): void {
    const item = this.getColumnByCodeAndActivityId(
      refreshedColumnInformation.itemReference,
      refreshedColumnInformation.activityId
    ) as InstrumentColumn;
    if (item) {
      const fieldsToRefreshData = Object.keys(refreshedColumnInformation.refreshedDataValues);
      if (isEmpty(fieldsToRefreshData) && pushMessage) {
        this.upToDateMessage(
          refreshedColumnInformation.itemReference,
          ActivityInputType.InstrumentColumn
        );
      } else {
        const changedColumns: string[] = [];
        fieldsToRefreshData.forEach((fieldName) => {
          const camelCaseFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
          (item as any)[camelCaseFieldName] =
          (refreshedColumnInformation.refreshedDataValues[fieldName] as StringValue | NumberValue).value;
          changedColumns.push(camelCaseFieldName);
        });
        this.updateNAToEmptyFields(item, ActivityInputType.InstrumentColumn);
        this.columnRefreshNotification.next();
        if (sendNotificationOut) {
          this.ColumnRefreshedByOtherUser.next({
            rowId: refreshedColumnInformation.itemReference,
            changedColumns,
            skipFlash: false
          });
        }
        if (pushMessage) {
          this.refreshedSuccessMessage(
            refreshedColumnInformation.itemReference,
            ActivityInputType.InstrumentColumn
          );
        }
      }
    }
  }

  private createRefreshLabItemsMaterialCommand(
    material: LabItemsMaterial
  ): RefreshLabItemsMaterialCommand {
    return {
      activityId: this.experimentService.currentActivityId,
      experimentId: this.experimentService.currentExperiment?.id as string,
      itemReference: material.code,
      brand: material.brand,
      catalogNumber: material.catalogNumber,
      concentration: material.concentration,
      grade: material.grade,
      lotNumber: material.lotNumber,
      materialStatus: material.materialStatus,
      materialType: material.materialType,
      materialUrl: material.materialUrl,
      name: material.name,
      notebookReference: material.notebookReference,
      purity: material.purity,
      status: material.status,
      expirationDate: material.expirationDate === NA ? undefined : material.expirationDate,
      inUseExpirationDate:
        material.inUseExpirationDate === NA ? undefined : material.inUseExpirationDate
    };
  }

  private createRefreshLabItemsInstrumentCommand(instrument: LabItemsInstrument): RefreshLabItemsInstrumentCommand {
    return {
      activityId: this.experimentService.currentActivityId,
      experimentId: this.experimentService.currentExperiment?.id as string,
      itemReference: instrument.code,
      groups: instrument.groups,
      manufacturerName: instrument.manufacturerName,
      model: instrument.model,
      name: instrument.name,
      nextMaintenanceDate: instrument.nextMaintenanceDate === NA ? undefined : instrument.nextMaintenanceDate,
      nextQualificationDate: instrument.nextMaintenanceDate === NA ? undefined : instrument.nextMaintenanceDate,
      restrictedUse: instrument.restrictedUse,
      useStatus: instrument.useStatus
    };
  }

  private createRefreshLabItemsColumnCommand(
    instrumentColumn: InstrumentColumn
  ): RefreshLabItemsInstrumentColumnCommand {
    return {
      activityId: this.experimentService.currentActivityId,
      experimentId: this.experimentService.currentExperiment?.id as string,
      code: instrumentColumn.code,
      itemReference: instrumentColumn.code,
      manufacturerName: instrumentColumn.manufacturerName,
      packingType: instrumentColumn.packingType,
      dimensions: instrumentColumn.dimensions,
      particleSize: instrumentColumn.particleSize,
      compendiaPackingPhase: instrumentColumn.compendiaPackingPhase,
      lotNumber: instrumentColumn.lotNumber,
      catalogNumber: instrumentColumn.catalogNumber,
      serialNumber: instrumentColumn.serialNumber,
      isActive: instrumentColumn.isActive
    };
  }

  public loadAuditHistoryDialog(source: ActivityInputType) {
    this.elnProgressSpinnerService.Show(LabItemsService.AuditHistoryLoadingMessage);
    this.auditHistoryService
      .loadLabItemsAuditHistory(
        this.experimentService.currentExperiment!.id,
        this.experimentService.currentActivityId
      )
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        let history;
        switch (source) {
          case ActivityInputType.Material:
            history = this.getAllAuditHistory(
              data,
              this.eventsForAuditHistory[ActivityInputType.Material],
              ActivityInputType.Material
            );
            this.elnProgressSpinnerService.Hide();
            this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
              history,
              $localize`:@@LabItemsMaterialsTableTitle:Materials`
            );
            break;
          case ActivityInputType.InstrumentDetails:
            history = this.getAllAuditHistory(
              data,
              this.eventsForAuditHistory[ActivityInputType.InstrumentDetails],
              ActivityInputType.InstrumentDetails
            );
            this.elnProgressSpinnerService.Hide();
            this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
              history,
              $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
            );
            break;
          case ActivityInputType.Consumable:
            history = this.getAllAuditHistory(
              data,
              this.eventsForAuditHistory[ActivityInputType.Consumable],
              ActivityInputType.Consumable
            );
            this.elnProgressSpinnerService.Hide();
            this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
              history,
              $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`
            );
            break;
          case ActivityInputType.InstrumentColumn:
            history = this.getAllAuditHistory(
              data,
              this.eventsForAuditHistory[ActivityInputType.InstrumentColumn],
              ActivityInputType.InstrumentColumn
            );
            this.elnProgressSpinnerService.Hide();
            this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
              history,
              $localize`:@@LabItemsColumnsTableTitle:Columns`
            );
            break;
        }
      });
  }

  private getAllAuditHistory(
    data: AuditHistoryDataRecordResponse,
    eventTypes: ExperimentEventType[],
    itemType: ActivityInputType
  ) {
    const historyRecords = data.dataRecords.filter((record) => {
      if(record.eventContext.eventType === ExperimentEventType.PromptSatisfied) {
        return record.eventContext &&
        itemType.toString() === this.experimentService.getPromptItemType((record as PromptSatisfiedEventNotification).promptId) &&
        eventTypes.indexOf(record.eventContext.eventType) > -1
      }
      else if(record?.eventContext?.eventType === ExperimentEventType.LabItemsCellChanged) {
        return record?.eventContext  &&
        (record as LabItemsCellChangedEventNotification).itemType === itemType &&
        eventTypes.indexOf(record.eventContext.eventType) > -1
      }
      else {
        return record?.eventContext  &&
        eventTypes.indexOf(record.eventContext.eventType) > -1 &&
        (this.auditDataRecordsFilterByEventType[record.eventContext.eventType] === undefined ||
          this.auditDataRecordsFilterByEventType[record.eventContext.eventType](
            record,
            itemType
          ));
      }
    });

    const filteredRecords = [...historyRecords.filter(x =>
      (x as StatementAppliedEventNotification).contentDetails?.find(m => m.path[3] !== itemType))];
   return historyRecords.filter(x => !filteredRecords.includes(x));
  }

  private watchActivitySelectionChanges() {
    this.experimentService.activitySelectionChanged.subscribe({
      next: (_selectedActivityNodeId) => {
        if(_selectedActivityNodeId){
          this.materialRefreshNotification.next();
          this.instrumentRefreshNotification.next();
          this.columnRefreshNotification.next();
          this.barcodeScanService.currentActivityId = _selectedActivityNodeId;
          this.activitySelectionChanged(_selectedActivityNodeId);
        }
      }
    });
  }

  private activitySelectionChanged(activityId: string) {
    this.barcodeScanService.labItemsMaterialCollection = this.getMaterialsOf(activityId);
    this.barcodeScanService.labItemsInstrumentCollection = this.getInstrumentOf(activityId);
    this.barcodeScanService.labItemsColumnCollection = this.getColumnOf(activityId);
  }

  public getLabItemsInstruments(): Array<LabItemsInstrument> {
    this.barcodeScanService.labItemsInstrumentCollection = this.getInstrumentOf(
      this.experimentService.currentActivityId
    );
    return this.barcodeScanService.labItemsInstrumentCollection.filter(i => !i.isRemoved);
  }

  public getLabItemsConsumables(): Array<Consumable> {
    this.labItemsConsumableCollection = this.getConsumableOf(
      this.experimentService.currentActivityId
    );
    return this.labItemsConsumableCollection.filter(c => !c.isRemoved);
  }

  private getInstrumentOf(activityNodeId: string): Array<LabItemsInstrument> {
    return (
      (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityNodeId
      )?.instruments as Array<LabItemsInstrument>) || []
    );
  }

  private getColumnOf(activityNodeId: string): Array<InstrumentColumn> {
    return (
      (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityNodeId
      )?.instrumentColumns as Array<InstrumentColumn>) || []
    );
  }

  private getConsumableOf(activityNodeId: string): Array<Consumable> {
    return (
      (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityNodeId
      )?.consumables as Array<Consumable>) || []
    );
  }

  public getLabItemsMaterials(): Array<LabItemsMaterial> {
    this.barcodeScanService.labItemsMaterialCollection = this.getMaterialsOf(
      this.experimentService.currentActivityId
    );
    return this.barcodeScanService.labItemsMaterialCollection.filter((m) => !m.isRemoved);
  }

  public getLabItemsColumns(): Array<InstrumentColumn> {
    this.barcodeScanService.labItemsColumnCollection = this.getColumnOf(this.experimentService.currentActivityId);
    return this.barcodeScanService.labItemsColumnCollection.filter(c => !c.isRemoved);
  }

  public getLabItemsRemovedMaterials(): Array<LabItemsMaterial> {
    return this.getMaterialsOf(this.experimentService.currentActivityId).filter((m) => m.isRemoved);
  }

  public getLabItemsRemovedInstruments(): Array<LabItemsInstrument> {
    return this.getInstrumentOf(this.experimentService.currentActivityId).filter(i => i.isRemoved);
  }

  public getLabItemsRemovedInstrumentColumns(): Array<InstrumentColumn> {
    return this.getColumnOf(this.experimentService.currentActivityId).filter(c => c.isRemoved);
  }

  public getLabItemsRemovedConsumables(): Array<Consumable> {
    return this.getConsumableOf(this.experimentService.currentActivityId).filter(c => c.isRemoved);
  }

  private getMaterialsOf(activityNodeId: string): Array<LabItemsMaterial> {
    return (
      (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityNodeId
      )?.materials as Array<LabItemsMaterial>) || []
    );
  }

  public getMaterialByCodeAndActivityId(
    code: string,
    activityId: string
  ): LabItemsMaterial | undefined {
    return (this.getMaterialsOf(activityId) || []).find((item) => item.code === code);
  }

  public getInstrumentByCodeAndActivityId(
    code: string,
    activityId: string
  ): LabItemsInstrument | undefined {
    return (this.getInstrumentOf(activityId) || []).find((item) => item.code === code);
  }

  public getColumnByCodeAndActivityId(
    code: string,
    activityId: string
  ): InstrumentColumn | undefined {
    return (this.getColumnOf(activityId) || []).find((item) => item.code === code);
  }

  public getConsumableByCodeAndActivityId(
    code: string,
    activityId: string
  ): Consumable | undefined {
    return (this.getConsumableOf(activityId) || []).find((item) => item.itemReference === code);
  }

  public getMaterialByCode(code: string): LabItemsMaterial | undefined {
    return this.getLabItemsMaterials().find((item) => item.code === code);
  }

  public getColumnByCode(code: string): InstrumentColumn | undefined {
    return this.getLabItemsColumns().find((item: { code: string; }) => item.code === code);
  }

  public getInstrumentByCode(code: string): LabItemsInstrument | undefined {
    return this.getLabItemsInstruments().find((item) => item.code === code);
  }

  public getConsumableByCode(code: string): Consumable | undefined {
    return this.getLabItemsConsumables().find((item) => item.itemReference === code);
  }

  public getCssRules = (
    dataSource: {
      code: string;
      tableData: Array<{
        [key: string]: ModifiableDataValue;
      }>;
    }[]
  ): any => {
    return {
      'bpt-grid-missing-data-gradient': (params: any) => {
        return (
          this.dataValueService.getExperimentDataValue(undefined, params.value)?.state === 'empty'
        );
      },
      'bpt-grid-modified-data-gradient': (params: any) => {
        return (dataSource || []).some(
          (row) =>
            row.code === params.data.code &&
            row.tableData.some(
              (data) =>
                data[params.colDef.field]?.isModified &&
                data[params.colDef.field].value.state !== ValueState.Empty
            )
        );
      }
    };
  };

  public evaluateUserPermissionsOnLabItems(
    workflowState?: ExperimentWorkflowState
  ): LabItemWisePermissions {
    const currentWorkFlowState =
      workflowState ||
      this.experimentService.currentExperiment?.workflowState ||
      ExperimentWorkflowState.Setup;

    const permissions: LabItemWisePermissions =
      LabItemsFeatureManager.EvaluateUserPermissionsOnLabItems(
        currentWorkFlowState,
        this.featuresByClientState
      );
    this.LabItemsFeatureAccessChange.next(permissions);
    this.latestLabItemsPermissionsSubject.next(permissions);
    return permissions;
  }

  private confirmThenRestoreMaterial(
    item: labItemType
  ): void {
    const labItemsMaterial = item as LabItemsMaterial;
    const restoreLabItemsMaterialCommand: RestoreLabItemsMaterialCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: labItemsMaterial.code,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRestoreMaterialConfirmationMessage:Are you sure you want to restore "${(item as LabItemsInstrument).name}" from removed materials?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreMaterial(restoreLabItemsMaterialCommand);
      }
    });
  }

  private hasRestorePermission(itemType: ActivityInputType): boolean {
    const permissions = this.evaluateUserPermissionsOnLabItems();
    return permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].restoreFeatureName
    ];
  }

  private hasRemovePermission(itemType: ActivityInputType): boolean {
    const permissions = this.evaluateUserPermissionsOnLabItems();
    return permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].removeFeatureName
    ];
  }

  private hasCellChangePermission(itemType: ActivityInputType): boolean {
    if (itemType === ActivityInputType.Consumable || ActivityInputType.InstrumentColumn) return true;
    const permissions = this.evaluateUserPermissionsOnLabItems();
    return permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].cellChangeFeatureName
    ];
  }

  sendRequestToRestoreMaterial(restoreLabItemsMaterialCommand: RestoreLabItemsMaterialCommand) {
    if (!this.hasRestorePermission(ActivityInputType.Material)) {
      this.failedToPerformOperationMessage(
        restoreLabItemsMaterialCommand.itemReference,
        ActivityInputType.Material
      );
      return;
    }
    this.labItemEventsService
      .labItemEventsExperimentIdRestoreMaterialPost$Json({
        experimentId: restoreLabItemsMaterialCommand.experimentId,
        body: restoreLabItemsMaterialCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.processRestoredMaterial(response.labItemsMaterialRestoredEventNotification);
            this.CheckForStudyActivityAndPrompt(restoreLabItemsMaterialCommand.itemReference);
          }
        }
      });
  }

  private CheckForStudyActivityAndPrompt(itemReference: string) {
    if (this.checkForStudyActivityInActivityInputs(itemReference)) {
      this.MaterialAlreadyPresentInStudyActivityMessage(itemReference, ActivityInputType.Material);
    }
  }

  private checkForStudyActivityInActivityInputs(itemReference: string): boolean {
    const isMaterialPresentInActivityInputs =
      this.experimentService.currentExperiment?.activityInputs
        ?.find(
          (activityInput) => activityInput.activityId === this.experimentService.currentActivityId
        )
        ?.materials.some((material) => material.code === itemReference);
    return isMaterialPresentInActivityInputs ?? false;
  }

  private processRestoredMaterial(notification: LabItemsMaterialRestoredEventNotification) {
    const item = this.getMaterialByCodeAndActivityId(
      notification.itemReference,
      notification.activityId
    ) as LabItemsMaterial;
    item.isRemoved = false;
    this.materialRefreshNotification.next();
    this.MaterialRestored.next(item);
    this.materialUpdateEvent.next(this.experimentService.currentActivityId);
    this.restoredSuccessMessage(notification.itemReference, ActivityInputType.Material);
  }

  private processLabItemsCellChanged(notification: LabItemsCellChangedEventNotification) {
    this.cellChangeNotification(notification, true);
  }

  public unpackModifiableDataValues<TSourceObject>(
    sourceObject: TSourceObject,
    cellData: { [key: string]: ModifiableDataValue | string }[],
    columnDefinitions: ColumnDefinition[],
    itemType: ActivityInputType
  ): void {
    if (!cellData) {
      return;
    }
    this.updateNAToEmptyFields(sourceObject, itemType);

    cellData.forEach((row) => {
      mapValues(row, (value: ModifiableDataValue | string, field: string) => {
        const column = columnDefinitions.find(
          (col) => col.field?.toLowerCase() === field.toLowerCase()
        );
        if (column) {
          (sourceObject as any)[column.field as string] = this.getPrimitiveValue(
            column,
            value as ModifiableDataValue,
          );
        }
      });
    });
  }

  private getPrimitiveValue(column: ColumnDefinition, value: ModifiableDataValue) {
    return this.dataValueService.getPrimitiveValue(column.columnType as ColumnType, value);
  }

  public labItemsCellValueChanged(
    itemType: ActivityInputType,
    changedCellEvent: BptGridCellValueChangedEvent,
    grid: BptGridComponent
  ) {
    this.continueToProcessChangedCellValue(itemType, changedCellEvent, grid);
  }

  addLabItemsConsumable = (
    addLabItemsConsumableCommand: AddLabItemsConsumableCommand,
    addRowInConsumableGrid: (
      labItemsConsumableAddedNotification: LabItemsConsumableAddedNotification
    ) => void
  ) => {
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    this.labItemEventsService
      .labItemEventsExperimentIdAddConsumablePost$Json({
        experimentId: experimentId,
        body: addLabItemsConsumableCommand
      })
      .subscribe({
        next: response => {
          let activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
            (labItemNode: ActivityLabItemsNode) =>
              labItemNode.nodeId === response.labItemsConsumableAddedNotification.activityId
          );
          activityLabItemNode = activityLabItemNode ??
            this.createActivityLabItemNodeForNonScannedItemsBy(
              response.labItemsConsumableAddedNotification
            );
          if (activityLabItemNode) {
            activityLabItemNode.consumables.push(
              response.labItemsConsumableAddedNotification as Consumable
            );
            addRowInConsumableGrid(response.labItemsConsumableAddedNotification);
          }
        },
        error: () => {
          this.disableButton = false;
        }
      })
  };

  private getLineItemData(itemType: ActivityInputType, rowId: string): any | undefined {
    return this.getItemHandler[itemType](rowId) as any;
  }

  private getColumnDefinition(grid: BptGridComponent, field: string): ColDef | undefined {
    return grid.colDefs.find((c) => c.field === field);
  }

  private getExperimentDataValueWhenValueChanged(
    row: any,
    changedCellEvent: BptGridCellValueChangedEvent,
    column: ColDef
  ): ExperimentDataValue | undefined {
    const oldCellValue = row[changedCellEvent.field as string];
    let valueBefore = changedCellEvent.oldValue;
    let valueAfter = changedCellEvent.newValue;
    if (column.type === ColumnType.Date) {
      valueBefore = valueBefore === NA ? NA : this.getOldDate(changedCellEvent.oldValue );
      valueAfter = valueAfter === NA ? NA : this.getNewDate(changedCellEvent.newValue );
    }

    const oldValue = this.dataValueService.getExperimentDataValue(column.type as ColumnType, valueBefore);
    const newValue = this.dataValueService.getExperimentDataValue(column.type as ColumnType, valueAfter);
    const newCellValue = DataRecordService.getModifiableDataValue(newValue, oldCellValue);
    if (isEqual(newCellValue.value, oldValue)) return undefined;
    return newValue;
  }

  getOldDate(date: any) {
    return date ? new Date(date): date
  }

  getNewDate(date: any) {
    return date ? convertDateToLocalDate(new Date (date)): date
  }

  private continueToProcessChangedCellValue(
    itemType: ActivityInputType,
    changedCellEvent: BptGridCellValueChangedEvent,
    grid: BptGridComponent
  ): void {
    if (!LabItemsService.isCellChangePendingToApply(changedCellEvent)) {
      return;
    }
    const row = this.getLineItemData(itemType, changedCellEvent.rowId as string);
    if (!row) return;

    const column = this.getColumnDefinition(grid, changedCellEvent.field as string) as ColDef;
    if (!column.type) column.type = grid.columnDefinitions?.find((c) => c.field === changedCellEvent.field)?.columnType;
    if (!column) throw new Error('Missing column reference.');

    if (!this.hasCellChangePermission(itemType)) {
      this.failedToPerformOperationMessage(row.code, itemType);
      return;
    }

    const dataValue = this.getExperimentDataValueWhenValueChanged(row, changedCellEvent, column);
    const labItemData: Array<TableRow> = row.tableData;
    const rowData = this.getRowData(labItemData);
    let cellValue: any;
    if (column.field === 'AdditionalInformation') {
      cellValue = rowData?.find(r => column.field && r.key === 'additionalInformation')?.value;
    } else {
      cellValue = rowData?.find(r => column.field && r.key === column.field)?.value;
    }
    cellValue.wasEmpty = this.dataValueService.getExperimentDataValue(undefined, changedCellEvent.oldValue)?.state === 'empty' && !cellValue.isModified;

    if (!dataValue) {
      row[changedCellEvent.field as string] = changedCellEvent.oldValue;
      return;
    }
    row[changedCellEvent.field as string] = changedCellEvent.newValue;
    this.postCellChangeToAPi(
      changedCellEvent,
      this.prepareChangeLabItemsCellCommand(
        row.code || row.itemReference,
        itemType,
        changedCellEvent.field as string,
        dataValue
      ),
      column.headerName as string
    );
  }

  private checkForChangeReason(changedCellEvent: BptGridCellValueChangedEvent, command: ChangeLabItemsCellCommand) {
    if(changedCellEvent.oldValue) {
      ChangeReasonService.oldValue = changedCellEvent.oldValue;
      ChangeReasonService.changeReasonId = uuid();
      command.changeReasonId = ChangeReasonService.changeReasonId;
    }
  }

  private postCellChangeToAPi(changedCellEvent: BptGridCellValueChangedEvent, command: ChangeLabItemsCellCommand, columnHeader: string) {
    this.checkForChangeReason(changedCellEvent, command);
    this.labItemEventsService
      .labItemEventsExperimentIdChangeCellValuePost$Json({
        experimentId: this.experimentService.currentExperiment!.id ?? command.experimentId,
        body: command
      })
      .subscribe({
        next: this.cellChangeNotification.bind(this),
        error: () => {
          this.cellChangeFailedMessage(command.itemType, command.itemReference);
        }
      });
  }

  private GetLabItemRefreshContextFrom(
    cellChangedNotification: LabItemsCellChangedEventNotification,
    skipFlash: boolean
  ): LabItemRefreshedContext {
    return {
      skipFlash,
      changedColumns: [cellChangedNotification.propertyName],
      rowId: cellChangedNotification.itemReference,
      cellChangeToUpdate: {
        propertyName: cellChangedNotification.propertyName,
        primitiveValue: this.dataValueService.getPrimitiveValue(
          cellChangedNotification.propertyValue.type.toString() as ColumnType,
          {value: cellChangedNotification.propertyValue} as ModifiableDataValue
        )
      }
    };
  }

  public static UpdateCellValueInGrid(
    labItemRefreshedContext: LabItemRefreshedContext,
    rowNode: IRowNode,
    reason = 'collaborativeEdit'
  ) {
    if (labItemRefreshedContext.cellChangeToUpdate) {
      rowNode.setDataValue(
        labItemRefreshedContext.cellChangeToUpdate.propertyName,
        labItemRefreshedContext.cellChangeToUpdate.primitiveValue,
        reason
      );
    }
  }

  private cellChangeNotification(
    cellChangedNotification: LabItemsCellChangedEventNotification,
    sendNotificationOut = false
  ) {
    const item = this.getItemByActivityHandler[cellChangedNotification.itemType](
      cellChangedNotification.itemReference,
      cellChangedNotification.activityId
    );
    if (item) {
      this.updatedCellChangedValue(cellChangedNotification, item);
    }

    if (
      cellChangedNotification.itemType === ActivityInputType.InstrumentDetails ||
      cellChangedNotification.itemType === ActivityInputType.InstrumentColumn ||
      cellChangedNotification.itemType === ActivityInputType.Material || cellChangedNotification.itemType === ActivityInputType.Consumable
    ) {
      this.cellChangedNotificationByItemType[cellChangedNotification.itemType].next(
        this.GetLabItemRefreshContextFrom(cellChangedNotification, !sendNotificationOut)
      );
    }
    this.notifyUpdatedLabItem(cellChangedNotification.itemType, item);
    if (cellChangedNotification.itemType === ActivityInputType.Consumable) return;
    this.cellChangedSuccessMessage(
      this.getItemReference(cellChangedNotification),
      cellChangedNotification.itemType
    );
  }

  private updatedCellChangedValue(cellChangedNotification: LabItemsCellChangedEventNotification, item: LabItemsMaterial | LabItemsInstrument | InstrumentColumn | Consumable) {
    const propertyNameFromNotification = cellChangedNotification.propertyName.charAt(0).toUpperCase() +
      cellChangedNotification.propertyName.slice(1);

    const propertyName = cellChangedNotification.propertyName.charAt(0).toLowerCase() +
      cellChangedNotification.propertyName.slice(1);

    const cell = item.tableData.find((data) => data[propertyNameFromNotification] || data[propertyName]);
    (item as any)[this.getPropertyNameFromCellChangeNotification(cellChangedNotification)] =
      (cellChangedNotification.propertyValue as StringValue | NumberValue).value;
    this.updateNAToEmptyFields(item as any, (item as any).activityInputType);
    if (cell) {
      cell[propertyNameFromNotification]
        = DataRecordService.getModifiableDataValue(cellChangedNotification.propertyValue, cell[propertyNameFromNotification] ?? cell[propertyName]);
    }
  }

  private getPropertyNameFromCellChangeNotification(
    cellChangedNotification: LabItemsCellChangedEventNotification
  ) {
    switch (cellChangedNotification.itemType) {
      case ActivityInputType.InstrumentDetails:
      case ActivityInputType.InstrumentColumn:
      case ActivityInputType.Material:
        return (
          cellChangedNotification.propertyName.charAt(0).toUpperCase() +
          cellChangedNotification.propertyName.slice(1)
        );
      case ActivityInputType.Consumable:
      default:
        return cellChangedNotification.propertyName;
    }
  }

  private notifyUpdatedLabItem(
    itemType: ActivityInputType,
    item: labItemType | undefined
  ) {
    if (itemType === ActivityInputType.Material) {
      this.MaterialUpdated.next(item as LabItemsMaterial);
    } else if (itemType === ActivityInputType.InstrumentDetails) {
      this.InstrumentUpdated.next(item as LabItemsInstrument);
    }
    else if (itemType === ActivityInputType.InstrumentColumn) {
      this.ColumnUpdated.next(item as InstrumentColumn);
    } else if (itemType === ActivityInputType.Consumable) {
      this.ConsumableUpdated.next(item as Consumable);
    }
  }

  private cellChangeFailedMessage(itemType: ActivityInputType, itemReference: string) {
    this.createNotificationMessage(
      $localize`:@@LabItemsCellChangeFailureMessage:Value changed failed for Lab Item ${LabItemsService.labItemsLabelsByItemType(itemType).singularHeading}`,
      `${itemReference}`,
      'error'
    );
  }

  private updateNAToEmptyFields(sourceObject: any, itemType: ActivityInputType) {
    if (itemType === ActivityInputType.Consumable) return;
    LabItemsService.FieldsNeedsAnNAAssessment.forEach((field) => {
      if (field in sourceObject) {
        const fieldValue = sourceObject[field];
        if (typeof fieldValue === 'undefined' || fieldValue === null || (fieldValue.trim && fieldValue.trim() === '')) {
          sourceObject[field] = NA;
        }
      }
    });
  }

  private static isCellChangePendingToApply(e: BptGridCellValueChangedEvent): boolean {
    return !(e.source === 'collaborativeEdit' || e.newValue === undefined || e.source === 'systemFields');
  }

  private prepareChangeLabItemsCellCommand(
    itemReference: string,
    itemType: ActivityInputType,
    propertyName: string,
    propertyValue: ExperimentDataValue
  ): ChangeLabItemsCellCommand {
    return {
      activityId: this.experimentService.currentActivityId,
      experimentId: this.experimentService.currentExperiment?.id as string,
      itemReference: itemReference,
      itemType: itemType,
      propertyName: propertyName,
      propertyValue: propertyValue
    };
  }

  private createNotificationMessage(summary: string, detail: string, severity: string = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  public readonly removeHandler: {
    [itemType: string]: (item: labItemType) => void;
  } = {
    material: this.confirmThenRemoveMaterial.bind(this),
    instrumentDetails: this.confirmThenRemoveInstrument.bind(this),
    consumable: this.confirmThenRemoveConsumable.bind(this),
    instrumentColumn: this.confirmThenRemoveInstrumentColumn.bind(this)
  };

  private confirmThenRestoreInstrumentColumn(item: labItemType) {
    this.sendInputStatus(
      LockType.lock,
      this.columnGridId,
      (item as InstrumentColumn).code,
      this.columnRestoreActionId
    );
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRestoreColumnConfirmationMessage:Are you sure you want to restore "${(item as InstrumentColumn).code}" from removed Column?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreInstrumentColumn(item as InstrumentColumn);
      },
      reject: () =>
        this.sendInputStatus(
          LockType.unlock,
          this.columnGridId,
          (item as InstrumentColumn).code,
          this.columnRestoreActionId
        )
    });
  }

  private sendRequestToRestoreInstrumentColumn(item: InstrumentColumn)
  {
    const restoreLabItemsInstrumentColumnCommand: RemoveLabItemsInstrumentColumnCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.code,
      experimentId: this.experimentService.currentExperiment?.id as string
    };

    this.labItemEventsService
      .labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response(
        {
          experimentId: restoreLabItemsInstrumentColumnCommand.experimentId,
          body: restoreLabItemsInstrumentColumnCommand
        }
      )
      .subscribe({
        next: (response: StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>) => {
          if (response.body.notifications.notifications.length === 0) {
            this.processRestoredInstrumentColumn(
              response.body.labItemsInstrumentColumnRestoredEventNotification
            );
          }
        }
      });
  }

  private confirmThenRemoveInstrumentColumn(item: labItemType) {
    this.sendInputStatus(
      LockType.lock,
      this.columnGridId,
      (item as InstrumentColumn).code,
      this.columnDeleteActionId
    );
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRemoveInstrumentColumnConfirmationMessage:Are you sure that you want to remove "${
        (item as InstrumentColumn).code
      }" from Column? The removed Column can be restored using the "View Removed Rows" option.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRemoveInstrumentColumn(item as InstrumentColumn);
      },
      reject: () => {
        this.sendInputStatus(
          LockType.unlock,
          this.columnGridId,
          (item as InstrumentColumn).code,
          this.columnDeleteActionId
        );
      }
    });
  }

  private sendRequestToRemoveInstrumentColumn(item: InstrumentColumn)
  {
    const removeLabItemsInstrumentColumnCommand: RemoveLabItemsInstrumentColumnCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.code,
      experimentId: this.experimentService.currentExperiment?.id as string
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response(
        {
          experimentId: removeLabItemsInstrumentColumnCommand.experimentId,
          body: removeLabItemsInstrumentColumnCommand
        }
      )
      .subscribe({
        next: (response: StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>) => {
          if (response.body.notifications.notifications.length === 0) {
            this.processRemovedInstrumentColumn(
              response.body.labItemsInstrumentColumnRemovedEventNotification
            );
          }
        }
      });
  }

  private processRemovedInstrumentColumn(
    notification: LabItemsInstrumentColumnRemovedEventNotification
  ) {
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    );
    if (activityLabItemNode) {
      const instrumentColumn = activityLabItemNode.instrumentColumns.find(
        (i) => i.code === notification.itemReference
      );

      if (instrumentColumn) {
        instrumentColumn.isRemoved = true;
        this.instrumentColumnRemovedNotification.next();
        this.promptsService.promptUnSatisfied.next(instrumentColumn.code);
        this.InstrumentColumnRemoved.next(instrumentColumn);
        this.removedSuccessMessage(notification.itemReference, ActivityInputType.InstrumentColumn);
      }
    }
  }

  private confirmThenRemoveMaterial(item: labItemType) {
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRemoveMaterialConfirmationMessage:Are you sure that you want to remove "${
        (item as LabItemsMaterial).name
      }" from materials? The removed material can be restored using the "View Removed Rows" option.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRemoveMaterial(item as LabItemsMaterial);
      }
    });
  }

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };

  getRowActionPermission() {
    let permissions: LabItemWisePermissions = {};
    this.latestLabItemsPermissions.subscribe({
      next: (latestPermissions) => {
        permissions = latestPermissions;
      }
    });
    return permissions[ActivityInputType.Material][LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Material].removeFeatureName];
  }

  private sendRequestToRemoveMaterial(item: LabItemsMaterial) {
    if (!this.hasRemovePermission(ActivityInputType.Material)) {
      this.failedToPerformOperationMessage(item.code, ActivityInputType.Material);
      return;
    }
    const removeLabItemsMaterialCommand: RemoveLabItemsMaterialCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.code,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRemoveMaterialPost$Json$Response({
        experimentId: removeLabItemsMaterialCommand.experimentId,
        body: removeLabItemsMaterialCommand
      })
      .subscribe({
        next: (response) => {
          if (response.body.notifications.notifications.length == 0) {
            this.processRemovedMaterial(
              response.body.labItemsMaterialRemovedEventNotification,
              true
            );
          }
        }
      });
  }

  private confirmThenRemoveConsumable(item: labItemType) {
    this.sendInputStatus(
      LockType.lock,
      this.consumableGridId,
      (item as Consumable).itemReference ?? '',
      this.consumableDeleteActionId
    );
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRemoveConsumableConfirmationMessage:Are you sure you want to remove this row? The row may restored through use of the View Removed Rows tool.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRemoveConsumable(item as LabItemConsumableDataSource);
      },
      reject: () => {
        this.sendInputStatus(
          LockType.unlock,
          this.consumableGridId,
          (item as Consumable).itemReference ?? '',
          this.consumableDeleteActionId
        );
      }
    });
  }

  private sendRequestToRemoveConsumable(item: LabItemConsumableDataSource) {
    const removeLabItemsConsumableCommand: RemoveLabItemsConsumableCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.itemReference,
      rowIndex: item.id,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRemoveConsumablePost$Json$Response({
        experimentId: removeLabItemsConsumableCommand.experimentId,
        body: removeLabItemsConsumableCommand
      })
      .subscribe({
        next: (response) => {
          if (response.body.notifications.notifications.length === 0) {
            this.processRemovedConsumable(response.body.labItemsConsumableRemovedEventNotification);
          }
        }
      });
  }

  private confirmThenRemoveInstrument(item: labItemType) {
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRemoveInstrumentConfirmationMessage:Are you sure that you want to remove "${
        (item as LabItemsInstrument).name
      }" from instruments? The removed instrument can be restored using the "View Removed Rows" option.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRemoveInstrument(item as LabItemsInstrument);
      }
    });
  }

  private sendRequestToRemoveInstrument(item: LabItemsInstrument) {
    if (!this.hasRemovePermission(ActivityInputType.InstrumentDetails)) {
      this.failedToPerformOperationMessage(item.code, ActivityInputType.InstrumentDetails);
      return;
    }
    const removeLabItemsInstrumentCommand: RemoveLabItemsInstrumentCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.code,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRemoveInstrumentPost$Json$Response({
        experimentId: removeLabItemsInstrumentCommand.experimentId,
        body: removeLabItemsInstrumentCommand
      })
      .subscribe({
        next: (response) => {
          if (response.body.notifications.notifications.length == 0) {
            this.processRemovedInstrument(response.body.labItemsInstrumentRemovedEventNotification);
          }
        }
      });
  }

  private confirmThenRestoreInstrument(item: labItemType) {
    this.confirmationService.confirm({
      message: $localize`:@@LabItemsRestoreInstrumentConfirmationMessage:Are you sure you want to restore "${(item as LabItemsInstrument).name}" from removed instruments?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreInstrument(item as LabItemsInstrument);
      }
    });
  }

  private sendRequestToRestoreInstrument(item: LabItemsInstrument) {
    if (!this.hasRestorePermission(ActivityInputType.InstrumentDetails)) {
      this.failedToPerformOperationMessage(item.code, ActivityInputType.InstrumentDetails);
      return;
    }
    const restoreLabItemsInstrumentCommand: RestoreLabItemsInstrumentCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.code,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRestoreInstrumentPost$Json$Response({
        experimentId: restoreLabItemsInstrumentCommand.experimentId,
        body: restoreLabItemsInstrumentCommand
      })
      .subscribe({
        next: (response) => {
          if (response.body.notifications.notifications.length == 0) {
            this.processRestoredInstrument(
              response.body.labItemsInstrumentRestoredEventNotification
            );
          }
        }
      });
  }

  private confirmThenRestoreConsumable(item: labItemType) {
    this.sendInputStatus(
      LockType.lock,
      this.consumableRemovedRowsGridId,
      (item as Consumable).itemReference ?? '',
      this.consumableRestoreActionId
    );
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreConsumable(item as LabItemConsumableDataSource);
      },
      reject: () => {
        this.sendInputStatus(
          LockType.unlock,
          this.consumableRemovedRowsGridId,
          (item as Consumable).itemReference ?? '',
          this.consumableRestoreActionId
        );
      }
    });
  }

  private sendRequestToRestoreConsumable(item: LabItemConsumableDataSource) {
    const restoreLabItemsConsumableCommand: RestoreLabItemsConsumableCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: item.itemReference,
      rowIndex: item.id,
      experimentId: this.experimentService.currentExperiment!.id
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRestoreConsumablePost$Json$Response({
        experimentId: restoreLabItemsConsumableCommand.experimentId,
        body: restoreLabItemsConsumableCommand
      })
      .subscribe({
        next: (response) => {
          if (response.body.notifications.notifications.length === 0) {
            this.processRestoredConsumable(
              response.body.labItemsConsumableRestoredEventNotification
            );
          }
        }
      });
  }

  saveNewPreference(
    $event: PreferenceAdded,
    puid: string,
    savedPreferences: BptGridPreferences,
    grid: BptGridComponent,
    controlId: string
  ): void {
    this.gridPreferenceService.saveNewPreference($event, puid, savedPreferences, grid, controlId);
  }

  deletePreference($event: PreferenceDeleted): void {
    this.gridPreferenceService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated, puid: string, id: string): void {
    this.gridPreferenceService.updatePreference($event, puid, id);
  }

  changeDefaultPreference($event: PreferenceSelectionChanged, puid: string, id: string): void {
    this.gridPreferenceService.changeDefaultPreference($event, puid, id);
  }

  private failedToRefreshMessage(itemReference: string, itemType: ActivityInputType) {
    this.createNotificationMessage(
      $localize`:@@LabItemsRefreshFailMessage:The ${
        LabItemsService.labItemsLabelsByItemType(itemType).singularHeading
      } for the lab item have failed to refresh, please contact administrator.`,
      `${itemReference}`,
      'error'
    );
  }

  private upToDateMessage(itemReference: string, itemType: ActivityInputType) {
    this.createNotificationMessage(
      $localize`:@@LabItemsRefreshUpToDateMessage:The data on the ${LabItemsService.labItemsLabelsByItemType(itemType).singularHeading} for the lab item is already up to date.`,
      `${itemReference}`,
      'info'
    );
  }

  private removedSuccessMessage(itemReference: string, itemType: ActivityInputType) {
    const successMessage = $localize`:@@LabItemRemovedSuccessMessage:The Lab Item ${
      LabItemsService.labItemsLabelsByItemType(itemType).singularHeading
    } has been removed successfully.`;
    const reference = itemType === ActivityInputType.Consumable ? '' : itemReference;
    this.createNotificationMessage(successMessage, `${reference}`, 'success');
  }

  doesRecipePromptTypeExist(promptType: PromptType): boolean {
    const promptsData = this.experimentService?.currentExperiment?.activityPrompts?.find(p => p.activityId === this.experimentService.currentActivityId);
    const promptTypeExist = promptsData?.prompts?.filter((p: PromptItem) =>p.type === promptType);
    if (promptTypeExist) return promptTypeExist.length > 0
    return false;
  }

  private restoredSuccessMessage(itemReference: string, itemType: ActivityInputType) {
    const successMessage = $localize`:@@LabItemRestoredSuccessMessage:The Lab Item ${
      LabItemsService.labItemsLabelsByItemType(itemType).singularHeading
    } has been restored successfully.`;
    const reference = itemType === ActivityInputType.Consumable ? '' : itemReference;
    this.createNotificationMessage(successMessage, `${reference}`, 'success');
  }

  private refreshedSuccessMessage(itemReference: string, itemType: ActivityInputType) {
    this.createNotificationMessage(
      $localize`:@@LabItemsRefreshSuccessMessage:The Lab Item ${LabItemsService.labItemsLabelsByItemType(itemType).singularHeading} has been refreshed successfully.`,
      `${itemReference}`,
      'success'
    );
  }

  private failedToPerformOperationMessage(itemReference: string, itemType: ActivityInputType) {
    this.createNotificationMessage(
      $localize`:@@LabItemsFailedToPerformOperationMessage:The action on the Lab Item ${
        LabItemsService.labItemsLabelsByItemType(itemType).singularHeading
      } cannot be performed because the experiment workflow state is ${
        this.experimentService.currentExperiment?.workflowState
      }.`,
      `${itemReference}`,
      'error'
    );
  }

  private cellChangedSuccessMessage(itemReference: string, itemType: ActivityInputType) {
    this.createNotificationMessage(
      $localize`:@@LabItemsCellChangeSuccessMessage:Value Changed successfully for Lab Item ${LabItemsService.labItemsLabelsByItemType(itemType).singularHeading}`,
      `${itemReference}`
    );
  }

  private MaterialAlreadyPresentInStudyActivityMessage(
    itemReference: string,
    itemType: ActivityInputType
  ) {
    this.createNotificationMessage(
      $localize`:@@MaterialAlreadyPresentInStudyActivityMessage:The ${
        LabItemsService.labItemsLabelsByItemType(itemType).singularHeading
      } is found to be included in the activity inputs, please exclude the material using refresh or remove`,
      `${itemReference}`,
      'warn'
    );
  }

  private MaterialRemovedDueToStudyActivityPresentMessage(
    itemReference: string,
    itemType: ActivityInputType
  ) {
    this.createNotificationMessage(
      $localize`:@@MaterialRemovedDueToStudyActivityPresentMessage:The ${LabItemsService.labItemsLabelsByItemType(
        itemType
    ).singularHeading} is removed due to study activities associated. Please refer removed rows for more information`,
      `${itemReference}`,
      'warn'
    );
  }

  private getItemReference(cellChangedNotification: LabItemsCellChangedEventNotification): string {
    switch (cellChangedNotification.itemType) {
      case ActivityInputType.Material:
      case ActivityInputType.InstrumentColumn:
      case ActivityInputType.InstrumentDetails:
        return cellChangedNotification.itemReference;
      case ActivityInputType.Consumable:
        return '';
      default:
        return cellChangedNotification.itemReference;
    }
  }

  private isApplyCellLockForRowActions(columnName: string) {
    return this.rowActionItems.includes(columnName);
  }

  private lockActionItems(lock: CellLock, renderer: Renderer2, cell: any, name: string) {
    if (lock.lockType === LockType.lock) {
      renderer.setAttribute(cell.parentElement, 'title', name);
      renderer.setStyle(cell, this.colorString, '#999999');
      renderer.setStyle(cell, 'opacity', `0.5`);
      renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      renderer.removeStyle(cell, this.colorString);
      renderer.removeAttribute(cell.parentElement, 'title');
      renderer.setStyle(cell, 'opacity', `1`);
      renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }

  public sendInputStatus(
    lockType: LockType,
    tableId: string,
    rowId: string,
    columnName: string,
    parentId?: string
  ) {
    const fieldLock = new CellLock(
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id,
      lockType,
      this.experimentService.currentModuleId,
      this.experimentService.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      tableId,
      rowId,
      columnName,
      parentId
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  public loadCellLocks(gridOptions: GridOptions, renderer: Renderer2) {
    const cellLocks = this.experimentNotificationService.inputLocks.filter(
      (item) =>
        item.lockType === LockType.lock &&
        (item as CellLock).activityId === this.experimentService.currentActivityId
    );

    this.applyCellLock(cellLocks as Array<CellLock>, gridOptions, renderer);
  }

  private getLabItemRowData(itemReference: string, tableData: { [key: string]: any }[]) {
    return tableData.find((t) => t.itemReference === itemReference || t.code === itemReference)?.tableData as Array<{
      [key: string]: any;
    }>;
  }

  public getSeverityIndicatorDefinition = (
    data: { [key: string]: any }[],
    params: ICellRendererParams
  ): any => {
    if (this.dataValueService.getExperimentDataValue(undefined, params.value)?.state === ValueState.Empty) {
      return { indicatorType: SeverityIndicatorType.Empty };
    }
    if (!params || !params.data || !params.colDef || !params.colDef.field) {
      return { indicatorType: '' };
    }

    const rowId = params.data.itemReference ?? params.data.code;
    const labItemData: Array<{ [key: string]: ModifiableDataValue }> = this.getLabItemRowData(rowId, data);
    const rowData = this.getRowData(labItemData);
    const changeReasonsNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    const fieldValue = rowData?.find(r => params?.colDef?.field && r.key === (params.colDef.field === 'AdditionalInformation' ? 'additionalInformation' : params.colDef.field))?.value;
    if (!!rowData && fieldValue && fieldValue.isModified) {
      const indicator = this.dataValidationService.validateChangeReasonForModifiedData(rowId, params.colDef.field, changeReasonsNode);
      if (indicator.indicatorType === '' as SeverityIndicatorType) return { indicatorType: SeverityIndicatorType.Modified };
      return indicator;
    }
    return { indicatorType: '' };
}

  private getRowData(labItemData: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    return labItemData.map((item) => {
      return {
        key: lowerFirst(Object.getOwnPropertyNames(item)[0]),
        value: item[Object.getOwnPropertyNames(item)[0]]
      };
    });
  }

  public applyCellLock(lockList: CellLock[], gridOptions: GridOptions, renderer: Renderer2) {
    lockList.forEach((lock) => {
      if (this.experimentService.currentActivityId !== lock.activityId) return;
      if (gridOptions && !gridOptions?.context?.inputLocks) {
        gridOptions.context = { inputLocks: {} };
        gridOptions.context.inputLocks[lock.rowId + lock.columnName] = lock.lockType === LockType.lock;
      };

      const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
        lock.experimentCollaborator.connectionId
      );

      lock.experimentCollaborator = lockOwner ?? lock.experimentCollaborator;
      const name = lockOwner?.fullName ?? lock.experimentCollaborator.firstName;

      if (this.isApplyCellLockForRowActions(lock.columnName)) {
        const actionCell = document.querySelector(
          `ag-grid-angular [row-id="${lock.rowId}"] [id="${lock.columnName}"] `
        );
        if (!actionCell) return;
        this.lockActionItems(lock, renderer, actionCell, name);
        return;
      }

      let cell = document.querySelector(
        `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"] `
      );
      if(!cell && lock.rowId.includes('PRP')) {
        cell = this.getPreparationCell(lock);
      }

      this.lockEditableCells(lock, renderer)
      if (!cell) return;

      if (lock.lockType === LockType.lock) {
        const borderColor = lockOwner?.backgroundColor ?? 'blue';
        renderer.setAttribute(cell.parentElement, 'title', name);
        renderer.setStyle(cell, this.backgroundColorString, '#F9F9F9');
        renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
        renderer.setStyle(cell, this.pointerEventsAttr, `none`);
      } else {
        renderer.removeStyle(cell, 'border');
        renderer.removeStyle(cell, this.backgroundColorString);
        renderer.removeAttribute(cell.parentElement, 'title');
        renderer.removeStyle(cell, this.pointerEventsAttr);
      }
    });
  }

  private getPreparationCell(lock: CellLock): Element | null {
    let preparationId = '';
    this.experimentService?.currentExperiment?.activityLabItems.forEach((activityLabItem: ActivityLabItemsNode) => {
      activityLabItem.preparations.forEach((preparation: ExperimentPreparation) => {
        if(preparation.preparationNumber === lock.rowId) {
          preparationId = preparation.preparationId;
        }
      })
    });
    return document.querySelector(
      `ag-grid-angular [row-id="${preparationId}"] [col-id="${lock.columnName}"] `
    );
  }

  private lockEditableCells(lock: CellLock, renderer: Renderer2) {
    const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
      lock.experimentCollaborator.connectionId
    ) ?? lock.experimentCollaborator;
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"] `
    );
    if (!cell) return;

    if (lock.lockType === LockType.lock) {
      const borderColor = lockOwner?.backgroundColor ?? 'blue';
      renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      renderer.setStyle(cell, this.backgroundColorString, '#F9F9F9');
      renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
      renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      renderer.removeStyle(cell, 'border');
      renderer.removeStyle(cell, this.backgroundColorString);
      renderer.removeAttribute(cell.parentElement, 'title');
      renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }


  public static getExclamationIndicator(tooltip: string): BptControlSeverityIndicator {
    return {
      indicatorType: SeverityIndicatorType.Exclamation,
      indicatorDefinition: {
        ...BptControlSeverityIndicators.ServerityIndicators[SeverityIndicatorType.Exclamation],
        Tooltip: tooltip,
        IsCustomTooltip: true
      }
    };
  }

  static labItemsLabelsByItemType(itemType: string): LabItemLabelsByType {
    switch (itemType) {
      case 'material':
        return {
          heading: $localize`:@@LabItemsMaterialsTableTitle:Materials`,
          singularHeading: $localize`:@@LabItemsMaterialTableTitle:Material`,
          restoredTooltip: $localize`:@@restoreMaterialTooltip:Restore Material`,
          refreshTooltip: $localize`:@@refreshMaterialTooltip:Refresh Material`,
          removedTooltip: $localize`:@@removeMaterialTooltip:Remove Material`,
        } as LabItemLabelsByType;
      case 'instrumentDetails':
        return {
          heading: $localize`:@@LabItemsInstrumentsTableTitle:Instruments`,
          singularHeading: $localize`:@@LabItemsInstrumentTableTitle:Instrument`,
          restoredTooltip: $localize`:@@restoreInstrumentTooltip:Restore Instrument`,
          refreshTooltip: $localize`:@@refreshInstrumentTooltip:Refresh Instrument`,
          removedTooltip: $localize`:@@removeInstrumentTooltip:Remove Instrument`
        };
      case 'instrumentColumn':
        return {
          heading: $localize`:@@LabItemsColumnsTableTitle:Columns`,
          singularHeading: $localize`:@@LabItemsColumnTableTitle:Column`,
          restoredTooltip: $localize`:@@restoreColumnTooltip:Restore Column`,
          refreshTooltip: $localize`:@@refreshColumnTooltip:Refresh Column`,
          removedTooltip: $localize`:@@removeColumnTooltip:Remove Column`
        };
      case 'consumable':
        return {
          heading: $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`,
          singularHeading: '',
          restoredTooltip: '',
          refreshTooltip: '',
          removedTooltip: ''
        };
      default:
        return {} as LabItemLabelsByType;
    }
  }
}

export type ChangeLabItemsCellCommand = {
  activityId: string;
  experimentId: string;
  itemReference: string;
  itemType: ActivityInputType;
  propertyName: string;
  propertyValue:
    | StringValue
    | StringArrayValue
    | NumberValue
    | InstantValue
    | LocalDateValue
    | SpecificationValue;
  changeReasonId?: string;
};
