import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridMode,
  BptGridPreferences,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration,
  BptRowActionElement,
  ColumnDefinition,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated,
  GridContextMenuItem,
  IFlagConfig,
  ISeverityIndicatorConfig,
  BptGridValuesPastedEvent,
} from 'bpt-ui-library/bpt-grid';
import {
    LabItemsConsumableAddedNotification
} from '../../../../app/api/data-entry/models';
import { ActivityInputType, ClientFacingNoteContextType, ColumnSpecification, ExperimentWorkflowState, NumberValue } from '../../../../app/api/models';
import { AddLabItemsConsumableCommand } from '../../../../app/api/data-entry/models/add-lab-items-consumable-command';
import { Consumable } from '../../../../app/api/models/consumable';
import { ExperimentService } from '../../services/experiment.service';
import { LabItemsService } from '../lab-items.service';
import { LabItemsConsumablesTableOptions } from './lab-items-consumable-table-options';
import * as uuid from 'uuid';
import { BaseComponent } from '../../../../app/base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnsubscribeAll } from '../../../../app/shared/rx-js-helpers';
import { DataRecordService } from '../../services/data-record.service';
import { BehaviorSubject, filter } from 'rxjs';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { GridPreferenceService } from '../../services/grid-preference.service';
import { UserPreferenceService } from '../../../api/services';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { FlagRendererService } from '../../services/flag-renderer.service';
import { CommentContextType } from '../../../../app/api/internal-comment/models';
import { Experiment } from 'model/experiment.interface';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-community';

import { DialogService } from 'primeng/dynamicdialog';
import { LabItemsRemovedConsumableComponent } from './lab-items-removed-consumable/lab-items-removed-consumable.component';
import { CellDataForCompletionTracking } from '../../model/cell-data-for-completion-tracking.interface';
import { ModifiableDataValue, ValueState } from '../../../api/models';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { FillWithNaGridHelperForGrids, NaGridTableIdentifierDataForGrids } from '../../shared/fill-with-na-helper';
import { PromptType } from '../../../prompt/models/prompt.model';


@Component({
  selector: 'app-lab-items-consumable',
  templateUrl: './lab-items-consumable.component.html',
  styleUrls: ['./lab-items-consumable.component.scss']
})
export class LabItemsConsumableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsConsumablesTableTitle:Consumables & Supplies `;
  @Output() cellEditedEvent = new EventEmitter<{ rowId: string, editedFields: { [key: string]: string } }>();
  @ViewChild('labItemsConsumableGrid') labItemsConsumableGrid!: BptGridComponent;

  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  containsRemovedRows = false;
  labItemsConsumableDataSource: Consumable[] = [];
  labItemsConsumableId!: string;
  LabItemConsumableSavedPreferences!: BptGridPreferences;
  allowRowAdd = true;
  reloadGrid = true;
  lockTimeOut = 0;
  backgroundColorString = 'background-color';
  consumableId = 'b3dee1b2-7ece-4262-8b02-b74e9f665188';
  experiment!: Experiment;
  completionPercent = 100;
  cellDataForCompletionTracking: CellDataForCompletionTracking[] = [];
  user!: User;
  gridActions!: BptGridRowActionConfiguration;
  columnDataFieldIds: string[] = [];
  isLabItemConsumableGridVisible = false;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService,
    private readonly labItemsService: LabItemsService,
    private readonly fillWithNaGridHelper: FillWithNaGridHelperForGrids,
    private readonly dataRecordService: DataRecordService,
    private readonly experimentWarningService:ExperimentWarningService,
    private readonly preferenceService: UserPreferenceService,
    private readonly gridPreferenceService: GridPreferenceService,
    private readonly router: Router,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly flagRendererService: FlagRendererService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly dialogService: DialogService
  ) {
    super(clientStateService, activatedRoute);
    this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.labItemsService.applyCellLock(
        lock,
        this.labItemsConsumableGrid.gridOptions,
        this.renderer
      );
    });
  }
  ngOnInit(): void {
    this.initializeGridRowActions();
    this.labItemsConsumableId = this.labItemsService.consumableGridId;
    this.user = { ...this.userService.currentUser };
    this.allowRowAdd =
      (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.Setup) ||
      (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InProgress) ||
      (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InCorrection);
    this.columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(
      this.allowRowAdd,
      this.severityIndicatorConfig,
      this.labItemsService.doesRecipePromptTypeExist(PromptType.ConsumablesAndSupplies),
      this.experimentService.currentExperiment?.workflowState
    );
    this.columnDataFieldIds = this.columnDefinitions.map((c: ColumnDefinition) => c.field as string) ?? [];
    this.refreshDataSource();
    this.populateCellsForCompletionTracking();
    this.completionTracking();
    this.loadGridPreferences();
    this.watchAddRowChanges();
    this.watchRouterPathChanges();
    this.watchConsumableAddedNotification();
    this.watchConsumableUpdatedNotification();
    this.watchConsumableRefreshNotification();
    this.watchWorkFlowStateChanges();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.consumableId);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.titleOfTable);
    this.watchClientFacingNoteNotification();
    this.watchInternalCommentsNotification();
    this.augmentColumnsWithCornerFlagProviderForCells.bind(this);

    this.flagRendererService.ClientFacingNoteAdded.subscribe({
      next: (note) => {
        this.elementRef.nativeElement.dispatchEvent(note);
      }
    });
    this.experimentService.changeReasonSliderDisplayDetails.subscribe({
      next: (data) => {
        if(data.isVisible) return;
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, this.labItemsService.reloadGridTimeout);
      }
    });
    this.currentUserRoleActionAssignment();
  }

  private currentUserRoleActionAssignment() {
    if (this.userService.hasOnlyReviewerRights()) {
      this.gridMode = BptGridMode.dataView;
    }
    this.columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(
      this.allowRowAdd,
      this.severityIndicatorConfig,
      this.labItemsService.doesRecipePromptTypeExist(PromptType.ConsumablesAndSupplies),
      this.experimentService.currentExperiment?.workflowState
    );
  }


  disableAddingConsumables(): boolean {
    return this.labItemsService.disableButton;
  }

  private watchClientFacingNoteNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.experimentService.clientFacingNoteEvents.subscribe({
        next: () => {
          this.labItemsConsumableGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }

  private watchInternalCommentsNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.internalCommentsRefresh.subscribe({
        next: () => {
          this.labItemsConsumableGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
    this.experiment = this.labItemsService.experimentService.currentExperiment as Experiment;
  }

  onGridReady() {
    this.augmentColumnsWithCornerFlagProviderForCells();
  }

  private populateCellsForCompletionTracking() {
    this.cellDataForCompletionTracking = [] ;
    for (const row of this.labItemsConsumableDataSource) {
      for (const fieldId of this.columnDataFieldIds) {
         if (fieldId === 'itemReference' || fieldId === 'rowIndex' || fieldId === 'promptsName') continue;
          // If the field is Item Reference or RowIndex, we are not considering it for calculating the completion percentage as they are not editable.
        const cellValue: ModifiableDataValue | undefined =(row as any)[fieldId]
        this.cellDataForCompletionTracking.push({
          rowId: row.itemReference,
          columnName: fieldId,
          state: cellValue ? ValueState.Set :ValueState.Empty
        });
      }
    }
  }

  sendCompletionStatus() {
    this.calculateCompletionPercentage();
    if (this.completionPercent === 100) {
      this.experimentService.nodeCompletionStatus.next({
        title: this.titleOfTable,
        id: this.consumableId,
        isComplete: true
      });
    } else if (this.completionPercent < 100) {
      this.experimentService.nodeCompletionStatus.next({
        title: this.titleOfTable,
        id: this.consumableId,
        isComplete: false
      });
    }
  }

  fillWithNaMenuItem(): GridContextMenuItem {
    const args: NaGridTableIdentifierDataForGrids = {
      isCellEditable: this.isCellEditable.bind(this),
      postChangeCellCommand: this.postChangeCellCommand.bind(this),
      grid: this.labItemsConsumableGrid,
      table: this.labItemsConsumableDataSource,
      columnDefinitions: this.columnDefinitions,
      idFieldName: this.labItemsConsumableId
    };
    return this.fillWithNaGridHelper.getContextMenuOptionsOfFillWithNa(args);
  }

  private isCellEditable(params: EditableCallbackParams) {
    const columnName = params.colDef.field;
    const currentColumnDefinition = this.columnDefinitions.find(
      (data) => data.field === columnName
    );
    if (!(currentColumnDefinition as ColumnSpecification)?.editable) return false;
    return true;
  }

  postChangeCellCommand(rowId: string, changedValues: { [key: string]: string }) {
    Object.keys(changedValues).forEach(k => {
      const field = k;
      const cellValueChangedEvent: BptGridCellValueChangedEvent = {
        type: 'cellValueChanged',
        oldValue: '',
        newValue: changedValues[field],
        field: field,
        rowId: rowId,
        gridId: this.labItemsConsumableId
      };

      this.labItemsService.labItemsCellValueChanged(
        ActivityInputType.Consumable,
        cellValueChangedEvent,
        this.labItemsConsumableGrid
      );
    }
    )
  }

  calculateCompletionPercentage() {
    if (this.cellDataForCompletionTracking.length === 0) {
      this.completionPercent = 100;
    } else {
      const totalCells = this.cellDataForCompletionTracking.length;
      let emptyCells = 0;
      this.cellDataForCompletionTracking.forEach((cell) => {
        if (cell.state === ValueState.Empty || cell.state === undefined) {
          emptyCells += 1;
        }
      });
      this.completionPercent = Math.floor(((totalCells - emptyCells) / totalCells) * 100);
    }
  }

  completionTracking() {
    this.populateCellsForCompletionTracking();
    this.calculateCompletionPercentage();
    this.sendCompletionStatus();
  }

  getContextMenu(): GridContextMenuItem[] {
    return [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      this.fillWithNaMenuItem(),
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.labItemsConsumableGrid?.gridApi.getFocusedCell();
          if (cell) {
            const row = this.labItemsConsumableGrid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string; // template validation prevents undefined
            if (
              row &&
              row.data.id &&
              colDef?.field &&
              row.rowIndex != null &&
              typeof row.rowIndex !== 'undefined'
            ) {
              this.flagRendererService.showClientFacingNotes(
                [
                  row.data.id,
                  field,
                  this.labItemsService.experimentService.currentActivityId,
                  ActivityInputType.Consumable
                ],
                ClientFacingNoteContextType.LabItems,
                this.labItemsService.experimentService.currentActivityId
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        disabled: !this.experimentService.isClientFacingNoteEnabled()
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          const cell =
            this.labItemsConsumableGrid && this.labItemsConsumableGrid.gridApi.getFocusedCell();
          if (cell) {
            const row = this.labItemsConsumableGrid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field as string;
            const fieldName = LabItemsConsumablesTableOptions.ColumnDefinition[field].displayName;
            if (row && row.id && row.rowIndex != null && typeof row.rowIndex !== 'undefined')
            this.flagRendererService.showInternalComments(
                [
                  this.labItemsService.experimentService.currentActivityId,
                  fieldName,
                  row.data.itemReference as string,
                  field,
                  (row.rowIndex + 1).toString(),
                  ActivityInputType.Consumable
                ],
                CommentContextType.TableCell,
              );
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }

  private  augmentColumnsWithCornerFlagProviderForCells ()  {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ): IFlagConfig => {
      const id = flag ==='bottom-right' ? (this.labItemsConsumableDataSource.find((x: any) => x.id ===  rowId) as any).itemReference : rowId;
      const fieldName = LabItemsConsumablesTableOptions.ColumnDefinition[field].displayName;
      return this.flagRendererService.getFlagRendererConfig(
        flag,
        this.flagRendererService.getPathBasedOnFlag(flag, fieldName, id, field, CommentContextType.LabItems, ActivityInputType.Consumable),
        CommentContextType.LabItems,
        rowId
      );
    };
    this.columnDefinitions.forEach((c: ColumnDefinition) => {
      c.flagConfigProvider = flagConfigProvider;
    });
    return flagConfigProvider;
  }

  pasteValues(e: BptGridValuesPastedEvent) {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      if (e.cellChangedEvents) {
        e.cellChangedEvents.forEach(element => {
          this.cellValueChanged(element);
        });
      }
    }
  }

  loadGridPreferences(): void {
    this.preferenceService
      .userPreferencesUserPreferenceKeyGet$Json$Response({
        userPreferenceKey: this.labItemsConsumableId
      })
      .subscribe((res) => {
        this.LabItemConsumableSavedPreferences = {
          enabled: true,
          preferences: this.gridPreferenceService.buildGridPreference(res.body.userPreferences)
        };
        this.isLabItemConsumableGridVisible = (this.labItemsConsumableDataSource && this.labItemsConsumableDataSource.length > 0 && this.LabItemConsumableSavedPreferences !== null) || this.containsRemovedRows;
        if (this.labItemsConsumableGrid) {
          this.labItemsConsumableGrid.savedPreferences = this.LabItemConsumableSavedPreferences;
          this.labItemsConsumableGrid.applyPreviousSelectedPreference();
        }
      });
  }

  saveNewPreference($event: PreferenceAdded): void {
    this.labItemsService.saveNewPreference(
      $event,
      this.user.puid,
      this.LabItemConsumableSavedPreferences,
      this.labItemsConsumableGrid,
      this.labItemsConsumableId
    );
  }

  deletePreference($event: PreferenceDeleted): void {
    this.labItemsService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated): void {
    this.labItemsService.updatePreference($event, this.user.puid, this.labItemsConsumableId);
  }

  changeDefaultPreference($event: PreferenceSelectionChanged): void {
    this.labItemsService.changeDefaultPreference($event, this.user.puid, this.labItemsConsumableId);
  }

  public severityIndicatorConfig = () => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  public readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.labItemsService.getSeverityIndicatorDefinition(
      this.labItemsConsumableDataSource,
      params
    );
  };

  private watchAddRowChanges() {
    this.activeSubscriptions.push(
      this.experimentService.addLabItemsConsumable.subscribe({
        next: this.addNewRow.bind(this)
      })
    );
  }

  addNewRow() {
    this.labItemsService.disableButton = true;
    this.addRow({
      activityId: this.experimentService.currentActivityId,
      itemReference: uuid.v4()
    });
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(LabItemsRemovedConsumableComponent, {
      width: '80%',
      autoZIndex: true,
      height: '50%',
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@labitemsRemovedConsumables:Removed Consumables and Supplies`,
      styleClass: 'eln-removed-consumable-dialog'
    });
  }

  private watchRouterPathChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_route: { url: string }) => {
        if (_route.url.toLowerCase().includes('/labitems')) {
          this.refreshDataSource();
        }
      });
  }

  private readonly watchConsumableUpdatedNotification = (): void => {
    this.activeSubscriptions.push(
      this.labItemsService.ConsumableUpdated.subscribe({
        next: this.updateConsumableIntoGrid.bind(this)
      })
    );
  };

  private readonly watchConsumableAddedNotification = (): void => {
    this.activeSubscriptions.push(
      this.labItemsService.ConsumableAdded.subscribe({
        next: this.addNewConsumableIntoGrid.bind(this)
      })
    );
  };

  private watchConsumableRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.consumableRefresh.subscribe({
        next: () => {
         this.refreshDataSource();
         this.completionTracking();
        }
      })
    );
  }

  private watchWorkFlowStateChanges() {
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {
        this.allowRowAdd = (state === ExperimentWorkflowState.Setup) ||
          (state === ExperimentWorkflowState.InProgress) ||
          (state === ExperimentWorkflowState.InCorrection);
          this.sendCompletionStatus();
        this.columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(
          this.allowRowAdd,
          this.severityIndicatorConfig,
          this.labItemsService.doesRecipePromptTypeExist(PromptType.ConsumablesAndSupplies),
          this.experimentService.currentExperiment?.workflowState
        );
        this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
          this.labItemsConsumableGrid.updateColumnDefinitions(columnDefinition)
        });

        this.initializeGridRowActions();
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, this.labItemsService.reloadGridTimeout);
      }
    });
    this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe({
      next: (notification) => {
        this.allowRowAdd = notification.state === ExperimentWorkflowState.Setup;
        this.initializeGridRowActions();
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, this.labItemsService.reloadGridTimeout);
      }
    });
  }

  private addNewConsumableIntoGrid(item: Consumable): void {
    this.experimentService.labItemsConsumableAdded.next();
    this.labItemsConsumableDataSource.push(item);
    this.refreshDataSource();
    this.labItemsConsumableGrid?.gridApi?.setGridOption('rowData', this.labItemsConsumableDataSource);
    this.labItemsConsumableGrid?.gridApi?.refreshCells({ force: true });
    this.completionTracking();
  }

  private updateConsumableIntoGrid(item: Consumable): void {
    if (!!this.labItemsConsumableDataSource && this.labItemsConsumableDataSource.length === 0) {
      this.labItemsConsumableDataSource.push(item);
      this.experimentService.labItemsConsumableAdded.next();
    }
    this.refreshDataSource();
    this.labItemsConsumableGrid?.gridApi.applyTransaction({
      update: [item]
    });
    this.populateCellsForCompletionTracking();
    this.calculateCompletionPercentage();
    this.sendCompletionStatus();
  }

  private refreshDataSource(): void {
    const consumables = this.labItemsService.getLabItemsConsumables();

    if (!!consumables && consumables.length > 0) {
      this.experimentService.labItemsConsumableAdded.next();
    }

    consumables.forEach((consumable) => {
      this.labItemsService.unpackModifiableDataValues<Consumable>(
        consumable,
        consumable.tableData,
        this.columnDefinitions,
        ActivityInputType.Consumable
      );
      const fields = consumable?.tableData.filter((r: any) => !!r.rowIndex);
              if(fields)
              {
                 const rowIndexField = fields[0];
                 const rowIndex = (rowIndexField?.rowIndex.value as NumberValue).value;
                 if(rowIndex){
                  (consumable as any).id = (parseInt(rowIndex)).toString();
                 }
              }

    });
    this.labItemsConsumableDataSource = [...consumables];
    this.labItemsConsumableGrid?.gridApi?.setGridOption('rowData', this.labItemsConsumableDataSource);
    this.containsRemovedRows = this.labItemsService.getLabItemsRemovedConsumables().length > 0;
    this.sendCompletionStatus();
    this.populateCellsForCompletionTracking();
    this.isLabItemConsumableGridVisible = (this.labItemsConsumableDataSource && this.labItemsConsumableDataSource.length > 0) || this.containsRemovedRows;
   }

  rowsAdded(_event: any): void {
    this.addNewRow();
  }

  cellValueChanged($cellValueChanged: BptGridCellValueChangedEvent): void {
    this.cellEditedEvent.emit({ rowId: $cellValueChanged.rowId as string, editedFields: { [$cellValueChanged.field as string]: $cellValueChanged.newValue } });
    this.labItemsService.labItemsCellValueChanged(
      ActivityInputType.Consumable,
      $cellValueChanged,
      this.labItemsConsumableGrid
    );
    this.completionTracking();
  }

  public addRow(row: AddLabItemsConsumableCommand): void {
    const addLabItemsConsumableCommand: AddLabItemsConsumableCommand = {
      activityId: this.experimentService.currentActivityId,
      itemReference: row.itemReference
    };
    this.labItemsService.addLabItemsConsumable(addLabItemsConsumableCommand, this.addRowInGrid);
  }

  private readonly addRowInGrid = (notification: LabItemsConsumableAddedNotification): void => {
    const rowData = notification.tableData.filter((p)=> p.rowIndex)[0];
    const rowIndex = (rowData?.rowIndex.value as NumberValue)?.value as string;
    const consumableId = (parseInt(rowIndex)).toString();
    this.labItemsConsumableDataSource = this.labItemsConsumableDataSource || [];
    const newConsumable = {
      activityId: notification.activityId,
      catalogNumber: notification.catalogNumber,
      details: notification.details,
      itemReference: notification.itemReference,
      lotNumber: notification.lotNumber,
      manufacturer: notification.manufacturer,
      name: notification.name,
      partNumber: notification.partNumber,
      tableData: notification.tableData,
      id: consumableId,
      isRemoved:false
    };
    this.labItemsService.unpackModifiableDataValues<Consumable>(
      newConsumable,
      newConsumable.tableData,
      this.columnDefinitions,
      ActivityInputType.Consumable
    );
    this.labItemsConsumableDataSource.push(newConsumable);
    this.labItemsConsumableGrid?.gridApi.applyTransaction({
      add: [newConsumable]
    });
    this.experimentService.labItemsConsumableAdded.next();
    this.refreshDataSource();
    this.completionTracking();
    this.labItemsService.disableButton = false;
  };

  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.Consumable);
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    this.lockTimeOut = window.setTimeout(() => {
      this.labItemsService.sendInputStatus(
        LockType.lock,
        e.gridId ?? '',
        e.rowId ?? '',
        e.field ?? ''
      );
    }, 500);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.labItemsConsumableGrid.gridApi.clearFocusedCell();
    this.labItemsService.sendInputStatus(
      LockType.unlock,
      e.gridId ?? '',
      e.rowId ?? '',
      e.field ?? ''
    );
  }

  onFirstDataRendered(e: any) {
    this.labItemsService.loadCellLocks(this.labItemsConsumableGrid.gridOptions, this.renderer);
  }

  private initializeGridRowActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.consumableDeleteActionId,
        enabled: this.isUserPermittedToEdit(),
        styleClass: 'far fa-trash-alt',
        click: this.rowDeleteActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      }
    ];
  }

  isUserPermittedToEdit(): boolean {
    if (this.labItemsService.getRowActionPermission() && !(this.userService.hasOnlyReviewerRights())) {
      return true;
    }
    return false;
  }

  private rowDeleteActionClick(e: BptGridRowActionClickEvent) {
    this.labItemsService.removeHandler[ActivityInputType.Consumable](e.params.data);
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }
}
