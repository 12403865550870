import { Injectable } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivityChangeReasonNode, ValueState } from '../../api/models';
import { DataValueService } from './data-value.service';
import { BptControlSeverityIndicator, BptControlSeverityIndicatorDefinition, SeverityIndicatorType } from 'bpt-ui-library/shared';
import { ModifiableDataFields, TableValueRow } from '../../model/experiment.interface';
import { TableDataService } from '../data/table/table-data.service';
import { ExperimentDataSource } from '../../api/data-entry/models/experiment-data-source';

@Injectable({
  providedIn: 'root'
})
export class DataValidationsService {
  constructor(private readonly dataValueService: DataValueService) { }

  /**
   * Returns a an object of type SeverityIndicatorType which maps to a styleClass and tooltip.
   *
   * Any new severity-indicator icon should be added to bpt-control-severity-indicator
   * a cell changed event and computes if the class should be applied to the cell.
   *
   * @param tableData pass in the array of rows (e.g. table.value from the experiment model)
   * @param params cell renderer params to identify the column
   */
  public getSeverityIndicatorDefinition = (data: TableValueRow[], params: ICellRendererParams, activityChangeReasonNode?: ActivityChangeReasonNode): BptControlSeverityIndicator => {
    const noIndicator = { indicatorType: '' as SeverityIndicatorType };
    const row = data.find(r => r.id === params.node.data.id);
    if (!row) return { indicatorType: SeverityIndicatorType.Empty }; // row not in model yet but value is missing if incoming value is undefined or null
    const field = params.colDef?.field;
    if (!field) return noIndicator; // can't happen

    if (field === 'id') return noIndicator;

    const cellValue = row[field as keyof ModifiableDataFields];
    if (TableDataService.rowIsPlaceholder(row)) {

      if (cellValue?.value?.state !== ValueState.Empty && cellValue?.value?.source === ExperimentDataSource.Recipe) {
        /**
           * Control comes here values when value doesn't contain experiment data or data entry fields and those values are filled in recipe only
           * One usage is Repeat for Each rows from recipe application.
           * when values are filled in recipe by right click selecting fill N/A All Cells or N/A Empty Cells.
           * Main Constrain Source should be recipe for hitting control here.
           */
        return { indicatorType: SeverityIndicatorType.boldPrimaryBlue };
      }
      return noIndicator;
    }

    if (!cellValue || cellValue.value.state === ValueState.Empty) return { indicatorType: SeverityIndicatorType.Empty };

    if (cellValue.isModified) {
      const indicator = this.validateChangeReasonForModifiedData(row.id, field, activityChangeReasonNode);
      if(indicator.indicatorType !== '' as SeverityIndicatorType) return indicator;
      return { indicatorType: SeverityIndicatorType.Modified };
    }

    //Here when soure is recipe it means values is already filled in recipe.

    if (cellValue.value?.source === ExperimentDataSource.Recipe) {
      return { indicatorType: SeverityIndicatorType.boldPrimaryBlue }
    }
    return noIndicator;
  }

  public validateChangeReasonForModifiedData(rowId: string, field: string, activityChangeReasonNode?: ActivityChangeReasonNode): BptControlSeverityIndicator {
    const noIndicator = { indicatorType: '' as SeverityIndicatorType };

    if(!activityChangeReasonNode) return noIndicator;
    const changeReasonsNotAvailableForCell = Object.values(activityChangeReasonNode.changeReasonAvailabilityMapping).find(c => c.contextPath.includes(rowId) && c.contextPath.includes(field) && !c.isMapped);
    if(changeReasonsNotAvailableForCell) {
      const invalidChangeReasonIndicator: BptControlSeverityIndicatorDefinition =  {
        Tooltip: 'Missing Change Reason',
        StyleClass: 'bpt-validation-error pi pi-info-circle'
      }
      return {
        indicatorType: SeverityIndicatorType.Modified,
        indicatorDefinition: invalidChangeReasonIndicator
      }
    }
    return noIndicator;
  }
}
