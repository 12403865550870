import { isEqual } from 'lodash-es';
import { ModifiableDataValue, NumberValue, ValueState, ValueType } from '../../../api/models';
import { Quantity } from 'bpt-ui-library/shared';
import { Injectable } from '@angular/core';

/** TODO: This should be updated to be Generic like `TableDataService` someday */
@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  constructor() { }

  quantityEdited = (oldValue: ModifiableDataValue | undefined, newQuantity: Quantity, modelEdited: (newValue: any, isEmpty?: boolean) => void, isEmpty = false) => {
    // event is fired too much
    if (isEqual(newQuantity, oldValue)) return;
    if (!oldValue && newQuantity.state === ValueState.Empty) return;

    // need to remove the nonsensical properties for empty or N/A states that bpt-quantity might give.
    if (newQuantity.state === ValueState.Empty) {
      delete newQuantity.exact;
      delete newQuantity.sigFigs;
      delete newQuantity.value;
    } else if (newQuantity.state === ValueState.NotApplicable) {
      delete newQuantity.exact;
      delete newQuantity.sigFigs;
      delete newQuantity.unitDetails;
      delete newQuantity.value;
    }

    if (isEqual(newQuantity, oldValue)) return; // check again due above tweak

    if (!oldValue) {
      modelEdited(newQuantity, isEmpty);
      return;
    }

    // If not technically equal, we need to drill into the properties we care about
    if (oldValue.value.type !== ValueType.Number) throw new Error("LOGIC ERROR: shouldn't get here. Plumbing is wrong.");

    const thisNumber = oldValue.value as NumberValue;
    const compare = (left: any, right: any) => left === right || !left && !right; // If both left and right are falsey, consider that a match (null vs. undefined, etc)
    if (
      thisNumber &&
      compare(newQuantity.type, thisNumber.type) &&
      compare(newQuantity.state, thisNumber.state) &&
      compare(newQuantity.value, thisNumber.value) &&
      compare(newQuantity.unit, thisNumber.unit) &&
      compare(newQuantity.sigFigs, thisNumber.sigFigs) &&
      compare(newQuantity.exact, thisNumber.exact)
    ) {
      return;
    }

    modelEdited(newQuantity, isEmpty);
  };
}
