import { Component, Input, OnDestroy, OnInit, AfterContentInit, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { Subject, Subscription, take } from 'rxjs';
import {
  ExperimentDataValue,
  ModifiableDataValue,
  FormItemType,
  NodeType,
  FieldType,
} from '../../../api/models';
import {
  FieldChangedEventNotification,
  ChangeFieldCommand,
  ExperimentWorkflowState,
  ExperimentEventType,
} from '../../../api/data-entry/models';
import { Experiment, FieldDefinition, FieldGroup, Form, FormItem } from '../../../model/experiment.interface';
import { DataRecordService } from '../../services/data-record.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { FieldLock, InputLock, LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ExperimentCollaboratorsService } from 'services/experiment-collaborators.service';
import { ExperimentService } from '../../services/experiment.service';
import { FieldDataForCompletionTracking } from '../../model/field-data-for-completion-tracking.interface';
import { AuditHistoryDataRecordResponse } from '../../../api/audit/models';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { RuleHandler } from '../../../rule-engine/rule-handler';
import {
  RuleActionNotificationService,
  SetFieldValueNotificationEvent,
} from '../../../rule-engine/action-notification/rule-action-notification.service';
import { RuleActionNotification, RuleNotificationContext } from '../../../rule-engine/actions/rule-action-notification';
import { CommentDetails } from '../../comments/comment.model';
import { CommentContextType } from '../../../api/internal-comment/models';
import { CommentService } from '../../comments/comment.service';
import { MenuItem } from 'primeng/api';
import { ExperimentNodeRetitleService } from '../../services/experiment-node-re-title.service';
import { CompletionTrackingService } from '../../../services/completion-tracking.services';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { EllipsisMenuBuilderHelper } from '../../../shared/ellipsis-menu-builder-helper';

type RuleCorrelatedEvent = string | undefined | RuleNotificationContext;
@Component({
  selector: 'app-data-form[form]',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Input() form!: Form;
  @Input() greatestTabOrder = 1;
  @Input() parentNodeId: string[] = [];

  private readonly subscriptions: Subscription[] = [];
  dynamicDialogRef!: DynamicDialogRef;
  internalCommentData?: CommentDetails;
  experiment!: Experiment;
  items!: MenuItem[];
  menuItems: MenuItem[] = [];
  isLoading = false;
  completionTrackingArray: FieldDataForCompletionTracking[] = [];
  fieldPathDictionary = new Map<string, string[]>();
  totalFields = 0;
  emptyFields = 0;
  completionPercent = 0;
  fieldsReady = 0;
  formIsReady = false;
  displayEllipseMenu = false;
  private readonly formNotificationSubject = new Subject<any>();
  formNotifications = this.formNotificationSubject.asObservable();
  public get RuleHandler(): RuleHandler {
    return this._ruleHandler;
  }
  private _ruleHandler!: RuleHandler;

  /** This reserved the additional tab order index for form. currently it reserves for audit history.
   * This count needs to increase when ever a new option is added for form.
   */
  public static readonly AdditionalTabOrderReservationCount = 1;

  retitleEnabled = false;
  nodeType = NodeType;

  get isOnOutputsPage(): boolean {
    return this.router.url.endsWith('/Outputs');
  }

  constructor(
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly notificationService: ExperimentNotificationService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly cdRef:ChangeDetectorRef,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly userService: UserService,
    private readonly experimentService: ExperimentService,
    private readonly commentService: CommentService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly completionTrackingService: CompletionTrackingService,
    private readonly ellipsisMenuBuilderHelper: EllipsisMenuBuilderHelper,
    private readonly router: Router
  ) {
    this.subscriptions.splice(
      0,
      0,
      this.dataRecordService.fieldChangedDataRecordReceiver.subscribe((data) =>
        this.applyFieldChangedDataRecord(data)
      ),
      this.notificationService.inputLockReceiver.subscribe((lock) => this.applyFieldLock(lock)),
      this.experimentService.experimentWorkFlowState.subscribe(
        (_state: ExperimentWorkflowState) => {
          this.buildContextMenuItems();
          this.buildEllipseMenuItem();
        }
      ),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        this.buildContextMenuItems();
      })
    );
    this.watchRuleActions();
  }

  ngOnInit(): void {
    this.buildContextMenuItems();
    this.buildEllipseMenuItem();
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.initializeRuleHandler();
    this.findNumberOfFields(this.form);
    this.buildFormPathDictionary([], this.form.fieldDefinitions);
    this.completionPercent = this.completionTrackingService.calculateFormCompletionPercentage(this.totalFields, this.formIsReady, this.form, this.completionTrackingArray);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.form.formId);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.form.itemTitle);
  }

  loadInternalCommentForFormLevel() {
    const activity = this.experiment.activities.find(
      (act) => act.activityId === this.experimentService.currentActivityId
    );
    const module = (activity?.dataModules ?? []).find(
      (mod) => mod.moduleId === this.experimentService.currentModuleId
    );
    this.internalCommentData = {} as CommentDetails;
    const nodeId = this.experimentService.currentExperiment?.id!;
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = [activity?.activityId, module?.moduleId, this.form.formId, CommentContextType.Module];
    this.internalCommentData.contextType = CommentContextType.Form;
    this.commentService.openInternalComments(this.internalCommentData) ;
  }
  ngAfterContentInit(): void {
    const formLock = this.notificationService.inputLocks.filter(
      (lock: InputLock) =>
        lock.lockType === LockType.lock && (lock as FieldLock).formId === this.form.formId
    );
    this.applyFieldLock(formLock as Array<FieldLock>);
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }
  /** Cast to FieldDefinition. For use in component template */
  asFieldDefinition = (item: FormItem) => item as FieldDefinition;

  /** Cast to FieldGroup. For use in component template */
  asFieldGroup = (item: FormItem) => item as FieldGroup;

  asFormItem = (item: FieldGroup | FieldDefinition) => item as FormItem;
  private initializeRuleHandler(): void {
    this._ruleHandler = new RuleHandler(
      this.form.formId,
      this.form.templateId,
      this.form.rules || [],
      this.ruleActionNotificationService
    );
  }
  private watchRuleActions() {
    this.watchRuleActionsOfFieldChange();
  }
  private watchRuleActionsOfFieldChange() {
    this.subscriptions.push(
      this.ruleActionNotificationService.SetFieldValueActionNotification.subscribe({
        next: this.settingFieldInstructionFromRule.bind(this)
      })
    );
  }
  private fieldChangedEventRuleEvaluation(changeFieldCommand: ChangeFieldCommand, eventSource: RuleCorrelatedEvent): string | undefined {
    return this._ruleHandler.fieldChanged(
      changeFieldCommand.path[changeFieldCommand.path.length - 1],
      { changeFieldCommand: changeFieldCommand, currentForm: this.form.value },
      eventSource
    )?.correlationId;
  }
  public settingFieldInstructionFromRule(
    notification: RuleActionNotification<SetFieldValueNotificationEvent>
  ) {
    console.log('Rule Notification', notification);
    if (notification.action.Context !== this.form.formId) {
      return;
    }
    const path = this.fieldPathDictionary.get(notification.action.Target);
    let formFieldPath: string[] = [];
    if (path) {
      formFieldPath = path;
    }
    notification.sourceEvent.path = formFieldPath;
    this.applyFieldChangedDataRecordRule(notification)
  }
  private applyFieldChangedDataRecordRule(data: RuleActionNotification<SetFieldValueNotificationEvent>) {
    if (data.sourceEvent.path && data.sourceEvent.event.templateInstanceId === this.form.formId) {
      this.formNotificationSubject.next(data);
    }
  }
  private applyFieldChangedDataRecord(data: FieldChangedEventNotification) {
    if (data.path && data.formId === this.form.formId) {
      this.formNotificationSubject.next(data);
      this.completionTrackingService.updateValueInFormCompletionTrackingArray(data.path, data.newValue, this.completionTrackingArray);
      this.completionPercent = this.completionTrackingService.calculateFormCompletionPercentage(this.totalFields, this.formIsReady, this.form, this.completionTrackingArray);
    }
  }

  applyFieldChangedEvent(command: ChangeFieldCommand) {
    this.updateField(command.path, command.newValue);
    this.fieldChangedEventRuleEvaluation(command, JSON.stringify(command.ruleContext));
    this.completionPercent = this.completionTrackingService.calculateFormCompletionPercentage(this.totalFields, this.formIsReady, this.form, this.completionTrackingArray);
  }

  private applyFieldLock(lockList: FieldLock[]) {
    lockList.forEach((lock) => {
      if (this.form.formId === lock.formId) {
        const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
          lock.experimentCollaborator.connectionId
        );
        lock.experimentCollaborator = lockOwner ?? lock.experimentCollaborator;
        this.formNotificationSubject.next(lock);
      }
    });
  }

  private updateField(fieldPath: string[], newFieldValue: ExperimentDataValue) {
    let field: any = this.form.value;
    for (let index = 0; index < fieldPath.length; index++) {
      const path = fieldPath[index];
      if (index === fieldPath.length - 1) {
        const oldValue = field[path] as ModifiableDataValue;
        field[path] = DataRecordService.getModifiableDataValue(newFieldValue, oldValue);
      } else {
        field[path] = field[path] ?? {};
        field = field[path] ?? {}; // empty object makes up for template incorrectly missing the form's structure
      }
    }
    this.completionTrackingService.updateValueInFormCompletionTrackingArray(fieldPath, newFieldValue, this.completionTrackingArray)
  }

  /**
   * Gets called to load audit history dialog
   */
  loadDialog() {
    this.isLoading = true;
   !this.form.formId.includes('EmpowerResultSet') ?
    this.auditHistoryService
      .loadFormAuditHistory(this.form.experimentId, this.form.formId)
      .subscribe({
        next: (data) => {
          this.getRecipeBlobsDetails(data);
        }
      })
    : this.loadHistoryDialog();
  }

  /**
   * Gets called to get recipe blob details before loading audit history dialog
   */
  private getRecipeBlobsDetails(records: AuditHistoryDataRecordResponse) {
    this.subscriptions.push(this.experimentService.areRecipeBlobDetailsFetched()
      .pipe(take(1))
      .subscribe({
        next: ((response) => {
          if (response) this.bindDataToAuditHistoryDialog(records);
        })
      }));
    this.experimentService.addRecipeAppliedEventBlobDetailsToCache(records);
  }

  onDoubleClick() {
    if(this.userService.hasOnlyReviewerRights() ||
    this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview) {
      return;
    }
    this.retitleEnabled = true;
  }

  private buildEllipseMenuItem() {
    this.menuItems = this.ellipsisMenuBuilderHelper.buildEllipseMenuItem(this.form.formId, true, false);
    this.displayEllipseMenu = this.menuItems.length > 0;
  }

  private bindDataToAuditHistoryDialog(data: AuditHistoryDataRecordResponse) {
    this.isLoading = false;
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      data.dataRecords.filter(dr => (dr.eventContext.eventType !== ExperimentEventType.ExperimentNodeOrderChanged)),
      this.form.itemTitle.concat(ELNAppConstants.WhiteSpace) + $localize`:@@Form:Form`,
      NodeType.Form,
      this.form.formId
    );
  }

  private findNumberOfFields(container: Form | FieldGroup) {
    container.fieldDefinitions.forEach((item) => {
      if (item.itemType === FormItemType.FieldGroup) {
        this.findNumberOfFields(item as FieldGroup);
      } else if (item.itemType === FormItemType.Field) {
        if((item as FieldDefinition).fieldType !== FieldType.Textblock)
          this.totalFields += 1;
      }
    });
  }

  applyFieldReadyEvent(eventObject: FieldDataForCompletionTracking) {
    if (eventObject.fieldType !== FieldType.Textblock) {
      this.completionTrackingArray.push(eventObject);
      this.fieldsReady += 1;
    }
    if (eventObject.value === undefined || eventObject.value === null || eventObject.isEmpty) {
      this.emptyFields += 1;
    }
    if (this.fieldsReady === this.totalFields) {
      this.formIsReady = true;
      this.completionPercent = this.completionTrackingService.calculateFormCompletionPercentage(this.totalFields, this.formIsReady, this.form, this.completionTrackingArray);
    }
  }
  private buildFormPathDictionary(path: string[], fieldDefinitions: any) {
    fieldDefinitions.forEach((fieldDefinition: any) => {
      if (fieldDefinition.itemType === 'fieldGroup') {
        path.push(fieldDefinition.field);
        this.buildFormPathDictionary(path, fieldDefinition.fieldDefinitions);
        path.pop();
      }
      if (fieldDefinition.itemType === 'field') {
        path.push(fieldDefinition.field);
        this.fieldPathDictionary.set(fieldDefinition.field, path.slice());
        path.pop();
      }
    });
  }

  private buildContextMenuItems(): void {
    this.items = [
      {
        label: $localize`:@@InternalComments:Internal Comments`,
        icon: 'pi pi-comments',
        id: `eln-context-form-title-${this.form.itemTitle.replace(new RegExp(' ', 'g'), '')}`,
        command: (_event$) => {
          this.loadInternalCommentForFormLevel();
        }
      },
      {
        label: $localize`:@reTitle:Retitle`,
        icon: 'icon-pencil',
        disabled: this.isRetitleOptionDisabled(),
        id: `eln-context-form-retitle-${this.form.itemTitle.replace(new RegExp(' ', 'g'), '')}`,
        command: (_event$) => {
          this.retitleEnabled = true;
        }
      }
    ];
  }

  private isRetitleOptionDisabled(): boolean {
    return this.userService.hasOnlyReviewerRights() ||
    !ExperimentNodeRetitleService.HasPermissionToEditTitle ||
    ExperimentNodeRetitleService.NotAllowedWorkflowStates.includes(
      this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
    );
  }

  private loadHistoryDialog() {
    this.isLoading = true;
    const activityId = this.form.formId.split("||")[1];
    this.auditHistoryService
      .loadActivityOutputsAuditHistory(this.form.experimentId, activityId)
      .subscribe({
        next: this.bindDataToOutputsFormAuditHistoryDialog.bind(this)
      });
  }

  private bindDataToOutputsFormAuditHistoryDialog(data: AuditHistoryDataRecordResponse) {
    this.isLoading = false;
    const resultSetId = this.form.formId.split("||")[3];
    const chromatographyDataId = this.form.formId.split("||")[2];
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      data.dataRecords.filter(dr => (dr.eventContext.eventType === ExperimentEventType.ChromatographyDataImported && (dr as any).chromatographyDataId === chromatographyDataId)),
      $localize`:@@empowerFormTitle:Empower Imported Results - ` + resultSetId
    );
  }
}
